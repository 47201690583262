import React from 'react';
import './App.css';

import {Link, NavLink} from 'react-router-dom';

import betterself_logo from './images/1x/betterself_logo.png';
import search_icon from './images/1x/betterself_search_icon.png';

import fb_logo from './images/1x/betterself_fb_logo.png';
import instagram_logo from './images/1x/betterself_instagram_logo.png';
import spotify_logo from './images/1x/betterself_spotify_logo2.png';

class MenuSup extends React.Component {

toggleMenu = function() {
    var x = window.document.getElementById("topNav");
    if (x.className === "Encabezado") {
        x.className += " responsive";
    } else {
        x.className = "Encabezado";
    }
}

closeMenu = function(){
    var x = window.document.getElementById("topNav");
    x.className = "Encabezado";
}

componentDidUpdate(){
    console.log("updated");
    var x = window.document.getElementById("topNav");
    x.className = "Encabezado";
}


render(){
        return(
            <div className="nav-sup">
                <div  className="Encabezado" id="topNav">
                    <div className="logo-container">
                        <Link className="Link" onClick={this.closeMenu} to="/"><img className="logo" style={{width:'100%'}} src={betterself_logo} alt="Logo Betterself" /></Link>
                    </div>
                    <div className="menu-nav">
                        <NavLink onClick={this.closeMenu} className="Link texto-s" activeClassName="selected" to="/cosmetics">COSMÉTICA</NavLink>
                        <NavLink onClick={this.closeMenu} className="Link texto-s" activeClassName="selected" to="/lifestyle">ESTILO DE VIDA</NavLink> 
                        {false && <NavLink onClick={this.closeMenu} className="Link texto-s" activeClassName="selected" to="/diagnostic">TEST</NavLink>}
                    </div>
                    <div className="menu-follow">
                        <div className="follow-us">
                            <span className="texto-pink texto-s">SÍGUENOS</span>
                            <div className="footer-social" style={{alignItems:'center', justifyContent:'center'}}>
                                <a style={{display:'flex', alignItems:'center', justifyContent:'center'}} onClick={this.closeMenu} href="https://www.facebook.com/betterselfmx/" rel="noopener noreferrer" target="_blank"><img src={fb_logo} alt="Facebook logo"/></a>
                                <a style={{display:'flex', alignItems:'center', justifyContent:'center'}} onClick={this.closeMenu} href="https://instagram.com/betterselfmx?igshid=w2jo00zje5kx" rel="noopener noreferrer" target="_blank"><img src={instagram_logo} alt="Instagram logo"/></a>
                                <a style={{display:'flex', backgroundColor:'white', alignItems:'center', justifyContent:'center'}} onClick={this.closeMenu} href="https://open.spotify.com/show/3sYqYGwjF8dvb24z1oJmsR?si=CX9wAfSnTfKfFRfn0DUyeg" rel="noopener noreferrer" target="_blank"><img src={spotify_logo} alt="Instagram logo"/></a>                                
                            </div>                        </div>
                        <Link className="Link search" to="/"><img className="logo" src={search_icon} alt="Buscar" /></Link>
                    </div>
                </div>
                <button className="icon" onClick={this.toggleMenu}>
                        <i className="fas fa-2x fa-bars"></i>
                </button>
            </div>
        )
    }
}
export default MenuSup;

