import React from 'react';
import './App.css';

import MapContainer from './MapContainer';

import lifestyle from './data/lifestyle.js';

import BannerHeader from './BannerHeader.js';
import SoloTexto from './SoloTexto';
import Paciente from './Paciente';

import encabezado from './images/1x/betterself_banner_experts.jpg';
import default_user from './images/1x/betterself_default_user_image.jpg'
import search_icon from './images/1x/betterself_ico_search.png';

import doctors from './data/doctors.js';

import { ReactComponent as IconFacebook} from './images/icons/SVG/icon_facebook.svg';
import { ReactComponent as IconTwitter} from './images/icons/SVG/icon_twitter.svg';
import { ReactComponent as IconWeb } from './images/icons/SVG/icon_web.svg';
import { ReactComponent as IconInstagram } from './images/icons/SVG/icon_instagram.svg';

const socialIcons = {
  Web: IconWeb,
  Instagram: IconInstagram,
  Facebook: IconFacebook,
  Twitter: IconTwitter,
  default: IconWeb
};


class Especialist extends React.Component {

  state = {
    doctors: doctors,
    cp: ''
  }
  initialDoctors = [...doctors];
  constructor(props){
      super(props);
      this.updateSearchValue = this.updateSearchValue.bind(this);
      this.searchCP = this.searchCP.bind(this);
      this.setMapsAPI = this.setMapsAPI.bind(this);
      this.filterDoctors = this.filterDoctors.bind(this);
  }
  componentDidUpdate(){

  }
  setMapsAPI(API){
    this.maps = API;
  }
  updateSearchValue(evt) {
    const re = /^[0-9\b]+$/;
    if(evt.target.value === '' || re.test(evt.target.value)){ 
        this.setState({
        cp: evt.target.value
        });
    }
  }

  banners = [
    {
      imagen: encabezado,
      text: ""
    },
  ]
haversineDistance(coords1, coords2) {
    function toRad(x) {
      return x * Math.PI / 180;
    }
    var R = 6371; // km
    if(!coords2.lat || !coords2.lng){
      return R+1;
    }    
    var x1 = coords2.lat - coords1.lat;
    var x2 = coords2.lng - coords2.lng;
    var dLat = toRad(x1);
    var dLon = toRad(x2)
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(coords1.lat)) * Math.cos(toRad(coords2.lat)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }  
  filterDoctors(location){
    const localDoctors = [...this.initialDoctors];
    localDoctors.sort((a,b) => {
        const distA = this.haversineDistance(location,{lat: a.lat, lng: a.lng});
        const distB = this.haversineDistance(location,{lat: b.lat, lng: b.lng});
        if(distA < distB) return -1;
        if(distA === distB) return 0;
        return 1;
    })
    return localDoctors;
  }
  searchCP(){
    if(!this.maps){
        return false;
    }
    if(this.state.cp.length < 4){
        return false;
    }
    this.maps.geocode({
        address:this.state.cp, 
        region:'.mx',
        componentRestrictions: {
            country: 'MX',
        }    
    }, (geocoderResults)=>{
        if(geocoderResults.length > 0){
            const geoLocation = geocoderResults[0].geometry.location;
            this.setState({
                doctors: this.filterDoctors({lat:geoLocation.lat(),lng: geoLocation.lng()})
            })
        }
    });

  }
  render() {
   return  (
    <div className="Contenedor">
    <BannerHeader descripcion='' imagen={encabezado} banners={this.banners} />      
    <div id="nuevo" style={{width:'100%'}}>
      <SoloTexto titulo='ENCUENTRA A TU ESPECIALISTA' color='texto-pink texto-bold texto-xl' style={{borderBottom:'2px solid #DCB8A4'}} alineacion='center'/>
      <hr style={{color: 'rgba(219,183,163,0.5)'}}/>
      <SoloTexto titulos={[
        'Descubre a los especialistas que te ayudarán a tener un mejor entendimiento de tu piel, así como una evaluación profesional sobre los tratamientos o procedimientos que puedes realizarte para lucir radiante. '
      ]} color='texto-gray texto-montserrat texto-m' alineacion='center'/>
    </div>
    <div className="specialist texto-pink">
        <div className="specialist-data">
            <div className="searchbar texto-gray texto-bold" style={{borderBottom:'2px solid #c6c6c6', width:'100%'}}>
               C.P: <input value={this.state.cp} style={{width:'80%',backgroundColor:'transparent', outline:'none'}} className="texto-gray texto-m" type="text" onChange={evt => this.updateSearchValue(evt)}/>
               <button style={{boxSizing:'border-box', width:'8%',backgroundColor:'transparent', border:'none', outline:'none'}} onClick={this.searchCP}><img width="100%" src={search_icon}></img></button>
            </div>
            {
                this.state.doctors.map((doctor)=>{
                    return (
                        <div key={doctor.id} className="specialist-details texto-gray texto-montserrat texto-s">
                            <span className="texto-bold texto-pink texto-s">{doctor.name}</span>
                            {doctor.address && <span><span className="texto-bold">Dirección:</span> {doctor.address}</span>}
                            {doctor.phone && <span><span className="texto-bold">Teléfono:</span> {doctor.phone}</span>}
                            {doctor.tratments.length > 0 && 
                              <>
                              <span className="texto-bold">Tratamientos disponibles:</span>
                                {doctor.tratments.map((tratment)=>{
                                  return (
                                    <div className={"tratamiento available"} >
                                        {tratment}
                                    </div>    
                                  )
                                })}
                              </>
                            }
                            {
                              doctor.contacts && doctor.contacts.length > 0 &&
                              <div style={{marginTop:'1rem', display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                  {doctor.contacts.map((contact)=>{
                                    const SocialComponent = socialIcons[contact.social] || socialIcons.default;
                                    return <div className="texto-s" style={{margin:'0.5em 0'}}><a href={contact.link} rel="noopener noreferrer" target="_blank">{<SocialComponent className="contact_icon" />}</a></div>
                                  })}
                              </div>
                            }
                        </div>
                    );
                })
            }
        </div>
        <div className="specialist-map">
            <MapContainer onReady={this.setMapsAPI} doctors={this.state.doctors}/>
        </div>
    </div>
  <div id="experiencias">
    <SoloTexto titulo='EXPERIENCIAS DE CAMBIO DE VIDA' color='texto-pink texto-bold texto-xl' alineacion='center'/>
    <hr style={{color: 'rgba(219,183,163,0.5)', height:'0px'}}/>
    <SoloTexto titulos={[
      '¿Te identificas con estas historias?',
      '¡No esperes más y acude a un especialista!' 
    ]} color='texto-grey texto-montserrat texto-m' alineacion='center'/>
    <div style={{margin:'0em auto 1em auto', display:'flex', flexDirection:'row', flexWrap:'wrap', alignItems:'flex-start', width:'100%'}} >
      <Paciente imagen={default_user} nombre="Susana Sil" paciente="Dermatóloga" descripcion="Después de cierta edad, perdemos  1% del colágeno en nuestra piel al año. Hoy en día, existen tratamientos estéticos no invasivos para recuperar este porcentaje y mantenerlo. Nunca hay que olvidar una buena rutina de skincare y gym facial"/>
      <Paciente imagen={default_user} nombre="Michele" paciente="Paciente Ultherapy" descripcion="Después de recibir este tratamiento, siento que mi apariencia coincide con la forma en que me siento por dentro"/>
      <Paciente imagen={default_user} nombre="Daniela Méndez" paciente="Psicóloga y Terapeuta Ocupacional" descripcion="Algunos tips para mejorar el rendimiento en tu trabajo, consisten en mantener una postura adecuada, colocar el monitor de la computadora a la altura de tus ojos, evitando puntos de tensión en manos y brazos, y sobre todo tomar tomar descansos para relajar tu cuerpo y articulaciones" />
      <Paciente imagen={default_user} nombre="Caro" paciente="Paciente Belotero" descripcion="Es súper efectivo; he visto el cambio en mi piel desde mi primera aplicación"/>
    </div>
  </div>
</div>
   )
  }
}

export default Especialist;
