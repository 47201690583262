import React from 'react';
import './App.css';

import lifestyle from './data/cosmetics.js';

import BannerHeader from './BannerHeader.js';
import Blog from './Blog.js';
import BlogHoriz from './BlogHoriz.js'

import Paciente from './Paciente';

import SoloTexto from './SoloTexto';

import encabezado from './images/1x/betterself_banner_1.jpg';
import banner_lifestyle from './images/1x/betterself_banner_lifestyle.jpg';
import banner_consulta from './images/1x/betterself_banner_consulta.jpg';

import default_user from './images/1x/betterself_default_user_image.jpg'

import cosmetics_1 from './images/1x/betterself_cosmetic_blog_1.jpg';
import cosmetics_2 from './images/1x/betterself_cosmetic_blog_2.jpg';
import cosmetics_3 from './images/1x/betterself_cosmetic_blog_3.jpg';
import cosmetics_4 from './images/1x/betterself_cosmetic_blog_4.jpg';
import cosmetics_5 from './images/1x/betterself_cosmetic_blog_5.jpg';
import cosmetics_6 from './images/1x/betterself_cosmetic_blog_6.jpg';
import cosmetics_7 from './images/1x/betterself_cosmetic_blog_7.jpg';
import cosmetics_8 from './images/1x/betterself_cosmetic_blog_8.jpg';

import { ReactComponent as IconFacebook} from './images/icons/SVG/icon_facebook.svg';
import { ReactComponent as IconTwitter} from './images/icons/SVG/icon_twitter.svg';
import { ReactComponent as IconWeb } from './images/icons/SVG/icon_web.svg';
import { ReactComponent as IconInstagram } from './images/icons/SVG/icon_instagram.svg';
import { ReactComponent as IconYoutube } from './images/icons/SVG/icon_youtube.svg';

import {Link} from 'react-router-dom';


const socialIcons = {
  Web: IconWeb,
  Instagram: IconInstagram,
  Facebook: IconFacebook,
  Twitter: IconTwitter,
  Youtube: IconYoutube,
  default: IconWeb
};

class CosmeticsPage extends React.Component {

  constructor(props){
    super(props);
    const post = props.match.params.post;

    this.state = {
      post: post,
      blogData: lifestyle[post]
    }
  }

  
  componentDidUpdate(){
    const changes = {};


    if(this.state.post !== this.props.match.params.post){
      changes.post = this.props.match.params.post;
      changes.blogData = lifestyle[changes.post];
    }
    if(Object.keys(changes).length > 0){
      this.setState(changes);
    }
  }

  render() {
   return  (<div className="Contenedor">
     <img className="blog-page-banner"  alt={this.state.blogData.title} src = {this.state.blogData.image || banner_lifestyle}/>      
    <div id="proyectar">
      <SoloTexto titulo={this.state.blogData.title} color='texto-mayus texto-pink texto-spaced texto-bold texto-l' alineacion='center'/>
      <div className="blog-page">
        {this.state.blogData.pars.map((par,id)=>{
          return <div key={"blog-page-par" + id} className="blog-page-par" >
              {par.title && <span className="blog-page-par-title texto-bold">{par.title}</span>}
              {par.text && <span className={"blog-page-par-text " + par.className ? par.className:"" }>{par.text}</span>}
              {par.list && <ul>{par.list.map((text,idx)=>{return <li key={'blog-page-par-list-' + idx}>{text}</li>})}</ul>}
              {par.gallery && <div className="blog-page-par-gallery">{
                par.gallery.map((image, idx)=>{return <img key={'blog-page-gallery-' + idx} src={image.src} alt={image.alt} width="50%" />} )}
              </div>}
              <div className="blog-author-contact" style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>              
              {par.contacts && par.contacts.map((contact)=>{
                const SocialComponent = socialIcons[contact.social] || socialIcons.default;
                return <div className="texto-s" style={{margin:'0.5em 0'}}><a href={contact.link} rel="noopener noreferrer" target="_blank">{<SocialComponent className="contact_icon" />}</a></div>
              })}
              </div>
            </div>
        })}
        {this.state.blogData.refs.length > 0 &&
          <div key="blog-page-reference" className="blog-page-par"><span className="blog-page-par-title">REFERENCIAS</span><ul>
            {this.state.blogData.refs.map((ref, id)=>{
                return <li key={"blog-page-par-ref"+id}>
                  <span className="blog-page-reference">{ref.text}</span>
                  {ref.link && <div><span className="blog-page-par-text">Recuperado de: </span><a className="blog-page-ref-link" href={ref.link} rel="noopener noreferrer" target="_blank">{ref.link}</a></div>}                
                  </li>
            })
            }
          </ul></div>
        }
        {this.state.blogData.author && 
          <div className="blog-page-author">
            <span className="blog-page-par-text texto-s">{this.state.blogData.author.brief}</span>
            <div className="blog-author-contact" style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
              <span>Sigue a {this.state.blogData.author.name}: </span>
              {this.state.blogData.author.contacts.map((contact)=>{
                const SocialComponent = socialIcons[contact.social] || socialIcons.default;
                return <div className="texto-s" style={{margin:'0.5em 0'}}><a href={contact.link} rel="noopener noreferrer" target="_blank">{<SocialComponent className="contact_icon" />}</a></div>
              })}
            </div>
            {this.state.blogData.author.links.map((contact)=>{
                const SocialComponent = socialIcons[contact.social] || socialIcons.default;
                return <div className="texto-s" style={{margin:'0.5em 0'}}><a href={contact.link} rel="noopener noreferrer" target="_blank" className="text-blue text-m" style={{textDecoration:'none', display:'flex', alignItems:'center'} }>{<SocialComponent className="contact_icon" />} <span className="texto-blue texto-m">{contact.text}</span></a></div>
            })}

          </div>
        }
        <hr style={{width:'100%', color:'#DCB8A4'}} />
        <div className="blog-share" style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
          <span style={{marginRight:'0.75rem'}} className="texto-mayus texto-s texto-pink">COMPARTIR</span>
          <a href={`https://www.facebook.com/sharer.php?u=${window.location.href}`} target='_blank' rel='noreferrer noopener'><IconFacebook className="share_icon" /></a>
          <a href={`https://twitter.com/intent/tweet?url=${window.location.href}&text=${this.state.blogData.title}`} target='_blank' rel='noreferrer noopener'><IconTwitter className="share_icon" /></a>       
        </div>
      </div>
      </div>
      <SoloTexto titulo="LO ÚLTIMO EN COSMÉTICA" color='texto-pink texto-bold texto-l' alineacion='center' />
      <div id="proyectar">
      <div style={{margin:'0em auto 1em auto', width:'100%'}} >
          <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
            {this.props.match.params.post !== "inyectables-usos-mitos-realidades" && <BlogHoriz imagen={cosmetics_8} link="/cosmetics/inyectables-usos-mitos-realidades" titulo="Procedimiento estéticos inyectables: usos, mitos y realidades" descripcion=""/> }
            {this.props.match.params.post !== "ultherapy" && <BlogHoriz mid imagen={cosmetics_7} link="/cosmetics/ultherapy" titulo="Ultherapy en voz de la Dra.Paty Herrera" descripcion=""/>          }
            {this.props.match.params.post !== "medicina-genomica" && <Blog mid imagen={cosmetics_6} link="/cosmetics/medicina-genomica" titulo="FAQs de la Medicina Genómica" />}
            {this.props.match.params.post !== "colageno-muy-importante" && <Blog mid imagen={cosmetics_5} link="/cosmetics/colageno-muy-importante" titulo="Coláge...¿no? ¡Sí, es muy importante!" />}
            {this.props.match.params.post !== "evita-el-envejecimiento-prematuro" && <Blog mid imagen={cosmetics_1} link="/cosmetics/evita-el-envejecimiento-prematuro" titulo="Tener 50 y parecer de 30 es el deseo de todas" />}
            {this.props.match.params.post !== "acido-hialuronico-que-hace" &&  <Blog mid imagen={cosmetics_2} link="/cosmetics/acido-hialuronico-que-hace" titulo="Ácido hialurónico: sí. Pero, ¿exactamente qué hace?" /> }
            {this.props.match.params.post !== "como-lograr-tu-betterself-segun-expertos" &&  <Blog mid imagen={cosmetics_3}  link="/cosmetics/como-lograr-tu-betterself-segun-expertos" titulo="¿Cómo lograr tu BetterSelf, según los expertos?" />}
            {this.props.match.params.post !== "bienvenida-al-invierno-beneficios" &&  <Blog mid imagen={cosmetics_4} link="/cosmetics/bienvenida-al-invierno-beneficios" titulo="Dale la bienvenida al invierno. ¡Descubre los beneficios"/> }
          </div>
        </div>
      </div>
      <div id="experiencias">
        <SoloTexto titulo='EXPERIENCIAS DE CAMBIO DE VIDA' color='texto-pink texto-bold texto-l' alineacion='center'/>
        <SoloTexto titulos={[
          '¿Te identificas con estas historias?',
          '¡No esperes más y acude a un especialista!' 
        ]} color='texto-grey texto-montserrat texto-m' alineacion='center'/>
        <div style={{margin:'0em auto 1em auto', display:'flex', flexDirection:'row', flexWrap:'wrap', alignItems:'center', justifyContent:'space-around', width:'100%'}} >
          <Paciente imagen={default_user} nombre="Susana Sil" paciente="Dermatóloga" descripcion="Después de cierta edad, perdemos  1% del colágeno en nuestra piel al año. Hoy en día, existen tratamientos estéticos no invasivos para recuperar este porcentaje y mantenerlo. Nunca hay que olvidar una buena rutina de skincare y gym facial"/>
          {false && <Paciente imagen={default_user} nombre="Michele" paciente="Paciente Ultherapy" descripcion="Después de recibir este tratamiento, siento que mi apariencia coincide con la forma en que me siento por dentro"/>}
          <Paciente imagen={default_user} nombre="Daniela Méndez" paciente="Psicóloga y Terapeuta Ocupacional" descripcion="Algunos tips para mejorar el rendimiento en tu trabajo, consisten en mantener una postura adecuada, colocar el monitor de la computadora a la altura de tus ojos, evitando puntos de tensión en manos y brazos, y sobre todo tomar tomar descansos para relajar tu cuerpo y articulaciones" />
          {false && <Paciente imagen={default_user} nombre="Caro" paciente="Paciente Belotero" descripcion="Es súper efectivo; he visto el cambio en mi piel desde mi primera aplicación"/>}
        </div>
      </div>

      {false && <BannerHeader descripcion='' imagen={encabezado} banners={
        [{
          imagen: banner_consulta,
          text: <div className="textoHeader texto-m" style={{position:'absolute', top:'10%', right:'10%', bottom:'10%', display:'flex', 'flexDirection':'column'}}>
            <span className='texto-white texto-montserrat'>Encuentra a los profesionales de</span>
            <span className='texto-white texto-montserrat'>la salud y estilo de vida</span>
            <span className='texto-white texto-montserrat'>más prestigiados e inicia tu</span>            
            <span className='texto-white texto-montserrat'>transformación</span>
            <span className='texto-white texto-tahu' style={{marginTop:'auto',marginBottom:'auto'}}>¡Descúbrelos!</span>
            <Link to="/specialist" style={{textDecoration:'none', borderTop:'1px solid white', borderBottom:'1px solid white'}} className='texto-spaced texto-white texto-bold specialist-link' >AQUÍ</Link>
          </div>
        }]
      } />}
    </div>)
  }
}

export default CosmeticsPage;
