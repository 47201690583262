import React from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';

import self_icon from './images/1x/betterself_self_icono.png'

class MapContainer extends React.Component{
    constructor(props){
        super(props);
        this.getAPI = this.getAPI.bind(this);
    }
    state = {
        showingInfoWindow: false,
        activeMarker: {},
        doctor: {tratments:[],lat: 19.3451032, lng: -99.1583336},
    };
    
    mapStyles = {
        width: '100%',
        position:'relative',
        height: '700px',
    };      
    getAPI(mapProps, map){
        const {google} = mapProps;
        const gc = new google.maps.Geocoder();
        this.props.onReady(gc);
    }
    onMarkerClick = (props, marker, e) => {
        this.setState({
            doctor: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }
    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
          this.setState({
            showingInfoWindow: false,
            activeMarker: null
          })
        }
      };

    shouldComponentUpdate(nextProps, nextState) {
        if(this.state.showingInfoWindow !== nextState.showingInfoWindow) return true;
        if(this.props.doctors !== nextProps.doctors) return true;
        return false;
    }
    
    render() {
        return (
            <Map
                google={this.props.google}
                onClick={this.onMapClicked}
                onReady={this.getAPI}
                zoom={this.state.showingInfoWindow? 12 : 6}
                containerStyle={this.mapStyles}
                style={this.mapStyles}
                initialCenter={{ lat: 19.3451032, lng: -99.1583336}}
                center={{lat:this.state.doctor.lat, lng:this.state.doctor.lng}}
            >
                {
                    this.props.doctors.map((doctor,id)=>{
                        return(                          
                            <Marker     
                            onClick={this.onMarkerClick} key={'doctor-'+id} position={{lat: doctor.lat, lng:doctor.lng}} {...doctor}/>
                        );
                    })
                }
                <InfoWindow
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}
                    onClose={()=>{this.setState({showingInfoWindow:false})}}>
                        <div key={this.state.doctor.id} className="specialist-details texto-gray texto-montserrat texto-s" style={{border:'none'}}>
                            <span className="texto-bold texto-pink texto-s">{this.state.doctor.name}</span>
                            <span><span className="texto-bold">Dirección:</span> {this.state.doctor.address}</span>
                            <span><span className="texto-bold">Teléfono:</span> {this.state.doctor.phone}</span>
                        </div>
                </InfoWindow>
            </Map>

        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAU8rMWTZfvh-9YwhWRGiF-pPVhAOphLEA'
  })(MapContainer);
  