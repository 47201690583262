import React, { Component } from 'react';
import './App.css';
import MenuSup from './MenuSup.js';
import PieDePagina from './PieDePagina.js';
import Opc from './funciones.js';
import ScrollToTop from './ScrollTop';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import LifestylePage from './LifeStylePage';
import CosmeticsPage from './CosmeticsPage';
import AvisoPrivacidad from './AvisoPrivacidad';
import CookieList from './CookieList';
import Diagnostic from './Diagnostic';
import Specialist from './Especialist';
class App extends Component {


  render(){ 
      return (
        <ScrollToTop>
          <header  id="encabezado">
          <MenuSup />
          </header>       
          <div id="contenido">           
            <Switch >
              <Route key="index" exact path="/" render={(props)=><Opc {...props}/>} />
              <Route key="diagnostico" exact path="/diagnostic" render={(props)=><Diagnostic {...props} />} />
              <Route key="especialista" exact path="/specialist" render={(props)=><Specialist {...props} />} />
              <Route key="cosmetics" exact path="/cosmetics" render={(props)=><Opc val="1" {...props} />} />
              <Route key="lifestyle" exact path="/lifestyle" render={(props)=><Opc val="2" {...props} />} />
              <Route key="lifestylePost" path="/lifestyle/:post" render={(props)=><LifestylePage {...props} />}/>
              <Route key="cosmeticsPost" path="/cosmetics/:post" render={(props)=><CosmeticsPage {...props} />}/>
              <Route key='aviso-privacidad' exact path='/privacidad' render={(props)=><AvisoPrivacidad {...props} />} />
              <Route key='cookie-list' exact path='/cookies' render={(props)=><CookieList {...props} />} />
            </Switch>
            <PieDePagina/>         
          </div>
        </ScrollToTop>
    );
  }
}


export default App;