import React from 'react';
import './App.css';
import {Link} from 'react-router-dom';
import Modal from './Modal.js';
import Opc from './funciones.js';
class Cotizar extends React.Component{
      state = {
            show: false
          };
          showModal = e => {
            this.setState({
              show: !this.state.show
            });
          
          };
          onClose = e => {
            this.props.onClose && this.props.onClose(e);
          };
render(){
return(
<div style={{width:"100%", margin:"2em 0em 2em 0em"}}>

<div className="flotante"style={{width:"fit-content"}}>    
        <a className="LinkButtonAzul" style={{textDecoration:'none'}}  onClick={e => {this.showModal();}}>SOLICITAR COTIZACIÓN</a>
                
        
</div>  

<div>
<Modal onClose={this.showModal} show={this.state.show}>
        <Opc val="11" />
</Modal>
</div>
</div>

      )}}

export default Cotizar;

