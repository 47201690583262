import React from 'react';
import './App.css';

import {Link} from 'react-router-dom';

class BlogHoriz extends React.Component{
    render(){
        return(
          <div className="blog-post-horizontal" >
            <div className="blog-post-horizontal-image">
              <img className="blog-post-horizontal-image" src={this.props.imagen} alt={this.props.titulo} />
            </div>
            <div className='blog-post-horizontal-text'>
              <span className='texto-grey texto-m texto-montserrat' style={{fontSize:'1rem'}}>{this.props.titulo}</span>
              {this.props.link && <Link className="LinkButton" to={this.props.link}>VER MÁS</Link>}
            </div>
            {this.props.link && <Link className="LinkButton" to={this.props.link}>VER MÁS</Link>}
          </div>
        )
    }}
    export default BlogHoriz;