import React from 'react';
import './App.css';

import {Link} from 'react-router-dom';

class Blog extends React.Component{
    render(){
      let className="blog-post";
      if(this.props.mid){
        className="blog-post-mid";
      }
        return(
          <div className={className} style={{color:'silver', textAlign:'left', maxHeight:'500px'}}>
            <img src={this.props.imagen} style={{width:'100%', objectFit:'cover'}} alt={this.props.titulo}/>
            <div className='blog-text'>
              <span className='texto-grey texto-m texto-montserrat' style={{fontSize:'1rem'}}>{this.props.titulo}</span>
            </div>
            {this.props.link && <Link className="LinkButton" to={this.props.link}>VER MÁS</Link>}
          </div>
        )
    }}
    export default Blog;