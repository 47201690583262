import React from 'react';
import './App.css';




class CookieList extends React.Component {

  
  componentDidUpdate(){
    
  }

  render() {
   return  (
        <div id="ot-sdk-cookie-policy"></div>
    );
  }
}

export default CookieList;
