import React from 'react';
import './App.css';


class BannerHeader extends React.Component {

    constructor(props){
        super();
        this.state = {
            banners: props.banners,
            selectedBanner: props.selected || 0
        };

    }

    updateSlide(){
        let newSelection = this.state.selectedBanner + 1;
        newSelection %= this.state.banners.length;
        this.setState({
            selectedBanner: newSelection
        });
    }

    componentDidMount(){
        setInterval(() => {
            this.updateSlide();
        }, 5000);    
    }

    render(){
        
        return(
            <div className="Header" style={{width:'100%', position:'relative', height:'fit-content'}} >
                <img alt="Banner" style={{width:'100%', minHeight:'150px', objectFit:'cover'}} src={this.state.banners[this.state.selectedBanner].imagen} />
                {this.state.banners[this.state.selectedBanner].text}
            </div>
        )
    }
}
export default BannerHeader;
