import React from "react";


import lifestyle_1 from '../images/2x/betterself_blog_lifestyle_1@2x.jpg';
import lifestyle_2 from '../images/2x/betterself_blog_lifestyle_2@2x.jpg';
import lifestyle_3 from '../images/2x/betterself_blog_lifestyle_3@2x.jpg';
import lifestyle_4 from '../images/2x/betterself_blog_lifestyle_4@2x.jpg';
import lifestyle_5 from '../images/1x/betterself_blog_lifestyle_5.jpg';
import lifestyle_6 from '../images/1x/betterself_blog_lifestyle_6.jpg';
import lifestyle_7 from '../images/1x/betterself_blog_lifestyle_7.jpg';
import lifestyle_8 from '../images/1x/betterself_blog_lifestyle_8.jpg';
import lifestyle_9 from '../images/1x/betterself_blog_lifestyle_9.jpg';
import lifestyle_10 from '../images/1x/betterself_blog_lifestyle_10.jpg';
import lifestyle_11 from '../images/1x/betterself_blog_lifestyle_11.jpg';
import lifestyle_12 from '../images/1x/betterself_blog_lifestyle_12.jpg';
import lifestyle_13 from '../images/1x/betterself_blog_lifestyle_13.jpg';
import lifestyle_14 from '../images/1x/betterself_blog_lifestyle_14.jpg';
import lifestyle_15 from '../images/1x/betterself_blog_lifestyle_15.jpg';
import lifestyle_16 from '../images/1x/betterself_blog_lifestyle_16.jpg';
import lifestyle_17 from '../images/1x/betterself_blog_lifestyle_17.jpg';
import lifestyle_18 from '../images/1x/betterself_blog_lifestyle_18.jpg';
import lifestyle_19 from '../images/1x/betterself_blog_lifestyle_19.jpg';
import lifestyle_20 from '../images/1x/betterself_blog_lifestyle_20.jpg';
import lifestyle_21 from '../images/1x/betterself_blog_lifestyle_21.jpg';
import lifestyle_22 from '../images/1x/betterself_blog_lifestyle_22.jpg';
import lifestyle_23 from '../images/1x/betterself_blog_lifestyle_23.jpg';
import lifestyle_24 from '../images/1x/betterself_blog_lifestyle_24.jpg';
import lifestyle_25 from '../images/1x/betterself_blog_lifestyle_25.jpg';
import lifestyle_26 from '../images/1x/betterself_blog_lifestyle_26.jpg';
import lifestyle_27 from '../images/1x/betterself_blog_lifestyle_27.jpg';
import lifestyle_28 from '../images/1x/betterself_blog_lifestyle_28.jpg';
import lifestyle_29 from '../images/1x/betterself_blog_lifestyle_29.jpg';
import lifestyle_30 from '../images/1x/betterself_blog_lifestyle_30.jpg';
import lifestyle_31 from '../images/1x/betterself_blog_lifestyle_31.jpg';
import lifestyle_32 from '../images/1x/betterself_blog_lifestyle_32.jpg';
import lifestyle_33 from '../images/1x/betterself_blog_lifestyle_33.jpg';
import lifestyle_34 from '../images/1x/betterself_blog_lifestyle_34.jpg';
import lifestyle_35 from '../images/1x/betterself_blog_lifestyle_35.jpg';
import lifestyle_36 from '../images/1x/betterself_blog_lifestyle_36.jpg';
import lifestyle_37 from '../images/1x/betterself_blog_lifestyle_37.jpg';
import lifestyle_38 from '../images/1x/betterself_blog_lifestyle_38.jpg';
import lifestyle_39 from '../images/1x/betterself_blog_lifestyle_39.jpg';
import lifestyle_40 from '../images/1x/betterself_blog_lifestyle_40.jpg';
import lifestyle_41 from '../images/1x/betterself_blog_lifestyle_41.jpg';
import lifestyle_42 from '../images/1x/betterself_blog_lifestyle_42.jpg';
import lifestyle_43 from '../images/1x/betterself_blog_lifestyle_43.jpg';
import lifestyle_44 from '../images/1x/betterself_blog_lifestyle_44.jpg';
import lifestyle_45 from '../images/1x/betterself_blog_lifestyle_45.jpg';
import lifestyle_46 from '../images/1x/betterself_blog_lifestyle_46.jpg';
import lifestyle_47 from '../images/1x/betterself_blog_lifestyle_47.jpg';
import lifestyle_48 from '../images/1x/betterself_blog_lifestyle_48.jpg';
import lifestyle_49 from '../images/1x/betterself_blog_lifestyle_49.jpg';
import lifestyle_50 from '../images/1x/betterself_blog_lifestyle_50.jpg';
import lifestyle_51 from '../images/1x/betterself_blog_lifestyle_51.jpg';
import lifestyle_52 from '../images/1x/betterself_blog_lifestyle_52.jpg';
import lifestyle_53 from '../images/1x/betterself_blog_lifestyle_53.jpg';
import lifestyle_54 from '../images/1x/betterself_blog_lifestyle_54.jpg';
import lifestyle_55 from '../images/1x/betterself_blog_lifestyle_55.jpg';
import lifestyle_56 from '../images/1x/betterself_blog_lifestyle_56.jpg';
import lifestyle_57 from '../images/1x/betterself_blog_lifestyle_57.jpg';
import lifestyle_58 from '../images/1x/betterself_blog_lifestyle_58.jpg';
import lifestyle_59 from '../images/1x/betterself_blog_lifestyle_59.jpg';
import lifestyle_60 from '../images/1x/betterself_blog_lifestyle_60.jpg';
import lifestyle_61 from '../images/1x/betterself_blog_lifestyle_61.jpg';
import lifestyle_62 from '../images/1x/betterself_blog_lifestyle_62.jpg';
import lifestyle_63 from '../images/1x/betterself_blog_lifestyle_63.jpg';
import lifestyle_64 from '../images/1x/betterself_blog_lifestyle_64.jpg';
import lifestyle_65 from '../images/1x/betterself_blog_lifestyle_65.jpg';
import lifestyle_66 from '../images/1x/betterself_blog_lifestyle_66.jpg';
import lifestyle_67 from '../images/1x/betterself_blog_lifestyle_67.jpg';
import lifestyle_68 from '../images/1x/betterself_blog_lifestyle_68.jpg';
import lifestyle_69 from '../images/1x/betterself_blog_lifestyle_69.jpg';
import lifestyle_70 from '../images/1x/betterself_blog_lifestyle_70.jpg';
import lifestyle_71 from '../images/1x/betterself_blog_lifestyle_71.jpg';
import lifestyle_72 from '../images/1x/betterself_blog_lifestyle_72.jpg';
import lifestyle_73 from '../images/1x/betterself_blog_lifestyle_73.jpg';
import lifestyle_74 from '../images/1x/betterself_blog_lifestyle_74.jpg';
import lifestyle_75 from '../images/1x/betterself_blog_lifestyle_75.jpg';
import lifestyle_76 from '../images/1x/betterself_blog_lifestyle_76.jpg';
import lifestyle_77 from '../images/1x/betterself_blog_lifestyle_77.jpg';
import lifestyle_78 from '../images/1x/betterself_blog_lifestyle_78.jpg';
import lifestyle_79 from '../images/1x/betterself_blog_lifestyle_79.jpg';
import lifestyle_80 from '../images/1x/betterself_blog_lifestyle_80.jpg';
import lifestyle_81 from '../images/1x/betterself_blog_lifestyle_81.jpg';
import lifestyle_82 from '../images/1x/betterself_blog_lifestyle_82.jpg';
import lifestyle_83 from '../images/1x/betterself_blog_lifestyle_83.jpg';
import lifestyle_84 from '../images/1x/betterself_blog_lifestyle_84.jpg';
import lifestyle_85 from '../images/1x/betterself_blog_lifestyle_85.jpg';
import lifestyle_86 from '../images/1x/betterself_blog_lifestyle_86.jpg';
import lifestyle_87 from '../images/1x/betterself_blog_lifestyle_87.jpg';
import lifestyle_88 from '../images/1x/betterself_blog_lifestyle_88.jpg';
import lifestyle_89 from '../images/1x/betterself_blog_lifestyle_89.jpg';
import lifestyle_90 from '../images/1x/betterself_blog_lifestyle_90.jpg';
import lifestyle_91 from '../images/1x/betterself_blog_lifestyle_91.jpg';
import lifestyle_92 from '../images/1x/betterself_blog_lifestyle_92.jpg';
import lifestyle_93 from '../images/1x/betterself_blog_lifestyle_93.jpg';
import lifestyle_94 from '../images/1x/betterself_blog_lifestyle_94.jpg';
import lifestyle_95 from '../images/1x/betterself_blog_lifestyle_95.jpg';
import lifestyle_96 from '../images/1x/betterself_blog_lifestyle_96.jpg';
import lifestyle_97 from '../images/1x/betterself_blog_lifestyle_97.jpg';
import lifestyle_98 from '../images/1x/betterself_blog_lifestyle_98.jpg';
import lifestyle_99 from '../images/1x/betterself_blog_lifestyle_99.jpg';
import lifestyle_100 from '../images/1x/betterself_blog_lifestyle_100.jpg';
import lifestyle_101 from '../images/1x/betterself_blog_lifestyle_101.jpg';
import lifestyle_102 from '../images/1x/betterself_blog_lifestyle_102.jpg';
import lifestyle_103 from '../images/1x/betterself_blog_lifestyle_103.jpg';
import lifestyle_104 from '../images/1x/betterself_blog_lifestyle_104.jpg';
import lifestyle_105 from '../images/1x/betterself_blog_lifestyle_105.jpg';
import lifestyle_106 from '../images/1x/betterself_blog_lifestyle_106.jpg';
import lifestyle_107 from '../images/1x/betterself_blog_lifestyle_107.jpg';
import lifestyle_108 from '../images/1x/betterself_blog_lifestyle_108.jpg';
import lifestyle_109 from '../images/1x/betterself_blog_lifestyle_109.jpg';
import lifestyle_110 from '../images/1x/betterself_blog_lifestyle_110.jpg';
import lifestyle_111 from '../images/1x/betterself_blog_lifestyle_111.jpg';
import lifestyle_112 from '../images/1x/betterself_blog_lifestyle_112.jpg';
import lifestyle_113 from '../images/1x/betterself_blog_lifestyle_113.jpg';
import lifestyle_114 from '../images/1x/betterself_blog_lifestyle_114.jpg';

import cosmetics_9 from '../images/1x/betterself_cosmetic_blog_9.jpg';


import iluminados_1  from '../images/gallery/iluminados_1.png';
import iluminados_2  from '../images/gallery/iluminados_2.png';

import linea_1 from '../images/gallery/linea_1.png';
import linea_2 from '../images/gallery/linea_2.png';

import red_1 from '../images/gallery/red_1.png';

import dewy_1 from '../images/gallery/dewy_1.png';

import smokey from '../images/gallery/smokey_1.png';

import acido_hialuronico from '../images/1x/acido_hialuronico.png';
import tips_bodas from '../images/1x/tips_planear_boda.png';
import tips_viajes from '../images/1x/infografia_tips_ode.png';
import infografia_labios from '../images/1x/infografia_labios.png';
import infografia_exfoliacion from '../images/1x/infografia_exfoliacion.png';
import infografia_verano from '../images/1x/infografia_verano.png';
import infografia_cuidado_piel from '../images/1x/infografia_cuidado_piel.png';
import infografia_ojos_labios from '../images/1x/infografia_ojos_labios.jpeg';
import infografia_cancer_mama from '../images/1x/infografia_cancer_mama.jpeg';
import infografia_mirada_fresca from '../images/1x/infografia_mirada_fresca.jpeg';
import infografia_reflexiones from '../images/1x/infografia_reflexiones.jpeg';
import infografia_labios_rusos from '../images/1x/infografia_labios_rusos.png';
import infografia_triangulo_juventud from '../images/1x/infografia_triangulo_juventud.jpeg';
import infografia_skin_quality from '../images/1x/infografia_skin_quality.jpeg';
import infografia_envejecimiento from '../images/1x/infografia_envejecimiento.jpeg';
import infografia_piel_parte_1 from '../images/1x/infografia_piel_parte_1.png';
import infografia_piel_parte_2 from '../images/1x/infografia_piel_parte_2.png';
import infografia_aspectonaturalyfresco from '../images/1x/infografia_aspectonaturalyfresco.png';
import infografia_summer_glowy_skin from '../images/1x/infografia_summer_glowy_skin.png';

export default {
    "winter-workout":{
        "image": lifestyle_1,
        "title":"Winter Workout. ¡Sí se puede!",
        "pars":[
            {"text":"El ejercicio físico, o work-out, sin importar la disciplina, puede hacerse durante todo el año, incluso en el invierno (winter workout). Muchas veces por miedo al frío, llegamos a interrumpir nuestra rutina de ejercicio. Las razones son muchas; van desde prevenir enfermedades respiratorias, hasta evitar lesiones por los cambios bruscos de temperatura."},
            {"text":"Esto no debería de ser así. ¿Sabías que hacer ejercicio en temporada de frío te ayuda a quemar más calorías?"},
            {"text":"Como dicen por ahí: \"a summer body is made in winter\", así que, si buscas un efectivo \"calorie burnout\", aprovecha estas fechas para perder peso, estar en buena forma y lograr un cuerpo de envidia. Te invitamos a revisar estos prácticos consejos para tu Winter Work-out:"},
            {"list":[
                "Usa varias capas de ropa que te abriguen al principio de la actividad, conforme vayas calentando puedes ir quitando prendas para sentirte más cómod@.",
                "Haz una rutina de calentamiento antes de salir a hacer deporte, así tendrás menor sensación térmica de frío. Si la temperatura es muy baja, te recomendamos disminuir el tiempo de tu rutina para no exponer demasiado a tu cuerpo.",
                "Si habitualmente te gusta correr, elige los horarios menos fríos en las horas centrales del día, evita los recorridos donde el viento sople fuerte.",
                "En espacios cerrados, puedes practicar ejercicios aeróbicos, como correr, nadar, o caminar, y si los combinas con un programa de acondicionamiento físico general que incluya trabajos de flexibilidad, tu Winter Work-out mejorará mucho."
            ]},
            {"text":"¡No dejes que nada te detenga! La constancia es la clave para ver resultados. Síguenos leyendo y descubre rutinas específicas de acuerdo a tu estilo de vida y zonas del cuerpo que desees trabajar."},
            {"text":"Betterself, en pro de un vientre plano, glúteos y piernas sin hoyuelos de celulitis y brazos firmes."}
        ],
        "refs":[
            {"text":"Sáez E. (13 de julio de 2018). Enfemenino. Bienestar. Plántale cara al frío. Cómo hacer ejercicio en invierno", "link":"https://www.enfemenino.com/bienestar/los-beneficios-de-hacer-ejercicio-en-invierno-s2060915.html"}
        ]
    },
    "mindfullness-mas-que-mente-positiva":{
        "image": lifestyle_2,
        "title":"Mindfullness = más que una mente positiva",
        "pars":[
            {"text":"¡Tener una actitud positiva es esencial para realizar todo lo que te propongas! Compartimos 6 fáciles consejos para disfrutar todos los días con una sonrisa y llena de mindfullness:"},
            {"title":"1. ACTITUD","text":"Dale la vuelta a la forma en cómo ves las cosas que te suceden. Si algunas veces sientes que te desalientas fácilmente, esfuérzate en cambiar, ya que casi todo puede tener una solución y sobre todo una buena enseñanza."},
            {"title":"2. ADIÓS A LAS MALAS VIBRAS","text":"¿Has escuchado que la energía se atrae? ¡Rodéate de gente positiva que te aporte buenas vibras! Las personas que siempre están de buen humor te acercarán a tu bienestar personal."},
            {"title":"3. EVITA LAS COMPARACIONES","text":"¡No te compares con nadie! Recuerda que eres absolutamente únic@ y que solamente tú puedes intervenir en las circunstancias que te rodean. Aprende a quererte como eres y agradécele a la vida por lo que tienes."},
            {"title":"4. LAS ACCIONES HABLAN POR TI","text":"A veces cometemos el error de buscar culpables cuando las cosas no salen como queremos. No olvides que lo más sano es asumir que sólo tu eres responsable de tu vida y de tus acciones, así que toma el control de ti mismo."},
            {"title":"5. VALORA A LOS QUE TE RODEAN","text":"Aprende a reconocer lo bueno en tu vida. Lo más importante son las personas que están contigo, familia, pareja, amigos, compañeros. Son parte de ti y gracias a ellos tu vida es magnífica. "},
            {"title":"6. APASIÓNATE POR LAS PEQUEÑAS COSAS", "text":"Estamos tan inmersos en nuestra vida diaria que pocas veces nos detenemos a pensar y a disfrutar realmente de los buenos momentos que se nos presentan. Disfruta de las pequeñas cosas: una plática, una comida familiar, un halago, actividades de fin de semana, etc. Valora lo bueno que se presenta en tu vida y disfrútalo al máximo."},
            {"text":"Todos tenemos momentos buenos y malos. Pero estamos convencidos de que mirar de forma positiva tu entorno te permitirá crecer y encontrar nuevas oportunidades."}
        ],
        "refs":[
            {"text":"Méndez A.. (s/f). Euroresidentes. Motivación. 5 consejos para tener una actitud positiva ante la vida.", "link":"https://www.euroresidentes.com/empresa/motivacion/5-consejos-para-tener-una-actitud"}
        ]
    },
    "tendencias-makeup-de-que-hablar":{
        "image": lifestyle_3,
        "title":"Las tendencias de makeup que darán de qué hablar",
        "pars":[
            {
                "text":"Descubre las mejores tendencias de makeup para brillar en cualquier ocasión. Anota y pon en práctica los siguientes consejos recopilados por expertos:"},
            {
                "title":"OJOS ILUMINADOS",
                "text":"Intensifica tu mirada colocando un efecto de luz en el lagrimal; los tonos metálicos y luminosos son lo más in de la temporada.", "gallery":[{src:iluminados_1, alt:'iluminados'},{src:iluminados_2, alt:'iluminados'}]},
            {
                "title":"LÍNEA DE AGUA",
                "text":"Renueva tu cosmetiquera agregando nuevos tonos. El delineado en la parte interna del ojo en colores variados, es un look que aunque estuvo de moda en los 90’s, ha regresado. La clave de este look es usar un producto a prueba de agua para garantizar un efecto duradero.",
                "gallery":[{src: linea_1, alt:'linea'},{src:linea_2, alt:'linea'}]
            },
            {"title":"RED FRENESÍ","text":"Un infalible. El clásico color carmín se impone esta temporada. Desde el rojo fuego hasta tonos más oscuros. Recuerda la regla de oro: cuando los labios van de rojo hay que dejarlos ser protagonistas.", "gallery":[{src:red_1, alt:'red frenesi'}]},
            {"title":"DEWY LOOK","text":"Está de moda la piel ultra humectada, ya que te da un aspecto saludable. La dewy skin te da un cutis con efecto satinado sutil. Para lograrla, hidrata intensamente tu piel y da toques ligeros de brillo y obtén ese look perfecto.", "gallery":[{src:dewy_1, alt:'dewy look'}]},
            {"title":"SMOKEY METÁLICO","text":"Los ojos misteriosos estarán siempre presentes. Cobre y plateado, ambos metálicos, son ideales para las fiestas. La clave para lograr este efecto es degradar 2 tonos de la misma gama, desde el lagrmal hasta el final del párpado.", "gallery":[{src:smokey, alt:'Smokey'}]},
            {"title":"BONUS", "text":"si quieres lucir un look aún más espectacular, recuerda que tus facciones más llamativas son tus cejas, que enmarcan tu mirada, y tus labios, que le dan vida a tu sonrisa. No tengas miedo, ¡dales un boost! Consulta con tu médico si eres candidat@ a un tratamiento de relleno o de bio-estimulación."}
        ],
        "refs":[
            {"text":"Redacción. (s/f). Bettina Frumboli. Magazine. Tendencia maquillaje-otoño-invierno 2019, looks, colores y productos", "link":"https://www.bettinafrumboli.com/magazine/tendencias/tendencia-maquillaje-otono-invierno-2019-looks-productos"},
            {"text":"Sánchez E. (3 de julio de 2019). Instyle. Belleza. Así es como Dios quiere que te maquilles este otoño-invierno 19/20.","link":"https://www.instyle.es/belleza/maquillaje/tendencias-maquillaje-otono-invierno-dior_44450"}
        ]
    },
    "hola-cuerpazo-dieta-de-invierno":{
        "image":lifestyle_4,
        "title":"Tu mejor versión con la dieta del invierno.",
        "pars":[
            {"text":"Tu cuerpo es sabio y conoce perfectamente lo que necesita; por ello es importante saber escucharlo. Durante el invierno, es crucial aportarle los nutrientes necesarios para reforzar el sistema inmunológico y prevenir enfermedades por los cambios climáticos."},
            {"text":"A continuación, compartimos algunas recomendaciones que te ayudarán a llevar la dieta de invierno, de forma saludable."},
            {"title":"DESCUBRE LOS SUPERFOODS", "text":"Aunque en invierno, la verdad es a tod@s se nos antoja consentirnos con sabores dulces, podemos evitar invadir nuestro cuerpo con toxinas y calorías vacías, para darle lugar a los superfoods. Los alimentos como la quinoa, el kale, la kombucha, la espirulina y el jengibre, entre otros, te brindan beneficios adicionales a la salud debido a la densidad de macronutrientes que contienen. "},
            {"title":"GET COZY","text":"En invierno incorpora a tu alimentación platillos calientes como sopas y tés. En esta temporada es muy importante consumir alimentos como verduras, frutos o leguminosas, que aportan una buena dosis de proteína y fibra a tu cuerpo; esto acompañado de abundantes líquidos evitará que te deshidrates y estés vulnerable a las enfermedades."},
            {"title":"FRUTAS DE TEMPORADA", "text":"En otoño e invierno, los frutos secos son una gran opción ya que contienen las calorías necesarias para afrontar los meses más fríos. Te recomendamos acompañarlos con nueces y semillas que contienen grasas polisaturadas, es decir grasas buenas."},
            {"text":"Cuidar tu alimentación y consentirte sin culpas puede ser muy sencillo. Prueba con distintos ingredientes y mézclalos adecuadamente para preparar platillos deliciosos sin descuidar tu figura."}
        ],
        "refs":[
            {"text":"Redacción. (s/f). Gobierno de México. El plato del bien comer… una guía para una buena alimentación.", "link":"https://www.gob.mx/salud/articulos/el-plato-del-bien-comer-una-guia-para-una-buena-alimentacion"},
            {"text":"Health Science Nestlé. (s/f). Boost MX. 5 hábitos para tener una salud más plena.", "link":"https://mx.boost.com/bienestar/5-habitos-para-tener-una-salud-mas-plena"}
        ]
    },
    "enriquece-belleza-interna":{
        "image": lifestyle_5,
        "title":"Enriquece tu belleza interna",
        "pars":[
            {"text":"¿Cuál es el discurso que te repites todos los días cuando te ves al espejo?", "className":"blog-page-italic blog-page-center"},
            {"text":"¿Dices cosas hermosas de ti misma?", "className":"blog-page-italic blog-page-center"},
            {"text":"La belleza exterior es importante. Cada día hay más mujeres buscando verse y sentirse mejor, además de más saludables. Gracias a innovadoras tecnologías cosméticas con respaldo médico, hoy es posible que las mujeres alcancen un nivel de belleza que les ayude a reflejar una maravillosa belleza interior."},
            {"text":"Así como es importante cuidar tu belleza exterior, es trascendente nutrir tu belleza interna.   Muchas veces la historia que nos contamos sobre nosotras mismas no es muy amorosa.  En ocasiones, elegimos criticarnos, desaprobarnos, juzgarnos y a veces, hasta preferimos no vernos al espejo.  "},
            {"text":"¿Te suena familiar?"},
            {"text":"El pensar y repetir constantemente palabras de crítica y desaprobación, nos conecta con un estado de carencia.  Tus palabras y tus pensamientos tienen mucho poder.  A través de ellos puedes crear aquello que sueñas y que anhelas.  Te invito a que reflexiones, ¿en qué te estás enfocando?  Recuerda que ahí donde pones tu atención, crece.   Es importante que re entrenes tu mente hacia una visión más positiva, que te permita identificar la belleza y las bendiciones que ya habitan en tu vida.  Hoy puedes elegir nutrir tu belleza interna y externa para genera un equilibrio y así permitir que esta belleza prevalezca en un largo plazo."},
            {"text":"Una herramienta que puede ayudarte a reconocer tu belleza son los decretos.  Ellos te ayudan a transformar tus pensamientos en los más amorosos posible, con una vibración de abundancia.  Te invito a que los repitas cada vez que te ves en el espejo:"},
            {"text":"Soy hermosa, soy exitosa, soy abundante.", "className":"blog-page-italic blog-page-blue"},
            {"text":"Me apruebo a mí misma.", "className":"blog-page-italic blog-page-blue"},
            {"text":"Confío en mí, confío en que puedo lograr eso que deseo.", "className":"blog-page-italic blog-page-blue"},
            {"text":"Soy mi mejor amiga, me respeto.", "className":"blog-page-italic blog-page-blue"},
            {"text":"Me amo y me acepto tal y como soy.", "className":"blog-page-italic blog-page-blue"},
            {"text":"Acepto y amo mi cuerpo.", "className":"blog-page-italic blog-page-blue"},
            {"text":"La energía que evocamos con estos pensamientos nos invita a expandir nuestro corazón."},
            {"text":"Recuerda que eres auténtica, no hay nadie como tú.  Eres una mujer especial por ser quien eres.    Tu historia personal y tus experiencias te hacen única.  Honra la mujer que eres, honra el proceso en el que estás.  Reconoce tu valor, es el primer paso para permitir que el amor y la belleza en tu vida crezcan cada día más."},
            {"text":"Que la luz de la consciencia guíe cada uno de tus pasos."},
            {"text":"Te mando todo mi cariño."},
            {"text":"Namasté."},
            {"text":"Ale.", "className":"texto-pink blog-page-italic"}
        ],
        "author":{
            "name":"Ale",
            "brief":"Ale Cuadros es experta en sanación, desarrollo personal y canalización de mensajes angelicales. Busca contribuir al despertar espiritual y bienestar de las personas que la rodean. Es Maestra de Meditación certificada por Jivan Vinod; Coach Espiritual certificada por Andrea de la Mora y Terapeuta en Sanación certificada por Tania Karam. Actualmente, imparte clases de meditación y es creadora de programas como: Sana tus Relaciones, Armoniza tu Vida y Da un Paso al Perdón.",
            "contacts":[
                {social:"Web",text:"www.alecuadros.com",link:"http://www.alecuadros.com"},
                {social:"Instagram",text:"@ale_cuadros_", link:"https://www.instagram.com/ale_cuadros_/"},
                {social:"Facebook", text:"@alecuadros.expandetuluz", link:"https://www.facebook.com/alecuadros.expandetuluz"},
            ],
            "links":[
                {social:"Web", text:"Escucha el audio con la Lluvia de Afirmaciones", link:"https://www.alecuadros.com/lluvia-de-afirmaciones"}
            ]
        },
        "refs":[
        ]
    },
    "mejores-tips-cuidar-piel-cabello":{
        "image": lifestyle_6,
        "title":"Los mejores tips para cuidar tu piel y cabello desde casa",
        "pars":[
            {"text":"Al pasar todo el día en casa, y poner menos esmero en tu arreglo personal que de costumbre; existen cosas que no debes pasar por alto, como los tips para cuidar tu piel."},
            {"text":"La piel es el órgano más extenso del cuerpo y junto con el cabello, es de lo que más requiere tratamientos y cuidados especializados. ¡No te preocupes! Los puedes hacer en casa tomando algunas precauciones: cuida tu cabello siempre y tampoco sobrecargues tu piel con productos. Por eso, junto con la doctora Carolina Martínez, dermatóloga de la Pontificia Universidad Católica de Río de Janeiro y Fellow en Pelo y Uñas de la Universidad de Miami, hicimos una guía para ayudarte a no cometer errores importantes."},
            {"title":"Manos: Adiós a las uñas de la Rosalía o Billie Eilish","text":"Olvídate de las uñas extra-grandes: en tiempos de prevención, es mejor tenerlas cortas y limpias. “Es importante lavar las manos con jabón dentro de las uñas y mantenerlas aseadas. Aplica crema después del lavado, ya que, sino, se pueden agrietar y depositar algún virus”, explica la experta."},
            {"text":"Dile adiós a los productos químicos que no están hechos para la piel. “Es importante que sepamos que para el lavado de manos no necesitamos soluciones desinfectantes extremas ni nada que reseque profundamente la piel. Podemos usar jabones comunes. Eviten desinfectantes y desengrasantes fuertes pensando que son más efectivos para el lavado de manos”."},
            {"title":"Protector solar, ¿dentro de la casa?","text":"Aunque no salgas, es importante, seguir limpiando tu piel y protegerla del sol todos los días. “En momentos de confinamiento o estrés, las enfermedades cutáneas, como el acné y la dermatitis, se exacerban. La limpieza de la piel es crucial, aunque no nos hayamos maquillado, antes de dormirnos, debemos lavarla con agua, jabón y nuestras soluciones desmaquillantes”."},
            {"text":"“Es fundamental la hidratación, y no olvidarse de aplicar protector solar, porque aún cuando no estemos expuestos al sol, la mayoría de los protectores también tienen filtros de luz azul, que nos protegen de las computadoras y de las luces de los celulares o televisores. Además, la luz de la casa también puede producir manchas”, afirma la experta."},
            {"text":"Solo recuerda no sobrecargar la piel de muchos productos, solamente con limpiar, hidratar y proteger, ya se puede cumplir con una rutina básica. ¡Si tienes tiempo extra, también puedes aprovechar para usar mascarillas hechas en casa! Te puedes entretener en su preparación y también tendrás efectos positivos en tu piel."},
            {"title":"Exfolia tu piel","text":"Hay personas que a pesar de estar en casa, hacen múltiples actividades. Por eso, la doctora Martínez recomienda la exfoliación, ya que “nos puede ayudar a eliminar las células muertas. Existen exfoliantes químicos de marca, o exfoliantes caseros que podemos hacer de azúcar gruesa, morena y miel, se colocan en todo el cuerpo, ayuda a la eliminación de células muertas y luego se enjuaga la piel”, agrega."},
            {"text":"Es importante hacerlo máximo dos veces por semana, de lo contrario, dañarás tu piel."},
            {"title":"Tinte en casa","text":"Se pueden recurrir a varios creadores de tutoriales para teñir el cabello en Youtube, como Luxy Hair, Melissxa Kayley, Brad Mondo, entre otros. Sin embargo, si sientes que te resultaría complicado, es mejor darte un respiro y esperar a una ocasión en que puedas asistir a un salón de belleza. Probablemente lo que menos quieres es hacer un desastre con tu cabello. "},
            {"text":"¡Pon manos a la obra en esta cuarentena y en cualquier momento, cuida tu piel y cabello; a tus familiares y a ti mismo!"}
        ],
        "refs":[
            {"text":"Nueva Mujer – Publimetro. Abril 03 de 2020"}
        ]
    },
    "ser-mama-papa-cuarentena":{
        "image": lifestyle_7,
        "title":"Cómo ser mamá o papá y sobrevivir en el intento durante la cuarentena",
        "pars":[
            {"text":"En medio del confinamiento, el home office y las clases en línea, ser padres en la cuarentena es una tarea complicada. Por eso, te damos unos cuantos tips para que este encierro sea más llevadero:"},
            {"title":"1.- Ser padres en cuarentena:","text":"Una primera opción es hablar con tus hijos acerca de las reglas de convivencia. Por ejemplo, estamos compartiendo los espacios, por lo que, para un niño lo que antes era su zona de juegos, será automáticamente también, la oficina de mamá o papá. Por eso, la importancia de platicarles a los nenes, la situación que vivirán de ahora en adelante."},
            {"title":"2.- Las rutinas son importantes:","text":"Mantener rutinas nos permite llevar el control y la certeza del mundo que les rodea a los más peques. Además, les ayuda a disminuir momentos de crisis. Cada familia de forma individual, puede evaluar las rutinas y hábitos esenciales durante el día, ya sea tomar clases, tomarse un tiempo para jugar, hacer tareas y mirar un poco de televisión."},
            {"title":"3.- Pausas saludables: ", "text":"Incorporar pequeños breaks durante la jornada de los niños, es ideal para que puedan desestresarse, ya sea con clases en línea de manualidades, ballet, karate, etc., lo importante es que sigan aprendiendo y desarrollándose con actividades extracurriculares. "},
            {"title":"4.- La paciencia es fundamental","text":"Recordemos que el confinamiento colectivo no es nada fácil y puede provocar muchísimas alteraciones dentro de nuestro estado emocional. Sé comprensivo con tus hijos e intenta solucionar todos los conflictos o diferencias de forma pacífica."},
            {"title":"5.- Conversa con ellos","text":"Tómense un momento al día para dialogar sobre lo que está pasando actualmente en el mundo. Esto, puede ayudar a nuestros pequeños a no entrar en pánico o estrés por el tema del Covid-19, y brindarles calma e información a su alcance."},
            {"title":"6.- ¡Sí a las videollamadas!","text":"La cuarentena contribuye a cuidarnos, pero no implica aislarnos del mundo exterior. Puedes organizar con tu supervisión, videollamadas con familiares y amigos de tus hijos para saber cómo se encuentran."},
            {"text":"Ser mamá o ser papá en cuarentena, no es cosa fácil. ¡Que el confinamiento sea un buen pretexto para seguir estrechando lazos con la familia!"},
        ],
        "refs":[
        ]        
    },
    "prevenir-tratar-celulitis":{
        "image": lifestyle_8,
        "title":"Prevenir y tratar la celulitis. Descubre algunos hábitos importantes",
        "pars":[
            {"text":"La celulitis es uno de los problemas más frustrantes que viven las mujeres, y puede meterse dentro de nuestra piel, ¡literal!"},
            {"text":"De acuerdo con estudios internacionales, expertos estiman que afecta a más del 80% de las mujeres. Por predisposición, inmediatamente la asociamos con el sobrepeso, pero hay otros factores que afectan como la edad, genética, tipo de raza, circulación y estilo de vida. Descubre como prevenir y tratar la celulitis a continuación."},
            {"text":"En general, es muy difícil eliminar la celulitis al 100%, sin embargo, si combinamos esfuerzos, podemos lograr una mejora significativa y un mejor aspecto de la piel. Para esto, debemos de crear el tratamiento integral que incluya:",
                "list": [
                    "Una dieta equilibrada",
                    "Ejercicio constante (cardiovascular intercalado con pesas)",
                    "Tomar agua",
                    "Masajes linfáticos y procedimientos estéticos"
                ]
            },
            {"text":"A veces pensamos que estos procedimientos pueden ser muy dolorosos o que nos lleven incluso a poner un “break” en nuestra vida para poder dar el tiempo de reposo necesario, sin embargo, hay procedimientos como Cellfinate, que te ayudan a eliminar los hoyuelos de la celulitis."},
            {"text":"Si ya recurrimos a algún tipo de procedimiento, es importante conservar los resultados, por eso debemos reenfocarnos a nuestra alimentación."},
            {"text":"Llegar a nuestro peso ideal ayuda mucho a prevenir y reducir la celulitis. Es por eso que debemos de eliminar alimentos altos en azúcares, grasas y sal. Esto incluye refrescos, pasteles, chocolates, comida frita, empanizada, gratinada, embutidos, etc. Por el contrario, debemos agregar alimentos naturales sin conservadores, altos en grasas buenas, y vitaminas y minerales, como fruta, verdura, cereales integrales, aguacate y nueces sin sal."},
            {"text":"La hidratación, también es vital para una piel sana, ya que la deshidratación es uno de los principales factores de este trastorno. ¡Evitemos el alcohol y refrescos!"},
            {"text":"Algunos de los alimentos que nos pueden ayudar en este proceso son:"},
            {"title":"1.- Té Verde:", "text":"Las catequinas del té verde nos ayudan a mantener el colágeno en nuestro organismo. Para mayor beneficio, deja reposar la bolsita por más de 5 min o usa matcha en polvo."},
            {"title":"2.- Huevo:", "text":"Los huevos son ricos en prolina y lisina, los cuales son importantes para la producción de colágeno."},
            {"title":"3.- Frutos rojos:", "text":"Al ser altos en antioxidantes, incluyendo la vitamina C, nos ayudan a combatir los radicales libres, los cuales dañan el colágeno."},
            {"title":"4.- Caldo de pollo (natural)", "text":"El caldo de pollo como lo hacían nuestras abuelas es muy rico en colágeno natural, ya que incluye huesos y vísceras. Lo podemos incluir en nuestras sopas y guisados."},
            {"title":"5.- Proteínas como salmón:", "text":"Aparte de ser alto en omega 3, nos ayuda a crear músculo."},
            {"text":"Recuerda que cada cuerpo es diferente, por lo que debemos de amarlo y cuidarlo todos los días para lograr la victoria."},
            {"text":"Toma más agua, come más fruta, haz más ejercicio, ¡y se más feliz!"},
        ],
        "author":{
            "name":"L.N Vivian Sadovitch",
            "brief":"",
            "contacts":[
                {social:"Instagram",text:"@salud360", link:"https://instagram.com/salud360"},
                {social:"Facebook", text:"Salud360", link:"https://www.facebook.com/Salud360/"}
            ],
            "links":[
            ]
        },
        "refs":[]
    },
    "imagen-personal-tiempos-de-confinamiento":{
        "image": lifestyle_9,
        "title":"Tips para mejorar tu imagen personal",
        "pars":[
            {"text":"En algunas ocasiones, no nos arreglemos para quedarnos en casa, sin embargo, verse y sentirse bien es muy importante. Para ello, nuestra experta en imagen personal, Daniella Moreno, nos asesora y nos da los mejores tips para mejorar la imagen personal en tiempos de cuarentena."},
            {"text":"Nuestro estado de ánimo influye en nuestra vida e imagen de una manera importante desde que despertamos. Aquí te dejamos 9 prácticos tips para cuidar tu imagen durante el confinamiento:"},
            {"text":"1.- Dormir bien. Al menos 6 horas. Es muy importante descansar para que tu cuerpo se recupere, y si al despertar, complementas con meditación, te ayudará a sentirte mejor."},
            {"text":"2.- Levantarse temprano. ¡Deja atrás al oso dormilón y actívate para buscar tu mejor versión!"},
            {"text":"3.- Mantén una rutina diaria. Arréglate todos los días como si fueras a salir, es más, ponte perfume, te hará sentir bien."},
            {"text":"4.- ¿Comerías en una junta? Evita comer durante las conferencias o videollamadas, así evitarás sonidos molestos y te puedes deshacer de un mal hábito como lo es alimentarte entre horas."},
            {"text":"5.- Báñate todos los días. Todos. Sí, sabemos que de vez en cuando es complicado, porque prefieres dormir unas horas extra o dedicar ese tiempo libre a otra actividad, pero tú te sentirás mucho más cómoda si lo haces, además de que estarás lista por si hay una videollamada personal o de trabajo inesperada."},
            {"text":"6.- Si haces home office, selecciona un buen lugar para trabajar. ¡Dile adiós a tu cama o la cocina! Ubica un espacio cómodo en el que puedas desplazarte sin problemas."},
            {"text":"7.- Evita hablar o hacer acciones negativas. Recuerda el dicho: “todo lo que digas puede ser usado en tu contra”. No gastes energía de esa manera, es mejor ser positiva y mantenerte agradecida ante cualquier circunstancia."},
            {"text":"8.- Puntualidad, ante todo. Si no eres puntual, dejarás una mala impresión con jefes, compañeros de trabajo y hasta amigos. Llega siempre a tiempo a tus reuniones virtuales. "},
            {"text":"9.- Selecciona asertivamente el color de tu outfit, ya que refleja cómo te sientes. Esto es lo que expresan los colores:",
                "list": [
                    "Rojo: emoción, pasión, sexualidad y poder. Este color lo usan los líderes de opinión para representar seguridad.",
                    "Negro: elegancia y vanguardia. Representa el alto protocolo y objetividad.",
                    "Azul: frescura, calma y confianza.",
                    "Verde: vida y juventud.",
                    "Rosa: el idioma del amor y la alegría. (Tip: cuando lo usan los hombres, llaman más la atención)",
                    "Violeta: considerado el color de la realeza."
                ]
            },
            {"text":"Ante momentos de crisis, lo importante es empezar por ti. Sentirte bien por dentro y por fuera para que tu estado de ánimo influya en tus acciones. ¡Proyecta en ti, tu mejor versión!"},
        ],
        "author":{
            "name":"Daniella Moreno",
            "brief":"",
            "contacts":[
                {social:"Web", text:"", link:"http://www.daniellamoreno.com/index.php"},
                {social:"Instagram",text:"@salud360", link:"https://www.instagram.com/daniellamoreno/"},
                {social:"Facebook", text:"Salud360", link:"https://www.facebook.com/consultoradeimagen/"}
            ],
            "links":[
            ]
        },
        "refs":[]
    },
    "aprendiendo-leer-personas":{
        "image": lifestyle_10,
        "title":"¡Aprendiendo a leer a los demás a simple vista!",
        "pars":[
            {"text":"Los seres humanos somos seres sociales por naturaleza, necesitamos un sentido de pertenencia; tenemos la necesidad de interactuar con las demás personas todo el tiempo. Por esta razón, es importante tener en mente que la comunicación con los demás va más allá de lo que decimos. También comunicamos con nuestro lenguaje no verbal, es decir, nuestra vestimenta y arreglo personal, los gestos que hacemos, entre muchos otros detalles. Por eso, necesitamos mantenernos atentos porque por medio de los detalles vamos aprendiendo a leer a los demás, a conocer mejor a una persona a simple vista, y mejorar nuestras relaciones interpersonales."},
            {"title":"¿Cómo comunicarnos de una manera efectiva?","text":"La especialista en imagen, Daniella Moreno, nos da tips de cómo comunicarnos de una manera efectiva, usando el método del Yin Yang, que pertenece a una de las 5 artes chinas, que, en este sentido significan: ", 
                "list":[
                    "Yin: cara",
                    "Yang: apariencia"
                ]
            },
            {"text":"Para aplicar esta metodología, primero debemos de identificar 3 zonas de nuestro rostro. De acuerdo con el tamaño de cada una, hay que definir cuál es la zona más grande o predominante para ayudarnos a establecer el tipo de comunicación a utilizar con la persona que estamos viendo. Si la zona más grande es:",
                "list":[
                    "Frente a cejas: se trata de una persona racional, esto quiere decir que la comunicación tiene que ser de una manera muy especifica y asertiva. No enfocada a las emociones.",
                    "Cejas a nariz:  es una persona práctica, a la que se le tiene que hablar directo y sin rodeos antes de que se aburra y perdamos su atención.",
                    "Labios a mentón:  esta es una persona emocional, hay que hablarle desde el corazón, con palabras suaves y que apelen a los sentimientos."
                ]
            },
            {"text":"¡Pero esto no es todo! El resto de los rasgos, nos ayudan a complementar para seguir definiendo la personalidad: "},
            {"title":"Un párpado más caído que el otro", 
                "list":[
                    "Párpado derecho: indica una predominancia “femenina”, es decir, que es una persona creativa y dulce.",
                    "Párpado izquierdo: indica una predominancia “masculina”, esto quiere decir que es una persona más analítica, racional y lógica."
                ]},
            {"title":"Forma de los ojos ",
                "list":[
                    "Ojos hacia abajo: es una persona que tiende a deprimirse fácilmente.",
                    "Ojos separados: puede ser algo impulsivo e intolerante.",
                    "Ojos pequeños: seguramente se trata de alguien analítico, emprendedor e intuitivo.",
                    "Ojos grandes: es una persona romántica e imaginativa."
                ]
            },
            {"title":"Nariz",
                "list":[
                    "Nariz ancha: es una persona que tiende a no escatimar tanto sus gastos.",
                    "Nariz visible y puntiaguda: se trata de una persona más ahorradora.",
                    "Nariz chata y pequeña: seguramente estás ante una persona emocional y cálida."
                ]
            },
            {"title":"Líneas de expresión",
                "list": [
                    "Líneas ascendentes: expresan felicidad.",
                    "Líneas descendentes: expresan tristeza."
                ]
            },
            {"title":"Dientes",
                "list":[
                    "Dientes ordenados y blancos: riqueza y buena salud.",
                    "Dientes desordenados: inestabilidad financiera, pueden llegar a ser chismosos.",
                    "Dientes hacia fuera: éxito fuera del país.",
                    "Dientes hacia adentro: persona solitaria."
                ]
            },
            {"text":"Estos son algunos detalles de la comunicación no verbal que se han identificado y que se presentan en la mayoría de las personas, sin embargo, es posible que en ciertos casos, esta “lectura” no coincida al 100% con la personalidad de alguien, pero sí que nos sirve de base para entender más a los demás."},
            {"text":"Y no solo eso, son detalles importantes a tomar en cuenta al momento de realizar un cambio o un procedimiento estético, ya que la forma que le des a tus rasgos o expresión, impactará en la imagen y personalidad que proyectas.",},
        ],
        "author":{
            "name":"Daniella Moreno",
            "brief":"",
            "contacts":[
                {social:"Web", text:"", link:"http://www.daniellamoreno.com/index.php"},
                {social:"Instagram",text:"@salud360", link:"https://www.instagram.com/daniellamoreno/"},
                {social:"Facebook", text:"Salud360", link:"https://www.facebook.com/consultoradeimagen/"}
            ],
            "links":[
            ]
        },
        "refs":[]
    },
    "tips-cumplir-dieta":{
        "image": lifestyle_11,
        "title":"5 Tips para cumplir con tu dieta",
        "pars":[
            {"text":"Estar a dieta puede ser muy tedioso, y muchas veces nos parece imposible de lograr. ¡No te preocupes! Que te tenemos los mejores tips para ayudarte a cumplir tu dieta, ayudarte para que el proceso sea más sencillo y logres tus objetivos a través del mindful eating."},
            {"text":"¿Qué es el mindful eating? Es estar consciente de lo que comemos, desde el desayuno hasta la cena. Antes de ingerir cualquier bebida o alimento, pregúntate si realmente tienes hambre o antojo. Si tu respuesta es sí, sigue estos consejos:"},
            {"title":"1. TOMA UN VASO DE AGUA NATURAL ANTES DE CADA COMIDA","text":"Hidratarte antes de cada comida te servirá para aumentar tu consumo de agua, además de calmar a tu estómago y a tu cerebro de la señal de hambre. Es importante no confundir el hambre con la sed, por lo que terminarás comiendo menos."},
            {"title":"2. ¡INCLUYE MÁS VERDURAS EN TU DIETA!","text":"Incluye por lo menos 1 taza de verduras crudas o 1 taza de verduras cocidas en cada tiempo de comida. Esto te servirá para aumentar el volumen de tus platillos sin comer de más. "},
            {"title":"3. HAZ TUS COLACIONES A TIEMPO","text":"Evita llegar al siguiente tiempo de comida con mucha hambre. Es importante respetar tus horarios de colaciones, por lo que, es importante que no pases más de 4 horas sin comer."},
            {"title":"4. ELIGE ALIMENTOS MÁS POR MENOS","text":"Existen alimentos que en mayor cantidad tienen menos calorías que otros. ¿Cómo cuáles? Si tienes mucha hambre, puedes optar por esas opciones:",
                "list":[
                    "Tortillas de nopal",
                    "Tostadas horneadas",
                    "Todos los frutos rojos",
                    "Pan light",
                    "Fruta",
                    "Tostadas de arroz",
                    "Palomitas infladas",
                    "Vegetales"
                ]
            },
            {"title":"5. UTILIZA LOS ALIMENTOS LIBRES","text":"Se les llama alimentos libres porque contienen menos de 20 calorías y menos de 6 gramos de carbohidratos por porción. Existen muchos alimentos libres en las dietas que podrás consumir sin culpas en cualquier momento, por ejemplo:",
                "list":[
                    "Apio",
                    "Lechuga",
                    "Espinaca"
                ]
            },            
            {"text":"¡Recuerda! Es importante cambiar tus hábitos para lograr un cambio en tu estilo de vida y lograr un cambio permanente para olvidarnos de las dietas."},
        ],
        "author":{
            "name":"L.N Vivian Sadovitch",
            "brief":"",
            "contacts":[
                {social:"Instagram",text:"@salud360", link:"https://instagram.com/salud360"},
                {social:"Facebook", text:"Salud360", link:"https://www.facebook.com/Salud360/"}
                ],
            "links":[
            ]
        },
        "refs":[]
    },
    "alimentos-piel-increible":{
        "image": cosmetics_9,
        "title": "Alimentos para una piel increíble",
        "pars":[
            {"text":"La nutrición es importante para la salud. Una dieta poco saludable puede dañar tu metabolismo, causar un aumento peligroso de grasa corporal e incluso dañar órganos como el corazón y el hígado. Lo que comes también afecta a otro órgano: tu piel. A medida que los científicos aprenden más sobre la dieta y el cuerpo, queda cada vez más claro que todo lo que comes puede afectar significativamente la salud y el envejecimiento de tu piel, y para darle siempre lo mejor, incluye estos alimentos para tu piel:"},
            {"title":"1.- Pepino", "text":"El pepino contiene una gran cantidad de agua, lo cual hidrata la piel y está lleno de enzimas con propiedades antiinflamatorias. Recuerda que la hidratación es clave para una piel joven. También contiene vitaminas B y C, zinc, hierro, ácido fólico, calcio, magnesio y potasio. "},
            {"title":"2.- Algas", "text":"Las algas marinas son ricas en vitaminas B y minerales como el hierro, que ayuda mejorar el flujo sanguíneo, lo que contribuye a una piel radiante. También es naturalmente bajo en sodio, lo que evita la hinchazón. Además, es alto en yodo, mejorando la función de la tiroides y el metabolismo. Elige una ensalada de algas marinas cuando vayas por sushi o agrégale alga espirulina a tus licuados de la mañana."},
            {"title":"3.- Kale y espinacas", "text":"Los vegetales de color verde oscuro son excelentes para ti, ya que son una fuente excelente de vitaminas A, C y E, con potentes propiedades antienvejecimiento y que ayudan a promover el crecimiento celular. El Kale también está cargado de minerales, como magnesio y calcio, necesarios para una piel sana."},
            {"title":"4.- Semillas o pepitas de calabaza", "text":"Son altas en vitaminas B y biotina, un nutriente esencial para fortalecer el cabello, las uñas y la piel. Las semillas de calabaza también son ricas en minerales, como el zinc, si le falta a tu cuerpo, puede provocar problemas como el acné. ¡A mí me encanta agregarlas a mi yogurt, ensaladas o solas!"},
            {"title":"5.- Cítricos", "text":"Toronja, limón, naranja, mandarina, lima… todos muy ricos en vitamina C, la cual es un potente antioxidante que interviene en la formación de colágeno, una proteína necesaria para que la piel conserve su firmeza y elasticidad. También conseguirás un buen aporte de vitamina C, comiendo verduras como los pimientos morrones, col, y frutas como la guayaba y el kiwi."},
            {"title":"6.- Pescados", "text":"Los pescados grasos como el salmón, contienen ácidos grasos omega-3 que pueden reducir la inflamación y mantener la piel hidratada. También son una buena fuente de proteínas, vitamina E y zinc de alta calidad."},
            {"text":"Todos estos alimentos pueden formar parte de tu dieta diaria, evita los productos ultra procesados llenos de azúcar añadida o grasas trans, que son los principales causantes del acné y otras afecciones de la piel."},
        ],
        "author":{
            "name":"Dra. Julia Salinas Dücker - Médico especialista en Nutrición Clínica ",
            "contacts":[
                {social:"Twitter", text:"", link:"https://twitter.com/juliasalinas"},
                {social:"Facebook", text:"", link:"https://www.facebook.com/antojodiabolico/"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/antojodiabolico/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    "tips-presupuesto-belleza":{
        "image": lifestyle_12,
        "title": "5 Tips para incluir la belleza y el cuidado personal en tu presupuesto",
        "pars":[
            {"text":"La palabra “presupuesto” tiene mala reputación. Algunos piensan que es algo muy complicado de hacer, que requiere de mucho tiempo y que contiene fórmulas complicadas. Es importante entender que el presupuesto solo requiere de sumas y restas, y te va a ayudar a llevar un mejor control de tus finanzas para tomar mejores decisiones con tu dinero e incluir la belleza."},
            {"text":"Leíste bien, es tu dinero, decisiones, gustos y metas. El presupuesto es algo muy personal en lo que solo tú tienes el poder para planear en qué quieres gastar e invertir."},
            {"text":"Aquí te dejo algunos consejos para empezar tu presupuesto y cómo dedicar una parte para tu cuidado personal, para verte y sentirte bien en todo momento:"},
            {"title":"1.- Lo que no se mide no se puede mejorar.", "text":"Registra tus gastos para saber en qué se te está yendo el dinero, esto te ayudará a eliminar de tu mente el famoso “no me alcanza”. Puedes utilizar la herramienta que mejor te acomode para empezar a registrar todos tus gastos, ya sea en una libreta, en excel, en las notas de tu celular o en alguna App (Fintonic, Monefy, Financiator, Yona, entre otras). Te recomiendo registrar todo por lo menos en un periodo de un mes, para que puedas identificar cuáles están siendo tus gastos hormiga, es decir, esos pequeños gastos que parecen inofensivos pero que a la larga suman una buena cantidad de dinero."},
            {"title":"2.- Haz un presupuesto personal con base en lo anterior:", "text":"Puedes utilizar la regla 50/20/30 (gastos fijos 50%, ahorro 20%, gastos variables 30%).",
                "list":[
                    "Gastos fijos: necesidades básicas como renta, luz, agua, gas, etc. En esta parte también puedes incluir todo aquello que es importante para ti y que te hace sentir bien, como una buena alimentación, una suscripción a un gimnasio, el kit de belleza, algún tratamiento, o hasta la terapia con la psicóloga. Recuerda que para estar bien con los demás, primero debes estar bien contigo mismo.",
                    "Ahorro: lo más fácil para encontrar tu motivación es tener claras tus metas, pues si no tienes planes, el dinero desaparece muy fácil. Con este ahorro puedes darte ciertos lujos que quieras, cumplir tus objetivos y lograr tu libertad financiera.",
                    "Gastos variables: entretenimiento, bares, restaurantes, gastos extras."
                ]},
            {"text":"Te recomiendo dividir tu ahorro en 4 rubros:", "list":[
                "1.- Fondo de emergencia: por lo menos 2 meses de tus gastos para cubrir cualquier imprevisto.",
                "2.- Corto plazo: ¿qué te gustaría cumplir en 1 año? Tal vez un viaje, tratamientos de belleza, artículos o experiencias que quieras vivir.",
                "3.- Mediano plazo: ¿qué te gustaría cumplir en 5 o 10 años? Comprar un coche, una casa, una maestría, etc.",
                "4.- Largo plazo: ¿qué te gustaría cumplir en más de 10 años? Tener el dinero suficiente para la educación de tus hijos, planear tu retiro, poner un negocio, etc. Recuerda que no por ser la meta más lejana es menos importante. " 
            ]},
            {"text":"Para cada ahorro encuentra el mejor instrumento de inversión. Te invito a seguirme en Instagram @finanzasconfa donde podrás encontrar algunos ejemplos que te ayudarán a tomar mejores decisiones."},
            {"text":"La fórmula de 50/20/30 no es regla, puedes acomodar tus porcentajes según tus necesidades."},
            {"title":"3.- Presupuesto en pareja.", "text":"Cuando ya vives con tu pareja, es importante que ajusten sus cuentas y revisen sus metas. Platiquen desde el principio cómo se van a administrar y hagan un presupuesto en pareja, según el modelo que quieran seguir. Aquí te dejo algunos ejemplos:", "list":[
                "a) Tú y yo somos uno mismo: se hace un solo presupuesto familiar y todo es de los dos.",
                "b) Vamos juntos al antro, pero cada quien sus cubas: se hace un presupuesto para los gastos en común, pero el restante cada uno lo utiliza para sus gastos personales.",
                "c) Roomies: se dividen en partes iguales los gastos del hogar, pero cada quien hace su propio presupuesto.",
                "d) Solo uno es económicamente activo: cuando deciden que solamente uno va a generar el ingreso familiar."
            ]},
            {"text":"Los modelos pueden cambiar en cada etapa de la pareja, pero es importante que siempre estén en constante comunicación. Revisen qué es importante para cada uno para que lo incluyan en su presupuesto. "},
            {"title":"4.- Presupuesto para tu boda", "text":"Muchas veces pensamos en el lugar, la música, la comida o el alcohol, pero dejamos al último nuestro arreglo personal. Te recomiendo que pongas en tu presupuesto “tratamientos de belleza” para estar radiante en tu gran día. Desde el maquillaje, el peinado, un día de spa y definir con tiempo si te quieres realizar algún tipo de tratamiento de belleza, ya sea en el cuerpo o en el rostro. Puedes ir con un dermatólogo o especialista para que te diga cuál es el mejor tratamiento para ti, ya sea para hidratar tu piel, eliminar acné, disminuir arrugas o lo que necesites para sentirte bien. Ojo: aunque ya tengas un presupuesto apretado por los gastos de la boda, ten cuidado con quién te haces los tratamientos para no caer en una estafa por buscar lo más barato. Si le vas a invertir, que sea algo que valga la pena para tu gran día. Recuerda que lo barato puede salir muy caro."},
            {"title":"5.- ¿La belleza es una inversión o un gasto? La pregunta del millón.", "text":"Definición de inversión: es una actividad que consiste en dedicar recursos con el objetivo de obtener un beneficio de cualquier tipo. Los recursos pueden ser tiempo, trabajo o capital (dinero)."},
            {"text":"Si revisamos la definición, la belleza puede ser una inversión, porque si le destinas dinero y tiempo, pero obtienes el resultado que buscabas, entonces está valiendo la pena la inversión. Sin embargo, también puede ser un gasto cuando realmente no lo estás aprovechando, ni le estás dedicando el tiempo y por eso no te genera un resultado. "},
            {"text":"Por ejemplo, si compras una crema que te tendrías que poner diario para que tenga un efecto positivo, pero te la pones cuando te acuerdas, entonces eso sí sería un gasto y estarías tirando el dinero a la basura. En cambio, si ahorras e inviertes en un buen tratamiento y obtienes un buen el resultado, entonces podrías decir que la inversión valió la pena. En conclusión, para mí, puede ser un gasto o una inversión, dependiendo del uso y el resultado."},
            {"text":"Invierte en ti, en lo que te haga feliz y saca la mejor versión de ti todos los días. Incluye en tu presupuesto lo que te haga sentir bien para verte bien."}
        ],
        "author":{
            "name":"Fátima González Franco Haghenbeck - Coach de finanzas personales y seguros",
            "contacts":[
                {social:"Facebook", text:"", link:"https://www.facebook.com/finanzasconfa"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/finanzasconfa/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    "beauty-menu":{
        "image": lifestyle_13,
        "title": "Beauty Menú: una receta rica y saludable",
        "pars":[
            {"text":"¿Hoy quieres cocinar algo diferente? Sabemos que en el día a día a veces nos da flojera meternos a la cocina. Pero siempre podemos cocinar algo sencillo y nutritivo en casa. ¡Date la oportunidad de consentirte! En esta ocasión, Betterself en conjunto con el Chef Gerardo Vazquez Lugo, del restaurante Nico’s, presentan el “Beauty Menu”."},
            {"text":"En esta ocasión, Betterself en conjunto con el Chef Gerardo Vazquez Lugo, del restaurante Nico’s, presentan el “Beauty Menu”."},
            {"text":"Para hablar de Nico’s, tenemos que remontarnos a 1957 en la ciudad de México. Un lugar de tradición familiar y de la cocina mexicana casera y emotiva. A lo largo de los años, Nico’s se ha convertido en toda una experiencia culinaria. Gerardo Vazquez Lugo mantiene el concepto de cocina tradicional, a través de productos artesanales, además, es considerado uno de los 50 mejores restaurantes de Latinoamérica."},
            {"text":"Esta receta está inspirada en el Mediterráneo, una de las cocinas más admiradas y respetadas del mundo. En el 2013 fue considerada como Patrimonio Inmaterial Cultural de la Humanidad por sus grandes beneficios a la salud. "},
            {"text":["Nuestro “Beauty Menú” consiste en una entrada de ", <span className="texto-pink">pimientos confitados</span>, " y un plato fuerte de ", <span className="texto-pink">jitomates rellenos de pollo y champiñones enyerbados bañados en salsa de yogur griego</span>,". ¿List@s? ¡Empecemos!"]},
            {"text":"Para los pimientos confitados, necesitarás:", "list":[
                "Pimientos rojos: 500 gramos o 6 piezas",
                "Ajo: 25 gramos o 3 dientes",
                "Tomillo: 5 gramos o media cucharada",
                "Mejorana: 5 gramos o media cucharada",
                "Laurel: 3 hojas",
                "Orégano: 10 gramos o una cucharada",
                "Pimienta negra: 10 piezas",
                "Aceite de oliva: 200 mililitros",
                "Aceite de maíz: 200 mililitros",
                "Frasco estilo “Mason Jar”: 1"
            ]},
            {"text":"Antes de empezar a cocinar, es importante conocer más de los nutrientes que te aportan los principales ingredientes – de acuerdo a la L.N Vivian Sadovitch:"},
            {"title":"Pimientos rojos", "text":"Los pimientos rojos contienen vitamina A, importante para una buena visión y un sistema inmune fuerte. Además, son ricos en antioxidantes, lo cual, retrasa el envejecimiento. Esta hortaliza es una gran fuente de vitamina B6 y magnesio, lo que se traduce en menos ansiedad, menos inflamación e incluso previene la hipertensión. Además, el licopeno, la sustancia que le da el color rojo, tiene propiedades muy importantes en la prevención de enfermedades crónicas como varios tipos de cáncer o enfermedades cardiovasculares y degenerativas."},
            {"title":"Aceite de oliva", "text":"Siempre es recomendable invertir en un buen aceite de oliva. Puntos extra si es prensado en frío. ¿Por qué y qué significa “prensado en frío”? Te explicamos: es aquel que se ha obtenido a una temperatura menor de 27ºC, y garantiza que todas las propiedades del aceite permanezcan intactas, siendo de máxima calidad."},
            {"title":"Mejorana y laurel", "text":"Ideales para la digestión. ¡Así que, a comer sin culpa!"},
            {"title":"Procedimiento:", "list":[
                "Coloca a fuego directo, ya sea en estufa o al carbón, 6 pimientos rojos hasta que la piel del pimiento se vuelva color negro.",
                "Retira del fuego y sin ocupar agua, retira la piel del pimiento. Puedes apoyarte con una servilleta o papel.",
                "Rebana 3 dientes de ajo en pedazos pequeños.",
                "Coloca en la estufa ¼ de taza de aceite, y los ajos en una cazuela para dorarlos.",
                "Corta en cubos grandes los 6 pimientos rojos.",
                "En un frasco, vacía por capas, los cubos de pimiento, agregando las hierbas de olor, pimienta negra, sal y orégano. Hazlo consecutivamente. ",
                "Rellena el restante del frasco con aceite de oliva para confitarlos."
            ]},
            {"text":["Vamos con el plato fuerte: ", <span className="texto-pink">champiñones enyerbados bañados en salsa de yogur griego.</span>]},
            {"title":"Ingredientes:", "list":[
                "Jitomate bola: 4 piezas",
                "Champiñones: 250 gramos",
                "Pechuga mediana: 1 pieza",
                "Ajo: 2 dientes",
                "Aceite de oliva: 2 cucharadas",
                "Yogurt griego: ½ taza",
                "Hierbas de olor: ¼ de taza"
            ]},
            {"text":"Además de rica y nutritiva, es una receta súper rápida. Sus beneficios:"},
            {"title":"Jitomate", "text":"Es un vegetal con un buen contenido de fibra, además, se compone de agua y contiene pocas calorías. Otro beneficio es que contiene vitaminas A, C y E, considerados como antioxidantes que mejoran el sistema inmune. También, es rico en potasio, manteniendo el cuerpo hidratado, evitando dolores o calambres. Y no olvidemos los licopenos, para evitar enfermedades cardiovasculares, degenerativas y cáncer."},
            {"title":"Champiñones", "text":"Gracias a su contenido rico en selenio, los champiñones ayudan a estimular la formación de células T, las cuales combaten las células cancerosas. Es una rica fuente de fibra, mejoran los niveles de insulina en la sangre, y ayudan a prevenir las enfermedades cardiovasculares."},
            {"title":"Yogur griego", "text":"¡Cremoso y súper rico! El yogur griego aporta proteínas fáciles de asimilar para reparar tejidos, mantener sano el sistema muscular y estimular el crecimiento celular. Los probióticos que contiene, cuidan la flora intestinal para preservar un buen funcionamiento digestivo."},
            {"title":"Procedimiento:", "list":[
                "Ahueca y salpimienta los jitomates con aceite de oliva.",
                "Hornea, junto con la carne del jitomate por aproximadamente 15 minutos."
            ]},
            {"title":"Relleno:", "list":[
                "Corta el pollo y los champiñones en cubos.",
                "Pica finamente los ajos.",
                "Hornea con sal, pimienta y aceita de oliva durante 20 minutos.",
            ]},
            {"title":"Salsa:", "list":[
                "Licúa la carne del jitomate junto con el yogur, la sal y la pimienta.",
                "Rellena los jitomates con los ingredientes previos.",
                "Hornea por 10 minutos más y sácalos del horno para bañarlos con la salsa de jitomate y yogur."
            ]},
            {"text":["Puedes ver más detalles sobre la preparación y los ingredientes en la transmisión ubicada en nuestro perfil de Instagram: ", <a className="Link texto-pink" href="https://www.instagram.com/tv/CGBesrOHNPB/?igshid=wnooemd8cfxk" rel="noopener noreferrer"  target="_blank">@betterselfmx</a>, "."]},
            {"text":["Por último, no olvides seguir a Vivian Sadovitch (",<a className="Link texto-pink" href="https://instagram.com/salud360?igshid=atcwmwm67hw7" rel="noopener noreferrer"  target="_blank">@viviansadovitch</a>,") al Chef Gerardo Vázquez Lugo (",<a className="Link texto-pink" href="https://www.instagram.com/chefgevalu/" rel="noopener noreferrer"  target="_blank">@chefgevalu</a>,") y Betterself (", <a className="Link texto-pink" href="https://www.instagram.com/betterselfmx/" rel="noopener noreferrer"  target="_blank">@betterselfmx</a>, ") para más contenido diseñado para encontrar tu mejor versión."]},
            {"text":"Listo, a disfrutar…saludable. ¡Provecho!"}
        ],
        "author":{
            "name":"BetterSelf",
            "contacts":[
                {social:"Web", text:"", link:"https://betterself.mx"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    "beneficios-caldo-hueso":{
        "image": lifestyle_14,
        "title": "Beneficios del caldo de hueso",
        "pars":[
            {"text":"El caldo de hueso se prepara y consume desde hace muchos años, a través de muchas generaciones y por diversas culturas. Forma parte de la base para muchas sopas, salsas y remedios caseros de abuelita contra la gripe. También tiene usos históricos para tratar dolencias que afectan los tejidos conectivos, como el tracto gastrointestinal, las articulaciones, la piel, los músculos y la sangre."},
            {"text":"Ahora que está muy de moda, déjame contarte en qué te puede ayudar. Básicamente, este caldo contiene los nutrientes que están en todos los huesos, los cuales son minerales súper importantes como el calcio, fósforo, magnesio, sodio y potasio. Además, es muy rico en colágeno, el cual es una proteína conformada por tres aminoácidos: glicina, prolina y lisina que tienen muchísimos beneficios para tu cuerpo. Por ejemplo: ",
                "list":[
                    "Ayuda a fortalecer tu cabello, uñas y piel: Las fibras de colágeno y elastina son parte de la estructura de todos estos, favoreciendo el crecimiento saludable de cabello, uñas y mejorando la elasticidad de la piel.",
                    "Apoya la función hepática: La glicina y la prolina son necesarios para el correcto funcionamiento de tu hígado, el cual nos ayuda a deshacernos de sustancias tóxicas, formar proteínas y para almacenar parte del glucógeno del cuerpo.",
                    "Evita problemas intestinales: La glutamina, otro componente presente en el caldo de hueso, es un componente vital de la pared intestinal.  Si tienes problemas digestivos crónicos, sensibilidades o intolerancias, el caldo de huesos te ayudará a sobrellevarlos mejor.",
                    "Mejora tu sistema inmune: Gran parte de tu sistema inmune depende de tus intestinos, por lo que mantenerlo sano te ayudará a protegerte de enfermedades y a recuperarte de ellas más rápido. Al agregar también los cartílagos al caldo de hueso, estimularás tus células inmunitarias ya que sus proteínas pueden nutrir, tanto el intestino como tu sistema inmunitario.",
                    "Fortalecimiento de las articulaciones: El caldo de huesos contiene glucosamina y condroitina, los cuales te ayudan a mantener y a reparar tus articulaciones. Se ha comprobado que, para personas mayores y deportistas, el colágeno hidrolizado o el caldo de hueso ayuda a reducir dolores articulares.",
                    "Fortalecimiento de las articulaciones: El caldo de huesos contiene glucosamina y condroitina, los cuales te ayudan a mantener y a reparar tus articulaciones. Se ha comprobado que, para personas mayores y deportistas, el colágeno hidrolizado o el caldo de hueso ayuda a reducir dolores articulares."
                ],
                "text":"Recuerda que, si lo vas a comprar ya hecho, asegúrate de que sus ingredientes sean 100% naturales y que los huesos que utilizan o que tu utilices en casa, provengan de un buen rastro, para así evitar que contengan metales pesados que disminuyan sus beneficios. "
            },
        ],
        "author":{
            "name":"Dra. Julia Salinas Dücker",
            "contacts":[
                {social:"Twitter", text:"", link:"https://twitter.com/@juliasalinas"},
                {social:"Facebook", text:"", link:"https://www.facebook.com/antojodiabolico"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/antojodiabolico"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    "alimentacion-acido-hialuronico":{
        "image": lifestyle_15,
        "title": "Alimentación y ácido hialurónico",
        "pars":[
            {"text":["El ácido hialurónico, también conocido como hialuronano, es una sustancia transparente y pegajosa que el cuerpo produce de forma natural. Uno de los precursores del colágeno. Las mayores cantidades se encuentran en la piel, el tejido conectivo y los ojos. Tiene como función principal retener agua para mantener tus tejidos bien lubricados y húmedos. El ácido hialurónico tiene una variedad de usos, muchas personas lo toman como un suplemento, pero también se usa en sueros tópicos, gotas oculares e inyecciones, ya que, lamentablemente, a partir de los 35 años tu cuerpo comienza a disminuir su producción, generando arrugas, líneas de expresión e incluso dolor en las articulaciones."]},
            {"text":"Esta molécula la encontramos de manera natural en la proteína animal, especialmente en carne de ternera, cordero, pato, pavo; pescados como el bacalao, y en mayor cantidad en el cartílago de los huesos como las aletas de tiburón, crestas y patas de gallina. Si no eres fan de éstos últimos, por suerte, puedes ayudarle a tu cuerpo a sintetizarlo mejor con otro tipo de alimentos ricos en vitaminas, minerales y aminoácidos esenciales para la formación de esta molécula.",},
            {"text":"Los alimentos que más ayudan a elevar tus niveles de ácido hialurónico son:",
                "list":[
                    "El perejil y el cilantro son altos en vitamina C, la cual es un antioxidante protector que mejora la formación de ácido hialurónico. ",
                    "La papa y el camote, pues los vegetales almidonados pueden aumentar la producción de ácido hialurónico en el cuerpo, ya que son ricos en ciertas proteínas, vitaminas y antioxidantes. ",
                    "La soya eleva tus niveles de estrógenos, y también de ácido hialurónico.",
                    "El cacao es alto en magnesio y sin el, no se forma correctamente esta molécula.",
                    "Los cereales integrales y semillas de calabaza son ricos en zinc, el cual es un mineral esencial para su formación.",
                    "Frutas y verduras de hoja verde oscuro en general, pues cada una aporta vitaminas, minerales y antioxidantes necesarios para la salud de tu piel.",
                    "Agua: Si tu cuerpo no está bien hidratado, entonces el ácido hialurónico no podrá funcionar adecuadamente.",
                    "Espinacas, brócoli, coles de bruselas y otras verduras ricas en magnesio, mineral esencial para la síntesis del ácido hialurónico."
                ],
            },
            {"text":"Y como siempre les digo, nada funciona si no llevamos una dieta saludable, por lo que consumir frutas y verduras ricas en antioxidantes, fibra, grasas saludables, alimentos con proteína vegetal, animal y carbohidratos complejos en las cantidades que tu cuerpo necesita, es básico para una apariencia bella y sana. "}
        ],
        "author":{
            "name":"Dra. Julia Salinas Dücker",
            "contacts":[
                {social:"Twitter", text:"", link:"https://twitter.com/@juliasalinas"},
                {social:"Facebook", text:"", link:"https://www.facebook.com/antojodiabolico"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/antojodiabolico"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },    
    "mejor-version-casa":{
        "image": lifestyle_16,
        "title": "Logra tu mejor versión desde casa y de manera gratuita",
        "pars":[
            {"text":"El 2020 supuso muchos retos y estar en casa cada vez se vuelve más retador. Si eres de los que ya pasó por las diferentes etapas, desde la negación hasta la resignación, y ya pasaste por el cambio de look, las 200 series en plataformas de streaming, 2000 películas y 34803 challenge en redes sociales, entonces en este blog te diremos cómo lograr tu mejor versión.",},
            {"text":"Seguramente has escuchado la frase o el comentario de alguien que dice que quien no sale más “letrado” de esta pandemia o con cuerpazo no aprovechó ni entendió nada. Y si bien es cierto que ahora tenemos oportunidad de cambiar nuestra rutina y distribuir de mejor manera el tiempo (o no) tampoco es una regla general para todos. Además de que cada quien lo vive de forma distinta, si eres mamá y trabajas seguro es un reto hacerla de maestra, chef, cenicienta, mujer empoderada e independiente y profesionista al mismo tiempo. O hay personas a las que anímicamente el dejar de ver a familiares y amigos o simplemente el no salir les ha costado más que nada.",},
            {"text":"Algo es seguro, todo esto también pasará. Y si tu propósito de nuevo año es intentar hacer algo por ti, ¿por qué no empezar por un podcast? Los podcast son una opción muy práctica para escuchar y aprender de nuevos temas sin necesidad de tomar una clase y ser evaluado, o de centrar totalmente tu capacidad visual y auditiva para ello. Puedes estar haciendo otras cosas mientras escuchas tu podcast favorito. Aquí te damos unos tips para decidirte a escuchar alguno:",
                "list":[
                    "1. Elige uno que te haga sentir bien, puede ser de temas de suspenso, de bienestar, de psicología, finanzas o de todo eso junto.",
                    "2. Haz otras cosas mientras escuchas, a diferencia de un libro no necesitas dedicarle el 100% de tu atención.",
                    "3. No te aburrirás. Los podcast generalmente no son consecutivos a diferencia de algunas series, puedes elegir uno que aborde temas distintos en cada capítulo.",
                    "4. Son una manera de autoayuda, un tiempo para ti, para conectar contigo misma, aprender cosas nuevas y ayudarte a encontrar tu mejor versión.",
                    "5. Tendrás la asesoría o la opinión de expertos de manera gratuita.",
                ],
            },
            {"text":["¿Qué te parece? ¿Los has escuchado o te han dado ganas de escucharlos? Cuéntanos en redes sociales, estamos como @betterselfmx ¡Ahora también puedes buscarnos en ", <a href="https://open.spotify.com/show/3sYqYGwjF8dvb24z1oJmsR?si=CX9wAfSnTfKfFRfn0DUyeg" rel="noopener noreferrer" target="_blank" class="texto-pink">Spotify</a>, "!"]}
        ],
        "author":{
            "name":"BetterSelf",
            "contacts":[
                {social:"Facebook", text:"", link:"https://www.facebook.com/betterselfmx"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    "elixir-felicidad":{
        "image": lifestyle_17,
        "title": "El elixir de la felicidad",
        "pars":[
            {"text":[<iframe src="https://open.spotify.com/embed-podcast/episode/3beYJPR4FX6BLRM3N1X8J7" width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media" title="Podcast E1"></iframe>]},
            {"text":"La felicidad es un tema subjetivo y que a veces pareciera inalcanzable. Sin embargo, ¿te has puesto a pensar qué sería de la felicidad sin la tristeza? El tener esos momentos oscuros es lo que nos permite saborear y disfrutar la plenitud. Algunos lo llaman el elixir de la felicidad.",},
            {"text":"Pero ¿qué es realmente la felicidad? Elsa Rentería la define como disfrutar el hoy y el ahora.  Es estar tranquilos, sin estar tan preocupados o metidos en el pasado o el futuro sino vivir el hoy. Gozar el momento por bueno o malo que sea. Ser feliz no significa que todo el tiempo debes estar sonriendo o riendo a carcajadas, pero sí disfrutar los pequeños detalles.",},
            {"text":"Dicho esto, te compartimos la receta para el elixir de la felicidad según Elsa Rentería:",
                "list":[
                    "1. Abraza los sentimientos que llegan a ti pero sin que se queden por siempre, vívelos y déjalos ir.",
                    "2. De todo lo malo también se aprende, sin importar que haya sido la relación más tóxica de la vida también te cambió y aprendiste qué es lo que no quieres y no mereces.",
                    "3. Cada quien es feliz con lo que le gusta, es decir, antes se definía la felicidad como tener la casa, el matrimonio, los hijos, etc. Ahora para algunos puede ser un trabajo, un hobbie, un viaje, un trabajo soñado, etc. Los tiempos cambian y esos tiempos en que nos juzgaba la abuela ya pasó.",
                    "4. Rodéate de gente chida, crea tu comunidad, tu tribu, que te entiendan en lugar de juzgarte.",
                    "5. Haz lo que a ti te gusta, por ejemplo, píntate el pelo, rellénate los labios, etc. Químicamente el arreglarte tiene un efecto en el cerebro, te ayuda a segregar las sustancias de la felicidad. A fin de cuentas la felicidad es como un orgasmo, son reacciones químicas pero en diferente zona.",
                    "6. Cuídate física, emocional y mentalmente, come bien, haz ejercicio. Sí hay días que no te sientes bien y no quieres hacer nada, pero en la medida de lo posible hay que echarle ganas. Un día a la vez.",
                    "7. Llena tu vida de amor, de tus seres queridos, de los animales, de la música, de un libro, etc. El amor está en los seres vivos y en otras cosas.",
                    "8. Haz una pausa una o dos veces al año para saber lo que quieres ahora y ahorita. Desde cosas sencillas como meterte al yoga, hacerte un facial, viajar, etc. Lo que quieres hoy no es lo mismo que querías hace 5 años.",
                    "9. Aprende a quererte, conociéndote. Date cuenta quién eres, qué te gusta, ten tus cosas favoritas, qué fue lo que disfrutaste. Disfrútate mucho, ama tu cuerpo, chuleate. No de una manera superficial, sino desde el interior.",
                    "10. Por último, deja de compararte y comparar tu vida con la de otros. Y este punto va muy enfocado a las redes sociales, nos dan una idea falsa de la felicidad. Recuerda depurar lo que no suma a tu vida."                
                ],
            },
            {"text":"En resumen, trabaja en ti, en lograr tu mejor versión. Y eso incluye muchos aspectos de tu vida, es un trabajo constante y una inversión a futuro. Sobre todo en estos tiempos de quedarnos en casa, más nos vale caernos bien porque ahora estamos todo el tiempo con nosotros mismos. Antes era muy fácil apagar nuestros pensamientos o sentimientos hablando en el trabajo con otras personas o en reuniones de familia o amigos. Ahora nos toca dedicarnos tiempo a nosotros, a mimarnos y descubrir cuál es nuestro siguiente paso para lograr la combinación perfecta de la felicidad."},
            {"text":["No olvides seguirnos también en Spotify y escuchar nuestro ", <a href="https://open.spotify.com/episode/3beYJPR4FX6BLRM3N1X8J7?si=sMbq8l06Tb-rfG50m7QsXg" class="texto-pink" rel="noreferrer noopener" target="_blank">podcast</a>, " cada 15 días, encuéntranos en todos los canales digitales como @betterselfmx."]}
        ],
        "author":{
            "name":"Elsa Rentería",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/princelsa/ "},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    "cumple-proposito-fit":{
        "image": lifestyle_18,
        "title": "Cumple tu propósito de ser fit sin morir en el intento",
        "pars":[
            {"text":[<iframe src="https://open.spotify.com/embed-podcast/episode/3rmZ4HJz211awA40f6OEmA" width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media" title="Podcast E2"></iframe>]},
            {"text":"Propósitos de año nuevo: hacer ejercicio, mejorar mi salud, bajar de peso para que me quede aquel vestido que lleva añoooos guardado… Día dos y renuncias porque estás súper adolorid@, tu cuerpo no se ha acostumbrado a los nuevos hábitos o aún con todo el esfuerzo del mundo no ves los resultados de tu propósito fit.",},
            {"text":"Para evitar que esto te pase y de una vez por todas transformes este eterno propósito en realidad, te compartimos lo que opinan las expertas. En voz de Dany Méndez, trainer de Sersana, ser fitness es buscar el bienestar integral y no sólo fijar tu atención en la parte física, en lucir espectacular en bikini o que se te marque el abdomen. Ser fit, es sentirte bien en todos los aspectos que te conforman como ser humano, es tener un balance en la parte física, emocional y mental."},
            {"text":"Por otro lado, Natalia Nallino, máster trainer de Sersana, opina que ser fitness tiene mucho que ver con nuestra parte emocional, respetar tu cuerpo, tus horas de sueño, etc. Y nos recuerda que este bienestar o equilibrio cambia todos los días, por ejemplo, el hambre que tienes hoy tal vez sea más/menos que ayer. La clave está en aprender a conocerte y saber que somos seres cambiantes y multifacéticos , es por ello que nuestras rutinas de cuidado, alimentación y salud deben ser adaptables y responder a las necesidades del momento."},
            {"text":"Te dejamos 12 tips puntuales para cumplir este propósito sin sufrir y convertir estos nuevos hábitos en un estilo de vida:",
                "list":[
                    "1. Empieza a tu propio ritmo y sin compararte con alguien más. Si eres principiante no te sientas intimidado, las clases en línea nos han dado esa apertura de ir a nuestro propio ritmo y sin sentirnos observados o juzgados.",
                    "2. Si estás entrenando mediante clases, comunícate con el maestro, exprésale tus miedos, dolores, etc. Eso es lo que muchas veces nos hace desistir rápidamente.",
                    "3. Tratar de empezar solos y no buscar expertos es otro error. Busca un coach para la parte fitness y una nutrióloga para la parte de dieta y alimentación.",
                    "4. Empieza por retos pequeños, por ejemplo hacer la mitad de la clase o la mitad de las repeticiones de la clase. Confía en ti, en tu cuerpo y en el coach.",
                    "5. Es normal el dolor muscular pero no que ahorita te duela la cadera, luego el hombro, luego la rodilla, etc. No es normal vivir con dolor, por eso debes comunicarte con tu entrenador.",
                    "6. Los cambios llevan tiempo y el primer cambio es sentirte bien, alegría interior de haber hecho algo por ti. Los cambios físicos vienen después.",
                    "7. No necesitas dedicarle horas ni hacer cambios radicales, con 20 minutos si eres principiante está bien. Enfócate en cambios realistas, chiquitos y sostenibles. Lo más importante es ir generando nuevas rutinas y nuevos hábitos.",
                    "8. Si tu objetivo era hacer ejercicio 3 días a la semana y es sábado e hiciste sólo un día, haz el domingo, no lo dejes al lunes. No tires la toalla, ve un día a la vez.",
                    "9. El movimiento es vida. Motívate, escoge un ejercicio que te guste y que disfrutes. Si no tienes tiempo, baila, sube y baja escaleras. ¡Sé creativ@! ",
                    "10. Cada quien tiene su proceso y sabe lo que necesita en ese momento de su vida sin ningún tipo de presión social o externa.",
                    "11. No te castigues, date mucho amor. Y no tengas miedo de empezar desde cero.",
                    "12. Busca el bienestar, no busques verte de cierta forma. Encuentra un balance que te funcione a ti."
                ],
            },
            {"text":"Ahora ya sabes por dónde empezar y si aún tienes dudas acércate a los expertos desde casa. El método Sersana es una plataforma que ofrece una combinación de disciplinas, desde danza, pilates, entrenamiento funcional, clases de cardio, etc.,  a través de clases vía zoom con diferentes instructores. Con Sersana Home no importa en qué parte del mundo estés, no tienes pretexto para no moverte."},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/danymdzazuela/"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/natnallino/"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/sersanamethod/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/SersanaMethod"},
                {social:"Web",text:"", link:"https://www.sersana.com/ "},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    "conecta-belleza-pureza":{
        "image": lifestyle_19,
        "title": "Conectar belleza con pureza. ¡Sí es posible!",
        "pars":[
            {"text":"A todos nos encanta vernos bien y que el paso del tiempo se note lo menos posible. Pero, cuando hablamos de tratamientos estéticos es difícil decidirse; sobre todo si eres primeriza, ya que hay varias cuestiones a tomar en cuenta. Si te encuentras investigando al respecto, tenemos un consejo para que no te quite la paz mental: elige un producto de calidad y acude con un profesional de la salud certificado para que te lo aplique correctamente. Logra el balance de belleza con pureza en unos sencillos pasos.",},
            {"text":"Para muestra, nada más que una gran celebridad como Gwyneth Paltrow, destacada actriz y ganadora de un Oscar, Globo de Oro y dos Premios del Sindicato de Actores por su interpretación de Viola de Lesseps en Shakespeare in Love; en un evento de talla internacional, habló de la importancia de elegir productos o tratamientos estéticos puros - es decir que tengan las menores alteraciones químicas posibles. Por esta razón es que Gwyneth Patrow decidió ser la cara de una nueva campaña global con Merz Aesthetics."},
            {"text":"A través de la campaña Go Pure, Merz Aesthetics hace alusión a la pureza de uno de los tratamientos no invasivos para reducir la apariencia de las arrugas dentro de su portafolio. En esta iniciativa, Gwyneth Paltrow representa a todas las mujeres que eligen la belleza a su manera, de forma preventiva y segura...claro, para lograr ¡su mejor versión! y fortalecer su autoestima. No es de extrañarse que Gwyneth participe en una iniciativa así, ya que a lo largo de su carrera, ella ha sido una figura clave en el activismo en pro de una vida más pura; ha participado en la narración de audiolibros, ha incursionado en la industria de la moda y en Goop®, en donde en su faceta de empresaria, fomenta el bienestar, la aceptación, el conocimiento personal y una mejora saludable en el estilo de vida."},
            {"text":[<i>"Para mí, la belleza consiste en profundizar en la felicidad en lugar de intentar perseguir la juventud,</i>," dijo Paltrow, de 48 años de edad, quien es considerada una autoridad en belleza y bienestar.", <i>"Y no es secreto que soy un libro abierto cuando se trata de probar nuevos regímenes de belleza, pero quiero saber qué hay dentro de un producto antes de aplicarlo en mi cuerpo. Encontrar productos altamente purificados y probados es muy importante. Esa es una de las muchas razones por las que empecé a usar este tratamiento hace unos años"</i>]},
            {"text":"Y es que, Gwyneth Paltrow tiene una filosofía muy clara en lo que a la belleza respecta: las mujeres deben darse el permiso de hacer lo que consideren mejor para sentirse bien. Paltrow ha sido pilar importante, alentando en cada momento a que las mujeres se sumen y prioricen el autocuidado"},
            {"text":"Todo esto en voz de una mujer líder de opinión, pero al sumar el conocimiento de una experta médica como lo es la Dra. Karen Flores, dermatóloga mexicana, quien tiene una larga historia aplicando este tratamiento inyectable de Merz Aesthetics para reducir la apariencia de las arrugas, toda esta idea se fortalece. La Dra. Flores constata, “las mujeres y hombres que lo prueban se sienten empoderados con sus resultados, pues además de ser una forma de auto consentirse y cuidar su imagen, es una alternativa que los ayuda a encontrar su mejor versión conservando sus rasgos únicos”. Lo cual nos demuestra que sin duda alguna se trata de una importante iniciativa a nivel global para mejorar nuestra calidad de vida y lograr nuestra mejor versión."},
            {"text":"¿Tú también compartes esta gran filosofía de vida? ¡Invita a más mujeres a sumarse! Y recuerda preguntarle a tu especialista sobre las opciones que tienes y cómo están formulados los tratamientos que te gustaría aplicarte para que siempre tengas la certeza de tener lo mejor a tu alcance."}
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    "conociendote-a-profundidad":{
        "image": lifestyle_20,
        "title": "Conocerte a profundidad",
        "pars":[
            {"text":[<iframe title="Betterself Podcast Ep5" src="https://open.spotify.com/embed-podcast/episode/2EYidYH176HFgIkyA2xewL" width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>]},
            {"text":"En el mes del amor y la amistad hablamos mucho de conocernos y poner en práctica el amor propio. Pero ¿sabías que la sexualidad es parte de conocerte a profundidad? Es como saber si te gusta el helado de vainilla o de chocolate.",},
            {"text":"Todos todos somos personas sexuales. La sexualidad y el placer son parte fundamental de nuestra salud y bienestar. No solo en temas de cómo nos relacionamos en la intimidad con nuestras parejas o con otras personas. El auto explorarse y conocerse es fundamental para sentirnos bien y lograr una relación perfecta con nosotros mismos. Saber lo que nos gusta nos ayudará a desenvolvernos mejor y disfrutar más cuando decidamos compartir estos momentos de placer con otra persona. Incluso, desearemos pasar más tiempo con nosotros mismos y podremos disfrutar aún sin involucrar a una pareja. "},
            {"text":["Algo interesante, nos comenta la Dra. Celeste Campos, psicóloga y sexóloga de la marca ", <a class="texto-pink" href="https://www.cherish.mx/" rel="noopener noreferrer" target="_blank">Cherish</a>, "; es que nuestro placer es diverso, es adaptable, pues depende de los canales de comunicación y sensorialidad que cada quién tiene más “activos” o “sensibles”, como la música, los olores, las sensaciones, texturas, etc. También puede ser que antes no nos gustaba algo y después de explorarlo con un acercamiento distinto podemos cambiar de opinión. Lo más importante es reconocer, aceptar y abrazar estos cambios o procesos en nuestro desarrollo."]},
            {"text":"Así es que si eres nuevo en este tema o te gustaría experimentar con tu pareja para reavivar la llama te dejamos estos consejos: ",
                "list":[
                    "Explórate a ti mism@. Mírate al espejo totalmente desnudo, conócete y haz las paces con tu cuerpo, be body positive.",
                    "Pon límites, una sexualidad libre significa saber reconocer tus límites y expresarlos. Saber qué te gusta, qué no te gusta y qué te gustaría explorar.",
                    "Integra un juguete a tu experiencia, no necesariamente sustituye a estar con una persona pero se puede volver un juego de roles, pónganse creativos.",
                    "Cede el control de ese juguete a tu pareja, hoy en día existen muchos juguetes incluso algunos que se manejan por medio de app por si no están físicamente juntos.",
                    "Siempre habla con la otra persona, mantengan abiertos los canales de comunicación y la curiosidad.",
                    "No sientas culpa por ninguno de tus placeres o por explorar tu sexualidad siempre y cuando no excedas los límites de las demás personas.",
                    "Hazte el amor a ti mismo. Los beneficios de cuidar tu salud sexual son: mejoras en la circulación, reducir el estrés, atender tu salud emocional y mental, evitar cuestiones cardíacas o respiratorias.",
                    "Siempre dedícate tiempo, no porque seas mamá, estés embarazada o incluso muy ocupada por tu trabajo, te olvides de tu parte sensual y sexual. Encuentra esos espacios, recuerda que si no te atiendes tú no vas a poder estar para quien te ama y te necesita.",
                    "Empodérate. Tu sexualidad es tu momento. Ten un momento exclusivo, llénalo de todos los placeres, esa comida que te gusta, el baño relajante, etc.",
                    "Mezcla olores, texturas, lubricantes de sabores, juega con la temperatura ¡impulsa y juega con tu sensorialidad!",
                    "Atiende tus pensamientos y fantasías, platícalo con tu pareja y lleguen a un acuerdo.",
                    "Tócate a ti mism@ despacio, toca tu piel, comienza a recorrer tu cuerpo y al último tus órganos sexuales."
                ]
            },
            {"text":"Recuerda que tu sexualidad es parte de ti y se trata de disfrutar el proceso, no sólo la meta que es el orgasmo. Para ello Cherish es ideal para guiarte en el camino. Más que una sex shop es un espacio seguro y confiable donde se habla de sexualidad de manera natural; sin tabúes ni juicios. "},
            {"text":["En ", <a class="texto-pink" href="https://www.cherish.mx/" rel="noopener noreferrer" target="_blank">Cherish</a>," puedes encontrar muchísimas alternativas en cuanto a juguetes sexuales, para disfrutar libremente a solas o en pareja, pero si aún no sabes cuál es el juguete para ti cuentan con atención personalizada, resolviendo dudas e inquietudes a través de especialistas en el tema. Y hacen envíos discretos a todo el país. Aprovecha el código de descuento que nos regalaron por ser parte de la comunidad BetterSelf y ¡pide ya! Código: BETTER10"]},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Web", text:"", link:"https://www.cherish.mx/san-valentin/?utm_source=referral&utm_medium=text&utm_campaign=febrero_bs"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/cherish.mx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/cherish.mx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    "finanzas-personales-cura-insomnio":{
        "image": lifestyle_21,
        "title": "Finanzas personales: La mejor cura para el insomnio",
        "pars":[
            {"text":[<iframe title="Betterself Podcast E3" src="https://open.spotify.com/embed-podcast/episode/34emw8TKAd2rK5Q3SH7oYU" width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>]},
            {"text":"Las finanzas personales son un tema complicado, muchas veces las vemos como nuestro peor enemigo y causa de todas nuestras preocupaciones al intentar dormir. Pero lejos de verlo así, hagamos algo por tener unas finanzas sanas este año.",},
            {"text":"En esta ocasión hablaremos con Fátima Gonzáles acerca de finanzas, el ahorro, fondo de emergencias, cómo manejar de manera correcta una tarjeta de crédito, si ya tengo deudas cómo puedo salir de ellas, objetivos, metas, etc."},
            {"text":"El principal problema al hablar de este tema, es que no tenemos un presupuesto a nuestros 40-50 años, de ahí que no entendemos en qué estamos gastando y perdamos el control de nuestro dinero. El primer paso es analizar en dónde estás parado y ver a dónde quieres llegar para lograrlo. Te compartimos los 10 pasos para volverte un experto en el manejo de tu dinero:",
                "list":[
                    "1. Para mejorar tus finanzas debes entender en dónde estás parado, analizar tus gastos. Registra tus gastos al menos un mes, lo ideal son 3 meses porque hay meses atípicos, para saber en qué estás gastando. Desde los chicles, la propina, el transporte, comida, antojos, etc. Para esto, existen aplicaciones como Fintonic, Monify, Finerio.",
                    "2. Ya que entendiste a dónde se va tu dinero, decide a dónde quieres que vaya. Reduce gastos y haz un presupuesto. Ve qué quieres hacer con tu dinero, y tradúcelo a una meta. Por ejemplo las vacaciones, el concierto del súper artista, tu boda, etc.",
                    "3. Toma el ahorro como un gasto fijo y siempre contémplalo dentro de tu presupuesto, pues es tan importante como pagar la renta. Hay fórmulas que recomiendan los expertos para distribuir tu dinero como la de 50% a gastos fijos, 30% gastos variables (gustos) y 20% ahorro. Pero lo recomendable es adaptarlo a ti, hacer una lista de gastos fijos y sobre lo que queda definir qué quieres hacer. Evita el “Gasto y ahorro lo que me queda” porque la mayor parte de las veces no nos sobra nada.",
                    "4. Invierte en ti, contempla un seguro de gastos médicos mayores. Estos han tomado relevancia ahora por el tema de la pandemia,  pero piénsalo, podrías llegar a tener un accidente o cualquier enfermedad ¡Que no te tome por sorpresa! Tan sencillo como que si compras un seguro para el coche ¿Por qué no uno para ti? Tu vida vale más que un coche y con una enfermedad puedes gastar mucho más de lo que te pueda costar el deducible hasta por pérdida total. Un seguro debe ser prioridad en tus finanzas.",
                    "5. Que tus deudas nunca superen el 30% de tus ingresos, eso es clave. Haz una lista de las deudas que tengas actualmente entre las tarjetas, un crédito hipotecario o automotriz, préstamo o compras a MSI.",
                    "6. Tip para usar MSI: cuando compres un producto o servicio a MSI asegura que la vida útil de ese producto sea mayor a los meses que lo vas a pagar. Por ejemplo, un refri, vale la pena pues te durará al menos 10 años; sin embargo, pagar el súper a 3 meses, no vale la pena si lo terminarás de consumir en menos de 3 semanas. Evita acumular más de 5 productos/compras a MSI.",
                    "7. Para liquidar o reducir tus deudas hay dos opciones: reducir gastos para poder dedicar ese monto a pagar deudas o aumentar ingresos. Este año nos ha dado oportunidad de buscar otras formas de tener ingresos, emprender desde cosas chiquitas. Levantarnos, reinventarnos y buscar nuevas oportunidades.",
                    "8. Una vez que tengo mi lista de deudas puedes acomodarlas de mayor a menor tasa de interés  y comenzar a liquidar o abonar más a la de mayor tasa de interés pues ésta es la que genera mayores intereses. Otra opción para bajar la frustración de tener deudas, es liquidar primero aquellas deudas de menor plazo y seguir con las de mayor plazo de pago.",
                    "9. No destines el 100% de tu capacidad de ahorro para pagar deudas. Del dinero extra a tus necesidades básicas destina el 20% para ahorro y el 80% para pagar deudas. Esto para evitar que tu situación se vuelva una bola de nieve, si el día de mañana te llega un imprevisto, vuelves a dar el tarjetazo y te volverás a endeudar. Si tienes $10 gasta 2 en ahorro y 8 en pagar deudas.",
                    "10. Normalicemos hablar de nuestras finanzas personales, decir “no tengo dinero”, “no quiero gastar en ese plan”, “quiero poner orden en mis finanzas”, etc. Se vale cancelar planes con amigos, en lugar de irte de fiesta y gastarte $1,000 dedicate a ahorrar y destinar ese dinero a tus metas.",
                ]
            },
            {"text":"Al hacer tu presupuesto, recuerda que no sólo trabajas para vivir, sino para disfrutar y vivir una vida llena de plenitud. Así como pones tus necesidades básicas, incluye un rubro dentro de tu presupuesto para gastos personales (belleza, masajes, maquillaje, tratamientos faciales)  En el caso de organizar las finanzas en pareja se vale que cada quien tenga un presupuesto para gustos individuales."},
            {"text":["El dinero es un medio para la felicidad pero no es el único fin. Si quieres saber más sobre finanzas personales sigue a ", <a class="texto-pink" href="https://www.instagram.com/finanzasconfa/" rel="noopener noreferrer" target="_blank">@finanzasconfa</a>]},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/finanzasconfa/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    "alimentos-siempre-conjunto":{
        "image": lifestyle_22,
        "title": "Alimentos que siempre debes comer en conjunto",
        "pars":[
            {"text":"Muchas veces mezclamos alimentos y sabores porque siempre saben mejor. Como es el caso de la leche y el café, pero ¿sabías que hay alimentos que funcionan mejor juntos dentro de tu cuerpo? Comer ciertos alimentos al mismo tiempo, no solo afectará tu paladar, sino que afectará la forma en que se absorben sus nutrientes. Aquí te dejo algunos ejemplos de excelentes combinaciones:",},
            {"title":"1.- Té verde y kiwi:", "text":"La próxima vez que te prepares una taza de té verde, ten a la mano un delicioso kiwi, pues las catequinas del té verde ayudan a acelerar el metabolismo y son mejor absorbidas cuando la vitamina C se une a la fiesta. ¡El kiwi tiene una concentración más alta de vitamina C que la naranja! "},
            {"title":"2.- Queso cottage y moras azules:", "text":"Para una colación que te quite el hambre a medio día y te llene de antioxidantes, mezcla un puñado de moras azules en un tazón de queso cottage. Debido a que esta fruta es rica en fibra y los grumos del queso cottage son ricos en proteínas de liberación lenta (caseína), formarás un combo que te mantendrá saciada hasta tu próxima comida."},
            {"title":"3.- Espinacas o kale con fresas:", "text":"Incluso un superfood como la col rizada o kale necesitan un complemento, así que, si eres vegetariano y estás luchando por obtener suficiente hierro en tu dieta, presta atención, pues el hierro encontrado en estas hojas, se absorbe mejor cuando las fresas o cítricos agregan su vitamina C a la mezcla."},
            {"title":"4.- Aguacate y ensalada de espinacas:", "text":"De esta manera podrás mejorar tu salud ocular sin tener que recurrir a las famosas zanahorias. Mediante la incorporación de aguacate y espinacas a tu dieta, puedes prevenir la degeneración macular relacionada con la edad, las cataratas y ceguera nocturna. Las grasas saludables en el aguacate y la luteína antioxidante en las espinacas, trabajan juntos para aumentar la salud de los ojos."},
            {"title":"5.- Plátano con crema de almendra:", "text":"Un smoothie de plátano puede ser un desayuno no muy balanceado debido a su contenido de azúcar, pero al combinarlo con un poco de mantequilla de almendra, agregarás fibra y proteína que trabajan contra el azúcar en la fruta. Las grasas encontradas en las nueces, ralentizan la tasa en la que tu cuerpo absorbe el azúcar, evitando picos de glucosa y manteniendo tus energías más estables. Prueba esta combinación como snack para antes de hacer ejercicio."},
            {"title":"6.- Zanahorias y aderezos con aceite de oliva:", "text":"Ciertos nutrientes, como las vitaminas liposolubles y los carotenoides, necesitan grasa para ser absorbidos por el cuerpo. Los carotenoides son compuestos que se encuentran en las verduras de color rojo, naranja y verde oscuro. Un estudio descubrió que las ensaladas con zanahorias o pimientos tenían que consumirse con un aderezo que contenía grasa para que los carotenoides fueran absorbidos de mejor manera."}
        ],
        "author":{
            "name":"Dra. Julia Salinas Dücker - Médico especialista en Nutrición Clínica",
            "contacts":[
                {social:"Twitter",text:"", link:"https://twitter.com/juliasalinas"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/antojodiabolico/"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/antojodiabolico/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    "labios-de-100":{
        "image": lifestyle_23,
        "title": "¿Cómo tener labios saludables?",
        "pars":[
            {"text":[<iframe src="https://open.spotify.com/embed-podcast/episode/2D0IWR319Ks70HwMYwjdcm" title="Podcast E6 Labios de 100" width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>]},
            {"text":"Los labios son un ícono de la belleza. Dicen que los ojos son la ventana del alma, pero a veces olvidamos que los labios también son un medio de comunicación importantísimo que ayudan a vernos bien, dar seguridad y sensualidad. Sigue leyendo, porque te revelaremos como tener unos labios saludables.",},
            {"text":"Platicamos con la Dermatóloga y especialista en Oncología Cutánea, Susana Sil Zavaleta acerca de los cambios que nos ha traído la pandemia en cuanto a estética personal. ¿Piensas que los labios no se ven por el cubre bocas? Error...los labios son la parte del cuerpo que más vemos a través de las videollamadas.  Las personas que trabajan, pasan al menos 8 horas frente a una pantalla - que a su vez juega muchas veces como espejo. Esto ha creado un “trending topic” en Google sobre cómo corregir todo lo que no te gusta de tu rostro. Incluso hay un nuevo término que se llama dismorfia de Zoom, que significa que al estarte viendo tanto en la pantalla, lo que buscas es tener una mejor versión de ti mism@ y arreglar lo que no te gusta, por ejemplo los labios.",},
            {"text":"La definición de unos labios sensuales está acompañada de palabras como turgentes, rojos y son puras descripciones que se asocian con el acto de besar a alguien. Cuando besas los labios se te ponen rojos, se te hinchan y se humedecen. Por eso nos gustan los labios que parecen recién besados.",}, 
            {"text":"Aquí te contamos muchos de los mitos acerca del relleno de labios y el cuidado en general de esta zona del cuerpo, de la mano de la Dra. Sil:",
                "list":[
                    "Inyectarse los labios no es necesariamente hacerte labios voluminosos, puedes hidratarlos con ácido hialurónico o sólo definir el contorno de labios ya voluminosos, acentuar el piquito, etc.",
                    "Se pierde sensibilidad en la zona. Esto es totalmente falso. El procedimiento es súper noble y dura menos de media hora.",
                    "La exfoliación es necesaria para la piel y sobretodo para los labios. ¡Falso! La piel es un órgano vivo que renueva sus células cada 21 a 28 días. Cuando nosotros nos exfoliamos la piel lo percibe como un traumatismo (dermatitis friccional) y a la larga va a generar reacciones cutáneas en respuesta a este traumatismo como manchas.",
                    "Los tratamientos sólo son para relleno y con fines estéticos. ¡Claro que no! Un tratamiento de ácido hialurónico puede ser para dar volumen o sólo para contornear e hidratar. Por ejemplo para que no se te corra el labial, se le conoce como labio parisino, se levanta el arco de cupido para hacer un piquito y quedan unos labios muy lindos en 2 minutos de procedimiento.",
                    "¿Duele? Esto depende de tu umbral del dolor pero por eso es importante ir con un especialista certificado que te ponga anestesia y te explique el procedimiento.",
                    "En el salón de belleza me pueden hacer mi relleno de labios. ¡No! Lo recomendable es que vayas con un médico especialista, y que elijas ponerte productos de calidad internacional y certificados dermatológicamente.",
                    "Te queda todo estirado y se ve muy producido. La tendencia actualmente es conseguir un resultado muy natural, que te permita seguir expresando.",
                    "Si te inyectas una vez te haces “adicto” a los tratamientos. Claro que no, en realidad todos somos adictos solamente a vernos bien en el momento correcto."
                ]
            },
            {"text":"Ahora ya lo sabes, la piel juega un papel bien importante en el humano socialmente, es lo que se ve y te ayuda a expresar sensualidad, autoridad, etc. Es el medio por el que sentimos también, la mayoría de las cosas las hacemos con el tacto. Por eso los labios denotan atractivo, jovialidad e incluso te dicen el estado de salud de una persona.",},
            {"text":"Recuerda que para lucir unos labios sensuales siempre es importante hidratarlos, el petrolato es una buena opción de manera tópica, y de forma inyectable opta por ácido hialurónico, o bien, si lo que buscas es reducir arrugas o labios tristes la toxina botulínica es la opción. Otro punto importante es proteger los labios del sol, usando bloqueador ya que las partes convexas de la cara son las más expuestas y donde somos más propensos a desarrollar cáncer de piel.",},
            {"text":"El arte de la medicina estética es entender al paciente, ver lo que necesita, analizarlo como una persona única. Así es que no le tengas miedo a mejorarte a ti mismo siempre y cuando acudas con un experto en el tema y te informes acerca de los ingredientes o productos que vas a introducir a tu cuerpo.",
                "list":[
                    <a href="https://www.facebook.com/dermatologacdmx" target="_blank" class="texto-pink" rel="noreferrer noopener">Facebook: dermatologacdmx</a>,
                    <a href="https://www.instagram.com/dermatologa.cdmx/" target="_blank" class="texto-pink" rel="noreferrer noopener">Instagram: @dermatologa.cdmx</a>
                ]},
            {"text":["Y si no estás en la ciudad de México, tenemos un directorio ",<a href="/specialist" target="_blank" class="texto-pink" rel="noreferrer noopener">aquí</a> ," con médicos certificados en toda la república mexicana."]}
        ],
        "author":{
            "name":"Dra. Susana Sil",
            "contacts":[
                {social:"Facebook",text:"", link:"https://www.facebook.com/dermatologacdmx"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/dermatologa.cdmx/ "},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'mejor-cura-corazon-roto':{
        "image": lifestyle_24,
        "title": "La mejor cura para un corazón roto",
        "pars":[
            {"text":[<iframe title="Podcast E4: La mejor cura para un corazón roto" src="https://open.spotify.com/embed-podcast/episode/5AgqZH27RhkLwNGAuz3Sx6" width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>]},
            {"text":"El amor lo disfrutamos de diversas maneras a lo largo de nuestra vida. La más convencional y, que más expectativas tiene, es el amor en pareja. ¿Cuántas veces esperamos ser más felices de novi@s o casad@s? ¿Cómo curamos un corazón roto? ¿No nos separamos de nuestra pareja porque “dónde voy a encontrar un amor así”? Ser feliz no debe ser tan complicado.",},
            {"text":"Al platicar con Karla Lara Coach, especialista en inteligencia emocional y social, nos dimos cuenta que aprender a estar con uno mismo, es el primer paso para una vida completamente distinta y extraordinaria. Estar en contacto contigo, tenerte, conocerte, evaluarte, reconocerte y amarte es el principio de cualquier construcción de vida increíble",},
            {"text":"El amor romántico que hemos aprendido nos hace pensar que nuestra felicidad depende de estar en pareja antes de siquiera estar bien con uno mismo. Un consejo para alcanzar tu mejor versión es escoger caminos que te acerquen al bienestar, a crecer y a desarrollarte como más te convenga. Bien dijo Karla Lara si una relación te hace sentir bien en todos los aspectos de tu vida: quédate, trabájala y disfrútala. Pero si te hace sentir mal en cualquiera de las áreas de tu vida: ahí no es.",}, 
            {"text":"Conocernos, aceptarnos y amarnos no solo es un trabajo interior y, es que, si una persona no cambia la relación que tiene consigo mism@, no puede cambiar la relación que tiene con la vida y con otras personas. Las cosas buenas que queremos para el cambio no están allá afuera ni en un futuro, sino en nosotras mismas y en el presente.",}, 
            {"text":"Te dejamos unos consejos rápidos que nos compartió Karla Lara coach para construir relaciones más efectivas con nosotros mismos y con nuestras parejas",
                "list":[
                    "Acepta las áreas de tu vida en las que no eres plenamente feliz y pregúntate ¿Qué vas a hacer para ayudarte a salir de ahí? Recuerda que la vida no tiene que ser una lucha constante con los problemas, sino un camino para encontrar crecimiento y felicidad.",
                    "Conócete sin miedo. Una forma útil de llegar al autoconocimiento y desarrollo personal es por medio de la terapia, ¡Anímate!",
                    "Pregúntate qué quieres dentro de tu vida. Conocernos y desarraigarnos de las expectativas relacionadas con el amor, nos ayuda a generar relaciones funcionales.",
                ]
            },
            {"text":"Lo mejor que puedes hacer es estar en contacto con la persona más importante en tu vida, es decir, contigo misma.",},
        ],
        "author":{
            "name":"Karla Lara Coach",
            "contacts":[
                {social:"Web",text:"", link:"https://www.karlalara.mx/"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/karlalara_coach/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'feminismo-que-si-que-no':{
        "image": lifestyle_25,
        "title": "Feminismo: Qué sí y qué no es",
        "pars":[
            {"text":[<iframe title="E7: Feminismo: qué sí y qué no es" src="https://open.spotify.com/embed-podcast/episode/1pC1Vznkz42LdvrRXm0hUw" width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>]},
            {"text":"¿Por ser mujeres debemos servirles de comer a los hombres o limpiar la casa? ¿Debemos de ser más sensibles, chismosas o competir con otras mujeres? Durante años a las mujeres se les ha dicho que hay ciertas características/roles que deben de ejercer solo por el simple hecho de ser mujeres. Y entonces llega a nuestra cabeza una pregunta sobre el feminismo, ¿en dónde quedan nuestras decisiones? ¿No será que existen miles de moldes y miles de formas de ejercer nuestro rol de género?",},
            {"text":"Sabemos que el feminismo ha tenido malas y buenas repercusiones en nosotras, pero ¿en realidad qué es? El feminismo es un movimiento social y político que empezó a finales del S. XVIII con las sufragistas. A grandes rasgos, busca que las mujeres tengamos conciencia como grupo colectivo de la opresión y explotación que vivimos a diario, y, así, generar un cambio desde lo personal a lo político.",},
            {"text":"Actualmente el feminismo ha llegado a más mujeres, entre ellas, a Chantal Escartin quien es activista de los derechos de las mujeres en la maternidad y primera infancia y, además, es directora del colectivo: Normalizando la lactancia materna en México. Con ella platicamos sobre ¿Cómo es ser una mamá feminista?",}, 
            {"text":"Conocernos, aceptarnos y amarnos no solo es un trabajo interior y, es que, si una persona no cambia la relación que tiene consigo mism@, no puede cambiar la relación que tiene con la vida y con otras personas. Las cosas buenas que queremos para el cambio no están allá afuera ni en un futuro, sino en nosotras mismas y en el presente.",}, 
            {"text":"Un beneficio del feminismo es que a las nuevas generaciones les da la oportunidad de criar con perspectiva de género; es decir, reconocer que el sexo no es lo mismo que el género. Entender que una niña no tiene que tener todo de rosa y un niño todo de azul. Las personas somos más que un género, somos individuos y tenemos la libertad de decidir cómo queremos ser."},
            {"text":"Chantal Escartin nos comparte que ella como mamá crea comunidad con familias que sean y eduquen a sus hijas e hijos sin prejuicios machistas, porque así ella se siente segura y tranquila. Y es que no hay que olvidar que los niños son un reflejo de lo que los papás opinan y dicen.",},
            {"text":"Chantal menciona que “Un ejemplo simple de educar sin prejuicios machistas es dejar que nuestras hijas e hijos vistan la ropa que más le guste sin importar que sea de la sección de niños o niñas”. Darles la libertad de escoger juguetes, películas o ropa por sí mismos, porque no tiene nada de malo que a una niña le gusten los dinosaurios y a un niño las muñecas.",},
            {"text":"Chantal Escartin es una feminista imperfecta que está en una deconstrucción y construcción constante. Siempre se está cuestionando todo. Así como no hay una manera de vivir el feminismo, no hay una manera de que todas las mujeres seamos iguales. Por eso, es importante ser empáticas entre nosotras y entender las diversas situaciones que vive cada una",},
            {"text":"Ahora, ¿Qué retos vienen como mamá feminista?:", "list":[
                "Posicionar el feminismo como algo positivo, que dejen de malentenderlo como “Ni machista ni feminista”. Solo buscamos que nos reconozcan como individuos y que se terminen las violencias machistas.",
                "Más cambios como la Ley Olimpia.",
                "Que los hombres se den cuenta que deben de cuestionarse sobre su machismo y en ciertos casos sobre la violencia explícita e implícita hacia las mujeres."
            ]},
            {"text":"¡Ser mujer para ser libre y decidir! Que se reconozca nuestro espacio para sentirnos seguras y sin miedo al momento de alzar la voz.",},
        ],
        "author":{
            "name":"@mamafeminista",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/soymamafeminista/"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/normalizandolalactanciamaterna/"}
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'guia-practica-ser-mama':{
        "image": lifestyle_26,
        "title": "Guía práctica para ser mamá",
        "pars":[
            {"text":[<iframe title="E14: Guía práctica para ser mamá" src="https://open.spotify.com/embed/episode/40oxI3cBJ2KOnUAqSHN6yE" width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>]},
            {"text":"La maternidad es uno de los cambios más importante en nuestras vidas, muchas veces lo representamos con una mamá superfeliz, con un glow natural y una sonrisa de oreja a oreja. Sin embargo, Karla Lara (mamá de 4) nos dice que la maternidad es de todos los colores, menos color de rosa. Si es tu caso, lee nuestra guía para ser mamá y haz las paces con las culpas, reconocerte como ser humano y encontrar el equilibrio entre ser mamá, sentirte bien contigo, ser profesionista y alcanzar todos tus sueños.",},
            {"text":"Karla es mommy blogger, abogada, mamá de 4, educadora perinatal, colaboradora de radio y televisión, podcaster, entre muchas otras cosas. Así es que cuando hablamos de ser multitasking Karla es el ejemplo perfecto. Ella nos platica que la fórmula para ser mamá es aceptar que no hay “v” de vuelta. Se trata de cómo vas a integrar esa maternidad a tu vida. Aquí te dejamos 15 tips para encontrar el camino:",
                "list":[
                    "1. Con el tiempo te relajas, no es lo mismo el primer hijo al cuarto. Con el tiempo, confías en ti, en lo que has aprendido y te vuelves especialista en tus propios hijos.",
                    "2. Es súper común tener depresión post-parto, arrepentimiento de tener hijos, añorar la vida que teníamos antes pero la vida no se acaba cuando te vuelves mamá.",
                    "3. Es importantísimo que te auto-reconozcas todos tus logros y en lo que eres una fregona, pareciera que está mal visto, que está castigado, que es arrogante, etc. pero no, eso te hace reconocer tu valor y tener fuerza y confianza en ti.",
                    "4. Exigirte o creer que una mujer a los 40 días de haber dado a luz va a volver a ser igual a como era es irreal y no nos referimos solo a peso y talla. Cada una vive su proceso y como todo, la vida es un constante cambio y evolución.",
                    "5. Es muy fácil delegar la autoridad cuando tú no te haces cargo al 100% de tus hijos, por lo que tú quieras. Por ejemplo, el hacerle caso 100% a los abuelitos porque “saben más”; se vale aceptar ayuda pero lo que no se vale es quejarse porque es más fácil culpar a otro.",
                    "6. Hay muchísimos mitos alrededor de ser mamá, como que ya no eres tan buena en lo que hacías o que ya no tienes tiempo o que vas a llegar tarde. Incluso nosotras compramos la idea de aparentar que estamos sufriendo al ser mamás. Empoderate, nunca pierdas eso.",
                    "7. El post-parto no es un buen momento para tomar decisiones importantes. Date un tiempo. Cuando creas que tienes cierto control sobre tu nueva forma de vida entonces es buen momento para retomar tu carrera profesional o tus sueños.",
                    "8. En la maternidad hay dualidad eterna. Por ejemplo, te gustaría ir a cenar con tu pareja pero en cuanto cruzas la puerta te arrepientes y te sientes fatal por dejar al bebé. Confía en que lo haces bien y no está mal darte esos espacios.",
                    "9. Recuerda tener una vida que no dependa 100% de tu maternidad, es súper importante. Porque en algún momento tus hijos van a dejar el nido y tú vas a seguir teniendo una vida.",
                    "10. Aprende a delegar con tu pareja. La responsabilidad de ser papás es compartida, no por ser la mamá tienes que pararte todas las noches a atenderlos o por default te tocan ciertas actividades.",
                    "11. Se vale tener días malos, no pasa nada con explicarle a tus hijos cómo te sientes. Por ejemplo, me duele la cabeza, yo también tengo hambre, estoy de duelo, etc.",
                    "12. Explícale a tus hijos que su papá y su mamá son personas de carne y hueso igual que ellos, tienen emociones y límites. Que tus hijos te vean real y falible, que perfecta, agotada y harta de una situación que se vuelve insostenible.",
                    "13. Si llegas a un extremo, la situación te rebasa y perdiste el control con o frente a tus hijos, explícales eso a los niños post-berrinche de mamá. Porque en el momento está bien difícil. Y sobretodo, suelta la culpa.",
                    "14. Cuando te veas al espejo nunca digas “ni modo es lo que hay”, porque si lo que hay no te gusta, no vas a ser feliz. Y eso traerá repercusiones en tu vida, de pareja, profesional, laboral, etc., porque te frustras. Si no te gusta lo que hay, haz las paces desde el amor propio y cámbialo.",
                    "15. La relación que mamá tiene con su propia imagen es algo que los niños ven y van absorbiendo. Es bien importante aceptarnos porque la belleza está en las diferencias. Mamás nunca se dejen para después, el hoy es hoy. Valoren lo que sí tienen y agradezcan, eso les va a cambiar la vida."
                ]},
            {"text":"Esperamos que estos consejos te hagan darle un nuevo enfoque a tu maternidad y que lo veas no como una limitante sino como una oportunidad para soltar esos momentos de culpa, porque muchos de ellos solo viven en ti, seguro tus hijos ni se van a acordar. Ellos atesoran muchísimo más los recuerdos bonitos que les has regalado.",}, 
        ],
        "author":{
            "name":"@karlamamadecuatro",
            "brief":"Si quieres conocer más o estás interesada en los cursos que ofrece Karla, síguela en sus redes sociales.",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/karlamamadecuatro/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/KarlaDoula"},
                {social:"Twitter", text: "", link:"https://twitter.com/karladoula?s=11"},
            ],
            "links":[
                {social:"Web", text:"Podcast Mujeres Conscientes", link:"https://open.spotify.com/episode/6ZNlHxtJKX477bT3HgYdtG?si=cD3ejaiYQmCnVbAfTKS0lw&nd=1"}
            ]
        },
        "refs":[
        ]        
    },
    'nutricion-ritual-belleza':{
        "image": lifestyle_27,
        "title": "La nutrición como elemento fundamental de nuestro ritual de belleza",
        "pars":[
            {"text":[<iframe title="E10: Nutrición como parte de nuestro ritual de belleza" src="https://open.spotify.com/embed/episode/2u1II84gvjDbRfFr4r6Oig" width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>]},
            {"text":"La nutrición no es un tema de belleza o estética, también es un tema de salud. Una dieta equilibrada es parte vital del bienestar integral y del equilibrio físico y mental. Además, es especialmente importante para mantener tu sistema inmunológico en perfectas condiciones.",},
            {"text":"Platicamos con la Dra. Julia, máster en nutrición clínica, con varios diplomados en temas relacionados a la salud y dietoterapias. Ella se ha dedicado por más de 10 años a dar talleres dentro y fuera del país, dando capacitaciones y asesorías."},
            {"text":"La Dra. Julia decidió dedicarse 100% a la nutrición después de haber sufrido sobrepeso, ansiedad, e inconformidad con su cuerpo desde niña. Comenzó con dietas y probando mil tratamientos hasta que decidió estudiar nutrición de forma profesional,  y con ello le dio un giro radical a su vida."},
            {"text":"Como ya todos hemos experimentado, la pandemia fue algo que modificó nuestras rutinas diarias y nuestros hábitos alimenticios. El porcentaje de personas con sobrepeso aumentó a causa del ocio y la poca actividad física derivada del aislamiento en casa."},
            {"text":"Así que si eres nuevo en este tema o quieres adentrarte más te dejamos estos consejos:",
                "list":[
                    "1. Cada cuerpo y metabolismo es diferente pero ¿Cómo elegir una dieta que me convenga? Hay dietas de moda, que ves en redes sociales o que realizan nuestras amistades. Para comenzar debemos conocernos, conocer nuestro cuerpo, nuestros antojos para después acercarnos a un profesional que nos  guíe y nos acompañe en el proceso.",
                    "2. La mayoría de los alimentos procesados contienen un potenciador de sabor llamado “Glutamato” que se vuelve un componente adictivo para nuestros cuerpos. Hay que conocer a detalle los ingredientes de los alimentos que consumimos, está bien comerse una o dos galletas procesadas, unas cuantas papas pero debemos saber controlar y no provocar un círculo vicioso. Es mejor optar por alimentos naturales que no contengan este ingrediente.",
                    "3. La clave está en planear tus alimentos, tener a la mano verduras, frutas, que se puedan congelar para poder improvisar una ensalada. La cuestión es no caer en el “chatarrismo”. Existen muchas opciones saludables, económicas y deliciosas para construir un menú equilibrado.",
                    "4. Recuerda, si quieres perder peso, adelgazar y aprender a comer saludable es totalmente innecesario que te la pases el día contando las calorías. No sirve de mucho, salvo para terminar obsesionandose y sintiendo ansiedad por la  dieta y la comida.",
                    "5. El peso y la forma del cuerpo son cosas que preocupan a la mayoría de las personas, nos obsesionamos por los cuerpos “perfectos” que vemos en redes sociales y nos sentimos atraídos por las famosas “dietas milagro”. Recuerda que cada cuerpo y persona es diferente, disfruta los cambios y procesos. La clave es tener paciencia.",
                    "6. Deja el miedo a la báscula, el número que aparece ahí no define tu estado de salud, ni tu peso ideal. No todo tu peso es grasa, el músculo también pesa.",
                    "7. Lo más importante es no caer en los estereotipos, no comparar tu cuerpo con el de l@s demás. ¡Siéntete a gusto contigo mism@ y preocúpate por tu salud!",
                ]},
            {"text":"Esperamos que con estos consejos te des la oportunidad de intentar cambiar tus hábitos y decidas por ti, tu cuerpo y sobre todo, tu salud."},
            {"text":"¡Disfruta cada cambio y procesos! Y recuerda que cada cuerpo es diferente."},
        ],
        "author":{
            "name":"Dra. Julia Salinas Dücker",
            "brief":"Si quieres seguir motivándome y  saber más sobre salud y nutrición sigue a la Dra. Julia Salinas Ducker en sus redes sociales:",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/antojodiabolico/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/antojodiabolico"},
                {social:"Twitter", text: "", link:"https://twitter.com/juliasalinas"},
                {social:"Web", text: "", link: "http://juliasalinasducker.com/antojo.php"}
            ],
            "links":[
            ]
        },
        "refs":[
        ]        
    },
    'cuidado-mental-pandemia':{
        "image": lifestyle_28,
        "title": "El cuidado de la salud mental tras vivir una pandemia",
        "pars":[
            {"text":"La pandemia afectó a los seres humanos más de lo esperado. El aumento de los casos de depresión, ansiedad e incluso de suicidios, es alarmante. Hoy, más que nunca, es primordial hablar sobre salud mental.",},
            {"text":"Lo que conocemos como salud mental es el estado de equilibrio que una persona debe tener en la parte emocional, psicológico y social."},
            {"text":"Para profundizar en este tema, platicamos con Mónica León, Licenciada en Psicología Educativa, con un estudios en en Terapia Narrativa. Mónica se ha dedicado a la atención psicoeducativa y a procesos de comunicación y Recursos Humanos."},
            {"text":"Sin duda, la COVID-19 no solamente afectó nuestra salud física y nuestra economía, también generó desajustes socioemocionales en las personas y sus familias. Los padecimientos o situaciones que se presentaron de forma más crítica  fueron:"},
            {"title":"1. Ansiedad","text":"La ansiedad puede ser normal bajo condiciones de estrés, sin embargo, cuando persiste, puede convertirse en un trastorno y puede llegar a ser incapacitante. Los trastornos de ansiedad son condiciones de salud mental que se relacionan con experimentar un exceso de angustia, miedo, nerviosismo, preocupación o terror."},
            {"text":"Aunque en diferentes formatos, la ansiedad puede presentarse tanto en adultos como en niños. Pero  ¿Cómo saber si está afectando a algún familiar? Aquí te dejamos algunos indicadores que se pueden llegar a presentar:",
            "list":[ 
                "Problemas digestivos, respiratorios, mareos o dificultad para conciliar el sueño.",
                "Sensación persistente de peligro.",
                "Las interacciones sociales, la toma de riesgos y las actividades de esparcimiento pueden verse limitadas por una preocupación constante.",
                "Incapacidad para pedir ayuda.",
                "Cambios importantes en la rutina por temor a realizar diferentes actividades."
            ]},
            {"text":"Los niños y las niñas pueden estar experimentando ansiedad si se muestran inquietos o hiperactivos por largos periodos, rompen en llanto sin causa aparente, experimentan preocupación por el futuro y presentan pesadillas frecuentes."},
            {"text":"Una de las causas de la ansiedad en los niños y niñas durante la pandemia, es la nula o restringida interacción con sus iguales."},
            {"title":"2. Divorcios", "text":"Otro fenómeno que ha sucedido durante la pandemia es la ola de divorcios y ¿Por qué pasa? La convivencia extrema, el miedo al contagio, el cambio en las rutinas, los niños en casa, la incertidumbre financiera; entre otros factores, representan todo un reto para las parejas. La pandemia también ha evidenciado el incremento de la violencia doméstica, en especial, la violencia hacia la mujer, un tema tan complejo que desafortunadamente la sociedad ha llegado a subestimar y normalizar."},
            {"text":"Tejer redes de apoyo entre las mujeres nos ha fortalecido porque al sentirnos apoyadas y comprendidas y no juzgadas, logramos reunir valor para alzar la voz y paulatinamente ir logrando un cambio digno y justo. Si te sientes atrapada en una situación de este tipo te recomendamos identificar tu red de apoyo, pedir ayuda y de ser posible, acudir con algún psicólo@ o grupo de atención."},
            {"title":"3. Suicidios", "text":"No dejemos atrás el suicidio. Durante la pandemia, en México los casos de suicidio se  incrementaron en promedio un 20%, especialmente entre los jóvenes de 20 a 29 años. Una persona que está pensando en cometer suicidio puede recibir atención oportuna si se detectan y atienden las señales de alarma. Si percibes algunas de estas señales en tu círculo de amigos o en familiares no dudes en acercarte a ellos, preguntarles cómo se sienten y cómo puedes ayudarlos. Hazles saber que tienen tu apoyo:",
            "list":[
                "Aumento de consumo de alcohol y drogas.",
                "Cambios significativos en la rutina cotidiana.",
                "Exposición a situaciones de riesgo.",
                "Dificultad para concentrarse y tomar decisiones.",
                "Aislamiento o reducción de las interacciones sociales.",
                "Dificultad para expresar las emociones.",
                "Autodescripciones negativas o comparativas con los otros."
            ]},
            {"text":"Pero no todas son malas noticias. La pandemia también nos ha permitido estar más cerca de nuestra familia, apreciar nuestra salud física y mental y replantear nuestras prioridades."},
            {"text":" ¡Te dejamos algunos tips que te ayudarán a sentirte más plen@ aún ante momentos de adversidad!","list":[
                "1. Nombra, reconoce y expresa lo que sientes.",
                "2. Mantén contacto social, realiza videollamadas o reúnete con tus amistades y familiares. (Con moderación, recuerda seguimos en pandemia)",
                "3. Descansa y ten una alimentación correcta y sana.",
                "4. Realiza actividad física.",
                "5. Prueba nuevos rituales personales y familiares. ",
                "6. No dudes en buscar ayuda profesional.",
                "7. Consiéntete y alimenta tu amor propio. ",
                "8. Date un espacio para practicar la meditación y la gratitud."
            ]},
            {"text":"Esperamos que esta información sea de utilidad para ti y los tuyos  y no olvides que así como es importante cuidar nuestra salud física, nuestra salud mental es esencial para mantener nuestro bienestar."},
            {"text":"¡Hablemos de salud mental!"},
            {"text":"Para conocer más sobre estos temas, te invitamos a que visites nuestros blogs anteriores y nos sigas en redes sociales, nos encuentras como BetterSelfmx."}
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'trastornos-del-sueno':{
        "image": lifestyle_29,
        "title": "Todo lo que necesitas saber sobre los trastornos del sueño",
        "pars":[
            {"text":[<iframe title="E8: Que nada te quite el sueño"  src="https://open.spotify.com/embed-podcast/episode/1WMx0weEpbdiCfQHZFXJFc" width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>]},
            {"text":"La pandemia produjo fenómenos de todo tipo y uno de los más notables es el insomnio. ¿Qué es y a qué se deben los trastornos del sueño? El insomnio es el trastorno del sueño más habitual y se caracteriza por no tener un descanso satisfactorio. Afecta con mayor frecuencia a la población adulta, pero también llega a presentarse en niños."},
            {"text":"Platicamos con la Dra. Patricia González, médico cirujano y especialista en neurología, quien se capacita constantemente en temas de alta especialidad como esclerosis múltiple y neurofisiología. Así que si tienes dificultades para dormir y no sabes con quién acudir, la Dra. Patricia es tú mejor opción, conócela y sigue leyendo acerca del sueño."},
            {"text":"¿Qué sucede durante el sueño? A medida que nos vamos quedando dormidos hasta que conciliamos un sueño profundo suceden cinco fases de actividad cerebral diferenciada, donde algunas de ellas se repiten de manera secuencial durante las horas de sueño.",
            "list":[ 
                "Fase 1. Se produce cuando empezamos a tener sueño y el ritmo Alfa disminuye.",
                "Fase 2. Es una etapa intermedia entre vigilia y sueño, aunque más profunda que la fase anterior. En esta fase aparecen los complejos K, cuya finalidad es que no nos despertemos ante ruidos ambientales.",
                "Fase 3. Las ondas de actividad empiezan a ganar amplitud y desciende su frecuencia. En esta fase comienza el sueño profundo.",
                "Fase 4. Es la fase de sueño profundo.",
                "Fase REM. Aproximadamente 90 minutos después de empezar el sueño comienza la fase de sueño REM (por sus siglas en inglés, rápidos movimientos oculares)."
            ]},
            {"text":"Prestando atención a la secuencia anterior podemos diferenciar dos tipos de fases durante el sueño, el sueño REM (fase 5) y el sueño no REM (fases 1, 2, 3 y 4). Durante las horas de sueño las fases de sueño REM y no REM se alternan en ciclos de aproximadamente 90 minutos.",
            "list":[
                "1. El sueño NO REM, es el sueño más profundo relacionado con el descanso y la recuperación del cuerpo.",
                "2. El sueño REM se enfoca en la concentración, la consolidación de la memoria y la percepción del dolor, es clave para la conexión de las neuronas."
            ]},
            {"text":"Si alguno de los ciclos se llega a romper, el cuerpo no logra descansar en su totalidad y es cuando, aún habiendo dormido ciertas horas, nos sentimos cansados al despertar."},
            {"text":"El promedio para un descanso satisfactorio es de 7.5 a 8 horas, sin embargo, no todas las personas logran concretar este tiempo de descanso con facilidad. Esto ocurre cuando se tiene alguna alteración en el ciclo de sueño, sintiéndose más despiertos durante la noche o cansados pero incapaces de conciliar un sueño profundo ¿Cómo puedo darme cuenta de ello? Se recomienda acudir con un especialista para realizar una serie de pruebas que te permitan conocer o encontrar qué cosas te privan del descanso."},
            {"text":"Algunas consecuencias por no descansar o no dormir lo suficiente son:",
            "list":[
                "Dificultad para bajar de peso",
                "Propensión a enfermedades como diabetes",
                "Sentirse fatigado durante el día",
                "Aparición de las famosas “ojeras”",
                "Envejecimiento prematuro, debido a que la piel se regenera mientras dormimos",
                "Afectaciones a la memoria"
            ]},
            {"text":"A continuación, la Dra. Patricia comparte algunos consejos para poder conciliar el sueño:",
            "list":[
                "1.- Establece hábitos, esto quiere decir, dormir y despertar a la misma hora.  El cuerpo humano necesita 21 días para generar nuevas rutinas, así que establece horarios claros para hacerle la vida más “fácil” a tu cuerpo.",
                "2.- Apaga dispositivos electrónicos  2 o 3 horas antes de dormir. Si trabajas todo el día en la computadora, es recomendable utilizar lentes para protección de luz azul y tomar descansos de 20 minutos para reposar los ojos.",
                "3.- Deja que el cuerpo se exprese y maneje las emociones durante el día, entre menos preocupaciones tengas antes de dormir, te sentirás relajado y listo para descansar.",
                "4.- Utiliza la cama SOLO para dormir, no realices otras actividades como tareas o ver televisión.",
                "5.- Cansa a tu cuerpo, realiza actividades físicas, sal a correr, a pasear a tu mascota, para que cuando llegue la noche ya sientas la necesidad de dormir.",
                "6.- La meditación es tu mejor amiga. Debido a que ayuda a liberar todos los pensamientos negativos, reduce el estrés y  amplía la visión de la vida.",
            ]},
            {"text":"Al dormir bien, el cuerpo produce una sustancia química llamada “Serotonina”, la famosa hormona de la felicidad que regula el estado de ánimo, además ayuda a tener un día activo, productivo y realizar las actividades diarias sin ninguna dificultad."},
            {"text":"Recuerda “Cuida tu sueño y tus emociones”. Cuéntanos en redes sociales ¿Te funcionaron estos consejos?"},
            {"text":"Para más información o asistir a consulta puedes comunicarte o agendar cita con la Dra. Patricia González al 55-46-33-14-87."},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'conexion-tierra-ambiente':{
        "image": lifestyle_30,
        "title": "¿Cómo puedes conectarte con la tierra y el medio ambiente?",
        "pars":[
            {"text":[<iframe title="E12: Conexión con nuestro planeta tierra"  src="https://open.spotify.com/embed/episode/3swP9J4y8OpT9rZuNmHsP3" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>]},
            {"text":"Hoy en día es tiempo de ser conscientes y reducir al máximo los residuos y la basura que generamos. De esta manera, disminuimos nuestra huella de carbono y nos permitimos vivir una vida con menos cosas materiales, acercarnos a la naturaleza y darle a la tierra y el medio ambiente el valor que se merece."},
            {"text":"Es por eso que en este blog tenemos a tres especialistas en el tema de zero waste, reciclaje, sustentabilidad y conexión con el medio ambiente. Paulina Jiménez es socia y fundadora de HK CERO, una marca de productos para el cuidado personal con base en ingredientes orgánicos; también es amante y experta en la relación naturaleza-belleza y se ha dedicado a estudiar cosmética natural y sólida en los últimos años. "},
            {"text":"Paulina lleva su marca en conjunto con Ana Paula Alvarado también socia y fundadora de HK CERO, amante de la naturaleza, de los animales, proteccionista independiente, apasionada de su trabajo y del medio ambiente.",},
            {"text":"Por otro lado, también nos acompaña Claudia Padilla, eco blogger, diseñadora gráfica, vegetariana y zero waster. Sus blogs nacen para inspirar a más personas a vivir con menos residuos, sin crueldad y en armonía con el planeta.",},
            {"text":"En esta ocasión nuestras tres invitadas nos compartieron todo acerca de cómo migrar de un estilo de vida cotidiano basado en el consumo a un estilo de vida más “minimalista”."},
            {"text":"Ellas comenzaron a involucrarse en la protección y cuidado del medio ambiente a partir de actividades cotidianas como: cuidar el agua, reciclar, brindar amor y respeto a los perros de la calle, a los animales, aprender a vivir con menos, observar y analizar que tenemos una razón de existir dentro de un ecosistema y una conexión real con la tierra. "},
            {"text":"Paulina y Ana Paula por su parte se dieron cuenta de que los primeros productos de su marca desechaban demasiados envases plásticos.  Fue así como tomaron la decisión de cambiar a productos sólidos, y así sumarse a esta increíble tendencia, ayudar al medio ambiente, a los animales (ya que HK Cero es una marca libre de crueldad animal) y al mismo tiempo cuidar  el cabello y la piel de sus clientes con productos veganos, hechos con ingredientes orgánicos y naturales.",},
            {"text":"¿No sabes cómo empezar? ¡No te preocupes! Paulina, Ana Paula y Claudia nos comparten algunos puntos importantes para iniciar un cambio desde casa: ",
            "list":[
                "1.- Reducir basura, reutilizar bolsas de plástico, envases y siempre encontrarle un segundo uso a nuestros deshechos.",
                "2.- Usar bolsas de tela o tuppers en vez de envases desechables.",
                "3.- Comprar a granel por ejemplo: semillas, té, etc.",
                "4.- Realizar compostas caseras.",
                "5.- Comprar marcas orgánicas y locales.",
                "6.- Reutilizar ropa, donarla entre la familia o amistades.",
                "7.- Hacer compras conscientes, preguntarnos ¿Lo necesito? ¿Lo voy a utilizar? ¿Ya tengo uno igual?",
                "8.- Poner foco en los productos de cuidado e higiene personal para que sean de origen natural, sin sulfatos/ químicos,  sin empaques/envases de plástico y libres de crueldad animal (https://www.hunabkunan.com/cheeks )"
            ]},
            {"text":"Además de estas acciones, te invitamos a sumarte este mes al movimiento Plastic Free July (Julio sin plástico), que propone pasar 31 días sin plásticos de un solo uso. ¿Te gustaría aportar un granito de arena? Para hacerlo, evita comprar productos con envase de plástico, ni utensilios: ya sean tenedores, cucharas, platos o vasos. ¿Te unes al reto? Tú eliges hacerlo un día, una semana, o todo el mes."},
            {"text":"Este movimiento global ayuda a millones de personas a ser parte de la solución a la contaminación plástica, para que podamos tener calles, océanos y comunidades más limpias. Y lo mejor es que cuidamos nuestro planeta."},
            {"text":"Para finalizar te dejamos algunos consejos de nuestras expertas para que te animes a realizar pequeños cambios para vivir en armonía con el planeta: ", 
            "list":[
                "1.- Aprende a vivir con menos ego, busca un bien común y encuentra un equilibrio.",
                "2.- Sé un ambientalista “imperfecto”, es decir, no busques cambiar de la noche a la mañana, regresa poco a poco a una vida más natural, sencilla, a lo simple y a realizar compras locales.",
                "3.- Transmite tu armonía y vive con paz.",
                "4.- Cuestiona tus hábitos de consumo.",
            ]},
            {"text":"Recuerda que solo tenemos un planeta tierra y es tarea de todos esforzarnos por su cuidado y preservación. Si te interesa saber más de este tema, del reto o tienes dudas sobre lo que puedes hacer por el planeta, puedes seguir y encontrar a nuestras expertas en instagram como: @pauhkunan @anapauguera @claugoesgreen"}
        ],
        "author":{
            "name":"Paulina, Ana Paula, y Claudia",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/hk_cero/"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/claugoesgreen/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'nuevas-generaciones-hijos':{
        "image": lifestyle_31,
        "title": "Las nueva generaciones y una vida sin hijos",
        "pars":[
            {"text":[<iframe title="E13: Cuando los hijos no están en mis planes" src="https://open.spotify.com/embed/episode/3B5tWJSaDPcIybeCvaxroU" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>]},
            {"text":"A lo largo de los años, hemos escuchado a mujeres hablar sobre el sueño de convertirse en mamás, tener hijos, experimentar el milagro de la vida, etc. En esta ocasión, leeremos la contraparte. A mujeres que abiertamente expresan su postura acerca de llevar una vida sin hijos y sin explorar la maternidad. Recuerda que, todos los temas del blog los tocamos siempre desde el respeto y la información para evitar emitir juicios de valor sobre cualquier postura."},
            {"text":"Para este blog platicamos con dos mujeres sobre un tema que raramente se pone sobre la mesa “Tomé la decisión de NO tener hijos”"},
            {"text":"Nuestra primera pluma invitada es Paola Bustani, Licenciada en Psicología, terapeuta sistémica relacional, especialista en trabajo, relaciones y trauma. Su experiencia profesional se divide en población altamente vulnerable a través de diferentes fundaciones y atendiendo en su consultorio clínico a personas adultas, parejas, familias tanto en España, México y Guatemala. Paola nos ayudó abordando el tema de los hijos desde un punto más psicológico y profesional.",},
            {"text":"Por otro lado, tenemos a Paulina Gómez Mascarel quien ha trabajado por más de 15 años en la industria editorial, ella es diseñadora gráfica y actualmente dirige la revista Central de Grupo Salinas. Ama las conversaciones profundas, acompañadas con una buena copa de vino y se considera soltera pero no sola. Ella como otras mujeres saben que los hijos no están en sus planes a futuro por lo que Paulina nos habló desde un punto más personal.",},
            {"text":"Tiempo atrás se creía que las mujeres solo alcanzaban la autorrealización personal y en pareja al tener hijos, si no lo hacían no cumplían su rol principal como mujer:  Dar vida."},
            {"text":"Sin embargo, cada vez es más común que los millennials decidan no tener hijos. Contrario a lo que se creía, las mujeres no nacemos con ese deseo incontrolable de algún día convertirnos en madres, en muchos casos se decide tener hijos por la pareja, los padres o solo por no sentirnos presionadas por el entorno/familia y amistades. Sin embargo, esta decisión de NO tener hijos es tan válida como el querer formar una familia de 10, no tiene nada que ver con ser egoísta."},
            {"text":"Tal vez , hoy en día los objetivos o planes de vida de las mujeres están cambiando, quizás estamos cada vez más enfocadas en terminar con nuestros estudios, tener un trabajo estable, encontrar el éxito profesional , dedicarnos y enfocarnos a nosotras mismas, viajar por el mundo y otros miles de proyectos de vida que se pueden encontrar.",},
            {"text":"Pero ¿Qué pasa cuando tomas esta decisión? Viene la presión social y familiar, llegas a cuestionarte ¿Estaré mal yo?, ¿Debo o no debo de ser mamá?, ¿Debo ir a terapia?, ¿Será un trauma? o ¿Tal vez estoy tomando la decisión a partir del miedo? ¡No te preocupes, no estás sola!"},
            {"text":"Paulina, además de ser una mujer muy exitosa y fregona en su rubro, decidió que los hijos no estaban en sus planes, ni ahora ni más adelante. Se dio cuenta que no le temía a la maternidad, de hecho le gusta mucho convivir con niños, sin embargo eso es algo muy diferente a decidir tener los propios."},
            {"text":"Las razones para decidir no ser madre son muchas y muy variadas. Van desde no tener un control propio de tu vida, no estar preparada tanto económicamente como mentalmente, no tener una pareja estable, no querer ser madre soltera, no sentir el deseo y no estar interesada en ello, no querer dar vida a alguien a quien quizás le toque un mundo y una realidad mucho más caótica que la actual, etc etc.  Cada persona es única y tiene sus propias ideas, experiencias, ambiciones y su propia definición de lo que es felicidad. Para algunas la felicidad es tener hijos y formar una familia, para otras no. Cada decisión es respetable y válida, no te sientas egoísta por decir \"me siento estable y feliz SIN SER MAMÁ\"."},
            {"text":"Otro punto a considerar es que los hijos no vienen a darnos algo que nosotros no hemos podido encontrar. La decisión se debe analizar a profundidad, ¿Realmente estoy bien económicamente, mentalmente y me encuentro estable con mi pareja para criar a un ser humano? Dar vida, educar y guiar no es una tarea NADA fácil por lo que si no estás al 100% convencida quizás lo mejor sea esperar hasta tener certeza.  Sin duda, hace falta diálogo abierto y honesto al respecto, es necesario para que actuales y futuras generaciones tomen una decisión mejor pensada sabiendo los pro y los contra de ambos caminos.",},
            {"text":"Es válido y normal pensar primero en ti, en tu futuro, crecer como persona y profesionalmente. No debemos olvidarnos y mucho menos perdernos como mujeres. Lo importante es abrazar lo que elijas, ser o no ser, y tomar una decisión propia, informada,  platicar con tu pareja para que estén en el mismo canal y leer acerca del tema, de ese modo no habrá dudas ni remordimientos a futuro."},

            {"text":"¿Aún no estás segura si quieres hijos? Aquí te dejamos algunos de estos libros para tomar una decisión informada y sin prejuicios o tabúes. ", 
            list:[
                "1. Maybe Baby - Andrea Smith",
                "2. Otherhood - William Sutcliffe",
                "3. Living the life unexpected - Jody Day",
                "4. The Baby Matrix - Laura Carroll",
                "5. Selfish, Shallow, and Self-Absorbed: Sixteen Writers on The Decision Not To Have Kids - Meghan Daum",
                "6. Childfree by Choice: The Movement Redefining Family and Creating a New Age of Independence - Amy Blackstone",
                "7. Tu hijo, tu espejo - Martha Alicia Chávez"
            ]},
            {"text":"Si deseas orientación psicológica puedes encontrar a Paola Bustani en instagram  como @psic.guztan y si te gustaría platicar más sobre este tema puedes seguir a @pgommasc"},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },     
    'transformacion-postparto':{
        "image": lifestyle_32,
        "title": "La transformación postparto",
        "pars":[
            {"text":[<iframe title="E15: Tratamientos estéticos que son un MUST para después de ser mamá" src="https://open.spotify.com/embed/episode/4idBCzs58EmlRthIkaadIh" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>]},
            {"text":"Durante el embarazo, la mujer observa los cambios en su cuerpo y como, mes a mes, ocurren transformaciones que responden a las necesidades del bebé que crece en su interior y que se prepara para el parto. En algunas ocasiones, después de haber dado a luz, las mujeres se sienten fatigadas, agotadas, dominadas por un torbellino de emociones y preguntándose si alguna vez podrán regresar a su figura ideal, volver a tener la misma seguridad sobre su cuerpo, sentirse bien con ellas mismas o arreglarse de algunas secuelas estéticas del embarazo. Lo que conocemos como transformación postparto. "},
            {"text":"Para entender más sobre las cirugías y sus procedimientos nos acompaña el Dr. Harold Dent, especialista en cirugía plástica estética y reconstructiva de alto nivel, su amplia experiencia le permite formar parte del cuadro de importantes instituciones médicas como en Hama Clinic, donde actualmente da consultas y es experto en rostro, abdomen, liposucciones, reducción de busto, rinoplastias, otoplastias y bichectomias."},
            {"text":"Los procedimientos estéticos que más demanda tienen después del parto son el levantamiento de pecho y  la “abdominoplastia”, que consiste en una reconstrucción de pared abdominal y eliminación de piel y grasa, los cuales tienen un 98% de satisfacción para las pacientes. Pero debemos recordar que cualquier cirugía estética te ayudará a sentirte y verte mejor siempre y cuando lo hagas por ti , no por tu pareja o en aras de cumplir algún estereotipo físico.",},
            {"text":"¿Sabes cuáles son los cambios naturales durante el embarazo? El Dr. Harold nos comparte algunos de ellos:",
            "list":[
                "1-Diástasis abdominal: Una disfunción del tejido conectivo de nuestro abdomen en la que se ocasiona una separación de los músculos rectos abdominales.",
                "2-Estrías: Durante el embarazo la piel se llega a estirar con el aumento de volumen que experimenta el cuerpo.",
                "3-Flacidez: La retención de líquidos en el embarazo es una de las causas más comunes de la flacidez del vientre y los muslos en el postparto."
            ]},
            {"text":"Desde luego lleva tiempo y esfuerzo recuperarse después del parto, mejorar el aspecto físico va en tres vías: retomar el ejercicio, continuar con buenos hábitos alimenticios y buscar apoyo de un profesional para regresar todo a su lugar. El Dr. Harol nos platica de algunos tratamientos estéticos a los que puedes recurrir para verte radiante después de dar a luz:",
            "list":[
                "*Cellfina: Tiene como objetivo combatir la celulitis a largo plazo.",
                "*Radiofrecuencia: Técnica utilizada en medicina estética para combatir la flacidez.",
                "*Ultherapy: Es un tratamiento no quirúrgico muy seguro que utiliza el ultrasonido para contrarrestar los efectos del tiempo y la gravedad en tu piel. Brinda firmeza y mejora la calidad de la piel.  El tratamiento produce nuevo colágeno en el interior, pero el proceso natural de envejecimiento de cada individuo dictará cuánto tiempo se traduce en resultados visibles en el exterior. Se puede aplicar en la cara, ceja, cuello, barbilla y escote."
            ]},
            {"text":"Los cambios se pueden observar inmediatamente después de la aplicación del tratamiento, pero los mejores resultados serán visibles después del segundo o tercer mes, tiempo que le toma al cuerpo generar colágeno de soporte nuevo."},
            {"text":"*Productos para el rostro: Ácido hialurónico o toxina botulínica, que tienen un gran impacto mejorando la apariencia de la piel, principalmente él recomienda 3 productos:",
            "list":[
                "1-Xeomeen: Es toxina botulínica Tipo A que se inyecta en los músculos y se utiliza para mejorar temporalmente la apariencia de las arrugas de moderadas a graves.",
                "2-Radiesse: Es hidroxiapatita de calcio, un componente que se encuentra de manera natural en nuestro cuerpo y que actúa recuperando el volumen perdido en distintas zonas. Ayuda a definir el contorno facial y corregir pequeñas imperfecciones del rostro.",
                "3-Belotero: Es un filler de ácido hialurónico que se utiliza para prevenir y tratar los signos de envejecimiento facial e hidratar el rostro. Productos 100% recomendables para regresar a tener un rostro impecable."
            ]},
            {"text":"Si estás considerando hacerte un tratamiento estético recuerda que debes esperar al menos hasta pasados los seis meses del parto. Es el tiempo que tarda el cuerpo en recuperarse y desprenderse de los kilos acumulados, hay que tener en cuenta el periodo de lactancia, durante el que se producen importantes cambios físicos."},
            {"text":"Lo más importante de la maternidad es aceptar los cambios con alegría, asumir las nuevas formas de tu cuerpo, cambiar aquello que no te gusta y disfrutar de tu nueva familia."},
            {"text":"Si recientemente te convertiste en mamá o estás pasando por los cambios del embarazo y quieres prepararte para después de dar a luz, puedes agendar cita con el Dr. Harold a los siguientes números telefónicos:",
            "list":[
                "Consultorio: 44-41-23-25.50",
                "WhatsApp: 44-43-38-78-81"
            ]},
        ],
        "author":{
            "name":"Dr. Harold Dent",
            "contacts":[
                {social:"Instagram","text":"", link:"https://www.instagram.com/hamaclinic/"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/dr.harold_dent/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/HamaClinicByHaroldent"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'ingredientes-desarrollo-educativo':{
        "image": lifestyle_33,
        "title": "Ingredientes clave para el desarrollo educativo",
        "pars":[
            {"text":[<iframe src="https://open.spotify.com/embed/episode/52E3ZE11loeSWsWXs4fdfO" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>]},
            {"text":"El desarrollo educativo por miles de años funcionó a través de una metodología presencial. Pero, ¿qué sucede cuando, de un momento a otro, los docentes, alumnos e instituciones educativas se ven obligados a tomar clases de forma remota? En esta ocasión, nos acompaña Álvaro, director general del Colegio Lakeside, un experto en aprendizaje que nos ayudará a entender, aprender y adaptarnos a las nuevas formas de enseñanza que llegaron para quedarse."},
            {"text":"Álvaro es experto en aprendizaje colaborativo, certificado por el Cooperative Learning Center y miembro del Centro Internacional de Aprendizaje Cooperativo. Álvaro también es autor y director del Sistema de Enseñanza y Aprendizaje ComunicARTE. Ha sido miembro distinguido del Apple Professional Development y es voluntario en diferentes organizaciones no gubernamentales. En fin, todo un conocedor en temas de enseñanza."},
            {"text":"De un día a otro, por el tema de la pandemia se cerraron las escuelas y los alumnos y profesores tuvieron que dejar de asistir a las aulas. La situación de emergencia sanitaria también provocó una emergencia educativa en México y en otros países; el aprendizaje a distancia aún estaba en pañales y el desarrollo de millones de  niños en juego.",},
            {"text":"La presencia de las herramientas tecnológicas, entendidas como el conjunto de herramientas, soportes y canales de acceso a la información, han empujado a la educación hacia un cambio de paradigma en la vida cotidiana, social y dentro de las instituciones. El principal reto de la educación a distancia es forjar una nueva metodología que permita que los alumnos se desarrollen socialmente, emocionalmente y que adquieran un pensamiento crítico. Algo que Álvaro menciona es que la educación emocional, permite preparar a los niños para la vida, el desarrollo de la conciencia, la capacidad de controlar sus emociones y adoptar una actitud positiva.",},
            {"text":"Ahora más que nunca,  el trabajo de “educar” no solamente está en manos de los docentes, sino que los padres de familia han tomado un rol importantísimo en el desarrollo cognitivo, adopción de nuevas rutinas y  hábitos en los niños y sobre todo en la formación de carácter.",},
            {"text":"La educación es una forma de brindar estabilidad y seguridad a los niños ya que les ayuda a implementar una rutina y permite que poco a poco ellos puedan utilizar su tiempo de forma más productiva. Álvaro comenta cómo es que en el Colegio Lakeside se realizaron algunas modificaciones para que los padres de familia no sintieran un sobreesfuerzo al combinar sus roles como trabajadores, padres y ahora también como docentes."},
            {"text":"*Algunos cambios fueron:",
            "list":[
                "*Reducir las horas de conexión: dejando descansos cada 50 minutos, después de cada materia.",
                "*Aplicar dos descansos en vez de uno.",
                "*Posibilidad de tener asistentes educativos que van a los domicilios y están al pendiente de los alumnos.",
                "*Los profesores permanecen conectados en el horario escolar, para atender las dudas del alumnado."
            ]},
            {"text":"La situación se ha vuelto complicada y seguramente papá y mamá sienten presión por tomar un papel que no estaban acostumbrados o que su hogar se convierta en escuela, pero es importante que los niños sientan el apoyo de la familia, estar atentos de ellos y ayudarles en lo que necesiten."},
            {"text":"En la escuela se aprende y se necesita aprender de la propia experiencia para poder crecer, Álvaro plantea que el colegio es comunidad, familia y esencia para un desarrollo potente, abarcando todos los ámbitos desde los que un niño puede recibir algún tipo de mensaje educativo."},
            {"text":"Si eres padre o madre de familia debes enseñarle a tu hijo a desarrollar su mente, enseñarle a vivir, no solo debe aprender de los libros sino de las experiencias diarias y de aquellos que le rodean.",},
            {"text":["Si deseas conocer más sobre temas de educación o tienes dudas acerca de cómo darle a tus hijos las mejores herramientas educativas en estos tiempos acércate a la comunidad del Colegio Lakeside, los puedes seguir en sus redes sociales:", <br/>,"Instagram: ", <a href="https://www.instagram.com/lakesidecolegio/" rel="noopener noreferrer" target="_blank" className="Link texto-pink">@lakesidecolegio</a>, <br/>, "Facebook: ", <a href="https://www.facebook.com/colegiolakesideoficial/" target="_blank" rel="noopener noreferrer" className="Link texto-pink">Colegio Lakeside Oficial</a>]}
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'belleza-salud-lifestyle':{
        "image": lifestyle_34,
        "title": "¿Cómo vemos la belleza, salud y el estilo de vida?",
        "pars":[
            {"text":[<iframe src="https://open.spotify.com/embed/episode/4o61XdwhwqsihbH0XvYt6S" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>]},
            {"text":"La belleza la reconocemos cuando la vemos, pero, ¿cómo podemos definirla? Cuando escuchamos la palabra belleza, la relacionamos con cualidades físicas: piel, ojos, labios, sonrisa. Lo relacionamos con la salud de pies a cabeza, reflejando justamente en buenos hábitos y estilo de vida. Independientemente de la cultura, parece que hay ciertos patrones, asociados a visualizar algo “hermoso”."},
            {"text":"Pero el tiempo pasa y las mujeres hemos cambiado ese chip, modificando algunas “reglas” para sentirnos más cómodas y para no estar peleadas con nosotras mismas."},
            {"text":"Para entender más sobre el tema de “belleza”, así como de sus mitos y realidades nos acompaña Sofía Domínguez, Lic. en Ciencias de la Comunicación. A Sofía le apasionan los medios digitales, en sus inicios se dedicó a la radio, pero su mayor prioridad fue postularse para trabajar en Instyle y finalmente tomó el puesto de Editora Digital en Glamour México.",},
            {"text":"Sofía nos cuenta que en ocasiones sentimos la necesidad de gustarle a nuestra pareja, de vernos bien para los demás, de seguir tendencias de moda o de maquillaje para poder encajar. Pero en realidad, hoy en día podemos salir luciendo 100% naturales o como mejor nos sintamos pues los estereotipos han ido cambiando y evolucionando con los años. Ya no es necesario portar mucho maquillaje o gastar en las mejores marcas, actualmente la belleza se trata de ser auténtica, reflejar tu esencia y tomar decisiones inteligentes.",},
            {"text":"Como hemos mencionado, los tiempos cambian y es por eso que Sofía nos puso al día con las mejores tendencias post covid:",
            "list":[
                "1.- Más interés por los productos que nos aplicamos en la cara. Buscar las marcas con ingredientes más orgánicos , naturales y sobre todo profesionales.",
                "2.- Maquillaje con naturalidad, destacando las partes de nuestra cara que antes no nos atraían. ",
                "3.- Usar tratamientos en rostro y cabello a base de ingredientes naturales.",
                "4.- Nutrir e hidratar diariamente nuestro rostro pues es el lienzo que nos hará lucir más saludables, bellas y radiantes."
            ]},
            {"text":"La belleza muchas veces forma percepciones falsas y en muchos casos crea modelos de perfección inalcanzables, una imagen creada principalmente a través de los medios de comunicación. Para no caer en inseguridades te recomendamos seguir a personas reales, no compararte y acercarte a las marcas que hoy en día buscan promover estilos de belleza más reales, inclusivos y de carne y hueso."},
            {"text":"*La sociedad ha premiado a las personas por su estética, su cuerpo, su peso y en último lugar por su interior. Esto ha sucedido siempre y llega a ser angustiante el hecho de catalogar a una persona primero por lo que nos muestra, y luego por lo que es ¡Cambia tu forma de emitir una primera impresión!",},
            {"text":"Sofía nos deja 5 consejos básicos para empezar a percibir la belleza del mundo desde otra perspectiva:",
            "list": [
                "1.- Crear nuestros propios ciclos y procesos, buscar un estilo de vida saludable",
                "2.- Informarnos y no dejarnos llevar por lo que vemos en las redes sociales.",
                "3.- Educarnos constantemente.",
                "4.- Atrevernos a probar cosas innovadoras que resalten nuestra escencia",
                "5.- Observar la belleza como inspiración."
            ]},
            {"text":"Recuerda aceptarte tal como eres, con tus virtudes y defectos, con tus imperfecciones internas y físicas. Proyecta  pensamientos positivos y auténticos para verte y sentirte más bella.  Está en ti aceptarte y abrazar quien eres sin querer complacer a los demás o parecerte a la “influencer” reconocida del momento."},
            {"text":"Llénate de confianza, todas somos valiosas por lo que somos, no por lo que hacemos. Lo más importante es no caer en estereotipos de belleza, no ser títeres de la sociedad o del consumo, apoyarnos entre mujeres. La de al lado no es tu competencia sino tu mejor aliada y fuente de inspiración.",},
            {"text":["¿Quieres saber más tips de belleza o tener contenido que sea de ayuda en tu proceso? Sigue en Instagram el trabajo de Sofía Domínguez en:", <a href="https://www.instagram.com/glamourmexico/" rel="noopener noreferrer" className="Link texto-pink" target="_blank">@glamourmexico</a>, <br/>, "Facebook: ", <a href="https://www.facebook.com/GlamourMexicoLatinoamerica/" className="Link texto-pink" target="_blank" rel="noopener noreferrer">Glamour México Latinoamérica</a>]}
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'hablemos-belleza-masculina':{
        "image": lifestyle_35,
        "title": "Hablemos de belleza Masculina",
        "pars":[
            {"text":"Los tiempos cambiaron, cada vez es más común que los hombres se preocupen por el aspecto físico, forma de vestir, encontrar su estilo y otras actividades que definen la belleza masculina. Decimos que durante el nuevo milenio y surgió un nuevo hombre, más preocupado por su imagen y por cuidarse por dentro y por fuera. La mentalidad de la sociedad avanza y con ello rompemos los estereotipos arraigados por años. La revolución de la belleza masculina es una realidad en pleno siglo XXI."},
            {"text":"Para comprender más del tema, nos acompañó Daniel Vásquez. Quien a sus 25 años ha liderado proyectos emergentes de moda y empresas digitales, actualmente es Editor de la Revista Bad Hombre. Daniel se caracteriza por su sensibilidad estética, por su alto entendimiento en las tendencias globales actuales y por su constante búsqueda por enfrentar nuevos retos artísticos y creativos, él es un experto en belleza masculina."},
            {"text":"Hoy en día, la belleza masculina es un sector que ha tomado muchísima relevancia en el mundo de la moda y  las marcas tanto de estilo de vida como de belleza . La publicidad dirigida a los hombres incita a que se cuiden el rostro y el cuerpo con productos como tintes y manicura, lo que ha dado lugar a nuevos cambios estéticos en ellos.",},
            {"text":"Una de las claves para quebrar el tabú de un hombre desaliñado, sucio y desprolijo, fueron las barberías, quienes acercaron la belleza al hombre de un modo más “masculino”. Trayendo consigo el principio de una apertura más amplia sobre este tema, así como tendencias especializadas en encaminar una imagen atendida.",},
            {"text":"Antes se consideraba correcto que los hombres lucieran cuerpos torneados y musculosos, estaba de moda la famosa expresión de pelo en pecho o “cuanto más peludo más varonil” y la mayor parte de los hombres contaba con barbas bien definidas. Ahora los hombres también han buscado eliminar el  vello, la moda cambió y dio lugar a un nuevo prototipo de hombre ideal. Sin barba, con pelo largo, con las cejas depiladas, para dar un look de joven rebelde.",},
            {"text":"Otra clave importante para esta evolución fue la proliferación del internet y el ambiente de las redes sociales. Estos han abierto fronteras y han permitido a los hombres abrirse al resto y expresarse de forma natural, mostrándose de una forma que nunca antes se había visto."},
            {"text":"Pues la tecnología, las redes sociales, la sociedad y blogs personales son algunos de los nuevos dictadores de los ideales de la belleza, cada vez más hombres se identifican con los cuerpos naturales y rechazan los músculos extremos. Es ahora cuando tienen sus propios ideales, sus propias reglas. La belleza de hoy es ser fiel con uno mismo, a su naturaleza, a su belleza natural, su estilo y lo que cada uno considera bello. Lo importante es estar feliz con uno mismo.",},
            {"text":"La cultura influye e impone sus códigos y estos cambian, se modifican a lo raro, a lo extravagante, lo distinto, lo original o lo bello, eso ha causado que lleguen nuevas tendencias en cuestión de belleza masculina, te compartimos algunas de ellas:",
            "list": [
                "*Esmalte de uñas en hombres.",
                "*Make up y sombras para hombres.",
                "*Cuidado personal.",
                "*Definición en marcaje mandibular. ",
            ]},
            {"text":"Para tener una mayor noción sobre las últimas tendencias en moda y estilo puedes seguir en redes sociales a Daniel Vásquez y a la revista BadHombre Magazine."},
        ],
        "author":{
            "name":"Daniel Vásquez",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/danielvasquez.g/"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/badhombremag/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/badhombremag/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'sobre-transicion-transgenero':{
        "image": lifestyle_36,
        "title": "¿Qué debo de saber sobre las transiciones transgénero?",
        "pars":[
            {"text":[<iframe src="https://open.spotify.com/embed/episode/1joqQoLLwINwhpKrplRpxM" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>]},
            {"text":"Las personas que no se identifican con su género de nacimiento, pasan por varios procesos de cambio para migrar de un género a otro. Las transiciones transgéneros tienen varios ejes de acción como lo es la parte social, la parte legal y la parte médica y/o física."},
            {"text":"Para comprender la transición y los procesos transgénero nos acompaña Pol Martínez Peredo, quién estudió la maestría en sexología y sensibilización, así como la especialidad de Psicoterapia Humanista en Gestelt en el IHPSG. Pol es docente del módulo de diversidad sexual de la maestría y doctorado en Sexología Humanista. Fundador y director de Musas de Metal Grupo de Mujeres Gay A.C., para la atención de mujeres lesbianas, bisexuales y personas trans. Además, Pol imparte capacitaciones y desarrolla propuestas de incidencia en políticas públicas. Él se considera trans-masculino, genderqueer y persona LGBT."},
            {"text":"“Transgénero” es un término que incluye las distintas maneras en que las identidades de género de las personas pueden diferir del sexo que se les asignó al nacer. Hay muchos términos diferentes que las personas trangénero utilizan para describirse a sí mismas. En ocasiones, la palabra trangénero se acorta como “trans”, “mujer trans” u “hombre trans”. Es mejor utilizar el lenguaje y las etiquetas que cada persona elige para sí misma.",},
            {"text":"Como se mencionaba existen diferentes términos para comprender más sobre el tema “transgénero”, uno de ellos es la “transición”; Pol lo define como el proceso mediante el cual se cambia de aspecto y se busca modificar la forma en que la sociedad te percibe y te trata, para convertirte en una persona del género con el  que te  sientes identificado internamente.",},
            {"text":"Para este proceso se debe incluir un tratamiento médico y hormonal y hacer el cambio de nombre y pronombre que prefiera la persona; también implica un cambio de apariencia y vestimenta y comunicarlo abiertamente ante sus amigos y familia. El tiempo que tome este proceso dependerá de la persona, puede ser un proceso largo y continuo, o ser algo que ocurre durante un periodo corto.",},
            {"text":"Los términos y definiciones cambian frecuentemente, por esa razón explicaremos cada una de las siglas de “LGBT” ya que  es importante entender y respetar los términos, nombres y pronombres que se utilizan en este mundo cada vez más inclusivo."},
            {"text":"“LGBT”  es la sigla compuesta por las iniciales de las palabras Lesbianas, Gais, ​ Bisexuales y Transgénero. En sentido estricto agrupa a las personas con las orientaciones sexuales e identidades de género relativas a esas cuatro palabras.",},
            {"text":"La L corresponde a las mujeres lesbianas, es decir aquellas mujeres cuya orientación sexual es homosexual.",},
            {"text":"La G corresponde a los hombres gay, es decir aquellos hombres cuya orientación sexual es homosexual.",},
            {"text":"La B corresponde al grupo de mujeres y hombres bisexuales, para quienes su orientación sexual está dirigida tanto a hombres como a mujeres.",},
            {"text":"La T hace referencia al grupo de personas transgeneristas eso quiere decir a los travestis, transformistas y transexuales, cuya identidad de género no corresponde a su sexo biológico.",},
            {"text":"En la sociedad nos asignan de acuerdo a nuestros genitales una identidad de género al nacer, ya sea mujer u hombre, y desde ahí se nos educa según sea el sexo.",},
            {"text":"Vivimos en una sociedad donde se remarca lo que está bien y lo que está mal, lo que es correcto o incorrecto, lo insano y lo que es sano. Es por eso que las personas transgénero se encuentran con muchas barreras sociales a la hora de construir su identidad.  Lejos de entrar en un patrón, lo que buscan es expresar su individualidad y quiénes son.",},
            {"text":"No todas las personas tienen las mismas experiencias, ni conciencias de identidad a la misma edad, ni toman las mismas decisiones sobre sus vidas, ni aceptan sus cuerpos de la misma manera, de ahí parte cada experiencia personal.",},
            {"text":"Pol nos comparte algunos consejos para las personas que están pasando por algún proceso de transición:",
            "list":[
                "*Apoyarse en su entorno familiar y social.",
                "*Apoyo psicoterapéutico posterior a la reasignacion de sexo. Se centra en ayudar a la persona en su propia definición de identidad de género, facilitando los procesos de adaptación social.",
                "*Buscar terapia hormonal (opcional) se usa para inducir al cuerpo los cambios físicos que causan las hormonas durante la pubertad, con el fin de que tu identidad de género coincida con tu cuerpo.",
                "*Vestirse y arreglarse de manera que sientas que tu estilo coincide con tu identidad de género."
            ]},
            {"text":"La forma en que se habla sobre las personas transgénero en el ámbito académico, científico, social y cultural están cambiando constantemente. Cada vez hay más concientización, conocimiento y apertura sobre las personas trans y sus experiencias.",},
            {"text":"Todos debemos de respetar las diferentes manifestaciones de la sexualidad y proteger a las personas “transgénero” que son discriminadas, millones de ellas en el mundo son criticadas, rechazadas o mal vistas por su identidad de género u orientación sexual.",},
            {"text":"Si conoces a una persona que está pasando por un proceso de transición, eres un familiar o amigo de alguien que ha dado este paso o tú decidiste hacer cambios para que tu género coincida con quién eres y necesitas una guía o centro de apoyo puedes seguir a Pol Martínez en sus redes sociales.",},
        ],
        "author":{
            "name":"Pol Martínez",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/polmartinezperedo/"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/musasdemetal/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/musasdemetalgay/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'historia-papa-mexicano':{
        "image": lifestyle_37,
        "title": "La historia de un papá mexicano",
        "pars":[
            {"text":"Hoy en día, hablamos de maternidad en diferentes medios. Ponemos énfasis en la maternidad, temas de crianza, embarazo y todo lo relacionado con cómo ser mamá sin morir en el intento. Pero, ¿qué pasa con los papás? ¿Cómo es que los hombres se preparan para el gran viaje de la paternidad? ¿Cómo es la realidad de un papá mexicano?"},
            {"text":"Qué mejor que escucharlo de viva voz de “Un papá mexicano” , Para este artículo platicamos con Miguel, papá de Pía y Blogger sobre paternidad mejor conocido en redes sociales como @unpapamexicano. Miguel es Ingeniero Químico egresado del Instituto Politécnico Nacional, actualmente trabaja en la industria energética, es papá de tiempo completo y daddy Blogger."},
            {"text":"Miguel comenzó la entrevista contándonos lo curioso que le resulta que aún sigamos escuchando frases como: “Mi pareja me ayuda mucho con nuestro hijo” o “Yo también le ayudo a mi esposa con los niños”. Este tipo de frases que utilizamos con cotidianidad,  nos llevan a pensar que la responsabilidad del cuidado y la crianza de los hijos es de la madre y que el padre solo tiene que colaborar en ciertas y contadas ocasiones.",},
            {"text":"Ahora, con el avance de la ciencia y la tecnología, sabemos que el cerebro de los hombres también cambia cuando nace el bebé. Miguel comenta que su vida cambió por completo en el momento en el que Pia nació. No solo cambió su forma de pensar, también cambiaron sus tiempos, sus actividades cotidianas y sobre todo sus prioridades. Hoy Miguel tiene como meta principal brindar una buena vida y educación a su hija.",},
            {"text":"El vínculo que el bebé establece con el padre, es tan importante para su desarrollo psicológico, como el que establece con la madre. Es por ello que los padres no pueden ni deben tener un papel secundario en la educación.  Cada pareja debe decidir el reparto de las tareas del hogar y el cuidado de los hijos con base en la disponibilidad de cada uno, pero también teniendo en cuenta que la responsabilidad es compartida pues los niños necesitan la atención y el cariño de ambos padres.",},
            {"text":"Si eres o serás papá primerizo y no sabes cómo involucrarte en el cuidado de tu bebé, aquí te compartimos algunas ideas:",
                "list":[
                    "1.- Toma en serio tu paternidad, tu figura como padre tendrá un gran impacto en el desarrollo de tu pequeño.",
                    "2.- Participa desde el principio en los cuidados básicos del bebé (cargarlo, darle de comer, cambiarle el pañal, bañarlo, vestirlo)",
                    "3.- Pasa tiempo a solas con tus hijos, pero no solo tiempo de juego, sino también procura salir a caminar, leer con ellos y hablar de su día."
                ]
            },
            {"text":"Convertirse en papá primerizo puede ser una experiencia emocionante y a su vez agotadora . Sin embargo, se pueden implementar medidas a fin de prepararse para esas emociones y desafíos.  El generar acuerdos desde el inicio con tu pareja es la clave. Hay que tener claro que el cuidado de los hijos es responsabilidad mutua y que tu pareja es el mejor aliado para lograrlo.",},
            {"text":"La relación padre-hijo constante y activa, genera autoestima, felicidad, manejo correcto de las emociones y promueve un el buen desarrollo personal ¿Quieres seguir guiándote sobre la importancia de la paternidad? Puedes seguir a Miguel en sus redes sociales.",},
        ],
        "author":{
            "name":"Miguel",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/unpapamexicano/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/unpapamexicano"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'filosofia-del-veganismo':{
        "image": lifestyle_38,
        "title": "La filosofía del veganismo",
        "pars":[
            {"text":[<iframe title="E22: Alimentación vegana y su relación con el estado de ánimo" src="https://open.spotify.com/embed/episode/06XeQf38YMWAbuHLFvhT3f" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>]},
            {"text":"El veganismo es un estilo de vida. Más allá de una forma de alimentarse, los veganos asumen una dieta diferente y con base en comer únicamente en vegetales, pues consideran que el ser humano no está por encima de ninguna otra especie animal."},
            {"text":"Para comprender más a fondo la filosofía del veganismo nos acompaña Jenniffer Delgado, fundadora y directora general de Anima Lux, empresa enfocada a la educación en alimentación vegana. Jenniffer también es plant based / vegan Chef, psicóloga, tallerista y conferencista."},
            {"text":"Las dietas veganas tienen un impacto positivo en nuestra salud mental y están significativamente asociadas a promover menor estrés, ansiedad y un estado psicológico equilibrado. El viejo dicho “somos lo que comemos” no solo aplica en nuestro cuerpo, sino también en nuestra mente.",},
            {"text":"Una de las principales razones por las que las personas se abstienen de tomar la decisión de dejar de consumir productos animales es porque creen que va a ser extremadamente difícil, o creen que la comida vegana no tiene tan buen sabor o  incluso se cree que no encontrarán qué comer. Pero, claro que existen opciones de platillos veganos deliciosos y se puede lograr tener una dieta balanceada, sana y llena de los nutrientes que tu cuerpo necesita sin necesidad de consumir productos de origen animal.",},
            {"text":"Ser vegano es más sencillo de lo que parece, sin embargo requiere una planificación, motivación y convicción sobre querer modificar tu estilo de vida. El cambio puede parecer un poco intimidante al principio pero no tiene por qué serlo si nos informamos bien y planeamos nuestra rutina.",},
            {"text":"¿Estás planeando abrazar al veganismo? No te preocupes, te compartimos los siguientes consejos:",
            "list":[
                "1.- Identifica aquellos platillos de tu dieta habitual que se puedan veganizar con poco esfuerzo.",
                "2.- Sustituye la leche y los yogures por alternativas vegetales como leche de coco o almendra.",
                "3.- Suscríbete a uno o varios blogs de cocina vegana. ",
                "4.- Realiza una lista de las verduras que habitualmente comes y rétate a añadir una nueva cada semana.",
                "5.- No te guíes por la mercadotecnia, busca personalmente qué ingredientes son favorables para tu dieta vegana.",
                "6.- Deja atrás los comentarios de las personas externas a tu círculo social. "
            ]},
            {"text":"Sigue estos pasos y en pocas semanas ya habrás adquirido unos cuantos  buenos hábitos y tendrás un repertorio básico de recetas y platillos que podrás disfrutar en tu proceso.",},
            {"text":"Si estás considerando convertirte en vegano, puede que tengas que reflexionar acerca de cómo te encuentras en tu vida, en tu círculo social, ya que implica un profundo compromiso.",},
            {"text":["¿Tienes el deseo de hacerlo, pero no tienes ni la más remota idea de cómo empezar? Sigue a Jenniffer en Instagram:", <a className="texto-pink" target="_blank" href="https://www.instagram.com/jennjoydelgado/" rel="noopener noreferrer">https://www.instagram.com/jennjoydelgado/</a>],},
        ],
        "author":{
            "name":"Anima Lux",
            "brief":"Empresa enfocada a la educación sobre alimentación vegana.",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/animaluxmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/animaluxmx/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'procesos-transgenero':{
        "image": lifestyle_39,
        "title": "¿Qué debo de saber sobre las transiciones transgénero?",
        "pars":[
            {"text":[<iframe title="E21 Procesos TRANSgénero" src="https://open.spotify.com/embed/episode/3p12a8NnqzF5aULvQzFMHp" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>]},
            {"text":"Nadie elige nacer con el aspecto físico que le toca. La mayoría de nosotros nos aceptamos, conformamos o hacemos lo posible para cambiarlo. Pero, cuando hay personas que no se sienten alineados con su género y su cuerpo, las cosas se complican."},
            {"text":"Para entender el tema sobre la transformación transgénero, nos acompaña el Dr. José Cortés, Cirujano Plástico egresado del Hospital General de México, quién además cuenta con una Especialidad en Cirugía Estética Craneofacial, es miembro del Comité Científico de la Asociación Mexicana de Cirugía General y a su vez es ponente Nacional e Internacional en procedimientos estéticos faciales."},
            {"text":"La disfonía de género es una desconexión entre el género que los demás asumen que tienes, basándose en las características del sexo, y el género que sientes que tienes. En el ámbito médico esta situación ocurre cuando el sexo biológico de una persona no coincide con su identidad de género, por ejemplo, un hombre se siente atrapado en el cuerpo de una mujer, o viceversa.",},
            {"text":"Gracias a la tecnología y a los avances médicos, en la actualidad, es viable realizar procedimientos de reasignación de género de forma exitosa.  Las técnicas y los objetivos de estas cirugías pueden variar según se trate de mujeres transgénero cuyo sexo biológico es masculino pero su identidad de género es femenino, u hombres transgénero a quienes les sucede lo contrario.",},
            {"text":"El Dr. José nos comparte algunos de los procedimientos disponibles para las reasignaciones de sexo.",},
            {"title":"Cirugías para la transexualidad femenina.",
            "list":[
                "Vaginoplastia: consiste en extraer el pene y proporcionar una vagina estética y funcional.",
                "Labioplastia: cirugía encaminada en lograr los labios característicos del genital femenino.",
                "Aumento de mamas: cirugía plástica que ayuda a la obtención de una silueta más femenina, en concreto para modificar el aspecto del pecho. ",
            ]},
            {"title":"Cirugías para la transexualidad masculina.",
            "list":[
                "Metaidoioplastia: El paciente sigue un tratamiento hormonal con el que se aumenta el tamaño del clítoris. Se crea un pene a partir de este órgano hipertrofiado. ",
                "Faloplastia: Consiste en crear el pene con tejidos del antebrazo, muslo, abdomen u otras zonas del cuerpo del paciente.",
            ]},
            {"text":"Para someterse a algún procedimiento de reasignación de sexo se deben de  cumplir  4 puntos importantes:",
            "list":[
                "1. Ser mayor de edad.",
                "2. Llevar un proceso de acompañamiento psicológico. ",
                "3. Tratamiento hormonal.",
                "4. Firmar un consentimiento informativo de lo que implica el procedimiento quirúrgico."
            ]},
            {"text":"Es importante seguir un protocolo de acción, ya que se trata de un tema complicado y delicado por la cantidad de implicaciones tanto físicas como psicológicas que conlleva todo el proceso.",},
            {"text":"Además de los procedimientos anteriores, que son los necesarios para llevar a cabo los cambios más evidentes, también es posible realizar otros procedimientos estéticos , menos invasivos para complementar los cambios.",
            "list":[
                "Radiesse: Es hidroxiapatita de calcio, un componente que se encuentra de manera natural en nuestro cuerpo, ayuda a recuperar volúmenes, definir contornos, corregir imperfecciones y potenciar la elasticidad y tersura naturales de la piel. Se utiliza para hacer marcaje mandibular tanto en hombres como mujeres. ",
                "Belotero:  Es un ácido hialurónico inyectable. Se puede utilizar para suavizar pequeñas arrugas, perfilar y armonizar el rostro, aumento de labios e incluso devolver volumen e hidratación a la piel. Los resultados son 100% naturales.",
            ]},
            
            {"text":"Por último, es importante resaltar que la cirugías de reasignación o cambio de sexo son un proceso complicado en el que se recomienda tener apoyo total de los familiares, sobre todo si implica tomar decisiones de posibles intervenciones quirúrgicas. ",},
            {"text":"Muchos cirujanos y médicos participan en el proceso de reasignación sexual y uno de ellos es el Dr. José Cortés, si estás en un proceso o deseas tener más conocimientos sobre el tema, síguelo en sus redes sociales."}
        ],
        "author":{
            "name":"Dr. José Cortés",
            "contacts":[
                {social:"Instagram",text:"", link:"https://instagram.com/dr.josecortes"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/cirujanoJoseCortes/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'retos-emprender-mujer-mexicana':{
        "image": lifestyle_40,
        "title": "Los retos de emprender como mujer mexicana",
        "pars":[
            {"text":[<iframe title="E23 Los retos de emprender como mujer mexicana" src="https://open.spotify.com/embed/episode/4xu3HpfJ1a0x5s5fWiFTBp" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>]},
            {"text":"La idea de emprender un negocio suele ser bastante atractiva.  Parece que emprender es una moda y los emprendedores tienen cierto aire de rockstars. En el ámbito empresarial, la iniciativa que tienen las personas para crear un negocio propio o empresa, trata de hacer algo que demanda dedicación, compromiso y que puede llegar a tener ciertas dificultades, sobre todo como mujer mexicana."},
            {"text":"Para comprender más sobre el emprendimiento y sus retos nos acompaña Karen Rodarte, Co-CEO & Co-founder de Pai Pai. Karen estudió diseño integral y fue ahí en la universidad que conoció a su ahora socia Andrea, juntas en 2013 fundaron Pai Pai, una marca Indie de cosméticos eco-friendly que enaltece las raíces mexicanas."},
            {"text":"Con ya 8 años de experiencia en el mundo del emprendedurismo, Karen nos cuenta que al iniciar un negocio propio uno nunca sabe a lo que se va a enfrentar. Existen muchos factores que definen el éxito o fracaso de un negocio, pero definitivamente la manera en la que uno empieza es clave.",},
            {"text":"Algunos consejos que nos compartió Karen para aquellos que están emprendiendo o pensando en hacerlo son:",
            "list":[
                "1.- Busca financiamiento: No basta con tener grandes ideas, necesitas tener capital para invertir o inversionistas que confíen en tu proyecto. El mejor financiamiento es el llamado friends & family pues tiene un costo/interés muy bajo e incluso en ocasiones nulo. ",
                "2.- Registrar la marca: El papeleo y los requisitos son tardados. No existe un manual que indique por dónde empezar entonces es una tarea de investigar y dedicarte tiempo para hacerlo y proteger tu marca.",
                "3.- Enfrentar las crisis: No todo es color de rosa , hay momentos críticos en cualquier empresa y es labor del emprendedor saber adaptarse, reinventarse y resurgir de sus cenizas para comenzar de nuevo y superar cualquier adversidad."
            ]},
            {"text":"Es importante que como emprendedor siempre busques la forma de  asesorarte y rodearte de gente experta que pueda ayudarte tanto en temas económicos como emocionales.",},
            {"text":"Emprender siempre cuesta tiempo, dinero y esfuerzo, es importante que sepas que la primera versión no siempre será la mejor, tienes que ser muy tolerante a la frustración, todo es una serie de esfuerzos y mejoras.",},
            {"text":"La clave para el éxito en la vida y en los negocios es vencer los pensamientos negativos, arriésgate e innova, permítete crecer, aprender cosas nuevas como: visualizar tu marca en 5 años, crear una planeación comercial y financiera y por último hacer lo que te apasiona.",},
            {"text":"Mantén tu vista siempre centrada en los objetivos, prepárate para los futuros obstáculos y permítete crecer de manera continua. No dejes que nada ni nadie  detenga tu crecimiento personal y laboral. Tu formación debe ser continua para lograr mejorar todas tus debilidades y hacerte ganar fortalezas."},
            {"text":"Cualquier emprendimiento será un camino duro pero gratificante. Si algunas vez has pensado en convertirte en tu propio jefe, ¿por qué no ahora?",},
            {"text":["Si deseas tener más motivaciones para tu emprendimiento puedes seguir a Karen en su Instagram personal: ", <a href="https://www.instagram.com/rodartekaren/ " rel="noreferrer noopener" className="texto-pink">@rodartekaren</a>, "." ],}
        ],
        "author":{
            "name":"Pai Pai",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/paipaimx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/paipaimx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'certeza-incertidumbre-parejas':{
        "image": lifestyle_41,
        "title": "Lo que necesitas saber de ti antes de empezar una nueva relación",
        "pars":[
            {"text":[<iframe title="E24 Certeza e incertidumbre en pareja" src="https://open.spotify.com/embed/episode/5ZaLf4KKiIhoDNzC6Q4ZEm" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>]},
            {"text":"Durante la pandemia surgieron, cambios que afectaron directamente nuestra vida diaria. La manera en que trabajamos; la forma en que nos comunicamos y convivimos con otras personas. También las relaciones de pareja tuvieron altibajos. Seguramente conociste o viviste de primera mano el final de una relación sentimental. Si ese es el caso, te invitamos a conocer a Karla Lara, mejor conocida como Karla Coach, para que nos hable sobre lo que debemos pensar antes de una nueva relación y qué podemos aprender de las relaciones que terminaron."},
            {"text":"Karla es especialista en inteligencia emocional, social y conducta humana. Tiene un Master y es coach en Programación Neurolingüística. Creó el podcast EXTRAordinario y es autora del libro Tus modelos del Amor. Ella nos invita a pensar qué es lo que consideramos importante más allá de los hobbies y lo económico: ¿Qué es lo que esperas del otro y qué te gustaría que esperen de ti? Incluso si es el momento correcto para tener una pareja."},
            {"text":"Debemos entender que hay momentos para todo y que es importante que reconozcamos en qué momento nos encontramos para poder vivir plenamente. Muchos pueden sentir ansiedad o culpa por no haber establecido o mantenido una relación por cierto tiempo, pero eso no es verdad. El tiempo en una relación no es una medida que valide lo que se vivió ni se relaciona con nuestra felicidad.",},
            {"text":"Desde que nacemos y conforme vamos creciendo parece que sólo al establecer una relación de pareja estaremos completos, pero la realidad es que la vida no es un camino trazado ni se experimenta de la misma forma en todos. Es por eso que muchas veces asociamos la soledad a un sufrimiento o incluso un castigo. Por el contrario, debemos reconocer que pasar tiempo solos es parte de la vida y que no hay nada insano en hacerlo.",},
            {"text":"Karla nos comparte un poco de su libro: no hay una sola forma de amar y de entender el amor. Nos propone entender que cada persona tiene una forma única de acercarse y amar al igual que los demás, no sólo desde una perspectiva de pareja. Es por eso que debemos entendernos para saber cuándo es el momento indicado para buscar pareja así como cuál es el tipo de relación que queremos establecer.",},
            {"text":"Aprender a amarnos es la base para poder realizarnos como parte de una pareja. La media naranja, el complemento perfecto y el final de cuento son sólo ideas prefabricadas que no deben guiar cómo vivimos el amor. Qué queremos, en qué momento nos encontramos y cómo darnos cuenta de las respuestas a esas preguntas tan importantes se analizan con Karla.",},
            {"text":"Si bien las expectativas internas y externas que vamos generando a lo largo de la vida nos pueden generar ansiedad, no debemos perder la perspectiva sobre lo que queremos y qué estamos viviendo. A los 20, 30, 40 o 50 años, cualquier edad es la ideal para conocer a una persona con quien compartir nuestra vida. Sólo nosotros sabremos determinar cuándo es el momento ideal y cómo será esa relación con otras personas. Nos habla también de no ver la vida como una lista de pendientes que vamos tachando o de una carrera contra los demás, sino de verla como un camino que vamos recorriendo a nuestro ritmo.",},
            {"text":"No dejes de escuchar el podcast de Karla para saber más sobre cómo afrontar estas preguntas y muchas más sobre nosotros y nuestras relaciones."},
            {"text":"Tampoco olvides seguir a Karla en sus redes sociales para saber más sobre este y otros temas.",},
        ],
        "author":{
            "name":"Karla",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/karlalaracoach"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/KarlaLaraCoach/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'herramientas-afrontar-adversidades':{
        "image": lifestyle_42,
        "title": "Mindset positivo, La mejor herramienta para afrontar las adversidades",
        "pars":[
            {"text":[<iframe title="E26 Mindset positivo: el arte de soñar y atraer" src="https://open.spotify.com/embed/episode/6Xsrltdikf8K2oo8ggAgMa" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>]},
            {"text":"Estar bien con nosotros mismos y tener empleo para afrontar las adversidades, es más complejo de lo que pensamos. Existen preconcepciones que mantenemos pese a los cambios generacionales y sociales generados continuamente durante años. Por eso, muchas veces nos sentimos insatisfechos, inseguros o ineficaces frente a los retos que nos presenta la vida. Si te sientes así, conoce a Wendy Abascal. Nos hablará sobre el mindset positivo, una herramienta más para la vida, porque la manera en que nos enfrentamos a las cosas y cómo dejamos que nos afecten es muy importante."},
            {"text":"Wendy es ejecutiva en Estrategias de Mercadotecnia y Negocios con más de 16 años de experiencia en la industria de la belleza. Ha trabajado para empresas líderes multinacionales en áreas como cuidado de la piel, maquillaje, fragancias, etc. Siempre fue una aficionada de la moda y la belleza y por eso ha participado como colaboradora editorial en la revista Socialité. Fundó  I Belong, una plataforma digital de health & wellness que promueve un cambio positivo en el mundo. Actualmente reside en New York con su familia."},
            {"text":"A partir de sus experiencias, Wendy nos habla de cómo surge I Belong para que las personas adopten y mantengan una mentalidad optimista frente a la vida diaria. Nos invita a pensar, primero, en cómo ha cambiado la percepción de belleza. Ahora nos preocupa más estar en equilibrio y definirnos únicos frente a los demás, porque nuestras imperfecciones son lo que nos da más valor. Ya que no hay una búsqueda por la belleza única y perfecta que antes se tenía como ideal.",},
            {"text":"Estudios científicos nos hablan de la relación del estrés con el desgaste físico. Algo que no sólo impacta en nuestro interior, como es obvio. Nuestra piel, nuestro rostro, nuestro semblante serán un reflejo del estrés que nos afecta por dentro. Por supuesto esto no quiere decir que la moda desaparecerá, sino que se adaptará a los cambios que se han dado y que continuarán. Un ejemplo claro es el cambio en la mentalidad sobre el impacto ambiental de las colecciones de ropa, algo que antes pasaba desapercibido.",},
            {"text":"Ahora bien, surge la pregunta: ¿Qué es un mindset positivo y cómo se adopta en nuestro día a día? Se trata de una práctica mental y de un ejercicio constante. No se busca que estemos felices todo el tiempo, sino ser optimistas frente a los problemas que se nos presentan. Es mejor buscar soluciones y tener calma frente a una crisis que perder el control y dejarnos llevar por nuestros impulsos.",},
            {"text":"Esto también va de la mano con la ley de la atracción que nos propone mentalizarnos sobre el resultado de un proceso o de una meta que queramos alcanzar. No se trata simplemente de decir quiero algo y lo obtendré sino que debemos estar mentalizados para el proceso ideal y cómo deberíamos reaccionar a él. Es decir, pensamos en un resultado y cada acción y reacción que hagamos deberá estar alineada con esa meta.",},
            {"text":"En I Belong se comparten estrategias, herramientas y consejos para modificar cómo nos enfrentamos a las situaciones de estrés. Por eso te invitamos a conocerlos y a seguir a Wendy en sus distintas redes sociales para conocer  más sobre estas herramientas para ti.",},
        ],
        "author":{
            "name":"I Belong",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/i.belong_/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/I-Belong-102111168638812"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'alimentacion-evitar-alimentos':{
        "image": lifestyle_43,
        "title": "Nuestra alimentación va más allá de evitar alimentos",
        "pars":[
            {"text":[<iframe title="E25: Las claves para transformar y crear nuevos hábitos" src="https://open.spotify.com/embed/episode/3Vj1B4atQ4MPa05jCr40P9" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>]},
            {"text":"Uno de los aspectos más afectados durante la pandemia fue la alimentación. Durante la cuarentena nuestros horarios, ritmos y costumbres cambiaron de forma abrupta, sin darnos tiempo suficiente para adaptarnos.  Como resultado, muchas personas aumentaron o perdieron peso; mientras que, otros han comentado que sus horarios de comida y apetito sufrieron alteraciones importantes."},
            {"text":"Grace Ibarra es licenciada en Nutrición, egresada de la Facultad de Salud Pública y Nutrición de la UANL.​ Fundó Hábitos Inteligentes, una consultoría nutricional que se especializa en la educación nutricional. Ha ayudado a cientos de pacientes a mejorar sus hábitos alimenticios para tener una mejor calidad de vida, además de trabajar en programas de salud en el ámbito empresarial."},
            {"text":"Empecemos por entender que la comida no sólo forma parte de nuestras funciones vitales, sino que se relaciona con todas las áreas de nuestra vida. Muchos estudios han demostrado que hay una relación entre lo que comemos con nuestro estado de ánimo. No por nada asociamos ciertos alimentos con la tristeza, la alegría o la euforia, pues lo que comemos y lo que omitimos nos afecta.",},
            {"text":"También debemos de ser conscientes de que cada cuerpo es único y diferente por lo que las dietas que se ponen de moda cada cierto tiempo no responden a un conocimiento verdadero sobre nosotros mismos. Cada cuerpo tendrá necesidades distintas y, además, cada persona tendrá una meta distinta: bajar de peso no es lo mismo que aumentar la musculatura. Todo cambio debe ir acompañado de un conocimiento nutricional sustentado.  Es por eso que Grace nos invita a entender que hay muchos factores alrededor de la comida:",
            "list":[
                "Economía personal",
                "Accesibilidad de ciertos alimentos",
                "Tipo de rutina",
                "Horarios de trabajo"
            ]},
            {"text":"Todos estos factores, pueden afectar de forma importante qué es lo que comemos y la cantidad de alimentos. No podemos negar que nuestra calidad de vida también determina qué comemos. Es por eso que enfermedades como la diabetes o la obesidad son tan difíciles de combatir.  No es lo mismo si tenemos tiempo para preparar comida en casa; si cerca de nosotros hay un mercado o una plaza comercial o si nuestro horario de trabajo y ritmo de vida nos permite elegir una variedad amplia de alimentos. Todos estos factores son importantes a la hora de analizar nuestra alimentación.",},
            {"text":"Por poner un ejemplo, ahora que muchos pasamos más tiempo en casa, sumado a la suspensión de actividades en espacios como gimnasios o centros deportivos, significó que nuestra actividad física disminuyó hasta casi el mínimo. Eso, claramente, afecta a la cantidad de calorías que consumimos y a la cantidad que podemos quemar a lo largo del día. Muchas veces no somos conscientes que caminar hacia el autobús, subir las escaleras para llegar a la oficina o caminar en un transbordo son actividades físicas igual de importantes que correr o realizar algún deporte.",},
            {"text":"Lo importante es reconocer que se quiere conseguir un cambio, ese  es el primer paso; después, buscar las herramientas que te ayuden no sólo a obtener un cambio momentáneo sino uno a largo plazo. Es por eso que debemos consultar a especialistas que no sólo se limiten a dar una respuesta sino una supervisión, guía y apoyo para conseguir lo que deseamos.",},
            {"text":"Con Grace aprenderás cuáles son las señales de alerta que te da tu alimentación o tus compras. Por ejemplo, la cantidad de alimentos procesados vs la verdura fresca, los dulces o pasteles vs las frutas que hay cerca de ti. Ten en mente que lo que es accesible para ti es lo que más consumimos a lo largo del día. Por mucho que queramos un cambio, si nosotros no retiramos de nuestra dieta lo que no queremos consumir, será imposible ver el resultado esperado.",},
            {"text":"Por eso, no dejes de seguir a Grace en todas sus redes sociales para que conozcas más sobre cómo mejorar tu alimentación y calidad de vida.",},
        ],
        "author":{
            "name":"Grace",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/habitosinteligentes/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/habitosinteligentes/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'dice-practica-yoga':{
        "image": lifestyle_44,
        "title": "Practicar yoga, esto es lo que dice de ti",
        "pars":[
            {"text":[<iframe title="E27: El secreto para ser pleno, feliz y abundante" src="https://open.spotify.com/embed/episode/2I3EeIQBJpaXuUXYymQXEt?utm_source=generator" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>]},
            {"text":"Practicar yoga es una de las actividades más socorridas cuando hablamos de relajación, actividad física de bajo impacto y de apoyo para la salud mental. Seguramente lo practicaste alguna vez o te interese saber más sobre el tema. Por eso que te contaremos sobre Daniela Amkie, una instructora que nos hablará qué es el yoga, cuáles son sus propiedades, cómo empezó a practicarlo, por qué se volvió su forma de vida y por qué lo recomienda."},
            {"text":"Aunque Daniela se graduó en Diseño Multimedia, era una apasionada de la creatividad y el emprendimiento; a los 27 años decidió dejar de lado esa vena creativa y centrar toda su energía y experiencia en enseñar y compartir su pasión: el yoga. Por supuesto, esta decisión no fue impulsiva, tuvo un origen muy interesante. Tan importante fue esa decisión que incluso llegó a tomar clases en la India. Es por eso que nos comparte su experiencia."},
            {"text":"Su encuentro con esta forma de vida se dio en la adolescencia. Después de sufrir altibajos emocionales y bullying en la escuela, su terapeuta le sugiere practicar yoga como una manera de canalizar energía y ayudarse frente a esos problemas que le aquejaban. Tras practicar de forma ininterrumpida a la par de sus estudios y otras actividades, decide dedicar su vida al aprendizaje y enseñanza para que otras personas que también lo necesiten puedan empezar.",},
            {"text":"Daniela nos invita a entender que el yoga puede ayudarnos a trabajar la relación entre mente y cuerpo. Porque, según ella, muchas veces luchamos sin darnos cuenta con problemas internos que vienen del pasado y que no hemos podido resolver. Dejando en evidencia que nuestro presente es resultado de nuestras experiencias. La práctica nos ayuda a conocernos, a entender cómo es que nos comportamos y cuáles son los mecanismos que nos impiden avanzar.",},
            {"text":"Llevándolo a la práctica, nosotros no nos adaptamos a las posturas, llamadas asanas, sino que las posturas se adaptan a nosotros. Además que aprendemos a vivir en el presente: dejando que las cosas que nos abruman pasen a segundo plano y nuestra consciencia sólo exista en el ahora.",},
            {"text":"Ella nos comparte cómo al principio las asanas más difíciles le generaban ansiedad y molestia por lo que las evitaba o dejaba de realizarlas. Después de hacer un análisis personal, llegó a la conclusión de que era un mecanismo que se hacía presente al practicar pero que podía ver en cualquier otro momento de su vida diaria. Ese conocimiento nos habla mucho de quiénes somos.",},
            {"text":"También, Daniela nos habla de su experiencia con los astros y qué es lo que obtiene de ese análisis. El ciclo de la luna, nos dice, se relaciona con la energía que podemos sentir y que nos impulsa o detiene en varios aspectos de nuestro día a día.",},
            {"text":"Otra de las cosas que nos invita a aceptar es fluir como un líquido que se adapta al recipiente en el que lo colocas, así nosotros debemos estar preparados para aceptar tanto las cosas buenas como las cosas malas. Lo importante siempre es responder con la mejor actitud frente a la adversidad.",},
            {"text":"Así como el yoga, también podemos aprender técnicas de respiración y de concentración, el llamado mindfulness que nos permiten conocernos más y tener las herramientas para vivir al máximo el presente. Estar alerta es vital para no perdernos tanto en el día a día como en el trabajo o la escuela.",},
            {"text":"La historia de Daniela, así como su experiencia nos invita a practicar esta valiosa disciplina así como a hacer una análisis que nos hable de nosotros mismos. Si quieres saber más puedes seguirla en todas sus redes sociales.",},
        ],
        "author":{
            "name":"Daniela",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/danielaamkie/?hl=es"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'dice-imagen-escritura':{
        "image": lifestyle_45,
        "title": "Qué dice de ti tu imagen personal y escritura",
        "pars":[
            {"text":[<iframe title="E28: El arte de la persuasión" src="https://open.spotify.com/embed/episode/6WQQKCG1qdXFl9lLE3DDVY?utm_source=generator" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>]},
            {"text":"¿Te has preocupado por tu imagen personal? Seguro, sientes que a veces no transmites lo que esperas o que los demás, ante la primera impresión, no forjan una idea correcta de ti. Para combatir esas dudas y la ansiedad que conlleva, Giovanna Massad Soda ha creado un sistema para ayudar a las personas con su imagen pública."},
            {"text":"Giovanna es licenciada en Imagología, grafóloga y especialista en lenguaje no verbal. Su novedoso sistema le ha dado mucho prestigio, pues ha combinado sus tres especialidades para ayudar tanto a prestigiosas marcas como a personas que solicitan su ayuda."},
            {"text":"Sus asesorías buscan ayudar a las personas a construir su imagen personal: ya no se busca cumplir una etiqueta rígida, sino que ahora existe la libertad de elegir lo que nos es cómodo y se adapta al contexto en el que nos desenvolvemos. Un ejemplo claro es si la empresa en donde trabajamos busca dar una impresión de ambiente amigable, fresco y cercano: probablemente un traje sastre negro no será la elección apropiada; mientras que ese mismo traje será apropiado en un despacho legal que busca mostrar una gran formalidad y profesionalismo.",},
            {"text":"Esa flexibilidad actual permite a Giovanna asesorar a partir del entendimiento. Qué quieren las personas que sea visto, cómo quieren ser percibidos, cuál es su personalidad son algunas de las preguntas que pueden esperar quienes busquen recibir este tipo de  asesorías.",},
            {"text":"Ella empieza por preguntar cuál es la meta que tienen sus clientes: quieren dar a entender algo, comunicar alguna sensación y a partir de eso analiza si el lenguaje no verbal que forma: el tono, los gestos y la postura son los adecuados. No es lo mismo dar una conferencia de motivación que una clase magistral. El ritmo, la energía y las estrategias cambiarán dependiendo del lugar y el público.",},
            {"text":"Algo similar sucede con la grafología: es una herramienta que permite conocer rasgos de la personalidad: nerviosismo, ansiedad, miedo; todo se refleja en nuestra manera de escribir y eso ayuda a Giovanna a saber qué es lo que necesitan las personas que la consultan. La pandemia, dice, puso en evidencia que las personas estaban pasando por muchas dificultades y eso se podía ver en la escritura. La intensidad, la inclinación o el temblor que se refleja al escribir nos pueden decir que hay algo que se debe tratar.",},
            {"text":"No es que Giovanna pueda ver a través de las personas, sino que se ha entrenado para poner atención a los detalles: la vestimenta, los gestos, la mirada, la sonrisa, etc. Esa es la meta de sus conocimientos: permitir y facilitar la comunicación.",},
            {"text":"Ella aconseja que pongamos atención a las personas que nos rodean: cómo se expresan, las palabras que eligen y cómo dan a entenderse. Así empezamos a entender que hay más cosas detrás de lo que decimos y podremos pensar en cómo debemos acercarnos a los demás y en qué ven los demás en nosotros.",},
            {"text":"Si te interesa conocer más sobre el trabajo de Giovanna o realizar una cita. Te compartimos sus redes sociales:",},
        ],
        "author":{
            "name":"Giovanna",
            "contacts":[
                {social:"Facebook",text:"", link:"https://www.facebook.com/people/Giovanna-Massad-Soda/100057615422426/"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/giovannamassadsoda/?hl=es"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'entender-las-tendencias':{
        "image": lifestyle_46,
        "title": "¿Qué hay detrás de las tendencias?",
        "pars":[
            {"text":[<iframe title="Tendencias con Pei Garza" src="https://open.spotify.com/embed/episode/4fBslx5IDfDn5KbJIkEmky?utm_source=generator" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>]},
            {"text":"Lo que ahora es tendencia y el cómo llegó a serlo, es un tema de actualidad. Las dinámicas de interacción y acceso a la información cambian y evolucionan constantemente. Para saber ¿qué hay detrás de las tendencias? Es importante poner atención a la opinión de Pei Garza. Un referente muy importante para todas las personas que lo escuchan en Radio Fórmula. Pei es un comunicador recientemente galardonado con el micrófono de oro por la Asociación Nacional de Locutores de México y ganador de los Q awards por la revista Q por su gran importancia en el mundo del entretenimiento."},
            {"text":"Pei Garza cuenta con más de 8 años de experiencia como comunicador, pero él empezó su carrera en el mundo de las relaciones públicas llevando marcas de alto prestigio, aunque descubrió su verdadera vocación cuando trabajó en L'Oréal debido a que tuvo la oportunidad de convertirse en presentador de televisión . A lo largo de los años, Pei se ha convertido en un experto en tendencias y redes.  Él ha vivido de primera mano los cambios en los canales de comunicación, actualmente permanece en un medio clásico como lo es la radio pero también está presente muy fuertemente  en las redes sociales que ahora forman parte importante de nuestras vidas."},
            {"text":"Ahora lo conocemos como  locutor, conductor de televisión, conferencista e influencer. Sobre esta última faceta, él comparte su perspectiva; ya que su proyecto actualmente se apoya en las redes sociales.",},
            {"text":"Este mundo virtual nos permite conocer a personas talentosas y muy interesantes, las cuales antes podían pasar desapercibidas. La radio, la televisión eran espacios de comunicación cerrados a unos cuantos, mientras que las redes permiten una interacción más cercana y directa.",},
            {"text":"Claro, él es consciente de que debe haber un equilibrio: las redes no deben buscar simplemente éxito tras éxito sin importar la sustancia que hay detrás, sino que debe haber un sentido y un valor en  la comunicación que se desarrolla con el público. El rechazo se da de manera inmediata, porque la oferta de entretenimiento e información aumenta diariamente. Entender y conocer al público es algo que se construye diariamente.",},
            {"text":"Sobre esto último, comenta que la gente quiere saber de todo y de la manera más rápida posible. Ya no hay formatos tan delimitados como los que había en la televisión y es por eso que los creadores de contenido deben entender dónde están y qué es lo que las personas esperan. Su trabajo no es fácil y por eso disfruta explicar qué es lo que hace y cómo su equipo lo apoya muchísimo.",},
            {"text":"Estas ideas permiten crear un contenido de valor como el que hace Pei; mientras que otros comunicadores solo buscan generar escándalos y ser virales. Pei habla de los peligros y de los engaños en que podemos caer por buscar la viralidad y el contenido que impacte: ahora se corre más peligro que nunca si se decide tomar el camino más fácil.",},
            {"text":"Por supuesto, también es consciente de los peligros que hay al comunicarse a través de redes sociales.  Piensa en los jóvenes que no entienden que las redes no son más que una ventana hacia la vida de las personas y no la vida en sí misma. Por ello siempre busca no caer en conversaciones que generan morbo y conflictos e invita a consultar y crear contenidos que tengan un verdadero valor detrás.",},
            {"text":"Así es como nace: Tendencias con Pei,  un espacio en donde todo el contenido está generado por un maravilloso grupo de expertos en el tema y en donde la gente tiene la confianza de saber que encontrará algo valioso. Ya que aunque parezca fácil, hay una gran cantidad de trabajo detrás que  nunca disminuye incluso con la experiencia de Pei y aceptación del público. El éxito de Pei no llegó de la nada: él lo busco. Por eso este proyecto de Tendencias no hace otra cosa que crecer y mejorar.",},
            {"text":"Invita, además, a las personas y empresas a aprovechar este cambio en la comunicación: quien no está en la redes, no se dará a conocer en el mundo. ",},
            {"text":"Te invitamos a conocer las tendencias del momento con Pei Garza, síguelo en sus redes sociales:",},
        ],
        "author":{
            "name":"Pei Garza",
            "contacts":[
                {social:"Facebook",text:"", link:"https://www.facebook.com/PeiGarza/"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/peigarza/?hl=es"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'momento-cambio-salud':{
        "image": lifestyle_47,
        "title": "El momento ideal para hacer un cambio en nuestra salud",
        "pars":[
            {"text":[<iframe title="E30: El reto de envejecer en plenitud" src="https://open.spotify.com/embed/episode/6KMW9mBtsm6au3aEkkNL8Y?utm_source=generator" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>]},
            {"text":"¿Quieres hacer un cambio en tu estilo de vida? Ya sea que quieras cambiar tu alimentación, realizar alguna actividad física o probar un deporte que no es popular o que carga con cierto estigma. Entonces necesitas conocer a Martha Castañeda, mejor conocida como “La nonna fit”. Una mujer que demuestra que siempre es posible empezar a tener una vida más saludable."},
            {"text":"Mujer, madre, abuela y administradora de un consorcio de 7 empresas, es un ejemplo de cómo se puede cambiar un estilo de vida poco saludable, similar al que muchos de nosotros podríamos tener, a dejar de fumar y correr siete maratones. Pese a todo, ella cuenta que nunca fue una persona sedentaria, ya que su ritmo de vida y su trabajo la obligaban a usar transporte público y caminar un tiempo suficiente; pero nunca se planteó un estilo de vida como deportista de alto rendimiento."},
            {"text":"El cambio vino con una triste noticia familiar. La enfermedad grave de un familiar fue el motor que le permitió empezar con este gran cambio: dejar de fumar por completo. Pero no se detuvo ahí ya que sin importar su edad, ella quiso que ese cambio en su vida fuera aún más grande.",},
            {"text":"La nonna fit empezó sin una preparación rigurosa: simplemente, un día decidió salir a correr en el parque más cercano a su casa y ponerse como meta competir en un medio maratón. Si bien reconoce que no es el inicio más recomendable, piensa que todos debemos empezar por poner una meta que no sea ambigua. Tener una meta y completarla dará pie a que te pongas más retos y siempre te impulses a seguir adelante.",},
            {"text":"Tres meses después de completar el medio maratón, decide completar un maratón y confiesa que, al terminarlo, estuvo a punto de dejar de ejercitarse por el cansancio y el desgaste que sintió. Algo que quizá muchos hemos sentido al hacer ejercicio. Su familia la felicitó por el logro, pero sintió que estaba en el límite de su capacidad. Y aunque la primera experiencia fue dura y complicada, al final, decidió continuar. Mejoró su equipo y aumentó el ritmo de sus entrenamientos.",},
            {"text":"Ese bajón que sintió después del maratón la llevó a unirse a un grupo de personas que también se ejercitaban para que la comunidad la alentara y también le permitiera mejorar. Piensa que el apoyo de otros es vital para seguir adelante. Otra cosa destacable es que para complementar su entrenamiento, Martha decide empezar con pole fitness pues sentía que sus brazos no tenían la misma fuerza que sus piernas.",},
            {"text":"El pole fitness es un deporte que tiene un gran estigma detrás y, al mismo tiempo, su práctica exige una gran fortaleza en los brazos; por eso, también necesitó poner a prueba su resistencia mental, ya que personas cercanas a ella criticaban y desaprobaban que Martha practicara este deporte. Sin embargo, ella se mantuvo firme y siguió practicando. Lo importante es que elijamos lo que nos motiva y nos hace felices.",},
            {"text":"A pesar de que la pandemia le permitió practicar con más regularidad, esta enfermedad vino con una complicada experiencia: hace unos meses, ella se contagió de covid-19. Pese a que su vida saludable le dio fortaleza, tuvo que pasar por la negación de su enfermedad y posteriormente  la aceptación de los efectos del virus en su cuerpo.",},
            {"text":"Sin embargo, la fuerza mental que construyó junto a la fuerza física le permitieron salir de la enfermedad y sobrellevar las secuelas. Ella rechazó la frustración y reinició su entrenamiento. Pequeños cambios tuvo que realizar en la rutina y en la intensidad de la misma, pero eso no le impidió recuperarse y seguir adelante. Porque como dice, lo más importante es empezar a nuestro ritmo.",},
            {"text":"Definitivamente, la historia de Martha nos invita a pensar que nunca es tarde para lograr un cambio en nuestra vida y que lo que vamos construyendo nos prepara para enfrentar los retos del futuro. Siempre puedes encontrar un buen momento para empezar con un cambio significativo en tu vida: dejar de fumar, mejorar tu condición física, iniciar alguna actividad deportiva, sólo necesitan de ti. Porque, como dice Martha: si ella a sus 62 años pudo, tú puedes.",},
            {"text":" Si estás interesada en saber más sobre Martha te invitamos a seguirla en sus redes sociales",},
        ],
        "author":{
            "name":"Martha",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/lanonnafit/?hl=es"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'lazos-formamos-nuestros-perros':{
        "image": lifestyle_48,
        "title": "Aprende cómo fortalecer el vínculo con tu mascota",
        "pars":[
            {"text":[<iframe title="E31: Un mundo pet friendly" src="https://open.spotify.com/embed/episode/0eJZcTUbG9FpqFDhmGfB9a?utm_source=generator" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>]},
            {"text":"Las personas constantemente buscamos entender a nuestras mascotas. No solo queremos convivir con ellos, cuidarlos y compartir nuestras vidas, también tenemos la sensibilidad para entender que tienen necesidades distintas y que deben ser atendidas. Para reforzar el vínculo con tu mascota y descubrir si lo realizas correctamente; conocer el trabajo de José Pablo Zavala te ayudará mucho."},
            {"text":"José Pablo es un entrenador canino con más de 10 años impulsando su proyecto Mumbii Training. En él, busca ofrecer opciones y mejoras para las relaciones de los dueños con sus mascotas. Mumbii se divide en varias áreas y cuenta con hospital, resort, tienda de accesorios y una academia en donde se imparten cursos de adiestramiento."},
            {"text":"José Pablo nos invita a pensar en nuestras mascotas: él, como muchos, tuvo una mascota de niño; sin embargo, siempre recuerda la frustración de no conseguir el comportamiento que esperaba, y así fue como nació su interés por el entrenamiento canino.",},
            {"text":"José Pablo decidió renunciar a su trabajo como oficinista y empezó su negocio con algo aparentemente sencillo: pasear perros. Sin embargo, lo que empezó como un paseo para ejercitarlos terminó siendo el cuidado, atención y entendimiento que las mascotas necesitaban. A partir de ese momento, las cosas se dieron naturalmente. De pasear y atender perros, José Pablo empezó a entrenarlos. Pues los dueños de los perros que cuidaba le comenzaron a pedir consejos y posteriormente a solicitarle el entrenamiento de sus mascotas.",},
            {"text":"José trabaja la idea de generar vínculos y no apego. Porque el apego genera conflictos, ya que los humanos volcamos los problemas y actitudes que tenemos en nuestras mascotas; mientras que el vínculo les permite ser animales plenos, es decir, completamente independientes.",},
            {"text":"Con esa idea, decide estudiar comportamiento canino para entrar de lleno al mundo de la atención y cuidado de los perros. Eso le permitió ser asesor de los dueños y entrenador de las mascotas; así como relacionarse con marcas especializadas en el cuidado canino. Se dio cuenta de que había un cambio en la industria y en el pensamiento que rodeaba a la vida de las mascotas, por eso, decidió que quería formar parte de ese cambio.",},
            {"text":"Poco a poco, las necesidades de los caninos le iban diciendo hacia dónde debía dirigir sus servicios: de entrenar, pasó a corregir conductas y, de cuidar a cerca de 45 perros en su departamento, terminó creando un hotel canino, porque descubrió y explicó a los dueños que no todos los perros pueden viajar o pasar temporadas solos.",},
            {"text":"Hay que entender que los animales se guían por instintos. No debemos olvidar que los animales tienden a responder a sus impulsos: morder compulsivamente, ladrar sin parar, correr al menor estímulo son respuestas a la falta de atención y aburrimiento.",},
            {"text":"Entender lo que sienten nuestras mascotas y ayudarlos a resolver las conductas que generan conflictos es la perspectiva que José nos invita a tener. Finalmente, nuestra vida con una mascota debe responder al amor y a la sana convivencia con ellos.",},
            {"text":"Para él, un mundo pet friendly no solo es dónde existen los espacios definidos para las mascotas donde puedan crecer, vivir y desarrollarse sanamente, sino donde las personas establecen un vínculo en que los dueños y los animales domésticos se desarrollan plenamente y viven felices.",},
            {"text":"Si quieres conocer más del trabajo de José Pablo te invitamos a seguirlo en las redes sociales de Mumbii Resort.",},
        ],
        "author":{
            "name":"Mumbii Resort",
            "contacts":[
                {social:"Facebook",text:"", link:"https://www.facebook.com/mumbiiresort/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'ozonoterapia-opcion-salud':{
        "image": lifestyle_49,
        "title": "Ozonoterapia: una opción más para la salud",
        "pars":[
            {"text":[<iframe title="E32: En la práctica médica: El paciente siempre es primero" src="https://open.spotify.com/embed/episode/2o7WwqXzuapUqr5aesZftp?utm_source=generator" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>]},
            {"text":"Probablemente, cuando escuchas hablar de ozono pienses en problemas ambientales y lo relacionas con sustancias peligrosas; sin embargo, la realidad es que el ozono es un compuesto que tiene muchas aplicaciones como desinfectante y purificador. Uno de los usos que podemos darle es la ozonoterapia. Si quieres conocer cómo se descubrió y cuáles son sus propiedades, te invitamos a leer."},
            {"text":"Utilizar el ozono como tratamiento surge en la Segunda Guerra Mundial. Se descubrió años antes que si una herida era introducida en una cubeta de agua con ozono se aceleraba la cicatrización y se reducían infecciones. Desde esos primeros experimentos hasta hoy, surgieron diversas aplicaciones y tratamientos."},
            {"text":"Es por eso, que la Dra. María Lozada de Clinique D’Ozono se preocupa por dar a conocer la ozonoterapia, cómo se realiza el tratamiento, para quién está indicado y, sobre todo, cuáles son las mejoras que pueden obtener los pacientes si deciden someterse al tratamiento. Ella es egresada de la UNAM y tiene un Master en Ozonoterapia por la Asociación Española de Profesionales Médicos en Ozonoterapia.",},
            {"text":"En Clinique D’Ozono los especialistas tienen una meta muy importante: llevar a cabo un cuidado integral de los pacientes, ya que no solo se busca diagnosticar y tratar una enfermedad con medicamento, sino que hay una búsqueda por mejorar la calidad de vida. Los tratamientos no están aislados de la alimentación o del estado de ánimo, como algunos pacientes pueden llegar a pensar. Es por eso que esa preocupación tan importante está presente en Clinique D’Ozono y en el trato de la Dra. María Lozada.",},
            {"text":"Hay que aclarar que la ozonoterapia no consiste en introducir directamente en el cuerpo grandes cantidades del compuesto, sino que se combina con un 95 o 97% de oxígeno médico; el restante 5 o 3% de ozono permiten que se desarrollen diversas reacciones a nivel molecular en el cuerpo. Principalmente se busca que haya un fortalecimiento del sistema inmune, aunque se pueden esperar otros resultados dependiendo de las necesidades del paciente.",},
            {"text":"Esto debe dejar claro que la ozonoterapia es un tratamiento tanto coadyuvante como preventivo, es decir, que va de la mano con otros tratamientos, por lo que si decide utilizarlo debe estar sustentado por especialistas que conozcan todas las implicaciones y protocolos de seguridad que requiere su aplicación.",},
            {"text":"El protocolo para saber si una persona puede recibir ozonoterapia empieza con un test y estudios clínicos que permitan conocer cómo está el paciente; por supuesto, se puede iniciar con un análisis personal que parte de las preguntas: ¿Estás cansado la mayor parte del tiempo?, ¿Notas algo extraño en tu piel? ¿Has sentido malestar en la boca o en los dientes? ¿Cuánto cansancio sientes? Estas preguntas nos pueden hablar del estrés oxidativo que se tiene en el cuerpo; algo que la terapia con ozono puede atender.",},
            {"text":"Si te interesa conocer más sobre este tratamiento, la oferta de Clinique D’Ozono y el trabajo de la Dra. María Lozada, te invitamos a seguirlas en sus redes sociales.",},
        ],
        "author":{
            "name":"Clinique D'Ozono y Dra. María Lozada",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/dra.marialozada/"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/clinicasdeozono"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/Cliniqueozono"},
            ],
            "links":[
            ]
        },
        "refs":[
        ]        
    },
    'arte-alcance-todos':{
        "image": lifestyle_50,
        "title": "Arte al alcance de todos",
        "pars":[
            {"text":[<iframe title="E33: El arte es el corazón de la expresión" src="https://open.spotify.com/embed/episode/13Pd5KSzzKObLrNsigOpNM?utm_source=generator" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>]},
            {"text":"Normalmente, pensamos en el arte como algo poco accesible, que pocos pueden hacer o tener. Hoy en día, gracias a la tecnología, internet y redes sociales, el arte logró democratizarse tocando la vida de muchas más personas. El arte al alcance de todos, gracias a que los artistas son cada vez más cercanos a sus audiencias; pues sus historias y obras se difunden con mayor facilidad."},
            {"text":"Este es el caso de Cecilia Maafs, ilustradora mexicana que ha trabajado con marcas nacionales e internacionales haciendo lo que más ama: Crear. Ella decidió dedicarse a aquello que le llena emocional y profesionalmente. De estar en un trabajo de oficina, papeles y escritorio pasó a un trabajo en donde  crear , soñar e inspirar son la clave."},
            {"text":"Ceci comenta que desde niña sintió el deseo de expresarse: siempre pintó y dibujó, pero nunca se atrevió a estudiar artes. Después de terminar su carrera universitaria, comenzó a trabajar en una empresa en el área de marketing, dejando un poco olvidado su lado artístico; sin embargo, en esa empresa tuvo contacto con ilustradores y artistas que le demostraron que era posible vivir de sus creaciones y creatividad.",},
            {"text":"Puso una fecha límite para dejar su trabajo y arriesgarse a dar un salto de fe para seguir su verdadera pasión. Aunque al principio titubeó por las inseguridades que ese cambio le generaba, decidió arriesgarse completamente esperando lo mejor. Mediante el ejercicio de la meditación, ella obtuvo la seguridad interna para enfrentar sus miedos y empezar de lleno con su trabajo soñado.",},
            {"text":"Al contrario de lo que pueden imaginar muchas personas, Ceci pasó por un proceso de rompimiento con su vida anterior. Se despidió de la estructura corporativa para adoptar un estilo de vida más libre y espontáneo . Sin embargo, debió crearse una rutina con cierta estructura, pues en el fondo las responsabilidades de un trabajo siguen y seguirán siendo las mismas: se tienen clientes y proyectos que deben completarse. El éxito lo determinan las horas de trabajo , la pasión dedicada a cada obra de arte, la resiliencia y la disciplina. Ceci nos dice que “Ser artista no es más fácil ni más difícil, es un trabajo como el de cualquiera pero lleno de colores”",},
            {"text":"Desde hace algunos años, las marcas han buscado acercar sus productos al público y personalizarlos mediante el trabajo de artistas y diseñadores que puedan comunicar una imagen, una idea o una emoción con la que sus clientes puedan identificarse. Esto, además de dar un valor agregado a sus productos, permite conectar de forma más cercana. Fue así que la marca Johnnie Walker se acercó a Cecilia para diseñar una familia de botellas edición limitada. La idea era conectar con ese lado sensible de los consumidores. Ceci piensa que ese ejercicio de marketing le ha permitido llegar a más personas.",},
            {"text":"Ella habla del gran logro que es vivir de su arte, porque más que pensar en llegar a un lugar o momento, su gran victoria es poder hacerlo todos los días, crecer y mejorar. Por eso, nos dice que nunca se ha sentido cansada o agotada de su trabajo como artista, pues siempre está motivada y con energía.",},
            {"text":"Para ella, todos hacemos arte en el momento en el que usamos nuestra creatividad e ingenio. Así que la pregunta es ¿De qué manera expresas lo que sientes, lo que piensas, cómo enfocas tu originalidad y tu esencia?  Todos y cada uno de nosotros tenemos algo que compartir con el mundo.",},
            {"text":"Si quieres conocer más sobre el trabajo de Ceci y encontrar inspiración, puedes seguirla en sus redes sociales.",},
        ],
        "author":{
            "name":"Cecilia Maafs",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/cecilia.maafs"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/cecilia.maafs "},
               ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'cuidar-agua-bebemos':{
        "image": lifestyle_51,
      "title": "Los secretos del agua que bebemos",
        "pars":[
            {"text":[<iframe title="E34: Los secretos del agua" src="https://open.spotify.com/embed/episode/4C9QktQFLb4bPYtSUP1vzS?utm_source=generator" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>]},
            {"text":"Beber 2 litros u ocho vasos de agua diariamente es un consejo que todos escuchamos desde pequeños. ¿Esto es igual para todos? ¿Qué proceso de purificación tiene el agua que bebemos? ¿Qué es el agua alcalina y cuáles son los beneficios que aporta a la salud? Descubre por qué, Héctor Cruzado, cofundador de Zoé Water, ha trabajado en cuerpo y alma para el exitoso desarrollo de este proyecto."},
            {"text":"Zoé Water surge del trabajo de tres personas interesadas en ofrecer un producto nuevo que aportara algo de valor a la industria del agua embotellada. Después de ver, de primera mano, la enfermedad de un familiar, Héctor se interesó por los beneficios que trae al cuerpo humano el consumir agua alcalina. Después se suman dos datos contrastantes: México es el mayor consumidor de agua embotellada y, sin embargo, está en el primer lugar de enfermedades cardiovasculares relacionadas a una mala alimentación e hidratación, por lo que había una oportunidad para crear un producto de alta calidad."},
            {"text":"En el caso de Zoé Water la producción inicia en la ultra purificación del agua para asegurarse de que la cantidad de minerales sea la correcta y se mantenga al mínimo; después se trabaja para que el pH sea de 8.5, de ahí su alcalinidad, que busca neutralizar la acidez del cuerpo.",},
            {"text":"A partir de esa base, dentro de Zoé Water,  se han creado otras líneas de producto como Zoé Sport que repone los electrolitos perdidos durante el ejercicio para evitar la deshidratación y Zoé Water Flavors que contiene un ligero y delicioso toque de sabor sin convertir al agua en un producto azucarado. ",},
            {"text":"Héctor nos comentó: “El ritmo de vida y los gustos de cada persona son súper distintos. Es por ello, que nosotros como empresa debemos ampliar nuestro portafolio de productos para adaptarnos a las necesidades de nuestros consumidores”",},
            {"text":"Por supuesto que este cuidado por la calidad del producto no sería sostenible si hubiera una despreocupación por el impacto ambiental. Algunas de las estrategias de Zoé Water es utilizar  botellas que contienen un 50% de materiales reciclados, impulsar proyectos de captación de agua y envases con mayor contenido para poder contar con botellas reutilizables. Además de buscar que el agua que se compre ayude a pequeños productores.",},
            {"text":"La historia de Zoé Water nos habla de la preocupación por dar un producto de la mejor calidad, con los mayores beneficios y cuidando al máximo el medio ambiente. Si no conoces los productos de Zoé Water, te invitamos a probarlos.",},
            {"text":"Sigue a Héctor Cruzado en sus redes sociales para conocer más sobre su trabajo y la experiencia que comparte con otros emprendedores. Además, te invitamos a seguir también a Zoé Water en redes sociales:",},
        ],
        "author":{
            "name":"Héctor Cruzado y Zoé Water",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/chavoruqo"},
                {social:"Twitter",text:"", link:"https://www.twitter.com/chavoruqo"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/zoewater"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'barreras-crecer-profesionalmente':{
        "image": lifestyle_52,
        "title": "¿Qué barreras nos impiden crecer profesionalmente?",
        "pars":[
            {"text":[<iframe title="E35: Los must para tu crecimiento laboral" src="https://open.spotify.com/embed/episode/7hybeHegfaCsdAFUtRH2kg?utm_source=generator" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>]},
            {"text":"Actualmente, para muchas personas, la palabra “coaching” suele tener una connotación negativa. Principalmente, porque desde hace algunos años, distintas personas utilizan el nombre, sin ninguna preparación. Prometen cambios increíbles en tu vida y tu persona. El trabajo de coaching es complejo y requiere de una preparación especial, además de una práctica supervisada. Por ello, Marta Ro, mindset coach, explica cómo llegó a ser una especialista en el tema y cómo es que trabaja con sus clientes para lograr su crecimiento profesional. Marta nos visitó en Betterself para desmitificar un poco este concepto."},
            {"text":"Existen varios tipos de coaching, cada uno está diseñado y sustentado en diferentes teorías que buscan el crecimiento de las personas. Marta, empezó como coach transformacional, continúo como coach ontológico y terminó enfocándose en mindset coach, aunque día con día sigue estudiando y capacitándose en diferentes vertientes de esta misma disciplina."},
            {"text":"El contacto con el coaching transformacional, que busca enfocar a las personas en sus objetivos y metas, le cambió tanto la vida que empezó a ser voluntaria y a participar de forma más activa en el mismo lugar en donde posteriormente comenzó su formación.",},
            {"text":"Parte del aprendizaje que ofrece Marta se basa en ayudar a romper con ideas arraigadas y con ello modificar las conductas y pensamientos que traemos ya programados desde nuestra niñez. Hay varias formas de encarar y de resolver los problemas que se nos presentan. Ella prefiere ser directa para así dar herramientas sólidas a sus clientes. Propone que veas la realidad y la posibilidad al mismo tiempo: cuando eres consciente de lo que pasa y de lo que podrías hacer para cambiar lo que no te gusta o te tiene insatisfecho, es cuando empiezas a crecer. Tus elecciones diarias son lo que realmente puedes controlar.",},
            {"text":"¿Por qué es importante la labor de Marta? Ella explica que el coaching va de la mano con la situación laboral del país. La pandemia significó la pérdida de muchos empleos, pero eso no significa que estemos sujetos a lo que nos sucede. Marta habla del mindset como la manera en que nos enfrentamos a los problemas: la ilusión de control sobre cosas que están a nuestro alrededor nos bloquean de lo que podemos hacer. Ya que vivimos intentando que cambien las cosas que no están en nuestras manos.",},
            {"text":"Claro que esto no niega una realidad: muchos empleos terminaron y eso tendrá efectos en la economía, la salud, el bienestar, etc. Lo que hace Marta es dar herramientas para enfrentar las cosas y evitar que nos bloqueemos por el miedo o la incertidumbre. El mundo cambió y por eso debemos adaptarnos en lugar de aferrarnos a otro momento en el que teníamos una situación diferente.",},
            {"text":"Lo más importante no sólo es salir del momento de crisis, sino aprender cuáles fueron los errores que cometimos. Pero para lograr eso, nuestra mente debe estar preparada para analizar, aceptar y encarar los problemas que acarreamos.",},
            {"text":"Puedes seguir a Marta Ro en sus redes sociales para conocer más sobre su trabajo.",},
        ],
        "author":{
            "name":"Marta Ro",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/martarocoach"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/martarocoach"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'reconstruccion-mamaria-fisica-emocional':{
        "image": lifestyle_53,
        "title": "Reconstrucción mamaria, una cuestión emocional y física",
        "pars":[
            {"text":[<iframe title="E36: Reconstrucción post cáncer de mama" src="https://open.spotify.com/embed/episode/3Q2S4ddJ6zsHVsVynZHI2P?utm_source=generator" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>]},
            {"text":"En México, una de cada diez mujeres padecerá cáncer de mama a lo largo de su vida. Si bien llevar una vida saludable es importante, la autoexploración y las visitas al médico al detectar cualquier anormalidad pueden llevar a la detección de un problema en etapa inicial. Pero si se detecta la enfermedad, ¿qué opciones hay? Para hablar de eso, el Dr. Gustavo Jiménez Muñoz, cirujano plástico, presidente de la fundación BRN (Breast Reconstruction Network), hablará de la cirugía de reconstrucción mamaria post cáncer de mama."},
            {"text":"Gustavo es lo que podríamos definir como un joven prodigio, ya que terminó el bachillerato a los 15 años y empezó la carrera de medicina a los 16 años. Siempre se interesó por la anatomía y la cirugía; por eso, al terminar la carrera se especializó en cirugía general; continuó en cirugía plástica estética y reconstructiva y terminó su aprendizaje en Estados Unidos y Canadá al centrarse en la reconstrucción de mamas. Actualmente trabaja en la clínica PHI Aesthetics."},
            {"text":"Comparte que su preocupación por la naturalidad de una cirugía estética en el cuerpo de la mujer tiene su origen en lo que pacientes pueden llegar a experimentar tras conocer el diagnóstico de cáncer. El ambiente familiar, el miedo al resultado y la propia identificación con el cuerpo son factores que pueden impactar psicológicamente a una mujer. No sólo es cómo se ven sino cómo se sienten al verse a sí mismas. Puede haber rechazo externa e internamente; además del efecto que implica saber un diagnóstico de cáncer.",},
            {"text":"Normalmente, el tratamiento en que se piensa al hablar de cáncer de mama es la mastectomía, es decir, la extirpación parcial o completa del seno, pero no es la única opción. Es por eso que el Dr. Gustavo invita a conocer otra opción: en países de primer mundo, tras detectarse la enfermedad, las pacientes son candidatas a una reconstrucción inmediata, la cual se hará con diferentes opciones, dependiendo de la persona, aprovechando que la enfermedad será curada con mayor facilidad. Además se evitará una segunda intervención.",},
            {"text":"Antes de cualquier intervención se debe realizar una exploración física completa y estudios que le permitan al médico detectar cualquier anormalidad. Debemos entender que no todos los casos son iguales, por lo que los especialistas deben valorar las mejores opciones. Sin embargo, la opción que considera el Dr. Gustavo  toma en cuenta la posibilidad de disminuir el impacto psicológico de una intervención al darle la tranquilidad a la paciente de recibir su reconstrucción en la misma operación.",},
            {"text":"Lamentablemente, en México, menos del 5% de las pacientes reciben una reconstrucción inmediata. Aún así, hay opciones para todas las mujeres que lo requieran o que hayan pasado por una mastectomía y quieran realizarse una reconstrucción. He ahí la importancia de la Fundación BRN, de la que el Dr. Gustavo es presidente, ya que se dedica a la reconstrucción altruista, con el fin de ofrecer este tipo de intervenciones a personas que podrían no tener la posibilidad de pagarlo.",},
            {"text":"Las ventajas que presenta este tipo de cirugía es que se disminuye impacto de una mastectomía y, hasta cierto punto, se comparte la recuperación de ambas operaciones: la paciente en cama se recupera de la mastectomía y de la reconstrucción al mismo tiempo. Es por eso que el Dr. Gustavo insiste en compartir su experiencia en este tema.",},
        ],
        "author":{
            "name":"Dr Gustavo y fundación BRN",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/dr.gustavojimenez"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/fundación-brn"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'adios-estres-rutinas':{
        "image": lifestyle_54,
        "title": "¡Adiós estrés! Rutinas para relajarse después de un día difícil",
        "pars":[
            {"text":"Después de un día complicado, lo único que queremos, es relajarnos y darnos tiempo para nosotros mismos. A veces, entre el estrés y la ansiedad, es difícil conseguirlo. Por eso, te presentamos algunos ejercicios que te ayudarán a relajarte al máximo."},
            {"title":"Antes de comenzar", 
                "list": [
                    "- Te sugerimos buscar un lugar cómodo y sin ruido. Puedes hacerlo solo o acompañado, como te sientas mejor. Trata que la luz del cuarto sea tenue, así te concentrarás más facilmente.",
                    "- La postura es importante. Antes de empezar, te puedes acostar con los brazos y piernas estiradas, y la cabeza apoyada en una almohada o tapete para yoga, o también lo puedes hacer sentada, pero siempre cómoda.",
                    "- Concéntrate en ti. Al principio puede ser difícil identificar las sensaciones que vienen de la mano de los ejercicios. ¡No hay prisa! Ve a tu ritmo.",
                    "- Hazlo un hábito. La manera de relajarte es practicando, recuerda que tarda algunos días adquirir un hábito."
                ]
            },
            {"title":"Ejercicios", 
                "list": [
                    "Respiración profunda: Esta técnica te ayudará a mantener una respiración profunda y relajada. Respirar correctamente es la clave para conseguir relajarte. Toma lentamente aire por la nariz y llévalo hacia los pulmones (inspira poco a poco hacia el abdomen), retenlo unos pocos segundos, y expúlsalo por la boca con los labios ligeramente fruncidos. Esta técnica te ayuda a relajarte.",
                    "Concéntrate en ti: Este ejercicio te ayuda a concentrarte de una mejor manera. Es común que la mente vaya de un pensamiento a otro. Con esta técnica, lograremos que se detenga para enfocarnos en lo que queremos. Primero, debemos tomar y soltar aire suavemente por la nariz, hasta concentrarnos en nuestra respiración natural. Por ejemplo: tratar de imaginar el recorrido del aire, desde las fosas nasales hasta llegar a los pulmones. Aquí, sabremos cuando nos estamos concentrando y cuando no.",
                    "Relajación pasiva: Esta técnica requiere un poco más de concentración, sin embargo, te ayudará a alcanzar de forma rápida, alivio y relajación. ¿Cómo? A través de pensamientos que generen sensaciones calmantes. Cierra los ojos y repite mentalmente cosas que te produzcan felicidad. Concéntrate en visualizarlas. De esta manera, podrás llegar a una estado de relajación profunda."
                ]
            },
            {"text":"Recuerda hacer estos ejercicios cuando sientas mucho estrés o simplemente como parte de tu rutina diaria. Escucha a tu cuerpo y reconoce aquellas sensaciones, solo así, podrás llegar a un estado de relajación óptimo que proyectarás en tu imagen.",},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
            {"text":"Heras C. (s/f). Mia Revista. Salud. 4 ejercicios para relajarse en un tiempo exprés", "link":"https://www.miarevista.es/salud/articulo/4-ejercicios-para-relajarse-en-un-tiempo-expres-111460019370"},
            {"text":"Redacción (4 de junio de 2019) La mente es maravillosa. Psicología clínica. 7 ejercicios de relajación para liberarnos (por fin) de la ansiedad.", "link":"https://lamenteesmaravillosa.com/7-ejercicios-relajacion-fin-liberarnos-la-ansiedad/"}
        ]        
    },
    'freelance-sin-regalar-trabajo':{
        "image": lifestyle_55,
        "title": "Cómo cobrar tu trabajo como freelance",
        "pars":[
            {"text":["Quieres ser freelance, pero, ¿no sabes cómo cobrar por tu trabajo? Te compartimos el ABC para comenzar."]},
            {"title":"A. Aprende a valorar tu trabajo.", 
              "text":["Como freelance antes de hablar de números, piensa que tu trabajo vale tanto como cualquier otro. Algunos suelen minimizar el trabajo personal por considerarlo simple o por el amor que se le dedica. Sin embargo, eso impide que las personas crezcan y cumplan sus metas. Los freelancers son los primeros que deben creer y respetar su trabajo."]
            },
            {"title":"B. Balance entre el costo y tus finanzas.", 
                "text":"Si te has quedado con la sensación de que el precio fue muy bajo, la razón es que no has dedicado tiempo a tus finanzas. Es normal sentir ansiedad o culpa al pensar en dinero, pero tener las cuentas personales claras ayuda a tener paz mental y a prepararse para el futuro. Es por eso que debes entender que tus ganancias deben ser suficientes para cubrir tus necesidades y propósitos o sueños."
            },
            {
                "title":"C. Calcula un precio por hora.",
                "text":"Existen muchas fórmulas o calculadoras en internet que parten de los gastos fijos y de las ganancias esperadas para fijar un precio por hora. Esto da a entender que el esfuerzo, medido en horas, es lo que estás cobrando: del proceso que hay detrás y no del producto final. Con esto en mente, podrás empezar a definir algo muy importante: el precio de tu trabajo. "
            },
            {"text":"Finalmente, debes pensar que fijar un correcto precio a tu trabajo es la mejor forma de seguir haciendo lo que te gusta y enfocarte en ti.",},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'comienza-anio-motivado':{
        "image": lifestyle_56,
        "title": "Inicia el año motivado",
        "pars":[
            {"text":"¿Has oído hablar del blue monday? Es una fecha que aparece en los periódicos o redes sociales cada año. En teoría se calcula a partir del clima con que inicia el año, el aumento en los costos de los productos y en los niveles de motivación de las personas. Eso nos da el tercer o segundo lunes de cada enero. Si bien está demostrado que es una idea errónea, ya que nada lo sustenta, refleja muy bien el sentir de las personas: enero es un mes donde falta energía."},
            {"text":"Aunque muchos aprovechan el mes de diciembre para poder planear propósitos, metas e incluso cambios radicales. El mes de enero dificulta eso. Pensemos en el propósito de realizar más deporte: muchas personas adelantan mensualidades para “obligarse” a ir al gimnasio o  compran equipo para alguna actividad. Sin embargo, antes de terminar el mes, han abandonado todo."},
            {"text":"¿Cómo podemos empezar el año motivados? Una forma sencilla es el de poner metas pequeñas: no busques un cambio radical en pocos días. Es mejor un cambio paulatino pero constante. Regresando al ejemplo de hacer más ejercicio: empieza por rutinas diarias o de cada tercer día con una duración de 10 o 15 minutos. Así no te sentirás forzado a lograr un cambio, pero sí formarás la costumbre de realizar actividad física."},
            {"text":"Un segundo ejemplo, para comer más sano, es el de incluir algún alimento saludable en tu alimentación diaria. Cambia una botana vespertina por una fruta o verdura, reduce las porciones de tu comida o busca una aplicación que te recuerde beber agua durante el día."},
            {"text":"Si lo que te interesa es mejorar tu memoria y vocabulario, ponte como meta leer un libro al mes. Divide sus hojas entre los días del mes y lee esa cantidad en la tarde o antes de dormir. Verás que terminarás el libro antes de lo que tenías planeado.  Eso sí, no creas que fallar un día significa que todo se ha perdido, por el contrario, una pequeña falta se puede reponer con otro pequeño esfuerzo. Lo importante es que no desistas."},
            {"text":"Por último, si eres de los que necesitan estímulos positivos: lleva un diario o anota en un bloc de nota los días y el tiempo invertido. Verás que en dos o tres meses habrás acumulado muchos logros."},
            {"text":"Los cambios pequeños pero constantes te aseguran que obtendrás resultados y que no te costarán un esfuerzo que te haga desistir rápidamente."},
            {"text":"Síguenos en redes sociales para tener más tips de cómo encontrar tu mejor versión y la motivación necesaria para alcanzar tus objetivos."},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'must-autoestima-bienestar-pandemia':{
        "image": lifestyle_57,
        "title": "Recupera tu autoestima y bienestar con estos 6 must",
        "pars":[
            {"text":"Hoy, podemos decir que no existen dudas de que la pandemia ha marcado un antes y un después en nuestras vidas. De un momento a otro, todo lo que conocíamos dejó de existir. Durante este periodo perdimos seres queridos, espacios, libertades, y, sobre todo tranquilidad; lo cual afectó, de alguna u otra forma, nuestra autoestima, impactando  nuestro bienestar físico y mental."},
            {"text":"Recordemos que la autoestima tiene que ver con todo lo que pensamos, sentimos y creemos de nosotros mismos. Es tan importante, que a través de ella, determinamos quiénes somos. De eso depende buena parte de nuestra salud mental."},
            {"text":"Si estás pasando por momentos difíciles, te decimos que NO ESTÁS SOLO. Por eso, queremos darte unas sugerencias que podrán ayudarte a reconectarte:"},
            {"title":"1. Cuida de ti antes de cuidar de los demás.","text":"Si algo hemos aprendido, es que, para cuidar de los otros, primero debes protegerte a ti. Dedica tiempo para realizar actividades que te gusten, celebra tus #PequeñasGrandesConquistas y reconoce todo lo que has hecho bien."},
            {"title":"2. Vive tus emociones, no las reprimas.", "text":"El primer paso es aprender a identificar tus emociones y aceptarlas. Tener miedo y sentir tristeza, frustración o desilusión, está bien, es normal; date la oportunidad de que fluyan a través de ti. La mejor manera de afrontar estos sentimientos es permitiéndote vivirlos."},
            {"title":"3. Realiza actividades que te hagan sentir bien.", "text":"Destina un poco de tiempo a hacer cosas que te permitan salir de la rutina; si te gusta cocinar, prepara nuevas recetas, si quieres bailar, ¡no te detengas! Lo importante es que hagas lo que a ti te hace sentir mejor."},
            {"title":"4. Practica un deporte.", "text":"Sabemos que no es fácil, pero una vez que inicies te darás cuenta de los muchos beneficios que tiene activarte físicamente; empezarás a verte y sentirte mejor y poco a poco se convertirá en tu estilo de vida. Recuerda que a veces la mente nos juega en contra: cada vez que sientas que no puedes, rétate a ti mismo y ve por mejores resultados."},
            {"title":"5. Siempre hay algo que agradecer.", "text":"Estamos acostumbrados a no agradecer por las cosas buenas que tenemos, casi siempre nos enfocamos en lo malo. Sin embargo, es recomendable dar las gracias por aquello que sí tienes, así como por lo que te da felicidad. La gratitud tiene efectos positivos en tu estado de ánimo."},
            {"title":"6. Céntrate en ti.", "text":"Antes de tomar cualquier decisión, respira hondo y piensa en qué es lo mejor para ti. A veces debemos dejar de pensar en los demás y ponernos como prioridad. Inicia el día arreglándote para lograr sentirte cómod@ con quién eres y ser feliz y plen@ con cómo te ves."},
            {"text":"¡Recuerda! verte bien te ayudará a sentirte más segur@."}
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'cuerpo-paso-tiempo':{
        "image": lifestyle_58,
        "title": "¿Qué sucede en el cuerpo con el paso del tiempo?",
        "pars":[
            {"text":"El envejecimiento es un proceso natural, paulatino y constante. Aunque envejecer es un proceso básicamente biológico, existen algunos factores que pueden acelerar o retrasar este proceso."},
            {"text":"Para ahondar en este tema, consultamos al Dr. José Cortés, cirujano plástico egresado del Hospital General “Dr. Manuel Gea González”, para platicar sobre cómo evitar el envejecimiento prematuro. El Dr. Cortés es miembro del Comité Científico de la Asociación Mexicana de Labio, Paladar Hendido y Anomalías Craneofaciales. Su trabajo como cirujano plástico le ha permitido ganar el Doctoralia Award desde el 2018 hasta el 2020."},
            {"text":"El Dr. Cortés explica que envejecer es un proceso natural. A lo largo de la vida, las células que conforman nuestros cuerpos van naciendo y muriendo continuamente. Conforme envejecemos este proceso sufre de alteraciones y las células se regeneran cada vez más lento."},
            {"text":"Un ejemplo evidente es la piel: la aparición de arrugas o manchas son un claro ejemplo de los cambios que experimentamos por la alteración en los procesos de regeneración celular. También podemos notar falta de firmeza en la piel debido a que la grasa que se encuentra debajo de la epidermis va disminuyendo, generando una pérdida de densidad. Otro cambio importante que viene con el envejecimiento es la absorción del calcio de nuestros huesos, lo que influye directamente en el tamaño del cráneo lo que puede intensificar la sensación de “piel colgada”. Cabe destacar que estas alteraciones o cambios varían de persona a persona."},
            {"text":"Ahora bien, este proceso inicia entre los 25 y 30 años, y  continuará a lo largo de nuestra vida. Podemos considerar que el proceso de envejecimiento es similar entre hombres y mujeres, aunque una diferencia es que las alteraciones en los niveles hormonales pueden presentarse antes en las mujeres."},
            {"text":"Ahora, ¿qué pasa cuando descuidamos nuestro cuerpo? Podemos notar cambios en nuestra piel a edades más tempranas y a eso le llamamos \"envejecimiento prematuro\"."},
            {"text":"El cuerpo reacciona a los efectos de nuestra rutina. Si bien, la edad es un factor al igual que los antecedentes que hay en nuestra familia, el cuidado de nuestra salud, alimentación y hábitos son claves para retrasar el envejecimiento, explica el Dr. Cortés. Las cremas o suplementos son útiles, pero no pueden revertir el daño por la acumulación de muchos años de malos hábitos. Las enfermedades que padecemos a lo largo de nuestra vida influyen de forma significativa en nuestro envejecimiento."},
            {"text":"Sin embargo, tenemos herramientas para enfrentarlo. Por ejemplo, al hablar de la piel podemos considerar diferentes tratamientos, dependiendo de la edad: el ácido hialurónico se puede usar en jóvenes para hidratar y prevenir arrugas. Cerca de los 30 años se pueden buscar tratamientos que reduzcan la formación de arrugas más marcadas como la toxina botulínica. Las opciones se determinan  en función de las necesidades de los pacientes y de la supervisión de un especialista."},
            {"text":"Además, El Dr. Cortés nos explicó que para aplicar un tratamiento hay que confrontar la realidad del paciente con las expectativas de cambio. Así se pueden valorar cuáles son las opciones quirúrgicas y no quirúrgicas que pueden resolver paulatinamente dichas necesidades. Actualmente tenemos opciones muy avanzadas y naturales que ayudan a los pacientes a verse y sentirse espectaculares por más tiempo."},
            {"text":"Claro está que la prevención siempre será la primera opción. De hecho, el Dr. Cortés comenta que actualmente lo visitan pacientes entre los 22 y 28 años para recibir tratamientos preventivos, obteniendo grandes resultados."},
        ],
        "author":{
            "name":"Dr José Cortés",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/dr.josecortes"},
                {social:"Web",text:"", link:"https://www.josecortes.com/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'mente-logro-importante':{
        "image": lifestyle_59,
        "title": "¿Qué pasa en nuestro cerebro cuando logramos algo importante?",
        "pars":[
            {"text":"Cuando enfrentamos una situación difícil, tenemos una pérdida o vivimos un cambio importante en nuestra vida, solemos tener el sentimiento de que casi nada nos ha salido bien. La mayoría de las veces nos enfocamos en lo negativo y, en muy pocas ocasiones, celebramos lo bueno. "},
            {"text":"Esta es la razón por la cual quiero que juntos recordemos: ¿cómo nos hacen sentir nuestros pequeños triunfos?, ¿cómo nos inspiran? "},
            {"text":"No sé cuáles son las sustancias que en nuestro cerebro se activan, pero cuando cierro los ojos y pienso en cada uno de los logros que he obtenido, encuentro paz. Automáticamente me siento más fuerte, segura y capaz de alcanzar mis metas."},
            {"text":"En mi memoria tengo muy presentes las palabras de una maestra de yoga que me inspiró y que decía: “vean bonito, sientan bonito, escuchen bonito y así su cuerpo estará bien” ¡Cuánta razón tiene! Estoy segura de que nuestros pensamientos definen nuestra personalidad y, en gran medida, nuestra vida."},
            {"text":"Debemos aprender a “sentir bonito” cada logro que tenemos, por pequeño o grande que sea. Es nuestro, pertenece a lo que somos y define a dónde vamos. Enfocarnos en lo malo sobrecarga el cerebro de pensamientos negativos; nos desconecta de la realidad y puede llegar a enfermar nuestro organismo."},
            {"text":"Sabemos que siempre habrá días malos, pero es nuestra responsabilidad aprender a vivirlos con naturalidad y tranquilidad. Son parte de la vida y sin ellos no podríamos crecer."},
            {"text":"Deseo invitarte a celebrar los grandes momentos que has vivido, quiero que en ellos encuentres la inspiración. Motiva a tu cerebro a seguir soñando y a querer más de la vida. Esa es la clave."},
            {"text":"Una vez me encontré a alguien que me felicitó por mi trabajo, mi personaje estaba bien hecho y logré que conectara con el público. Me sentí halagada, llena de gratitud y felicidad. Eso me inspiró a ser mejor actriz. En mis días malos recuerdo ese momento, sonrío y pienso que en ustedes encuentro la inspiración. ¡Su cariño es mi mayor logro!"},
            {"text":"Quisiera cerrar invitándolos a disfrutar sus triunfos. Recuerden que éstos son el resultado de mucho esfuerzo. Gocen cada momento, sueñen, decreten y vivan con plenitud. Nuestros logros se deben celebrar siempre. 💞"},
        ],
        "author":{
            "name":"Valentina Buzzurro",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/_buzzurro_/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/valentinabuzzurroofficial"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]        
    },
    'colageno-a-z':{
        "image": lifestyle_60,
        "title": "Colágeno de la A a la Z",
        "pars":[
            {"text":"Últimamente, escuchamos mucho sobre el colágeno: que si debemos prevenir su pérdida o que si hay que incluirlo en la dieta diaria. ¿Sabes qué es el colágeno, qué funciones tiene realmente y por qué es importante? Aquí te contamos todo lo que debes de saber: su función en el cuerpo humano y qué opciones existen para mantener sus niveles a lo largo de los años."},
            {"text":"Empecemos por conocer qué es el colágeno, se trata de una proteína cuya función es mantener unidas las diferentes estructuras del organismo. Ésta se fabrica por las células llamadas fibroblastos y está presente en todos los seres humanos. Se trata de la proteína más abundante: se calcula que una de cada cuatro proteínas en el cuerpo es colágeno."},
            {"text":"Al tener una proporción tan grande, podemos destacar algunas de sus funciones: se encarga de unir y permitir el estiramiento de tejidos como músculos, ligamentos, piel y tendones; ayuda a la curación de la piel después de una lesión; en otras palabras, es responsable de la firmeza y elasticidad de los órganos; además facilita el reforzamiento de los huesos. De ahí que el aspecto de la piel, las uñas o el cabello dependen de esta proteína."},
            {"text":"Actualmente, se sabe que existen más de 19 diferentes tipos de colágeno, dependiendo del tejido en donde se encuentre. En el cuerpo encontramos principalmente 5 tipos de colágeno que se encargan de funciones vitales como formar cadenas para otorgar resistencia en los tejidos ante la presión; ser el sostén para los músculos y permitir la filtración de sustancias. Pese a su gran importancia, la producción de colágeno disminuye con el paso del tiempo. Se calcula que cerca de los 40 años, sólo se genera la mitad de lo que el cuerpo era capaz de generar en la adolescencia. Es por eso que con la edad se pierde la elasticidad y flexibilidad de los músculos y la piel; se sufre de osteoporosis y otras deficiencias comunes de la vejez. "},
            {"text":"Aunque hay una disminución en la producción de colágeno con la edad, actualmente existen tratamientos que ayudan a restaurar los niveles de esta importantísima sustancia en el cuerpo, ya sea que estimulen su producción o que se adquieran como complemento alimenticio. Algunas de las opciones incluyen el uso de láser, ingerir colágeno directamente; o el uso de tratamiento no invasivos como el ultrasonido micro-focalizado, que consiste en la utilización de tecnología ultrasónica con visualización en tiempo real para activar el colágeno desde las capas más profundas de la piel logrando un efecto lifting en el rostro. Algunas de las celebs que lo han probado y lo aman son Martha Debayle, Jennifer Aniston, Courtney Cox, Adriana de Moura, entre otras."},
            {"text":"Cuéntanos en redes sociales, ¿te animarías a reinventar el colágeno de tu rostro?"},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
            {text:"Meisenberg, G. and W.H. Simmons, Principios de bioquímica médica. 2018: Elsevier Health Sciences."},
            {link:"https://www.mayoclinic.org/es-es/diseases-conditions/wrinkles/diagnosis-treatment/drc-20354931"}
        ]
    },
    'abc-acido-hialuronico':{
        "image": lifestyle_61,
        "title": "El abc del ácido hialurónico",
        "pars":[
            {"text":"En la búsqueda de consejos para mejorar la piel, escuchamos seguido del ácido hialurónico. Una de las sustancias más importantes en nuestro cuerpo. Recientemente, lo vemos como un ingrediente importante en productos de belleza, mascarillas, suplementos y una larga lista de productos."},
            {"text":<img style={{width:'100%'}} alt="Infografía ácido hialurónico" src={acido_hialuronico} />},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'tips-autoestima-alta':{
        "image": lifestyle_62,
        "title": "5 tips para que tu autoestima se mantenga siempre alta",
        "pars":[
            {"text":"En mi práctica diaria, es común encontrarme en situaciones en las que mi principal objetivo es convencer a las personas de que no hay mayor acto de amor que el de cuidar de uno mismo y sigan algunos tips de autoestima. "},
            {"text":"A veces, nos enfrentamos al reto de luchar contra nuestros miedos y prejuicios. Créeme ¡me ha pasado! Pero te puedo asegurar que, una vez que empiezas a confiar en ti, te vuelves más fuerte, tu autoestima crece y empiezas a salir de esa zona de confort que te impide alcanzar tus metas."},
            {"text":"La autoestima es ese sentimiento que te permite saber cómo te sientes contigo mismo. La mayoría de las veces está determinada por un conjunto de creencias, valores y pensamientos que hemos ido creando a partir de la experiencia. Por eso, hoy quiero darte 5 tips para que se mantenga siempre alta."},
            {
                "title":"1.- Pregúntate, ¿cuál es el por qué de tus miedos?",
                "text":"Cada vez que sientas que algo te da miedo, cuestiónate ¿por qué? ¿Cuál es el origen? Buscar hacerle frente, comprender de dónde proviene y cómo puedes enfrentarlo es el primer paso para vencerlo. Recuerda “No hay mayor terror que aquel que viene de tus propias ideas”"},
            {
                "title":"2.- Aprende a conocerte.",
                "text":"Todos necesitamos tiempo para pensarnos y reinventarnos, no tengas miedo de estar solo. Date la oportunidad de hacer una remembranza de tu vida. Enfócate en lo que has hecho bien, aprende de lo que no ha salido como quieres y trabaja para mejorar."
            },
            {
                "title":"3.- Intenta eso que siempre has querido hacer.",
                "text":"El peor enemigo en nuestra vida es no hacer nada. ¡Levántate! Realiza planes, piensa en las cosas que siempre has querido y busca la manera de alcanzar tus metas. Sentirte bien contigo no depende de lo que logras, sino de cómo te sientes con lo que haces. La opinión que tienes de ti es el reflejo de tus acciones."
            },
            {
                "title":"4.- Celebra tus logros",
                "text":"Reconoce tu esfuerzo todos los días. Todos tenemos momentos en los que sentimos que no podemos más. Si estás pasando por algo parecido, te invito a que celebres tus triunfos ¿Fue un mal día? ¿Lo más que pudiste hacer fue salir de tu cama? ¡Siéntete orgulloso! Solo tú conoces tu proceso. Si tuviste una mañana maravillosa y lograste alcanzar la meta de hacer una rutina de ejercicio combinada con una buena alimentación y te sientes feliz por eso, ¡celébralo! No importa lo que estás pasando, aprende a festejar tus pequeñas grandes conquistas. "
            },
            {
                "title":"5.- ¡Cuida tu nutrición!",
                "text":"Busca decir no a los alimentos procesados y opta por lo natural. Cuida de ti y de todo lo que consumes. Pon atención en lo que comes, los contenidos que ves en redes sociales y hasta las personas de las que te rodeas. Una vez me dijeron: “eres el promedio de las 5 personas más cercanas con las que te relacionas, ¡escógelas bien!” Estoy convencida de que es cierto. Por eso, intenta que tu círculo cercano esté conformado por personas que te apoyen, sumen, inspiren y te inviten a aprender de ellas."
            },
            {"text":"Y como recordatorio, por favor ¡no te compares! Todos somos diferentes, venimos de contextos y experiencias distintas. Aprende a valorarte por quién eres y evita cualquier sentimiento de inferioridad. Recuerda: son esas pequeñas diferencias las que nos hacen únicos e irrepetibles. "}
        ],
        "author":{
            "name":"Mariana Custodio, fundadora de Nutrideas",
            "contacts":[
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'fibroblastos-rol-envejecimiento':{
        "image": lifestyle_63,
        "title": "Los fibroblastos: su rol en el proceso del envejecimiento",
        "pars":[
            {"text":"Existen diferentes tipos de células en la piel, unas de las más importantes son los fibroblastos. ¿Nunca los habías escuchado o no sabes qué son? No te preocupes, a continuación, te compartiremos más información."},
            {"text":"Los fibroblastos son células ubicadas en la dermis, es decir, una de las capas profundas en la piel, y son responsables de la producción de fibras de colágeno y elastina, que le brindan soporte y firmeza a la piel. Imaginemos que estas fibras son como el resorte de un colchón, y los fibroblastos, son los encargados de producirlos para mantener “este colchón” firme. Alrededor de los 18-20 años, estos “se duermen”, perdiendo el soporte necesario, lo que provoca que aparezcan las temidas líneas de expresión, flacidez y otros signos del envejecimiento. Y si a esto le sumamos estrés, mala alimentación o contaminación, el envejecimiento en la piel logra acelerarse más fácilmente. Por lo que, es recomendable cuidarte, prevenir y llevar una vida saludable para seguir sintiéndote y viéndote joven y bella."},
            {"text":"Existen varios tratamientos que ayudan a “despertar” a estos famosos fibroblastos para que continúen su producción y permitan mantener una piel visiblemente joven."},
            {"text":"Los tratamientos pueden incluir desde cremas para aplicar diariamente sobre el rostro, productos inyectables que bio-estimulen la producción de colágeno, hasta procedimientos vía ultrasonido microfocalizado o incluso cultivos de fibroblastos, los cuáles, ayudan a mejorar los signos de la edad, mejorando notablemente la elasticidad, textura y luminosidad de la piel."},
            {"text":"Probablemente has escuchado de celebridades que se han aplicado tratamientos a base de cultivos de fibroblastos. La técnica es muy interesante, se extrae una muestra de la piel, luego de se cultivan los fibroblastos para ser aplicados vía intracutánea, y pasarán aproximadamente 3 meses para notar cambios en la piel. Al ser nuestras propias células, no produce ningún efecto adverso. "},
            {
                "title":"Ventajas",
                "list": [
                    "Se pueden estimular y reactivar a los fibroblastos presentes en el tejido",
                    "Incrementa la producción propia de colágeno, elastina y ácido hialurónico",
                    "Retrasa notablemente el proceso de envejecimiento",
                    "Es natural",
                    "Los resultados son graduales pero efectivos"
                ]
            },
            {
                "text":"Sin duda alguna, los fibroblastos son células que debemos cuidar, ya que son indispensables para una piel bella. ¡Despiértalos y vuelve a sentirte radiante y hermosa! Si te interesa un tratamiento así, busca a un especialista certificado y no olvides mantener la eficacia con otro de los procedimientos antes mencionados para asegurar resultados a largo plazo."
            },
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
            {"text":"Redacción. (s/f). EnColombia. Consejos de estética. ¿Qué sostiene la piel?", "link":"https://encolombia.com/salud-estetica/estetica/consejos/que-sostiene-la-piel/"},
            {"text":"Gutiérrez R. (29 de marzo de 2016). Piel Clinic. Qué son los fibroblastos y para qué sirven.", "link":"https://pielclinic.com/cultivo-de-fibroblastos-rejuvenecimiento-facial-natural/"},
            {"text":"Guerra M. (Sumedico Portal). 1 de marzo de 2018. Fibroblastos: una técnica para rejuvenecer nuestra piel con células especializadas.", "link":"https://www.youtube.com/watch?v=JsyKt8H_Kqg"}
        ]
    },
    'que-es-diastasis-abdominal':{
        "image": lifestyle_64,
        "title": "¿Qué es la diástasis abdominal?",
        "pars":[
            {"text":"Si después de meses de haber dado a luz tienes malestares en el vientre, puede que tengas una afección común; no tan conocida entre las mamás primerizas: se trata de la diástasis abdominal. Conoce un poco más de los síntomas y cómo tratarla. ¡Esperamos que te sea de mucha ayuda! Recuerda que ante cualquier síntoma o padecimiento debes de acudir con tu médico de cabecera."},
            {"title":"¿Qué es?","text":"Uno de los tantos cambios que se presentan durante y después del embarazo. Es el aumento en el tamaño del vientre, pero hay ocasiones en que este aumento tiene secuelas que continúan por un tiempo bastante prolongado incluso después del parto. Esta afección es común aunque pocas veces detectada a tiempo. Se trata, en esencia, de una alteración estética en la que el abdomen se ve descolgado y flácido, con abultamientos cuando se hacen esfuerzos físicos. Además puede tener consecuencias en la digestión. "},
            {"text":"Este padecimiento se presenta comúnmente en mujeres mayores de 35 años que dan a luz a un bebé de peso elevado o que tuvieron un embarazo múltiple y se detecta en el último trimestre del embarazo o en el posparto inmediato; aunque no es una condición que se limite a mujeres embarazadas. También puede presentarse en embarazos de mujeres jóvenes debido a cuestiones genéticas, en personas que cargan una gran cantidad de peso o en personas con obesidad abdominal."},
            {"title":"¿Cuándo se presenta?", "text":"La diástasis abdominal se da cuando hay una separación de los músculos rectos del abdomen, ya que hay un tejido conectivo que, en condiciones normales, los mantiene unidos, a esta separación se le denomina línea alba. Los síntomas que presentan los pacientes son: dolores lumbares, hinchazón abdominal y mala digestión. Aunque se trata mayormente de un problema estético habitual, en algunos casos puede afectar a la estabilidad del cuerpo e incluso dar lugar al desarrollo de hernias."},
            {"text":"Si bien para un correcto diagnóstico debes de acudir con un médico calificado, hay un sencillo ejercicio de autoexploración para detectar si se tiene diástasis abdominal. La persona se debe colocar boca arriba con el abdomen descubierto, se estiran los pies juntos y se elevan unos centímetros. Si sobre la línea media del ombligo se forma un bulto, es probable que la persona tenga esta condición."},
            {"title":"Tratamientos", "text":"Dependiendo de la condición del paciente se pueden elegir distintas terapias. La más común es la fisioterapia, donde un especialista guía distintos ejercicios y da masajes que permiten la recuperación tanto de la firmeza como de la forma del vientre. Lo ideal es iniciar con los ejercicios entre 6 y 8 semanas después del parto. La duración del tratamiento puede variar entre los 6 y 12 meses, dependiendo de cada paciente."},
            {"text":"El segundo tratamiento más común es el de la cirugía de reparación endoscópica. Ésta opción se elige cuando la fisioterapia no muestra avance,  cuando se ha retrasado el diagnóstico o cuando se ha detectado una hernia. Se trata de una cirugía mínimamente invasiva que consiste en realizar tres incisiones pequeñas en la zona abdominal sobre el hueso del pubis. Después de separar la grasa subcutánea de los músculos, se realiza una sutura para regresar a los músculos a su sitio."},
            {"text":"En caso de que exista un exceso de piel, se debe realizar una abdominoplastia, es decir, una cirugía reconstructiva que elimina el exceso de piel, grasa y tensión de los músculos de la pared abdominal. De este modo se posiciona una cicatriz horizontal lo más baja posible para disimular y remodelar el abdomen, la cintura y reconstruyendo el ombligo. En algunos casos se puede realizar una liposucción para disminuir el volumen; además de apoyarse en bioestimuladores de colágeno para mejorar la tensión, revitalizar y disminuir las cicatrices de la piel."},
            {"text":"Esperamos que este blog haya resuelto todas tus dudas. Ya sea que tengas diástasis abdominal, creas tenerlo o conozcas a alguien con los síntomas que mencionamos, recuerda siempre consultar a un médico especialista en el área. En BetterSelf puedes encontrar información sobre salud, belleza y estilo de vida. Recuerda que estar saludable es la mejor forma de vivir tu mejor versión."}
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
            {"text":"Cuenca, Osmar, Rodríguez, Agustín, & Segovia, Arturo. (2017). REPARACIÓN ENDOSCOPICA DE DIASTASIS DERECTO Y DEFECTOS DE LA LÍNEA MEDIA. Cirugía paraguaya, 41(2), 37-40.", "link":"https://dx.doi.org/10.18004/sopaci.agosto.37-40"},
            {"text":"De Oliveira, C. S. F. P., da Silva Almeida, T. J., de Oliveira Martins, L., Sorpreso, L. A. T. M., & Finck, N. S. (2021). Hidroxiapatita de cálcio: uma revisão quanto à eficacia, segurança e imaginologia quando usado como preenchedor e como bioestimulador. Research, Society and Development, 10(14), e05101421689-e05101421689."},
            {"text":"M. T., Braga, M. D., Bernardes, N. O., & Andrade, S. C. (2009). Prevalence of diastasis of the rectus abdominis muscles immediately postpartum: comparison between primiparae and multiparae. Brazilian Journal of Physical Therapy, 13(4), 275-280."},
            {"text":"Pérez, C. C., Carrera, I. D. C., & González, Y. G. (2019). ¿Cuál es el mejor ejercicio para la rehabilitación de la diástasis abdominal?. Rehabilitación, 53(3), 198-210."}
        ]
    },
    'tips-planear-boda':{
        "image": lifestyle_65,
        "title": "4 tips para planear tu boda",
        "pars":[
            {"text":"Casarse es una de las experiencias más importantes y bonitas de la vida. Muchas personas imaginan el momento, el vestido, la ceremonia, la canción que bailarán e incluso el pastel que cerrará la noche. La planeación del gran día es vital para llevarla al final soñado, por eso te compartimos nuestros mejores tips para planear tu boda."},
            {"text":<img style={{width:'100%'}} alt="Infografía tips planear tu boda" src={tips_bodas} />},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'tips-proximo-viaje':{
        "image": lifestyle_66,
        "title": "10 tips para preparar tu próximo viaje",
        "pars":[
            {"text":"Odemaris Ruíz, actriz y conductora mexicana, que dedica gran parte de su tiempo en viajar y compartirlo con las personas, nos da sus mejores tips para preparar tu próximo viaje. "},
            {"text":<img style={{width:'100%'}} alt="Infografía tips planear tu próximo viaje" src={tips_viajes} />},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'lineas-expresion-arrugas':{
        "image": lifestyle_67,
        "title": "¿Líneas de expresión o arrugas? Diferencias, prevención y cuidado.",
        "pars":[
            {"text":"¿Sabías que las líneas de expresión no son lo mismo que las arrugas? Muchas personas confunden ambos conceptos, pero algo es seguro, tod@s buscamos disimularlas o disminuirlas.  El primer paso para lograrlo es entender sus diferencias y características. En BetterSelf contactamos a la Dra. María Ramos de Lombardi para conocer qué son las arrugas, cuáles son las diferencias con las líneas de expresión y cómo se pueden prevenir y/o tratar."},
            {"text":"Antes de empezar con las especificaciones sobre las arrugas, debemos entender que la piel de cada persona es diferente y sus características determinan el tipo de líneas de expresión que se formarán con la edad.  La Dra. Ramos-Lombardi explica que existen tres tipos de piel: seca, grasa y mixta; y cada una de ellas necesita de rutinas de cuidado diferentes."},
            {"text":"En las pieles secas por ejemplo, con el paso de los años se pueden formar múltiples líneas delgadas; mientras que en las pieles grasas se forman pocas líneas pero más profundas. Es por ello que cada tipo de piel debe de tratarse de forma especial. Sin embargo, es importante consultar a un especialista para que conozcas tu tipo de piel y sepas cuáles son los mejores cuidados para la prevención y corrección."},
            {"text":"Debemos entender que todas las personas tenemos y desarrollaremos arrugas, ya que se trata de pliegues que se forman en la piel de forma natural; pero no todas son iguales. Existen dos clasificaciones y cada una de ellas tiene características únicas: al primer tipo se le llaman arrugas estáticas y se forman por la pérdida de volumen de la piel, es decir, surgen desde el nacimiento y se marcan con la edad. Al segundo tipo se le llama arrugas dinámicas o líneas de expresión, y se forman cuando se realiza algún gesto con los músculos faciales. Cada tipo necesita una atención diferente. "},
            {"text":"Tras conocer las características de ambos tipos de arrugas, surge la pregunta: si se forman naturalmente, ¿hay algo que se pueda hacer para prevenir y/o tratar ? La respuesta es ¡sí!  La mejor forma de prevenir la aparición de las arrugas es con un correcto cuidado de la piel: una rutina de skincare personalizada que atienda a las necesidades de tu rostro. La hidratación es uno de los pilares al igual que el uso de protector solar, aunque no se deben desatender aspectos como la limpieza facial tanto de día como de noche. "},
            {"text":"En un segundo nivel de atención, actualmente existen opciones para el tratamiento de las arrugas. El primero, quizá el más conocido, es la toxina botulínica que relaja los músculos faciales, con este tratamiento se disminuyen los movimientos de los gestos por lo que se recomienda para las líneas de expresión del entrecejo. Si lo que se busca reducir son arrugas estáticas, actualmente existen rellenos de ácido hialurónico que reponen la falta de este componente para rellenar el surco de la arruga y además permiten perfilar ciertas zonas del rostro."},
            {"text":"Aunque lo más recomendable siempre será la prevención, ya que debemos ser conscientes, comenta la doctora, de que no se puede eliminar una arruga al 100%. Lo que se busca con los tratamientos correctivos es disminuir y dar un resultado natural."},
            {"text":"No olvides consultar siempre a especialistas y utilizar productos avalados y seguros para tu cuidado. Tu mejor versión siempre va de la mano con tu cuidado. "},
            {"text":"Si quieres conocer más sobre el cuidado de la piel, puedes seguir a la Dra. Ramos-Lombardi en sus redes sociales"}
        ],
        "author":{
            "name":"Dra. Ramos-Lombardi",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/skinmedicalmex"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/skinmedicalmex"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'basicos-cuidado-labios':{
        "image": lifestyle_68,
        "title": "Básicos del cuidado para labios",
        "pars":[
            {"text":"En todo momento es importante mostrar unos labios envidiables. Para ello, la Dra. Lydia Fernanda compartió unos increíbles tips para el cuidado de nuestros labios, además de recomendarnos los mejores tratamientos que existen actualmente."},
            {"text":<img style={{width:'100%'}} alt="Infografía básicos del cuidado para labios" src={infografia_labios} />},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'como-funciona-exfoliacion':{
        "image": lifestyle_69,
        "title": "Exfoliación química: ¿Qué significa AHA, BHA y PHA?",
        "pars":[
            {"text":"Uno de los pasos más comunes de la rutina de skincare es la exfoliación. Un proceso importante para limpiar y cuidar tu piel. Dentro de los factores que debes considerar en tu rutina, son los hidroxiácidos, pero ¿qué significa AHA, BHA, y PHA? ¡Te decimos! "},
            {"text":<img style={{width:'100%'}} alt="Infografía básicos del cuidado para labios" src={infografia_exfoliacion} />},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'tips-piel-verano':{
        "image": lifestyle_70,
        "title": "Tips para cuidar tu piel durante el verano",
        "pars":[
            {"text":"Si piensas aprovechar la temporada para salir y disfrutar del sol, seguro querrás saber como cuidar tu piel durante el verano y que luzca radiante todos los días. En Betterself te compartimos los mejores tips para que sepas todo lo necesario y mantengas tu piel hidratada y saludable."},
            {"text":<img style={{width:'100%'}} alt="Infografía básicos del cuidado para labios" src={infografia_verano} />},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'must-cuidado-piel':{
        "image": lifestyle_71,
        "title": "Must para el cuidado de la piel.",
        "pars":[
            {"text":"El cuidado de la piel es un tema en tendencia desde hace algunos años. Entre toda la información que podemos encontrar existen muchas dudas o aplicaciones que se contradicen. En Betterself nos acercamos con la Dra. Cristina Córtes, experta dermatóloga, para consultar sobre los básicos del cuidado de la piel en el rostro."},
            {"text":<img style={{width:'100%'}} alt="Infografía must para el cuidado de la piel" src={infografia_cuidado_piel} />},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'especialista-sobre-tu-piel':{
        "image": lifestyle_72,
        "title": "¿Qué puede decirte un especialista sobre tu piel?",
        "pars":[
            {"text":"Cuando pensamos en el cuidado de la piel, podemos encontrar diversos productos que prometen cambiar la piel y eliminar todos los problemas casi mágicamente. Sin embargo, si no conocemos qué es lo que necesita nuestra piel, podemos cometer errores o incluso dañarla. Por eso que en BetterSelf consultamos a la Dra. Cristina Cortés, especialista para resolver las dudas que puedas tener sobre qué hace un especialista en el cuidado de la piel."},
            {"title":"1. ¿Quiénes son los especialistas que pueden valorar o diagnosticar nuestra piel?", "text":"Siempre es importante consultar a especialistas para que la información que recibamos tenga un sustento científico y sea segura. Es por eso que los especialistas que se pueden consultar son: dermatólogos, médicos estéticos y cirujanos plásticos. Éstos tienen el conocimiento médico y profesional para determinar las características y necesidades de cada persona."},
            {"title":"2. ¿Qué aspectos de la piel revisa o puede tratar un especialista?", "text":"Consultar a un especialista conlleva una valoración sobre la salud y calidad de nuestra piel.  Algunos de los aspectos que  pueden tomarse en cuenta: la presencia de arrugas, la salud de los poros de la piel, las características de algunas cicatrices, la presencia y tipo de acné, firmeza y elasticidad, etc. Estos factores servirán para conocer el estado de la piel y poder empezar con tratamientos o cambios en la rutina de limpieza o skincare."},
            {"text":"Además de estos aspectos, también se puede valorar la pigmentación de la piel, es decir, si la piel tiene un color uniforme o si se presentan manchas oscuras o claras. Si se presentan, se pueden tomar las decisiones necesarias para mejorar la calidad y salud."},
            {"text":"Tip:  un aspecto que se ha añadido últimamente es el del glow,  es decir,  que tu piel tenga un brillo natural y no se vea apagada. También puedes consultar a tu especialista sobre este tema."},
            {"title":"3.  ¿Cuáles son los factores que pueden afectar a tu piel?", "text":"Contrario a lo que  se puede llegar a pensar, no sólo los factores externos la afectan. Un aspecto que debemos considerar son los factores internos o endógenos: se refieren a las características que encontramos internamente."},
            {"text":"Por ejemplo: la genética  determina el tipo de piel que tenemos.  Seguro has escuchado sobre las diferencias entre la piel seca y la piel grasosa;   estas características son dadas por los genes y no son modificables.  La edad es otro factor que puede determinar cuestiones como la humectación,  el acné, los niveles hormonales, etc."},
            {"text":"En cambio, los factores externos son aquellos que experimentamos y no tenemos control sobre ellos. Uno muy importante es la contaminación:   tanto en el agua como en el aire podemos encontrar agentes patógenos o  alérgenos que impactarán directamente en nuestra piel. La radiación ultravioleta o incluso la luz que proporcionan los aparatos electrónicos (luz azul) también tienen un impacto en nuestra piel."},
            {"text":"Además debes considerar la calidad de vida: nuestra dieta puede propiciar cierto tipo de enfermedades o condiciones; también el tabaquismo o la falta de ejercicio; la falta de sueño y el estrés continuo tienen un impacto negativo en nuestra salud y en la salud de nuestra piel."},
            {"title":"4. ¿Cómo podemos cuidar de nuestra piel?", "text":"En casa se puede recurrir a sérums o cremas (siempre aprobadas por un especialista)."},
            {"text":"Mientras que en los consultorios puedes encontrar tratamientos no invasivos como la radiofrecuencia localizada, bioestimuladores de colágeno que se inyectan directamente en la piel, peeling o láseres para la firmeza de la piel, toxina botulínica que se utiliza para corregir o disminuir las arrugas y las líneas de expresión, ácido hialurónico para hidratar o voluminizar, entre otros."},
            {"title":"5. ¿Cada cuánto debes consultar con un especialista?", "text":"Lo más recomendable es visitar a tu especialista cada año. Una primera consulta te dará una valoración y consejos o rutinas para cuidar tu piel. Sin embargo, si se tiene una afección las citas serán determinadas por el propio especialista para revisar y supervisar los tratamientos que sean necesarios."},
            {"text":"Lo más recomendable es que al visitar a tu especialista lleves una lista con las dudas o problemas que hayas notado para que sea una consulta aún más productiva. Puede que no se pueda revisar todo, pero también podrás tener una mayor confianza."},
            {"text":"Si quieres consultar a la Dra. Cristina Cortés, lo puedes hacer en la ciudad de Monterrey en la Clínica Valle, San Pedro Garza-García y la Clínica Cumbres, Monterrey."}
        ],
        "author":{
            "name":"Dra. Cristina Cortés",
            "contacts":[
                {social:"Instagram", text:"", link: "https://www.instagram.com/dracristinacortes"},
                {social:"Facebook", text:"", link: "https://www.facebook.com/dermatologademexico/"},
                {social:"Website", text:"", link: "https://​​www.dracristinacortes.com"}
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'skincare-ojos-labios':{
        "image": lifestyle_73,
        "title": "Básicos de skincare para ojos y labios.",
        "pars":[
            {"text":"Durante las temporadas secas y frías del año debemos poner especial atención en los cuidados básicos del skincare para ojos y labios. Aunque su cuidado debe ser tarea de todos los días, siempre será importante tener una rutina de pasos básicos que ayude a cuidarlos."},
            {"text":<img style={{width:'100%'}} alt="Infografía Básicos de skincare para ojos y labios" src={infografia_ojos_labios} />},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'prevenir-cancer-mama':{
        "image": lifestyle_74,
        "title": "Aprendamos juntas a prevenir el cancer de mama",
        "pars":[
            {"text":"El cáncer de mama es el tipo de cáncer que provoca la mayor cantidad de muertes en mujeres. Los expertos todavía no determinan las razones que lo causan; sin embargo, existen diversos factores de riesgo que aumentan las probabilidades de padecerlo. Algunos son genéticos, otros tienen que ver con nuestro estilo de vida."},
            {"text":<img style={{width:'100%'}} alt="Infografía prevenir el cancer de mama" src={infografia_cancer_mama} />},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'mirada-fresca-cautivante':{
        "image": lifestyle_75,
        "title": "5 Tips para lucir una mirada fresca y cautivante",
        "pars":[
            {"text":"Rejuvenecer la mirada y parecer menos cansada es uno de los goals estéticos del momento. Recopilamos los mejores tipos para mantener una mirada fresca, reducir bolsas y ojeras, deshinchar los párpados y mantener saludable tu mirada."},
            {"text":<img style={{width:'100%'}} alt="Infografía prevenir el cancer de mama" src={infografia_mirada_fresca} />},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'preparar-piel-para-invierno':{
        "image": lifestyle_76,
        "title": "¿Cómo preparar la piel para el invierno?",
        "pars":[
            {"text":"Los expertos comparten sus secretos para preparar la piel para el invierno: sueros de vitamina C, ácido hialurónico y FPS. El invierno está a la vuelta de la esquina, y con él, también el clima frío, acompañado de la piel seca, labios partidos, enrojecimiento en los lugares equivocados."},
            {"text":"El invierno está a la vuelta de la esquina, y con él, también el clima frío acompañado de la piel seca, labios partidos, enrojecimiento en los lugares equivocados."},
            {"text":"Durante los meses de invierno, es importante ajustar ligeramente nuestra rutina de la piel. Aquí te compartimos los must para esta temporada: ",
                "list":[
                    "1. Usa varias capas en el cuidado de tu piel: Aplica sueros que atraigan la humedad sobre la piel limpia, como los que contienen ácido hialurónico. Luego, unta un aceite facial encima y añade una crema nutritiva hasta arriba para cerrar el trato.",
                    "2. Pon atención extra en el área de los ojos y labios: Ahí la piel es más delgada y tiene pocas o ninguna glándula sebácea para protegerse. Usa sueros y aceites en el contorno de los ojos que alivian y protegen la piel. En los labios, usa bálsamos oclusivos.",
                    "3. Incluye un suero de vitamina C: Esto estimula la producción de colágeno y regeneración celular. Y, aunque esto ya lo debes tener saber de memoria, recuerda que aún en los meses de frío es importante aplicar tu protector solar todos los días.",
                    "4. Evita desmaquillantes fuertes y con mucho alcohol: Mejor, elige productos en crema o bálsamo. Puedes hacerte una limpieza doble si tu piel está muy seca: usa agua en la primera limpieza, pero que la segunda sea en seco o con algún producto en leche u oleo.",
                    "5. Usa aceite facial en la noche: Busca ingredientes como rosa mosqueta, soya y jojoba, con propiedades sumamente hidratantes."
                ]
            },
            {"text":"El frío puede producir algunas alteraciones en nuestra piel, por lo que es importante que durante el tiempo de invierno sumemos ciertos cuidados a nuestra rutina de skincare. La hidratación o el uso de mascarillas son aspectos esenciales para cuidar la piel durante esta estación del año."},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
            {"text":"S.A (2019). GILA. PROTEGE TU PIEL ESTE INVIERNO.", "link":"https://www.ela.org.mx/2019/01/protege-tu-piel-este-invierno/"},
            {"text":"FMD. (2014).  FUNDACIÓN MEXICANA PARA LA DERMATOLOGÍA. Boletín: Cuidados De La Piel En Época Invernal.", "link":"https://fmd.org.mx/boletin-cuidados-de-la-piel-en-epoca-invernal/"},
        ]
    },
    'reflexiones-antes-fin-anio':{
        "image": lifestyle_77,
        "title": "Reflexiones para antes de fin de año",
        "pars":[
            {"text":"Se acerca el cierre de año y con él llegan muchos momentos de recapitulación, de evaluar lo que hemos hecho en el año, qué propósitos logramos cumplir, cuáles no, qué queremos para el año entrante, entre otras cosas. Por ello, te daremos algunas opciones que puedes considerar en tus reflexiones de fin de año para cerrar con todo el 2022 y comenzar el 2023 con objetivos claros y así lograr tu mejor versión…"},
            {"text":<img style={{width:'100%'}} alt="Infografía prevenir el cancer de mama" src={infografia_reflexiones} />},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'skincare-hombres-basicos-comenzar':{
        "image": lifestyle_78,
        "title": "Skincare para hombres: básicos para comenzar",
        "pars":[
            {"text":"¿Quién dijo que el skincare sólo era para las mujeres? Hoy en BetterSelf te contamos los básicos para que puedas comenzar a cuidar tu piel y consentirte de la mano del Dr. Samuel García, especialista en Medicina Estética; y así tener herramientas para encontrar tu mejor versión. Sigue leyendo y descubre todo lo que platicamos durante la entrevista con el Dr. Samuel."},
            {"title":"¿Cuáles son los signos del envejecimiento más comunes en hombres?",
            "list":[
                "Arrugas y flacidez",
                "Caída del cabello",
                "La deshidratación de la piel por el alcohol y esto hace que los tratamientos estéticos duren menos tiempo."
            ]},
            {
                "title":"¿Qué opciones existen para tratar la flacidez?",
                "text":"Se puede tratar con bioestimuladores de colágeno, lo que hacen es que el cuerpo produzca colágeno de manera extraordinaria. Uno de ellos es la hidroxiapatita de calcio, que reafirma la piel, le da un aspecto y firmeza natural. Es un procedimiento acumulativo y progresivo ya que mejora mes a mes, hasta llegar a los 12 o 18 meses. Se aplica con cánula para hacer vectores o también se puede combinar con aparatología para el mismo propósito, es decir un bioestimulador de colágeno térmico  + un bioestimluador inyectable.",
            },
            {
                "title":"¿Por dónde debo empezar cuando quiero cuidar mi piel y prevenir los signos de envejecimiento?",
                "list":[
                    "Valoración con un médico para tener una rutina de skincare de acuerdo a tu tipo de piel",
                    "Tratamientos y limpieza facial",
                    "Aplicación de toxina botulínica",
                    "Los masajes faciales antes de dormir ayudan un poco pues drenan la saturación de líquidos. Son tratamientos de mantenimiento básicos, es decir funcionan bien como complemento a los cuidados anteriormente mencionados."
                ],
            },
            {
                "title":"¿Qué tratamiento es el más común en hombres?",
                "text":"Los hombres de aproximadamente 35 años de edad suelen hacerse el marcaje mandibular, es ideal ya que una mandíbula marcada es símbolo de una masculinidad y estiliza el rostro. Se hace con hidroxiapatita de calcio, o ácido hialurónico, para definir el arco mandibular y dura hasta 18 meses. Se sugiere hacer un retoque a los 12 meses. Recuerden siempre consultar a un médico, hacerlo con especialistas, capacitados y certificados.",
            },
            {
                "title":"¿Cuáles son las recomendaciones para pieles entre los 20-30 años de edad?",
                "list": [
                    "Protector solar cada 4 horas",
                    "Limpiarse el rostro antes de dormir",
                    "Dosis de toxina botulínica mínimas para prevenir arrugas",
                    "Vitamina C en suero para disminuir la producción de grasa",
                    "Y para la caída del cabello puede ser la aplicación de células madre que ayudan a que el folículo se engrose y alargue el periodo de caída del cabello"
                ],
            },
            {
                "title":"¿Y para pieles, por ejemplo, de entre 60 y 70 años?",
                "list":[
                    "Toxina botulínica, normalmente en dosis mayores",
                    "Hidroxiapatita de calcio y/o ácido hualirónico para armonizar el rostro",
                    "Ácido hialurónico para reponer las pérdidas grasas y ayudar a tensar la piel. Ya que para esa edad la flacidez se vuelve más abrupta que en las mujeres, sirve también para el relleno de ojeras, surcos nasogenianos o para poner un poco de mentón. Este tratamiento tiene una durabilidad menor de entre 9 y 12 meses."
                ],
            },
            {
                "title":"¿Cómo elegir a un profesional de la salud para cuidar la piel?",
                "list":[
                    "Siempre tiene que ser alguien con cédula profesional y su título en el consultorio",
                    "Experiencia en: medicina estética, dermatología o cirugía plástica",
                    "El trabajo que ha hecho o referencias",
                    "Que te dé confianza"
                ],
            },
            {
                "text":"Ahora ya lo sabes, comienza a cuidar tu piel siempre con un profesional de la salud que pueda indicarte lo mejor para tu tipo de piel y necesidades específicas. Recuerda que el cuidado de la piel puede ayudar a nuestro estado de ánimo y a incrementar los niveles de confianza. Nunca es muy tarde para comenzar."
            }
        ],
        "author":{
            "name":"Dr. Samuel García V.",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/samuelgarciadoctore/"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/the_beautycenter/"},
                {social:"Web", text:"", link:"https://thebeautycenter.com.mx/"}
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'redefiniendo-propositos-anio-nuevo':{
        "image": lifestyle_79,
        "title": "Redefiniendo propósitos de año nuevo",
        "pars":[
            {"text":"Estamos a nada de cerrar el año y es momento de comenzar a definir qué propósitos tenemos para el siguiente. Seguramente te estarás proponiendo comer mejor, descansar más, hacer ejercicio, viajar, etc., pero hay un tema que debes considerar si es que aún no está en tu lista."},
            {"text":"La salud mental es un tema de gran importancia y más después de haber pasado por una pandemia mundial. Y si te estás preguntando ¿cómo puedo ponerme como propósito la salud mental para este 2023? Para eso platicamos con la psicóloga clínica hospitalaria, Marta Haces, quien nos cuenta cómo reconocer cuando estamos en equilibrio y cuándo necesitamos priorizar nuestra salud mental."},
            {"title":"¿Qué es la salud mental?",
             "text":"El equilibrio y bienestar que tenga cada persona en cuanto a su historia, desarrollo y logro de metas personales que lo hagan sentir satisfecho. Es el poder tomar decisiones para sacar adelante los problemas, que siempre los hay."},
            {"text":"Se ha hablado mucho de los estragos en la salud mental que dejó la pandemia, pero poco de los beneficios. Y es que, como todo, existe un lado positivo de la situación como lo son: ",
            "list": [
                "Hábitos nuevos",
                "Conductas",
                "Adaptabilidad",
                "Habilidades",
                "Aprendizajes"
            ]},
            {"text":"Por otro lado, se dieron muchos casos de ansiedad. Y la ansiedad también tiene su lado positivo porque es una conducta adaptativa. Es decir, después de todo lo que vivimos es normal pasar por un proceso de ansiedad, es lo que nos hace humanos y nos hace estar conectados con la parte social y emocional de lo que se vivió alrededor del mundo. El problema es cuando se bloquean las funciones de vida como por ejemplo la ansiedad se empieza a manifestar en insomnio, o en depresión, algo que se vuelve recurrente, y puede provocar  que te estanques ahí. A esto se le llama somatizar y es transformar problemas psíquicos en síntomas orgánicos de manera involuntaria, es decir enfermar al organismo."},
            {"text":"Si sigues atravesando por un problema de este tipo o lo que sientes se está somatizando, es importante acudir a una psicoterapia o bien visitar a un psicólogo para revitalizar de nuevo nuestra calidad de vida; hacer ejercicio, comer bien, yoga, etc., ayudan a nuestro  bienestar pero nunca está de más la ayuda de un profesional en el área."},
            {"title":"¿Cómo te puedes dar cuenta de que necesitas ayuda profesional o que tu salud mental está en riesgo? ",
            "list":[
                "Problemas laborales",
                "Problemas en el hogar",
                "Problemas con familiares, amigos o incluso mascotas",
                "Problemas de relaciones o afectos",
                "Problemas de sueño"
            ]},
            {"text":"Recuerda que todos necesitamos terapia, ir al psicólogo es algo normal, no existen personas locas, hay personas enojadas, angustiadas, tristes, etc.  Así es que tomar terapia te puede ayudar a estar en equilibrio, sin duda es algo voluntario pero es una forma de ayudarte a ti mismo y demostrarte ese amor propio y autocuidado."},
            {"text":"Este año nuevo, comienza a pensar también en ti y a priorizar tu salud mental para estar en equilibrio y disfrutar todo lo nuevo que te trae el 2023."}
        ],
        "author":{
            "name":"Martha Haces",
            "contacts":[
                {social:"Facebook",text:"", link:"https://www.facebook.com/CEPCH.SC/"},
                {social:"Web", text:"", link:"https://www.cepch.com.mx/"}
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'russian-lips':{
        "image": lifestyle_80,
        "title": "Russian lips: ¿Qué son y por qué se han vuelto tendencia?",
        "pars":[
            {"text":"Los labios son un símbolo de belleza desde hace tiempo. Cuando sonreímos o hablamos es lo primero que se nota y lo que usualmente se recuerda de una persona. Por eso, constantemente vemos contenidos sobre las rutinas de skincare de las celebs o incluso las tendencias para lucir unos labios hermosos. Acá te contamos todo lo que tienes que saber sobre ellas."},
            {"text":<img style={{width:'100%'}} alt="Infografía prevenir el cancer de mama" src={infografia_labios_rusos} />},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
  'triangulo-de-juventud':{
        "image": lifestyle_81,
    "title": "Todo sobre el triángulo de la juventud",
    "pars":[
            {"text":<img style={{width:'100%'}} alt="Infografía triángulo de la juventud" src={infografia_triangulo_juventud} />},
            ],
    "author":{
            "name":"Betterself",
      "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
                ],
      "links":[

            ]
    },
    "refs":[
        ]
  },
  'relacion-depresion-ansiedad':{
    "image": lifestyle_82,
    "title": "La relación entre la depresión y la ansiedad",
    "pars":[
        {"text": "¿Sabías que en México se estima que aproximadamente 15% de la población padece trastornos de ansiedad generalizada y el 9% padece depresión? 😱😱"},
        {"text": "Hace unos días se conmemoró el día mundial de la lucha contra la depresión (13 de enero), y es por esto que queremos hablar sobre la importancia de la concientización respecto a la salud mental. Para ello invitamos a la Psicoterapeuta Moni León."},
        {
          "title":"¿Qué es la depresión y ansiedad?",
          "text":"Ambas son afecciones de salud mental; aunque son diferentes, presentan varias similitudes y muchas veces suelen ir acompañadas o una como causa de la otra.",
          "list":[
            "Depresión: Se manifiesta como una pérdida de interés y de sentido. Puede ser ocasionada por factores químicos de nuestro cerebro o bien, por situaciones ambientales como las pérdidas, los fracasos, la violencia, entre otros.",
            "Ansiedad: Sensación constante de amenaza, de peligro o miedo que puede ser irracional. En ocasiones puede presentarse como un mecanismo de supervivencia, pero cuando se vuelve crónica y afecta la vida diaria, se considera un trastorno de salud mental. Es un sistema de alerta que se asocia a diferentes estímulos o contextos y puede estar acompañada de síntomas físicos.",
          ]
        },
        {"title":"Síntomas Comunes:",
        "list": [
            "Depresión: Sentimiento de vacío, falta de interés por las actividades, desesperanza, irritabilidad, frustración, entre otras. ",
            "Ansiedad: Insomnio, variaciones en la frecuencia cardiaca, somatización, sensación de amenaza y de alerta, miedo y preocupación."
        ]},
        {"text":"Una persona puede padecer ambas y pueden compartir sintomatología, sin embargo, puede que haya personas que sólo padecen una de ellas. Además, estos trastornos pueden ser consecuencia de causas externas como la violencia, los estereotipos, los estigmas, los problemas de autoestima, las crisis ambientales, la discriminación, etc. Las personas que consumen sustancias suelen ser más vulnerables a padecer estos problemas de salud mental."},
        {"text":"La adolescencia es un momento muy importante para la prevención ya que hay muchísimos cambios a nivel hormonal y físicos en donde los adolescentes buscan su identidad, su espacio seguro, etc. Es un momento de vida muy importante en temas de salud mental y si se interviene oportunamente, es menos probable que se desarrollen estas afecciones en la adultez."},
        {"text":"Después de la pandemia por COVID-19, la depresión y la ansiedad fueron resultado del aislamiento, falta de trabajo o la pérdida de seres queridos, por ello es importante atenderlas ya que incluso pueden impactar significativamente en la tasa de mortalidad."},
        {"title":"¿Cómo tratar la depresión y ansiedad?", "text":"Lo indicado sería primero recurrir a un programa de psicoterapia. Hay enfoques que ayudan, como la terapia cognitivo-conductual o la terapia narrativa; pero cuando ya hay un desajuste a nivel químico es momento de tener una valoración con un psiquiatra. Para estos casos es necesaria la terapia dual, es decir con medicación y psicoterapia. Conforme la persona avanza en el proceso se puede retirar la medicación, pero en ocasiones no hay que dejarla a un lado hasta poder estar en un mejor lugar."},
        {"title":"¿Cómo ayudar a alguien que padece estos problemas?", "text":"Hay que tener la capacidad de escucha, el acompañamiento es súper importante, no minimizar lo que siente la persona con un “échale ganas” o “ya no estés triste”. Una de las mayores constantes en personas con depresión o ansiedad es el sentimiento de soledad."},
        {"text":"También es recomendable identificar las redes de apoyo, ya sea en la familia, amigos, instancias de salud, entre otros."},
        {"text":"Hay call centers en distintas universidades que pueden ayudarte con atención psicológica gratuita o instancias de gobierno con programas de salud mental."},
        {"title":"Consejo si aún no tomas terapia o no has pedido ayuda: ",
        "list":[
            "Considera que la salud mental es tan importante como la salud física y se necesita tener equilibrio en ambas para tener bienestar.",
            "El ejercicio físico. tiene un impacto positivo en la salud mental.",
            "No es tu culpa sentirte así, estás atravesando por un mal momento pero ese mal momento va a pasar si buscas ayuda o bien si tienes una red de apoyo.",
            "Normaliza tus emociones, mantente abierto ya que están ahí por algo y tienen algo que comunicar."
        ]},
        {"text":"Recuerda que no es necesario esperar a que algo malo nos ocurra para acudir a terapia psicológica. En ciertos momentos, solamente necesitamos a alguien que nos escuche, que no nos juzgue y que nos ayude a desarrollar estrategias para comprender nuestras emociones."}
    ],
    "author":{
      "name":"Psicoterapeuta Mónica León",
      "contacts":[
        {social:"Web",text:"", link:"https://conversacionesconstructivas.com"},
      ],
      "links":[

      ]
    },
    "refs":[
        ]
  },
  'que-es-skin-quality':{
      "image": lifestyle_83,
    "title": "Skin Quality",
    "pars":[
        {"text":"¿Qué se interpone entre tú y una piel radiante? Si necesitas consejos para mejorar la relación con tu piel la Dra. América Vilchez, médico cirujano y especialista en medicina estética; nos comparte las señales a las que hay que prestar atención y sus mejores tips para tener una piel increíble."},
        {"title":"Skin quality, ¿qué es?","text":"Es el estado general de la piel que se obtiene al analizar diferentes factores como su color, elasticidad, microorganismos naturales y la genética de cada persona."},
        {"text":<img style={{width:'100%'}} alt="Infografía triángulo de la juventud" src={infografia_skin_quality} />},
        ],
    "author":{
        "name":"Betterself",
      "contacts":[
          {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
          {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
          ],
      "links":[

        ]
    },
    "refs":[
      ]
  },
  'prevenir-envejecimiento-piel-hermosa':{
      "image": lifestyle_84,
    "title": "Cómo prevenir el envejecimiento y lucir una piel hermosa",
    "pars":[
        {"text":"Cuando hablamos del cuidado de la piel, tod@s queremos conocer el secreto de la eterna juventud. Queremos todos los beneficios en pocos o sencillos pasos y lo cierto es que, como todo; requiere constancia, cuidado y atención."},
        {"text":"Platicamos con la Dra. América Vilchez, médico cirujano y especialista en medicina estética; quien nos contó la verdad sobre cómo tener una piel sana, con calidad y prevenir el envejecimiento. Además nos contó la verdad sobre el mito #1 en consulta."},
        {"text":<img style={{width:'100%'}} alt="Infografía Prevenir Envejecimiento y lucir una piel hermosa" src={infografia_envejecimiento} />},
        ],
    "author":{
        "name":"Betterself",
      "contacts":[
          {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
          {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
          ],
      "links":[

        ]
    },
    "refs":[
      ]
  },
  'mujeres-alzan-voz':{
      "image": lifestyle_85,
    "title": "Cuando las mujeres alzan la voz",
    "pars":[
        {"text":"Se acerca el día de la mujer y en estos días escuchamos mucho del empoderamiento femenino y de mujeres emprendedoras, que aunque parecen conceptos simples, no lo son y han trascendido a lo largo de la historia de la humanidad.  Para hablar de este tema y compartirte algunos consejos de cómo ser una mujer empoderada y/o emprendedora platicamos con Fernanda Ortega, Social Media Manager de Unidas Mujeres Poderosas y Cone, CEO de Unidas Mujeres Poderosas."},
        {"title":"¿Qué es Unidas Mujeres Poderosas y cuál es su propósito?", "text":"Unidas Mujeres Poderosas es una comunidad que se encarga de impulsar a las mujeres en sus negocios. El propósito es crear alianzas, conocer  personas, sobre todo mujeres; para hacer que sus negocios crezcan y a la par empoderarlas en otros aspectos de sus vidas, emocional y psicológicamente, para impulsarlas en sus emprendimientos."},
        {"title":"¿De dónde viene esta falta de empoderamiento en la historia de la mujer?", "text":"A lo largo de la historia, las mujeres han enfrentado barreras sociales, culturales y económicas que han limitado su capacidad para desarrollarse plenamente en el ámbito laboral. La falta de empoderamiento en las mujeres ha sido resultado, muchas veces, del machismo en México; que ha impedido el avance y la expresión de lo que realmente son las mujeres. Los roles impuestos por el sistema patriarcal también han sido un obstáculo para el empoderamiento de las mujeres. Y aunque esto ha ido cambiando con los años, es algo que ha ido heredándose en menor medida generación tras generación. "},
        {"title":"Ok, pero el empoderamiento es ¿un trabajo individual o también la sociedad influye?", "text":"El empoderamiento es un trabajo tanto interno como social. Para empoderarse, es importante trabajar en uno mismo para salir de la zona de confort y luchar contra los obstáculos externos. La eliminación del pensamiento \"no puedo\" y la muestra de lo que una mujer puede hacer por sí misma son importantes para empoderarse."},
        {"text":"En Unidas, se deja la creencia de competir entre mujeres y se busca la unión y la colaboración para hacer más, ser más poderosas y más fuertes."},
        {"title":"Ser una mujer empoderada o ser una mujer poderosa, ¿cuál es la diferencia? ",
          "list":[
            "Mujer empoderada: Es un proceso que empieza en uno mismo al reconocer tus capacidades y tu valor.",
            "Mujer poderosa: Es cuando se entiende el proceso y comienzas a actuar para ser mejor en la vida personal y profesional."
          ],
        },
        {"text":"Recuerda que poderosas somos todas, por el simple hecho de ser mujer."},
        {"title":"Consejos para ser una mujer emprendedora:", "list":[
          "Perder el miedo, dejar de creer que no eres capaz y aceptar que vas a fracasar. ",
          "Juntarte con una comunidad que te sume y no te reste.",
          "Enriquecerse con nuevo conocimiento.",
          "Salir de tu zona de confort.",
          "Sentirte bien contigo mismo.",
          "Haz lo que tú quieras hacer pero sé la mejor.",
          "Trabaja en ti y rompe las limitaciones."
        ]},
        {"text":"El Instagram Unidas - Mujeres Poderosas crea contenido gratuito de utilidad para la comunidad por medio de alianzas con otras mujeres que quieren compartir sus conocimientos y experiencias. De esta manera, ayudan a las mujeres emprendedoras que necesitan sanar, crecer su negocio y empoderarse. No importa desde donde estés, siempre tendrás la información a tu alcance y una comunidad con la cual compartir tu experiencia y proceso."},
        ],
    "author":{
        "name":"Unidas Mujeres Poderosas",
      "contacts":[
          {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
          {social:"Facebook",text:"", link:"https://www.instagram.com/unidasmujerespoderosas/?hl=es"},
          {social:"Web", text:"", link:"https://unidasmujerespoderosas.com/"},
          {social:"Instagram", text:"", link:"https://instagram.com/fernandaortegai?igshid=NDk5N2NlZjQ="},
          {social:"Instagram", text:"", link:"https://instagram.com/cone.kinetic?igshid=NDk5N2NlZjQ="}
          ],
      "links":[

        ]
    },
    "refs":[
      ]
  },
  'cuidados-piel-menopausia':{
      "image": lifestyle_86,
    "title": "Cuidados específicos para una piel en menopausia",
    "pars":[
        {"text":"La menopausia es un proceso natural en la vida de las mujeres que además de marcar el final de la menstruación y la fertilidad de una mujer, también implica una serie de cambios hormonales y fisiológicos que afectan a todo su cuerpo, incluyendo la piel."},
        {"text":"Por ello platicamos con una experta sobre estos cambios, la Dra. Daniela Lara Del Valle. Dermatóloga con amplia experiencia en restauración estética facial en la mujer madura."},
        {"title":"¿Qué le pasa a la piel durante la menopausia?",
          "list": [
          "Pérdida gradual de su capacidad para retener la humedad.",
          "Disminución de la producción de colágeno.",
          "Aparición de arrugas, flacidez y sequedad."
        ]},
        {"text":"Estos cambios suelen ser relativamente bruscos y acelerados."},
        {"title":"¿Cómo cuidar de la piel en esta etapa?", "text":"Es importante consultar con los profesionales de la salud, así, el tratamiento será personalizado y se podrá atenuar la aparición de estos cambios donde el hipoestrogenismo está tan marcado."},
        {"text":"Algunas recomendaciones básicas son:",
          "list":[
          "Una sana alimentación y tomar suficiente agua.",
          <ul>
            <li>Dermolimpiador micelar o suave</li>
            <li>Un sérum de vitamina C</li>
            <li>Fotoprotector mineral ideal para el tipo de piel</li>
            <li>Complementar con suplementos vitamínicos y antioxidantes</li>
          </ul>
        ]
        },
        {"text":<p style={{color:'#DCB8A4', textAlign:"center"}}>“La piel se hereda pero tú decides qué calidad darle con los cuidados y hábitos que mantengas.”</p>},
        {"title":"Hablemos del contorno facial y el envejecimiento", "text":"El proceso de envejecimiento es sistemático, integral y multinivel. En el rostro, los cambios comienzan desde la profundidad, afectando los huesos de la cara, ocurriendo resorción ósea (pérdida de tejidos óseos)."},
        {"text":"Por eso el rostro cambia conforme envejecemos y se puede notar en:", list:[
          "Arco de la ceja: los párpados y la ceja misma tienen una apariencia más baja de la que solían tener.",
          "Aparición de los surcos nasogenianos y “pliegues de marioneta”.",
          "La piel en la zona de la mandíbula se puede comenzar a “colgar”.",
          ["Pómulos: cuando pierden volumen óseo contribuyen a que el tercio medio e inferior del rostro desciendan y ",<a href="/lifestyle/triangulo-de-juventud" target="_blank" rel="noopener noreferrer" className="texto-pink">el triángulo de la juventud</a>," comienza a invertirse."]
        ]},
        {"title":"Entonces ¿Cómo mejorar el contorno facial?",
          "text":" Es posible mejorarlo con el reposicionamiento de tejidos. Cada paciente es diferente, por lo tanto, sus alternativas también:",
          "list": [
          "Lifting quirúrgico, es la solución más adecuada para reposicionar tejidos con demasiada pérdida de volumen y flacidez.",
          "Rellenos de ácido hialurónico o bioestimulantes con hidroxiapatita de calcio que además de reposicionar dan estructura y revitalizan la piel.",
          "Con tecnología como el ultrasonido micro-focalizado de alta intensidad con visualización, por su propia seguridad, al cual conocemos como Ultherapy®; que es una opción de lifting facial no quirúrgico que estimula la producción de colágeno por efecto térmico."
        ]
        },
        {"text":"Tip: este tipo de tratamiento mínimamente invasivo, sumado al uso de bioestimuladores pueden dar resultados excelentes y naturales."},
        {"text":<p style={{color:'#DCB8A4', textAlign:"center"}}>“Un buen producto y en las mejores manos, te garantizará buenos resultados”</p>},

        {"title":["Tratamientos con ", <a target="_blank" rel="noopener noreferrer" href="/cosmetics/acido-hialuronico-que-hace" className="texto-pink">ácido hialurónico</a>," ¿en qué consisten y para qué tipo de necesidades de la piel están indicados?"],
          "text":"El ácido hialurónico es una molécula que existe de manera natural en todos nuestros órganos y es de gran importancia en nuestra piel, manteniéndola hidratada."
        },
        {"text":<p style={{color:'#DCB8A4', textAlign:"center"}}>“Todos los tipos de piel pueden ser candidatos a la aplicación de ácido hialurónico a partir de la mayoría de edad.”</p>},
        {"text":"En medicina estética existen desde emulsiones tópicas en el rostro hasta productos inyectables con ácido hialurónico de alto o bajo peso molecular y reticulados que entrecruzan las cadenas de las moléculas y aumentan su densidad."},
        {"text":"Éstos se aplican en el rostro en puntos estratégicos para:", "list":[
          "Mejorar asimetrías",
          "Generar un efecto lifting",
          "Contribuir a restaurar el óvalo facial",
        ]},
        {"text":"Con el producto y los cuidados adecuados, el efecto puede durar un año y en algunos casos hasta 18 meses pues existen ácidos hialurónicos birreticulados, en donde el proceso de entrecruzamiento es doble: añaden cadenas de bajo y alto peso molecular para que la degradación del producto tarde más de 12 meses."},
        {"text":<p style={{color:'#DCB8A4', textAlign:"center"}}>“Me encanta una marca en particular de ácido hialurónico hecha en Alemania que es birreticulado y que tiene glicerol en su formulación, molécula que hidrata muy bonito los labios.” </p>},
        {"text":"La belleza no es propia de una edad, sino de tener adecuadas proporciones en nuestro rostro y una piel de calidad. Actualmente se puede lograr con el uso de los productos adecuados y de herramientas de beautificación. Siempre busca profesionales certificados que tengan experiencia en sacar la mejor versión de ti. Tu seguridad y confianza son indispensables."},
        ],
    "author":{
        "name":"Dra. Daniela Lara Del Valle.",
      "contacts":[
          {social:"Instagram",text:"", link:"https://www.instagram.com/dermantra/"},
          {social:"Facebook",text:"", link:"https://www.facebook.com/dermantra"},
          {social:"Web", text:"", link:"http://www.dermantra.com"},
      ],
      "links":[

        ]
    },
    "refs":[
      ]
  },
    'tips-saludable-cualquier-edad':{
        "image": lifestyle_87,
        "title": "7 tips para mantenerse saludable sin importar la edad",
        "pars":[
            {"text":"¿Cómo te imaginas a futuro, en 10, 20 ó 30 años? Algunos ni siquiera quieren pensarlo, mientras que otros tienen la idea de que su vida irá en declive para entonces."},
            {"text":"Para cambiar esta perspectiva, hemos platicado con Martha Patricia Castañeda, mejor conocida como @lannonafit en Instagram y TikTok. Ella demuestra constantemente que esta etapa de la vida sigue llena de nuevas oportunidades y que los buenos hábitos son clave para mantener una buena salud. Como ella suele decir: "},
            {"text":<p style={{color:'#DCB8A4', textAlign:"center"}}>“La tercera edad es una oportunidad, no una condena.”</p>},
            {"text":"Te compartimos los 7 tips que nos compartió para conmemorar el Día Mundial de la Salud:",
                "list":[
                "1. Deja de fumar: Para la nonna, abandonar este hábito fue clave en el comienzo de una vida más saludable pues suele tener un efecto sobre cómo percibes el sabor de los alimentos y en cómo trabaja tu metabolismo.",
                "2. Cambia la mentalidad: Si piensas que tu vida termina al llegar a los 60 años, sólo te enfocarás en aquello que reafirme esta opinión. En cambio, intenta prevenir y pensar qué vivirás el resto de tu vida de la mejor forma posible.",
                "3. Alimentación e hidratación consciente: Incluye alimentos que sabes que le hacen bien a tu cuerpo, como las frutas, verduras y la carne; las porciones van a depender de tus gustos y necesidades nutrimentales. Disfruta de la comida y de tus antojos sin culpa pero con moderación. Una buena alimentación e hidratación también son un buen tip de belleza pues pueden ayudar a sentirte con más energía y mejorar el aspecto de tu piel.",
                "4. Encuentra un impulso o motivación y persiste: En su caso, Martha eligió hacer deporte por una cuestión más emocional,  una nuera suya estaba pasando por un tratamiento contra el cáncer de mama y decidió hacer un tributo de vida, esa fue su primera motivación. Ella entiende que hay momentos en los que quieres abandonar todo, pero persistir y contar con el apoyo de otra persona es muy importante al crear un hábito. Lo que nos lleva al siguiente tip.",
                "5. Ve paso a paso: La constancia hace de motivación cuando ésta se acaba. Por ejemplo si quieres tener la rutina de caminar ponte metas que puedas cumplir y determina un periodo de tiempo en el que no puedes fallar. Al terminar ese plazo de tiempo es tu decisión continuar o abandonar e intentar otra cosa.",
                "6. Prepara tu ropa un día antes. No solo elijas la ropa que te pondrás mañana, decide también qué ropa te pondrás para hacer ejercicio, eso ahorra tiempo y puede funcionar como motivación.",
                "7. Cuida tu salud mental. Este es un ámbito muy personal para Martha; para ella tomar terapia psicológica le ha ayudado a superar momentos difíciles. Su consejo es que encuentres aquello que funcione contigo, ya sea meditando, con psicólogos, psiquiatras, chamanes, es válido siempre y cuando te ayude. Como la nonna dice: “No puedes andar con tantas cargas mentales sin que te afecten físicamente.”"
            ]
            },
            {"text":"El Día Mundial de la Salud nos recuerda la importancia de tomar acción sobre ciertos aspectos en nuestras vidas y entornos, eso incluye prevenir e implementar buenos hábitos que nos ayudarán en el futuro. Martha también nos recuerda que la vida no se puede apreciar completamente si hay algo que nos mantiene enfocados en lo negativo por mucho tiempo, agradece las experiencias vividas y continúa hacia adelante. Concluimos este blog con una frase que nos encantó de ella:"},
            {"text":<p style={{color:'#DCB8A4', textAlign:"center"}}>“No nos encariñemos con los dolores o con el sufrimiento, existen, son parte de la vida; pero si no se encariña uno con ellos pasan más rápido, nada es para siempre.”</p>},
            ],
        "author":{
            "name":"Martha Patricia Castañeda. @lanonnafit",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/lanonnafit/"},
                {social:"Web", text:"", link:"https://www.tiktok.com/@lanonnafit?_t=8atUeC9QrOX&_r=1"},
                ],
            "links":[
            ]
        },
        "refs":[
        ]
    },
    'preguntar-especialista-piel-parte-1':{
        "image": lifestyle_88,
        "title": ["Lo que debes preguntar en una primera cita con un especialista en piel",<br/>,"(Parte 1)"],
        "pars":[
            {"text":"La piel es nuestra carta de presentación ante los demás, nada mejor que habitar una piel sana que eleve nuestra confianza. Por ello la Dra. Victoria de la Fuente, dermatóloga con amplia experiencia en este campo. nos compartió algunos datos sobre la piel y tratamientos por los que puedes preguntar durante tu consulta médica para que comiences tu viaje hacia una piel de calidad."},
            {"text":<img style={{width:'100%'}} alt="Infografía Lo que debes preguntar en una primera cita con un especialista en piel (Parte I)" src={infografia_piel_parte_1} />},
            ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
                ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'preguntar-especialista-piel-parte-2':{
        "image": lifestyle_89,
        "title": ["Lo que debes preguntar en una primera cita con un especialista en piel",<br/>,"(Parte 2)"],
        "pars":[
            {"text":"La piel es nuestra carta de presentación ante los demás, nada mejor que habitar una piel sana que eleve nuestra confianza. Por ello la Dra. Victoria de la Fuente, dermatóloga con amplia experiencia en este campo. nos compartió algunos datos sobre la piel y tratamientos por los que puedes preguntar durante tu consulta médica para que comiences tu viaje hacia una piel de calidad."},
            {"text":<img style={{width:'100%'}} alt="Lo que debes preguntar en una primera cita con un especialista en piel (Parte 2)" src={infografia_piel_parte_2} />},
            ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
                ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'lucir-aspecto-natural-fresco':{
        "image": lifestyle_90,
        "title": ["Cómo lucir un aspecto",<br/>,"natural y fresco"],
        "pars":[
            {"text":"Sabemos lo importante que es mirarse al espejo y ver una versión que refleje tu energía y vitalidad, por ello platicamos con la Dra. Kassandra Hernández, una máster en Medicina Estética y Regeneración Celular quién nos ayudó a identificar algunos factores que afectan a la piel y cómo prevenirlos."},
            {"text":<img style={{width:'100%'}} alt="Cómo lucir un aspecto natural y fresco" src={infografia_aspectonaturalyfresco} />},
            ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
                ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'depresion-post-parto':{
        "image": lifestyle_91,
        "title": ["Depresión post-parto o Baby Blues"],
        "pars":[
            {"text":"Con el nacimiento de un bebé suelen llegar grandes cambios emocionales y físicos, sobre todo en las primeras semanas después de dar a luz.  En algunos casos, los sentimientos negativos suelen permanecer por mucho más tiempo, llegando a desarrollar depresión y ansiedad a niveles muy profundos que no permiten disfrutar la llegada del nuevo miembro de la familia."},
            {"text":"En esta ocasión decidimos tener una perspectiva más personal sobre el tema. Para ello invitamos a platicar con nosotros a Mónica Cantú. Ella padeció depresión post parto al tener a su primer hijo, su experiencia se complicó más porque nadie le había platicado sobre la posibilidad de desarrollar depresión post parto. La siguiente información que nos compartió es resultado de su propia investigación en el tema, junto con la experiencia a lo largo de ser madre de tres hijos y de consultas con diferentes médicos."},
            {"title":"Algunos síntomas de Baby blues son: ", "list":[
                "Mucho llanto y tristeza: De forma interna, el cuerpo sufre una descompensación química sobre todo a nivel hormonal. “Es un proceso tan hormonal que en ocasiones puedes llorar sin tener una razón clara del por qué lo haces”",
                "Problemas al vincularse con el bebé: Incluso si es un hijo planeado y su llegada se esperaba con emoción, puede que la nueva mamá no quiera hacerse cargo o pasar tiempo a solas con él.",
                "Sentimientos de incapacidad y ansiedad: “No logro calmar al bebé” “No puedo bañarlo yo sola por que siento que puede ocurrir un accidente”, son sólo algunas frases que pueden bajar la autoestima y la seguridad en ella misma para encargarse del cuidado del bebé."
            ]},
            {"title":"El entorno también puede afectar a las mujeres con depresión post parto, de diversas formas:","list":[
                "Redes sociales en donde hay mujeres luciendo cuerpos espectaculares a pesar de haber dado a luz hace unas semanas; mensajes que hablan más de recuperar algo que perdiste, “recupera tu figura”, más que aceptar las transformaciones, el convertirse en una mujer distinta.",
                "Una maternidad idealizada: de amigos o familiares haciendo comparaciones, o críticas, lo que las lleva a desarrollar sentimientos de culpa por no cumplir con las expectativas de cómo debería ser una madre en esa etapa. El poder querer y entender a un hijo toma su tiempo."
            ]},
            {"title":"¿Solo las mamás primerizas pueden padecer baby blues?", "text":"A pesar de que las madres primerizas son más propensas a padecer depresión post parto, la respuesta es no. Cada hijo se puede interpretar de forma diferente, por ejemplo, algunas mujeres pueden sufrir de este tipo de depresión cuando nace su último hijo, porqué será la última vez que carguen a un recién nacido propio."},
            {"text":"También depende mucho de la experiencia de parto, si fue de forma natural o cesárea, y de la recuperación post parto, por ejemplo si tomó mucho tiempo en reponerse o se experimentó mucho dolor."},
            {"title":"¿Qué pasa si no se recibe ayuda de forma oportuna?","text":"Algunas de sus consecuencias pueden poner en riesgo a: ", "list":[
                "La calidad de vida tanto de la madre como la de su bebé. Incluso hay un riesgo de muerte.",
                "La relación de pareja, ocasionando distanciamiento o resentimiento porque la otra persona está adaptándose más rápido a esta etapa."
            ]},
            {"title":"Formas de prevención:",
                "text":"Aunque no existe un tratamiento que evite a las mujeres padecer este tipo de depresión, sí se puede evitar que se agrave."},
            {"text":"Estar informados sobre los focos rojos suele ser una buena forma de prepararse para esta etapa post parto. Esto no aplica sólo para las mujeres, cualquier persona puede aprender un poco de este tema y poder ser más comprensibles con una mujer que tenga este padecimiento."},
            {"text":"Crear una red de apoyo de mujeres que hayan tenido hijos recientemente, entre todas pueden ayudarse a entender que es normal sentirse de cierta manera, que es un proceso que toma tiempo y que lo que están viviendo se puede resolver."},
            {"text":"Los médicos también tienen un papel importante en la detección de signos que puedan denotar depresión post parto."},
            {"title":"¿De qué forma una mamá puede afrontar los sentimientos negativos?", "list": [
                "Aprender a reconocerlos: Estar muy alerta de sí misma, identificar lo que es y no es normal en su personalidad o en su comportamiento. Si eres madre y te encuentras en esta etapa, pregúntate ¿Cómo te sientes con el bebé? ¿Cómo te sientes contigo misma?",
                "Identificar la causa de esos sentimientos, entenderlos y tratar de darles solución, no reprimirlos. Ponerles nombre y dejar que ocupen un espacio en tu mente puede ayudar a liberarlos, como quitarse un peso de encima.",
                "Tener mucha paciencia y amor propio: aceptar esos pensamientos y escucharlos, se vale reconocer ese cansancio, ese enojo, esa tristeza. Es válido pedir tiempo para sí misma.",
                "Vincularse con personas que están pasando por la misma etapa también suele ayudar a aceptar estos sentimientos y a sanar. He ahí la importancia de las redes de apoyo.",
                "Acudir con un médico: Los profesionales de la salud especializados en ginecología, pediatría o psiquiatría evaluarán cada caso y ellos determinarán si es necesario recetar antidepresivos que no interfieran con la lactancia. Es totalmente válido pedir valoraciones de distintos médicos.",
                "Es importante no automedicarse."
            ]},
            {"title":"¿Cómo podemos ayudar a alguien con depresión post parto?",
                "text":"No hay una receta que aplique en general, lo más recomendable es preguntar ¿qué es lo que necesita? Si puedes realizarlo adelante, muestra tu apoyo siempre desde una posición de respeto, comprensión y sin juicios negativos."},
            {"text":"Cada madre tiene su forma de disfrutar y vivir su maternidad, pero no es un trabajo con el que deban de cargar ellas solas."}
        ],
        "author":{
            "name":"Mónica Cantú",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/demiparami.mc/"},
                ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'alimentacion-balance-hormonal-2':{
        "image": lifestyle_93,
        "title": ["La relación de la alimentación con el balance hormonal y la tiroides", <br/>, "Parte II"],
        "pars":[
            {"text":"Esta es la parte II de la plática que tuvimos con Lorena Torres, ella es nutrióloga, especialista en salud intestinal y balance hormonal que cuenta con más de 10 años de experiencia en tiroides. Ella nos comparte algunas cosas que debes tomar en cuenta para saber si tu tiroides está funcionando de manera correcta y cómo puedes cuidar su funcionamiento."},
            {"title":"Factores que pueden causar el mal funcionamiento de la tiroides.", "list":[
                    "Predisposición genética. Hay mayor probabilidad si algún familiar del lado materno también tiene alguna enfermedad relacionada con la tiroides.",
                    "Estrés: ya sea a nivel fisiológico o emocional.",
                    "Mala calidad en la nutrición, hidratación o de sueño."
                ]},
            {"text":"⚠️ OJO: las pastillas para adelgazar y las anticonceptivas también pueden propiciar el desarrollo de problemas en el funcionamiento de la tiroides. "},
            {"title":"¿Cómo se detectan los tipos de enfermedades de la tiroides?", "text":"Es necesario que el profesional de la salud indique realizar un ultrasonido de tiroides y un análisis de laboratorio con un perfil tiroideo completo que incluya los niveles de:"},
            {"list":[
                    "TSH: hormona estimulante de la tiroides.",
                    "Hormonas T3 y T4 libres.",
                    "Anticuerpos antitiroideos."
                ]},
            {"title":"¿Qué padecimientos se pueden detectar con estos análisis?",
                "list": [
                    "Hipertiroidismo: una mayor producción de hormonas que ocasionan una sobreestimulación en el resto de los órganos corporales. Suelen aumentar la frecuencia cardiaca, la temperatura del cuerpo y la sudoración.",
                    "Hipotiroidismo: disminución en la producción o funcionalidad de las hormonas tiroideas en los órganos del cuerpo. Tiende a bajar la frecuencia cardiaca y la temperatura corporal."
                ]
            },
            {"title":"Tipos de hipotiroidismo:" , "list":[
                    "1.- Subclínico: Sólo una de las tres principales hormonas tiroideas están fuera de rango. Ya sea la hormona TSH, T3 o T4. Con mayor frecuencia la TSH, que es la hormona estimulante de la tiroides y por lo tanto la más sensible a cambios.",
                    "2.- Clínico: Dos o más de estas hormonas están fuera de rango.",
                    "3.- Hipotiroidismo de Hashimoto: El nivel de anticuerpos anti-tiroideos es elevado. Esto tiende a inflamar y a atacar la propia glándula, provocando hipotiroidismo en el corto, mediano o largo plazo."
                ]
            },
            {"text":"Los primeros dos tipos se tratan de un desbalance hormonal, mientras que el tercero ya es considerada como una enfermedad autoinmune."},
            {"text":"“Los padecimientos de tiroides no se curan, se controlan”"},
            {"title":"El hipotiroidismo se refleja en una serie de cambios:", "list":[
                    "A nivel de la piel: la producción de colágeno y la elastina disminuyen. La piel se vuelve reseca, en casos con Hashimoto, las pieles se vuelven propensas a la rosácea.",
                    "En el sistema digestivo: No tenemos la misma eficiencia para digerir los alimentos y aumentan los malestares como: gastritis, estreñimiento, reflujo, entre otros.",
                    "De forma emocional: disminución en la dopamina y serotonina, que son neurotransmisores relacionados al bienestar y la felicidad, con periodos de irritabilidad, depresión leve o tristeza profunda. "
                ]
            },
            {"title":"¿Cómo cuidar de la tiroides desde la alimentación?", "text":"No hay un alimento que por sí solo genere un desbalance hormonal en la tiroides, más bien es la cantidad y la frecuencia de consumo."},
            {"text":"Tip: Evitar consumir en medida de lo posible lácteos y gluten."},
            {"text":"Incluir alimentos vegetales: Variedad de verduras, frutas, leguminosas y cereales,  pues ayudan a tener una microbiota intestinal más diversa que absorba de mejor manera los nutrientes."},
            {"text": "Nota: Mientras más variada y completa sea tu alimentación, tu estado de nutrición será más óptimo y en consecuencia, te sentirás mejor."},
            {"text":"Los cambios en nuestros hábitos no suceden de la noche a la mañana, pero pueden ser más fáciles de implementar si cuentas con el apoyo y guía de un especialista en nutrición. Su papel es precisamente que puedas aprender o reaprender a disfrutar de la comida, que logres una buena alimentación que te brinde todos los nutrientes necesarios y darte recomendaciones que puedan ayudarte a llevar un estilo de vida sostenible que sirva a largo plazo."},
            {"text":"Como nutrióloga, Lorena está convencida que la nutrición es la base para poder alcanzar una versión balanceada y más feliz, a su manera, sin extremos ni restricciones."},
            {"text":"Si quieres lograr sentirte bien, procura cuidar tu alimentación y estilo de vida en la medida de tus posibilidades."},
            {"text":"¿Te gustaría conocer más tips de la Lic. Lorena Torres? Puedes seguirla en sus redes sociales:"}
        ],
        "author":{
            "name":"Lorena Torres",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/lore_nutrition/?igshid=YmMyMTA2M2Y%3D"},
                {social:"Facebook", text:"", link:"https://www.facebook.com/lorenatorresnutricion"}
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'alimentacion-balance-hormonal-1':{
        "image": lifestyle_92,
        "title": ["La relación de la alimentación con el balance hormonal y la tiroides", <br/>, "Parte I"],
        "pars":[
            {"text":"¿Te ha pasado que te recomiendan una dieta “buenísima” para perder peso y después de probarla por un tiempo parece que no obtienes resultados? Más allá del éxito de una dieta, lo importante es tener una alimentación consciente, saber qué comidas nos ayudarán, pues los órganos de nuestro cuerpo dependen mucho de ello para funcionar de forma óptima y sentirnos bien."},
            {"text":"Dentro de nuestro cuerpo todo se encuentra conectado de forma increíble. Para demostrarlo, hoy nos enfocaremos en la tiroides, un órgano pequeño pero con una función enorme. Por ello platicamos con Lorena Torres, nutrióloga, especialista en salud intestinal y balance hormonal que cuenta con más de 10 años de experiencia en el tema. \n"},
            {"text":"A Lorena le apasiona apoyar en el mejoramiento de la salud y calidad de vida de las personas, más allá del peso o composición corporal, logrando un enfoque integral. Por ello nos ayudó a entender mejor la importancia de la tiroides para el resto del cuerpo y cómo la alimentación puede influir en su funcionamiento."},
            {"title":"¿Qué es la tiroides?",
                "text":"Es una glándula pequeña que se encuentra a la altura del cuello, debajo de la manzana de Adán."},
            {"text":"Se encarga de producir y mantener un balance de hormonas que ayudan a la correcta función de nuestro metabolismo. Es decir, de todas las funciones que están conectadas en nuestros diferentes sistemas: nervioso, digestivo, respiratorio, cardiovascular, por mencionar algunos."},
            {"text":"Todo esto con el objetivo de que te sientas bien."},
            {"title":"Principales red flags que nos pueden ayudar a detectar si hay un mal funcionamiento de la tiroides:",
                "list":[
                    "Cansancio excesivo",
                    "Pérdida de nuestra capacidad de atención",
                    "Cambios en la calidad de sueño",
                    "Cambios drásticos de humor",
                    "Síntomas digestivos como: gastritis, reflujo, estreñimiento",
                    "Tener manos o pies fríos",
                    "Resequedad en la piel",
                    "Pérdida de cabello",
                    "Uñas quebradizas",
                    "En el caso de las mujeres: alteraciones en el ciclo menstrual"
                ]
            },
            {"text":"Si presentas dos o más de estos síntomas, es recomendable acudir a consulta con un especialista en salud para obtener un diagnóstico personalizado."},
            {"text": "Para saber más sobre el funcionamiento de tu tiroides, no te pierdas la parte II de este blog."}
        ],
        "author":{
            "name":"Lorena Torres",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/lore_nutrition/?igshid=YmMyMTA2M2Y%3D"},
                {social:"Facebook", text:"", link:"https://www.facebook.com/lorenatorresnutricion"}
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'amor-colores-cuidado-piel':{
        "image": lifestyle_94,
        "title": ["El amor es de colores y el cuidado de la piel también"],
        "pars":[
            {"text":"Amar libremente es romper las barreras de género. Amar libremente también implica cuidarse a uno mismo. Una buena forma de expresar ese amor propio es en la piel. Para hablar de este tema, invitamos al Dr. Emimael Flores, cirujano estético con especialidad en medicina estética y director de Perfect Skin México, quien al igual que nosotros apoya la idea de que cada vez más personas se liberen de estereotipos o etiquetas y decidan darle más importancia a su cuidado personal, que no sólo les brinde salud, también bienestar y confianza para ser ellas mismas."},
            {"title":"Por una belleza llena de salud y sin barreras.", "text":"Al ser el órgano más grande del cuerpo, todas las personas tenemos la obligación de cuidar nuestra piel con el fin de preservar la salud. Su importancia va más allá de una cuestión de género."},
            {"title":"Una forma de amor propio.", "text":"Hoy en día, la frase “quiero envejecer con dignidad” toma otro significado que va más ligado al amor propio, pues se trata de cuidar nuestra piel, mantenerla sana por mucho más tiempo, para sentirnos bien y que no la afecten demasiado factores como: fotoenvejecimiento, enfermedades cutáneas, arrugas y líneas de expresión."},
            {"title":"Un objetivo a largo plazo.",
                "text":"Al hablar de envejecimiento, hay dos tipos de factores que es importante considerar si deseamos mantener nuestra piel sana por más tiempo:"},
            {"text":<table>
                    <tr>
                        <td>Podemos Modificar:</td>
                        <td>No podemos modificar</td>
                    </tr>
                    <tr>
                        <td>
                            <ul>
                                <li>Alimentación</li>
                                <li>Actividad física</li>
                                <li>Calidad y cantidad del sueño</li>
                                <li>Estilo de vida</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Factores genéticos</li>
                                <li>Cuestiones hormonales</li>
                            </ul>
                        </td>
                    </tr>
            </table>},
            {"text":"¿Sabias que ...? Las personas que trabajan por la noche tienden a envejecer un poco más rápido a pesar de que duerman ocho horas al día."},
            {"title":"Más allá del género ¿qué factores debemos tomar en cuenta al momento de elegir una rutina de skincare o un tratamiento estético? ",
            "list":[
                "Calidad sobre cantidad. Los productos recomendados por un profesional de la salud deben provenir de laboratorios o marcas certificadas.",
                "Características de la piel. Los tratamientos se pueden enfocar en prevenir o corregir manchas, arrugas y líneas de expresión, controlar la producción de grasa, evitar el fotodaño, mejorar la textura de la piel, etc.",
                "Alto grado de personalización. Los especialistas deben tomar en cuenta: calidad de la piel, estructura facial, incluso motivaciones emocionales de la persona (aquello que quieren mejorar o resaltar)."
            ]},
            {"title":"Aliados en las transiciones de género.", "text":"Los tratamientos estéticos brindan la posibilidad de ayudar a las personas a sentirse bien y cómodas con ellas mismas cuando no se identifican dentro de lo que se denomina como “masculino” o “femenino”."},
            {"text":"“Lo que me gusta de la bandera LGBTTTIQ+ es esta diversidad de colores, porque es lo mismo que podemos hacer en la medicina.”"},
            {"title":"Una tendencia hacia la naturalidad.", "text":"Muchas personas buscan un aspecto joven y descansado, algunas opciones populares son:"},
            {
                "list":[
                    "Ácido hialurónico: que dependiendo de su densidad, ayuda a tratar diferentes zonas del rostro.",
                    "Bioestimuladores de colágeno.",
                    "Toxina botulínica, como tratamiento preventivo.",
                    "Tecnología no invasiva: como Ultherapy®."
                ]
            },
            {"title":"¿Cómo empezar a cuidar de la piel?",
                "list":[
                    "Acudir a una consulta de valoración con un especialista médico de confianza.",
                    "Elegir productos o tratamientos avalados, respaldados científicamente.",
                    "No solo enfocarse en lo superficial de la piel, también hay que darle soporte desde el interior para mejorar y mantener una buena calidad de la piel.",
                ]
            },
            {"text":"Cada persona tiene necesidades y preocupaciones específicas en cuanto al cuidado de la piel, los tratamientos pueden variar según esas necesidades individuales. No hay una única forma de cuidado de la piel que se aplique a todas las personas. "},
            {"text":"“En la salud y belleza cada piel importa, cada color importa.”"},
        ],
        "author":{
            "name":"Dr. Emimael Flores",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/perfectskinmx1/"},
                {social:"Facebook", text:"", link:"https://www.facebook.com/perfectskinmx1"},
                {social:"Web", text:"", link:"https://www.tiktok.com/@perfectskinmx1?_t=8d712roOY34&_r=1"}
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'como-superar-procrastinacion':{
        "image": lifestyle_95,
        "title": ["¿Cómo superar la procrastinación?"],
        "pars":[
            {"text":"Imagina que estás bajo presión por terminar un proyecto cuya entrega es el día de mañana. Los nervios te invaden, llega el estrés, ese sentimiento de culpa “¿Porqué no empecé antes?”, te bloqueas porque te sientes menos productiv@ y eficiente. ¿Has estado en esa situación?"},
            {"text":"Probablemente, has escuchado de la palabra “procrastinar” y conozcas su significado. Se trata de un problema real, más común de lo que piensas. En BetterSelf siempre buscamos a grandes expertos en cada tema que abordamos. Por ello, para entender mejor a la procrastinación y aprender a superarla, invitamos a Blanca Mery Sánchez, quien cuenta con un máster en neurociencia, es conferencista, neuro coach, escritora y fundadora de la empresa Mente Sana."},
            {"title":"Procrastinación vs. pereza.", "text":"Aclaremos que no son lo mismo. Cuando procrastinamos comenzamos a evitar la situación, por ejemplo: tomar una decisión, resolver algo."},
            {"text":"En la pereza, el mensaje en tu cabeza es: “no quiero”, “qué aburrido. En la procrastinación es: “No puedo”, “no soy capaz”, “no estoy listo” “me va a ir mal”, evitamos la incomodidad y el sufrimiento."},
            {"title":"¿Por qué lo hacemos?",
                "text":"Entre las posibles razones están:"},
            {"list": [
                    "Miedo al fracaso",
                    "Miedo al éxito",
                    "Ansiedad de ejecución",
                    "Depresión",
                    "Hábitos mentales relacionados con la evitación"
                ]
            },
            {"text":"Cuando tu cerebro entra en ese ciclo de procrastinación, es como una guerra dentro de tu cabeza entre la corteza prefrontal (la parte lógica) y el sistema límbico (encargado del placer). Usualmente gana este último, es el que te dice “Empecemos a trabajar luego, tenemos tiempo, mejor ve un capítulo más de la serie”."},
            {"text":"Las personas que suelen actuar por impulsividad tienden a procrastinar más."},
            {"title":"¿Es un acto inconsciente o un mal hábito?", "text":"Así como la ansiedad es un hábito mental, la procrastinación es otro."},
            {"text":"Cuando tienes que afrontar una situación en determinado tiempo, tu cerebro tiende a buscar diferentes actividades que le resulten más placenteras. Esto genera una huella neuronal y forma una red que te dirige a la misma conducta evasiva. A cada actividad incómoda le encuentras distractores para salir de la incomodidad."},
            {"text":" Pueden pasar dos cosas:"},
            {"text":<table>
                    <tr>
                        <td>Precrastinación</td>
                        <td>Procrastinación</td>
                    </tr>
                    <tr>
                        <td>
                            <ul>
                                <li>Cuando comienzas por las cosas más fáciles y aplazas las que te resultan difíciles.</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Aplazas, inventas excusas para no asumir las tareas que son incómodas, desagradables o exigentes.</li>
                            </ul>
                        </td>
                    </tr>
                </table>},
            {"title":"¿Cómo nos puede afectar a largo plazo?", "text":"Podemos entrar en un ciclo de distracción crónica, evasión y una constante ansiedad por no terminar las cosas."},
            {"text":"A nivel individual baja tu autoeficacia, te sientes menos capaz, evitas arriesgarte, te autosaboteas."},
            {"text":"A nivel profesional puede que tengas un alto potencial, pero si no lo expresas, no tendrás un alto rendimiento, nadie verá las cosas que eres capaz de hacer.",
                "list":[
                    "Pierdes oportunidades",
                    "Bloqueas la expresión de tu talento"
                ]
            },
            {"title":"Entonces, ¿hay que ver a la procrastinación como algo malo?", "text":"Más que una cuestión de bueno o malo, es una conducta que tenemos que analizar, ¿qué tanto está afectando tu vida? Y cuando esos sentimientos de angustia o incapacidad no disminuyen, es válido acudir con un profesional de la salud."},
            {"text":"Todos procrastinamos, a mayor o menor medida y en diversas actividades. La clave está en saber identificar la procrastinación y actuar para detenerla."},
            {"title":"Micro-pasos hacia un camino con más enfoque.", "list": [
                    "1. Define tu victoria del día.",
                    "2. Crea la mentalidad de “soy capaz, estoy al nivel”.",
                    "3. Visualiza el momento de ti alcanzando tu meta."
                ]
            },
            {"text":"Así, cada vez que terminas una actividad relacionada con tu victoria, esta amplificará la emoción de logro y tu cerebro recibirá esa sensación de comodidad que tanto desea. Entre más regulación, orientación al logro, a superar los retos; progresarás más en la actividad que quieras y que tienes que hacer. Normalicemos el pedir ayuda, sobre todo la profesional, esta puede ayudarte a avanzar para que sigas alcanzando tus metas sin sufrir tanto en el proceso."}
        ],
        "author":{
            "name":"Blanca Mery Sánchez",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/soyblancamery/"},
                {social:"Youtube", text:"", link:"https://www.youtube.com/@soyBlancaMery"},
                {social:"Web", text:"", link:"http://www.mentesana.co"}
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'mente-cuerpo-alma-alimentacion':{
        "image": lifestyle_96,
        "title": ["Mantén tu mente, cuerpo y alma jóvenes desde la alimentación."],
        "pars":[
            {"text":"¡Oh, la juventud! Más allá de ser propia de una edad, creemos que es una etapa que nos desafía a descubrir quiénes somos, a explorar nuestros sueños, y crear nuevas versiones de nosotros. Nos gustaría que puedas disfrutar de esta etapa por mucho tiempo, con toda la salud y vitalidad posible, es por ello que invitamos a Grace Ibarra, fundadora de Hábitos Inteligentes, para que nos comparta cómo podemos mantenernos jóvenes desde la alimentación y cuáles son sus mejores consejos."},
            {"title":"Alimentación y el envejecimiento prematuro", "text":"El envejecimiento prematuro ocurre por diversas razones y nuestros hábitos influyen mucho, entre ellos está el tener una alimentación de mala calidad nutricional: alta en azúcares, sodio, grasas saturadas; principalmente se refiere a consumir más productos procesados que naturales."},
            {"text":"Los alimentos naturales le dan al cuerpo la energía y los nutrientes necesarios para su correcto funcionamiento y pueden protegerlo del daño oxidativo, es decir, no acelerar el proceso de envejecimiento natural."},
            {"text":"He ahí la importancia de llevar una correcta nutrición y actividad física desde edades tempranas, además de que nos ayudará a prevenir o mejorar el pronóstico de muchos padecimientos en el futuro."},
            {"title":"La alimentación y los estados de ánimo",
                "text":"Tienen una relación directa y recíproca:"},
            {"list": [
                    "Alimentación sana = Sentimiento de bienestar.",
                ]
            },
            {"text":"Si estás pasando por una situación que te genera alguna alteración emocional también influye en la forma en cómo elegimos los alimentos, y podemos tener:",
                "list": [
                    "Hambre fisiológica: Comer porque es una necesidad natural.",
                    "Hambre emocional: La comida es un recurso emocional ante ciertas situaciones."
                ]
            },
            {"text":"Cuando uno se siente bien consigo mismo y con su cuerpo encontrará las diferentes maneras de atenderlo de forma saludable y amorosa."},
            {"text":<table>
                    <tr>
                        <td colspan="3" style={{textAlign:'center'}}>Pilares del bienestar</td>
                    </tr>
                    <tr>
                        <td>
                            <span className={"blog-page-par-title"}>Alimentación:</span> <br/><br/>
                            Procurar que sea bien distribuida, variada, equilibrada y completa.
                        </td>
                        <td>
                            <span className={"blog-page-par-title"}>Actividad Física:</span> <br/><br/>
                            Nos ayuda a mantener la movilidad y una buena oxigenación para las células. <br/><br/>
                            Implementar ejercicios tanto aeróbicos y anaeróbicos.
                        </td>
                        <td>
                            <span className={"blog-page-par-title"}>Descanso:</span> <br/><br/>
                            No solo es dormir suficiente, sino también la calidad del sueño. Este último también se ve afectado por la alimentación y actividad física que hagamos.
                        </td>
                    </tr>
                </table>},
            {"title":"¿Qué nutrientes tienen propiedades antioxidantes?", "text":"Recuerda: no existe algún alimento que por sí solo nos ayude a prevenir algún padecimiento o a rejuvenecernos."},
            {"text":"Hay alimentos que en conjunto brindan macronutrientes y micronutrientes que resultan en un beneficio para la salud."},
            {"title":"¿Cuáles son los macronutrientes?", "list":[
                    "Proteínas: ayudan a la forma estructural del cuerpo.",
                    "Grasas saludables: Proveen energía, algunas de ellas son el omega 3 o el aceite de oliva.",
                    "Carbohidratos: aportan fibra."
                ]
            },
            {"title":"¿En dónde encontramos a los micronutrientes?", "list":[
                    "Complejo B: brinda energía y participa en muchos procesos metabólicos.",
                    "Frutas: son ricas en Vitamina C y vitamina A, conocidas por ser antioxidantes.",
                    "Verduras: en ellas encontramos minerales como el magnesio, selenio, potasio. indispensables para diferentes funciones del organismo."
                ]
            },
            {"text":"Las frutas y verduras, dependiendo de su color, producen diferentes compuestos conocidos como fitoquímicos que conforman una buena fuente de antioxidantes. Entre más variedad de colores más nutrientes puedes obtener."},
            {"title":"Tips para mantenernos hidratados durante el día", "text":"La hidratación es clave para la vida y puede influir directamente en el correcto funcionamiento del sistema digestivo y en el aspecto de la piel. Por ello procura:"},
            {
                "list":[
                    "Consumir suficiente agua al día, en promedio dos litros.",
                    "Priorizar el consumo de agua ante cualquier otra bebida con azúcares.",
                    "Sumar una fuente de electrolitos y minerales para una correcta rehidratación.",
                    "Moderar la cantidad que consumimos de bebidas hidratantes comercializadas."
                ]
            },
            {"text":"Cada persona tiene necesidades nutricionales únicas por lo que te recomendamos que consultes siempre con un profesional de la salud. Tu mejor versión depende mucho de los buenos hábitos, considera que la juventud también puede ser un sinónimo de una actitud y un estilo de vida saludable."}
        ],
        "author":{
            "name":"Grace Ibarra",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/habitosinteligentes/"},
                {social:"Facebook", text:"", link:"https://www.facebook.com/habitosinteligentes"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'summer-glowy-skin':{
        "image": lifestyle_97,
        "title": "Summer Glowy Skin",
        "pars":[
            {"text":"¿Sabías que el sol brillante y las altas temperaturas pueden afectar nuestra piel de manera significativa? Pero calma que hemos preparado la información necesaria para que luzcas una piel hermosa y saludable durante la temporada de verano. Los consejos vienen de un gran experto: el Doctor Dante Villamil, Director Médico en Krankenhause Dermatología y Dermatopatología. Especialista en cabello y además cuenta con un Máster en Dermatología Cosmética."},
            {"text":<img style={{width:'100%'}} alt="Infografía Summer Glowy Skin" src={infografia_summer_glowy_skin} />},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/betterselfmx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/betterselfmx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'cuidados-piel-embarazo':{
        "image": lifestyle_98,
        "title": "Cuidados básicos en la piel antes, durante y después del embarazo",
        "pars":[
            {"text":"El embarazo es un momento de alegría y emoción, pero también de nuevas experiencias para las que tenemos que estar preparadas. Con las hormonas en constante cambio, es natural que muchas mujeres experimenten problemas en la piel durante esta etapa. Por ello la Dra. Regina Malo, especialista en Dermatología Clínica y Estética, miembro de Academias Nacionales e Internacionales; nos comparte cuáles son los cambios por los que puede pasar la piel y algunos cuidados a considerar en las etapas alrededor del embarazo."},
            {"title":"Cuidados antes del embarazo.", "text":"La hidratación es un must incluso antes del embarazo, para mantener una buena turgencia en la piel y reducir la probabilidad de desarrollar alguna alergia u otro padecimiento. La protección solar también es primordial porque en cuanto empieza el embarazo hay una mayor posibilidad de que aparezcan manchas. Lo óptimo es embarazarse cuando no existe algún padecimiento activo (acné, melasma, dermatitis atópica, etc)."},
            {"title":"Cambios en la piel durante el embarazo.", text:"En los primeros meses, debido al aumento de hormonas es más frecuente notar casos de:"},,
            {
                "list": [
                    "Acné: que puede comenzar a desarrollar o aumentar, esto se debe principalmente a la progesterona.",
                    "Melasma: son manchas que aparecen en la piel de la cara.",
                    "Lunares o fibromas (verrugas en el cuello): su tamaño puede aumentar debido a las hormonas del crecimiento.",
                    "Estrías: ocasionadas por el aumento de volumen de la piel cuando se estira."
                ]
            },
            {"text":"A nivel interno de la piel, hay cambios en la elastina, una proteína clave que brinda elasticidad y estructura. Cuando los niveles de cortisol aumentan, las fibras elásticas se debilitan y al estirarse pueden llegar al punto de romperse, esto impide que la piel vuelva a su tamaño original. En su lugar aparecen las estrías."},
            {"title":"Cuidados de la piel durante el embarazo.", "text": "Para una mujer que está embarazada o que desea estarlo es importante acudir con un especialista para revisar la rutina de cuidados que tiene ya que existen medicamentos tópicos de uso diario que deben evitarse durante el embarazo. Si la seguridad de uso de un producto en esta etapa no está comprobada científicamente es mejor suspenderlos. Algunos ejemplos son el retinol, ácido salicílico, hidroquinona, entre otros."},
            {"text":"El nivel de hidratación de la piel cambia durante el embarazo, generalmente la piel se vuelve más oleosa, por ello es esencial buscar el producto indicado que no sea demasiado pesado al grado de causar acné o demasiado ligero al punto de no hidratar lo suficiente y hacer que la función de barrera de la piel se debilite."},
            {"text":"La limpieza facial es muy importante, dependiendo de cada caso, es posible que los especialistas recomienden o apliquen tratamientos con vitamina C o ácido glicólico para mantener la luminosidad."},
            {"title":"¿Qué cambios en la piel se pueden esperar después del parto?", "text":"En esta etapa, el nivel de producción hormonal baja y la mayor parte de la energía del cuerpo se enfoca a procesos como la producción de leche. Algunos de los cambios son:"},
            {
                "list": [
                    "Uñas frágiles o quebradizas.",
                    "Efluvio telógeno, es decir, una caída de cabello importante, la buena noticia es que es posible recuperarse de este padecimiento con el cuidado y tratamiento adecuados.",
                    "Estrías, que generalmente aparecen en las últimas semanas del embarazo. Recuerda que son cicatrices que necesitan ayuda para repararse, con los tratamientos adecuados se puede mejorar su apariencia, pero no desaparecerán por completo.",
                    "Manchas, en esta etapa la piel sigue siendo más propensa a desarrollarlas."
                ]
            },
            {"title":"Recomendaciones para cuidar, revitalizar o mejorar el aspecto de la piel post-parto.", "text":"Durante el embarazo y post parto no está recomendado el uso de fillers o skin boosters en el rostro debido a que hay mucha variación en cuanto a la composición corporal y el nivel de retención de líquidos en el cuerpo. Si quieres consentirte después del embarazo y darle un boost de cuidados a tu piel con algún procedimiento estético es recomendable esperar cuatro meses para darle al cuerpo tiempo de lograr mayor estabilidad."},
            {"text":"Recuerda, también es importante tener tiempo para cuidar de ti misma y de tu piel. Por ello es importante acudir con un especialista que te ayude a crear una rutina de skincare adecuada a ti y a la etapa de vida en la que te encuentras. La meta no es una “piel perfecta” si no una piel sana con la que te sientas cómoda."},
            {"text":"¡Encuentra a la doctora Regina Malo en redes sociales!"}

        ],

        "author":{
            "name":"Dra. Regina Malo",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/drareginamalojuvera/"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/senshiderma/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/senshi.mx"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'salud-belleza-pareja-poderosa':{
        "image": lifestyle_99,
        "title": "Salud y belleza ¿Por qué son una pareja poderosa?",
        "pars":[
            {"text":"Probablemente esta sea una de las relaciones más sanas que conozcas, cuando hay armonía y balance entre estas dos cosas se refleja en ti, en tu actitud, en tu bienestar y en tu piel. Descubre cómo puedes hacer que la salud y la belleza se vuelvan una pareja poderosa que impulse tu confianza con la información y algunos consejos que nos compartió nuestra invitada: la Dra. Azucena Rodríguez Lupian, Directora de Aesthetic Clinic, médico cirujano, experta en dermatología y especializada en realizar medicina estética avanzada no invasiva."},
            {"title":"Rasgos que denotan salud y belleza.",
                "list": [
                    "Armonía del rostro: esto no es sinónimo de simetría, consiste en lograr un equilibrio entre los rasgos faciales.",
                    "Triángulo de la juventud: que es la forma que va desde ambos pómulos a la barbilla. Los  pómulos altos, sin pérdida de volumen en las mejillas suelen conformar una cara de aspecto más joven. Si quieres conocer más puedes visitar nuestro blog: Todo sobre el triángulo de la juventud.",
                    "Características de la piel: Es agradable al tacto, suave y a la vez firme; tiene un tono uniforme, luce humectada y luminosa; se encuentra libre de grasas y de imperfecciones. Siempre que una piel está sana transmite bienestar, eso es un reflejo de la belleza y las demás personas lo notan."
                ]
            },
            {"title":"¿Qué puede afectar a la apariencia de la piel?.", text:"Cuando el estilo de vida está lleno de buenos hábitos y el cuerpo recibe los nutrientes necesarios, esto no solo se refleja en el nivel de salud, también se refleja en una piel con más luminosidad, suavidad y con una buena función de su barrera protectora."},
            {"text":"En cambio, los excesos o malos hábitos vuelven a la piel opaca, más propensa a deshidratarse, a arrugarse y sufrir diversos padecimientos."},
            {"text":"También hay factores genéticos que predisponen a las personas a presentar alguna afección en la piel, por ello son importantes las consultas y revisiones médicas constantes."},
            {"title":"¿Cómo mantener una piel saludable?", "text":"A medida que envejecemos es importante adaptar los cuidados y los productos de belleza que usamos, esto es con el objetivo de mantener una piel sana con un aspecto fresco y juvenil por mucho más tiempo."},
            {
                "list": [
                    ["Implementar una rutina de skincare, que incluya:", <ul><li>Limpieza</li><li>Antioxidantes</li><li>Hidratación</li><li>Protección solar</li></ul>],
                    ["Tratamientos estéticos, existen opciones mínimamente invasivas que pueden ayudar a crear nuevo colágeno, brindar mayor hidratación y mejorar o prevenir la apariencia de arrugas y líneas de expresión en la piel.", <ul><li>Bioestimuladores de colágeno</li><li>Toxina botulínica</li><li>Ácido Hialurónico</li><li>Tecnología de ultrasonido microfocalizado</li></ul>],
                ]
            },
            {"text":"Estas medidas en conjunto pueden ayudarnos a:", list: [
                    "Prevenir el envejecimiento prematuro.",
                    "Verse y sentirse más saludable.",
                    "Alcanzar nuevos niveles de belleza."
                ]
            },
            {"text":"Hoy en día hay muchas opciones que pueden ayudar a retardar el envejecimiento. A veces los cuidados se enfocan hacia la piel del rostro, pero también existen diferentes tratamientos para cuidar, mejorar la textura, prevenir la flacidez y mantener la suavidad de la piel en zonas del cuerpo como los brazos, glúteos, piernas, etc. Para conocer estas opciones con más detalle puedes consultar directamente con un profesional de la salud."},
            {
                "title":"¿Qué podemos hacer para lograr este equilibrio entre salud y belleza en nuestra piel y apariencia?",
                "list": [
                    "1. Tener una rutina de skincare, que cuente con los puntos básicos que ya mencionamos.",
                    "2. Ver en qué etapa de la vida estás (tu edad), pues las necesidades de la piel cambian con el tiempo.",
                    "3. Alimentación sana, más verduras y frutas; menos comida procesada.",
                    "4. Actividad física regular, para mantener un tono muscular adecuado.",
                    "5. Evitar el contacto directo con agentes o sustancias que pueden dañarla, como el sol, el tabaco, los detergentes y los cosméticos fuertes."
                ],
            },
            {
                "text":"Sabemos que llevar un estilo de vida saludable a veces puede ser desafiante, el autocuidado es una responsabilidad de trabajar por tu bienestar, significa dedicar tiempo para ti y volverte consciente de qué es lo que tú y tu cuerpo necesitan para disfrutar de la vida, con una buena salud a nivel mental, físico y emocional. Si estás en ese proceso de reforzar tu amor propio no dudes en recurrir a los expertos que te brinden seguridad y confianza. Nunca es tarde para construir una rutina de amor propio en donde te agradezcas por lo increíble que eres y reconozcas todo lo que tu cuerpo hace por ti."
            },
        ],

        "author":{
            "name":"Dra. Azucena Lupian",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/dra.lupian/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/dralupian/?locale=es_LA"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'adios-ojeras':{
        "image": lifestyle_100,
        "title": "¡Adiós ojeras!",
        "pars":[
            {"text":"¿Tienes ojeras y no sabes cómo mejorar su apariencia? ¡Es hora de decirles adiós y dar la bienvenida a esa mirada radiante y llena de confianza! Para ello, contamos con la información que nos compartió nuestra invitada experta en Medicina Estética, la Dra. Diana Hernández, quien constantemente se actualiza en los diferentes productos y procedimientos estéticos para brindar soluciones de belleza con la más alta calidad y seguridad."},
            {"text":"A diferencia de otras zonas del rostro o cuerpo, la piel del contorno de ojos es mucho más delgada y sensible, lo que muchas veces deja visible los vasos sanguíneos de la zona. En consecuencia, la zona de la ojera se puede ver más oscura y dar un aspecto de cansancio o tristeza."},
            {"title":"Algunos factores que pueden hacer que las ojeras sean más notorias:",
                "list": [
                    "Malos hábitos de sueño, que impiden el descanso.",
                    "Exposición solar constante, prolongada y sin la protección adecuada.",
                    "Fricción o tallado excesivo de la piel, puede causar pigmentación.",
                    "Predisposición genética, que puede determinar cierto grado de hundimiento en la ojera o una bolsa muy pronunciada."
                ]
            },
            {"title":"Otro factor importante que puede marcarlas: El proceso natural de envejecimiento.",
                "list": [
                    "Aparición de arrugas finas en la piel alrededor de los ojos.",
                    "Mayor tendencia a la aparición de pigmentación oscura.",
                    "Pérdida gradual de elasticidad de la piel, volviéndola más flácida.",
                    "Reabsorción de los cojinetes adiposos (grasa en el rostro), lo que puede marcar un hundimiento en la zona de las ojeras.",
                    "Flacidez en los ligamentos que dan sostén, lo que puede hacer que las “bolsitas” de las ojeras se marquen más."
                ]
            },
            {
                "title":"¿Qué tipo de ojeras hay? ",
                "list": [
                    [<span style={{fontWeight:'bold'}}>Hundidas:</span>, "Aparecen cuando se pierde volumen en el surco de la ojera."],
                    [<span style={{fontWeight:'bold'}}>Pigmentadas:</span>, "Suelen ser de color oscuro."],
                    [<span style={{fontWeight:'bold'}}>Vasculares:</span>, "Tienen un tono azul o violáceo, debido a que los vasos sanguíneos son más visibles."],
                    [<span style={{fontWeight:'bold'}}>Abultada:</span>, "También conocidas como “bolsas”."],
                    [<span style={{fontWeight:'bold'}}>Mixtas:</span>, "Son la suma de diferentes tipos de ojeras."],
                ]
            },
            {"text": [<span style={{fontWeight: 'bold'}}>La buena noticia:</span>,"Es posible mejorar la apariencia de las ojeras con una rutina constante y adecuada de skincare. Para mejores resultados, se puede complementar con procedimientos estéticos poco invasivos. Para un cuidado óptimo es importante acudir con un profesional de la salud capacitado y especializado en alguno de estos campos: Medicina Estética, Dermatología y/o Cirugía Plástica."]},
            {
                "title":"¿Qué activos/ingredientes pueden ser eficaces para combatir las ojeras?",
                "list": [
                    [<span style={{fontWeight:'bold'}}>Ácido hialurónico:</span>, "ayuda a hidratar la zona, disminuir arrugas finas y en caso de los inyectables, a voluminizar."],
                    [<span style={{fontWeight:'bold'}}>Cafeína:</span>, "presente en algunos contornos de ojos, suele ayudar a estimular la circulación sanguínea en la zona."],
                    [<span style={{fontWeight:'bold'}}>Vitamina K:</span>, "tiene propiedades descongestionantes, puede ayudar a mejorar la microcirculación y a disminuir la coloración oscura."],
                    [<span style={{fontWeight:'bold'}}>Retinol:</span>, "está presente en algunas cremas de contorno de ojos, puede ayudar a renovar la piel."],
                    [<span style={{fontWeight:'bold'}}>Ácido kójico:</span>, "suele ayudar a mejorar el oscurecimiento causado por el fotodaño."],
                ]
            },
            {
                "title":"Diferencias entre tratamientos tópicos, Skin Boosters y fillers",
                "text": "Los tratamientos tópicos ayudan a mejorar la piel de manera superficial, generalmente contienen sustancias activas que se absorben por la piel. Son excelentes opciones para una prevención.",
            },
            {
                "text":"Mediante los tratamientos inyectables es posible garantizar una absorción completa de los ingredientes activos en la zona tratada. Además de que los resultados son visibles desde la primera sesión y mejoran de forma gradual.",
                "list": [
                    "Los skinboosters de ácido hialurónico pueden ayudar a mejorar la hidratación y redefinir la estructura de la piel sin dar volumen.",
                    "El ácido hialurónico en los fillers tiene la función de voluminizar para restaurar forma y estructura que se van perdiendo con el tiempo."
                ]
            },
            {
                "title":"¿Con qué frecuencia realizarlos?",
                "list": [
                    "Depende del diagnóstico de cada persona, en general se pueden hacer tratamientos preventivos como skin boosters cada 4 a 6 meses.",
                    "En caso de aplicación de ácido hialurónico para corregir volumen perdido, se puede realizar cada año."
                ]
            },
            {
                "text":"En ambos casos es necesario consultar con un profesional de la salud."
            },
            {
                "title":"¡Recuerda, los buenos hábitos suman!",
                "text":"Tomar acciones preventivas en el cuidado de la piel es clave para una apariencia fresca y descansada por mucho más tiempo. Consulta con tu médico especializado en tratamientos estéticos para que pueda hacer una valoración de tu tipo de ojera y pueda ofrecerte las opciones adecuadas para ti. Hoy en día existe una gama muy amplia de tratamientos para esta zona con amplia seguridad y excelentes resultados. El objetivo que compartimos en BetterSelf con la Dra. Diana Hernández es que obtengas resultados naturales que logren resaltar tu belleza sin que pierdas tu esencia."
            }
        ],

        "author":{
            "name":"Dra. Diana Hernandez",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/dra.dianahernandez/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/dra.dianahernandez"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'poder-momento-vida-plena':{
        "image": lifestyle_101,
        "title": "El poder del momento presente para una vida más plena",
        "pars":[
            {"text":"Si tuvieras una máquina del tiempo, ¿a qué época de tu vida te gustaría viajar? ¿cambiarías algo? ¿actuarías diferente?. A través de las experiencias vividas, cada persona lleva consigo una historia única que puede definir quién y cómo es. Pero, cuando se presentan ciertos problemas, mirar atrás puede ser clave para sanar. En esta ocasión, para hablar de este tema y la importancia de cuidar nuestra salud mental, contamos con la participación especial de Sofía Rosendo, psicóloga con maestría en psicoterapia Gestalt."},
            {"title":"La razón que no te permite dejar el pasado", "text":"Pareciera que son muchos los motivos por los que a una persona le cuesta dejar atrás su pasado, pero en realidad el común denominador es “la falta de aceptación de la realidad”. Ya sean heridas de la infancia, resentimientos, culpa, duelo, o situaciones inconclusas; lo que inconscientemente busca es refugiarse en un momento de su historia en el que considera que aún tiene “el control”, porque es muy duro admitir con plena consciencia que le está costando vivir algo que no le gusta y que desearía que fuera diferente."},
            {"title":"Una forma de autosabotaje ", "text":"Siempre que no vivas en el presente te estás autosaboteando. Para fines prácticos el tiempo es un constructo a partir del cual el ser humano hace derivaciones del pasado, de aquello que ya pasó, que ya no existe; y del futuro, lo que no ha pasado, lo que aún no existe."},
            {"text":"No importa si tu cabeza está en el pasado o el futuro, si no está en el presente, no está en la realidad."},
            {"text":"Identificar este tipo de situaciones es algo que se puede hacer en terapia, pero no exclusivamente. Es común que cuando existe un tema que no se ha superado, este logra colarse de manera recurrente, no solo en pensamientos, también en las conversaciones."},
            {"text":"Cuando el pasado empieza a interferir en la vida diaria de una persona al punto de generar ciertas disfuncionalidades en su conducta, pensamientos o emociones, es sin duda, el momento de buscar ayuda profesional."},
            {"title":"El proceso de resignificar el pasado", "text":"El cambio y la evolución son constantes gracias al aprendizaje y crecimiento que se da a lo largo de la vida, no eres lo que solías ser, ni serás lo que eres. Debido a eso, la percepción sobre los hechos que te acontecieron se pueden ir modificando si tienes la disposición de visitar el pasado desde un lugar distinto; un presente con más información y comprensión para abrirse a las posibilidades de darle, desde la retrospectiva, un significado diferente a las experiencias vividas."},
            {"title":"El papel del autocuidado y el mindfulness.", "text":"El mindfulness puede ayudar para ambos propósitos ya que básicamente consiste en la atención consciente, intencional y libre de juicios del momento presente; es una práctica que permite estar en el aquí y en el ahora."},
            {"text":"El tema de autocuidado puede volverse más extenso porque abarca varias esferas:"},
            {
                "list": [
                    [<span style={{fontWeight:'bold'}}>Físico:</span>, "Verse y sentirse bien son clave en el fortalecimiento de la autoestima, su efecto se puede reflejar en una mayor motivación y desempeño."],
                    [<span style={{fontWeight:'bold'}}>Social:</span>, "Cuidar la paz propia y buscar esa sensación de comodidad con quienes te rodean. Romper la relación o distanciarse de aquellos que te han dañado, o cuyas actitudes pueden resultar perjudiciales para ti es lo más adecuado. Crear redes de apoyo con amigos o personas de confianza, pueden hacer que atravesar este proceso resulte más sencillo."],
                    [<span style={{fontWeight:'bold'}}>Mental:</span>, "Cuidar tus pensamientos, en especial, cuando se trata del “si yo hubiera” (pasado) y “si yo tuviera” (futuro, pues se infiere que en el presente no se tiene) ya que son pensamientos que no llevan a ninguna parte, no cambian lo sucedido, ni el presente, pero sí se pueden desarrollar frustración."],
                    [<span style={{fontWeight:'bold'}}>Emocional:</span>, "La práctica del mindfulness, antes mencionada, podría ser un recurso para trabajar el autocuidado emocional, tal como asistir a terapia, escribir sobre cómo te sientes ante ciertas situaciones, y expresar las emociones libremente."],
                ]
            },
            {"text":"Aprender del pasado con base en las experiencias vividas es posible. Cuando se habla y trabaja en superar un evento del pasado, ya estás mejorando tu presente."},
            {"text":"Sofía Rosendo comenta que no es entusiasta de “planear el futuro” porque se puede tener una idea, a veces muy clara, de lo que se quiere y que no, y en efecto crear un plan para conseguirlo pero es de vital importancia recordar que siempre se encontrarán situaciones que fueron imposibles de predecir, envueltas en factores ajenos a tu control y también habrá que adaptarse, aprender de ello, porque lo que ahora parece un futuro distante, en un tiempo será el pasado del que busques obtener una lección."},
            {"text": [<p style={{textAlign:'center'}}>“Que el pasado no defina tu futuro.”</p>]},
        ],

        "author":{
            "name":"Mtra. Sofía Rosendo González",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/psic.sofia.rg/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/psic.sofia.rg/?locale=es_LA"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'cuidados-full-body-parte-2':{
        "image": lifestyle_103,
        "title": ["Cuidados Full Body para mantener tu piel radiante y saludable.", <br/>, "Parte II"],
        "pars":[
            {"text":"El cuidado de la piel debe ser integral, sin embargo muchas veces nos olvidamos de la piel del cuerpo. Te invitamos a leer la continuación de la plática que tuvimos con el Dr. Jorge Krasovsky. Él es cirujano plástico mexicano, certificado con 20 años de experiencia y con alta especialidad en cirugía facial y cirugía estética corporal. Además es uno de los más altos exponentes y con más práctica en el tema de inyectables faciales, en esta ocasión nos platicará algunas formas de cómo puedes lucir un cuerpo espectacular."},
            {"text":["Si aún no has leído la parte 1, puedes ", <a href="/lifestyle/cuidados-full-body-parte-1" rel="noreferrer noopener" target="_blank">hacerlo aquí.</a>]},
            {"title":"¿Qué zonas corporales pueden delatar la edad con el tiempo?", "text":"Principalmente las zonas que comienzan a mostrar signos de envejecimiento son aquellas que están más expuestas: la cara, el cuello, el escote y las manos. Después tenemos zonas que suelen estar más cubiertas como los brazos, las rodillas, el abdomen y los glúteos."},
            {"text":"Además de los cuidados básicos, algunos tratamientos que pueden ayudar a prevenir o mejorar los signos de envejecimiento en el cuerpo son:"},
            {"text":[<span style={{fontWeight:'bold'}}>Bioestimulación.</span>, " Ya sea por medio de inyectables o por tecnología de ultrasonido, la producción de colágeno ayuda mucho a retrasar la aparición de flacidez y arrugas en distintas zonas del cuerpo. Si buscas mejorar o prevenir las arrugas o flacidez en el cuello o escote, la tecnología de ultrasonido es de las mejores opciones."]},
            {"text":[<span style={{fontWeight:'bold'}}>Ácidos hialurónicos.</span>, " Estos tienden a ser solo para el cuidado facial y su función puede ser desde mejorar la hidratación de la piel,  disminuir la profundidad de las líneas y arrugas hasta recuperar el volumen y definir el contorno del rostro."]},
            {"text":[<span style={{fontWeight:'bold'}}>Hidroxiapatita cálcica.</span>, " Se trata de un mineral natural presente en el cuerpo, al aplicarse por medio de inyecciones puede ayudar a brindar soporte y mayor definición a la mandíbula. En el dorso de las manos recupera el volumen. También es un excelente estimulador de colágeno para reducir la flacidez en zonas como el cuello, rodillas, abdomen, brazos, piernas, muslos y glúteos."]},
            {"text":"Si la celulitis es una de tus preocupaciones, la bioestimulación con hidroxiapatita de calcio es uno de los principales tratamientos que dan resultados positivos."},
            {"text":"La combinación de diversos tratamientos para mejores resultados es posible, pero esto depende mucho de tus objetivos y de la evaluación que realice el profesional de la salud.\n"},
            {"title":"¿Cada cuánto se deben realizar estos tratamientos tanto preventivos como correctivos?", "text":"La duración de los tratamientos antes mencionados dependen mucho del metabolismo de cada persona. Por ello, los expertos de la salud siguen protocolos que ayudan a indicar los tratamientos ideales y la frecuencia para realizarlos. Algunos pueden ser una vez cada mes, cada seis o incluso una vez al año, todo depende de los resultados que se buscan y de la zona que se vaya a tratar. Lo más importante de estos tratamientos es que el especialista sepa valorar, diagnosticar y estar al pendiente de cuándo es el momento adecuado para una segunda aplicación o sesión."},
            {"title":"Recomendaciones finales", "text":"Evita los tratamientos “patito” aunque los recomiende la celebridad del momento, porque muchos de sus resultados no están totalmente comprobados y la seguridad de uso no está garantizada. Siempre consulta antes con tu médico de confianza y utiliza los productos o tratamientos que éste te recomiende."},
            {"text":"Y por último, recuerda que siempre es un excelente momento para empezar a cuidarse y a recurrir a estos tratamientos. Actualmente existe una amplia gama de opciones para cualquier etapa de la vida y que se adaptan a las condiciones de la piel. El verse bien no está peleado con la edad, elige cuidarte y sin duda vas a sentirte bien."}
        ],

        "author":{
            "name":"Dr. Jorge Krasovsky",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/dockrasovsky/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/DrKrasovskyreal/?locale=es_LA"},
                {social:"Twitter", text:"", link:"https://twitter.com/dockrasovsky?lang=es"},
                {social:"Web", text:"", link:"http://www.drkrasovsky.com/"}
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'cuidados-full-body-parte-1':{
        "image": lifestyle_102,
        "title": ["Cuidados Full Body para mantener tu piel radiante y saludable.", <br/>, "Parte I"],
        "pars":[
            {"text":"Cuerpo solo hay uno, por eso es fundamental sentir comodidad en nuestra propia piel. Los problemas de imagen corporal tienen el poder de afectar negativamente la confianza y el estado de ánimo general, lo que a su vez puede afectar otros aspectos de la vida."},
            {"text":"Hoy traemos un tema importante que puede ayudarte a cuidar de tu cuerpo, sin descuidar tu salud y belleza. Nuestro experto de hoy es cirujano plástico mexicano, certificado con 20 años de experiencia y con alta especialidad en cirugía facial y cirugía estética corporal. Nos referimos al Dr. Jorge Krasovsky, quien es uno de los más altos exponentes y con más práctica en el tema de inyectables faciales, en esta ocasión nos platicará algunas formas de cómo puedes lucir un cuerpo espectacular."},
            {"title":"¿Cómo se ve un cuerpo sano?", "text":"Hablar de un cuerpo sano es una cuestión muy integral, es decir, que la salud va de la cabeza a los pies, tiene una buena nutrición y no sufre de padecimiento alguno. En el aspecto físico además:"},
            {"list":[
                "Tiene buena calidad de la piel, es decir, que está hidratada, lubricada, con buena resistencia y de preferencia sin manchas.",
                    "Está tonificado, con curvas.",
                    "Hay un buen balance entre músculo y grasa."
            ]},
            {"text":"La suma y el balance de los cuidados que se le dan al cuerpo de forma integral son muy importantes a la hora de lucir un aspecto saludable desde edades muy jóvenes."},
            {"title":"Básicos de cuidado corporal ", "list": [
                "Protección solar: “El sol es el archienemigo #1 de la piel” porque la puede secar, deshidratar, manchar y envejecer.",
                    "Hidratación: Que se puede obtener vía oral (al beber agua), de forma externa con cremas y sérums o de forma intermedia con tratamientos estéticos que ayudan a que la piel atraiga más agua.",
                    "Ejercicio: En pieles jóvenes el ejercicio puede ayudar a eliminar las toxinas del cuerpo por medio de la sudoración. Pero cuando uno abusa del ejercicio, tiene poca grasa corporal o después de los 50-55 años hay que cuidar al doble la piel porque la deshidratación lejos de ayudarla puede perjudicarla.",
                    "Limpieza: La limpieza con agua y frotar el jabón con tus manos es suficiente, hay que dejar atrás la idea de exfoliar diariamente con cepillos o estropajos muy abrasivos que pueden remover las células buenas de la piel, causar sensibilidad o volverla propensa a manchas. En su lugar el Dr. Krasovsky recomienda acudir a limpiezas faciales cada cierto tiempo, siempre y cuando sean realizadas por profesionales certificados."
            ]
            },
            {"title":"Piel joven y piel madura: dos tipos de cuidado totalmente diferentes", "text":"La piel joven es mucho más hidratada, tiene mucho colágeno y elastina, además suele ser más oleosa (producir más grasa); todo esto le sirve de protección por lo que no requiere de muchos cuidados. Hay que entender que la piel va madurando y después de cierta edad todos estos factores se van perdiendo. Sin duda después de los 30 años, se recomienda cuidar mucho más de la piel e incluso apoyarse de tratamientos como los inyectables para mejorar la hidratación, la producción de colágeno (bioestimuladores), mejorar la calidad de la piel y prevenir o suavizar la aparición de arrugas o líneas de expresión."},
            {"text":"Si quieres conocer cómo cuidar tu piel y mejorar su calidad, mantente pendiente de nuestro blog porque próximamente subiremos la parte 2 de esta interesante plática que tuvimos con el Dr. Jorge Krasovsky."}
        ],

        "author":{
            "name":"Dr. Jorge Krasovsky",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/dockrasovsky/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/DrKrasovskyreal/?locale=es_LA"},
                {social:"Twitter", text:"", link:"https://twitter.com/dockrasovsky?lang=es"},
                {social:"Web", text:"", link:"http://www.drkrasovsky.com/"}
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'labios-hermosos-sanos':{
        "image": lifestyle_104,
        "title": ["Labios hermosos y sanos todo el año."],
        "pars":[
            {"text":"El cuidado de los labios es un aspecto vital en nuestra rutina de belleza y autocuidado. En nuestro afán por resaltar la importancia de mantener unos labios sanos y atractivos, nos hemos acercado a un experto en cuidado de los labios, el Dr. Alejandro García Balbás. El Dr. Alejandro cuenta con una distinguida trayectoria de 9 años en medicina estética, y actualmente dirige la Clínica Médica Estética AGB en Querétaro; es Speaker para Merz Aesthetics® y embajador del Colegio LATAM. Nos brindará valiosos consejos y cuidados fundamentales para conservar la salud y la belleza de nuestros labios."},
            {"text":"La piel de los labios es notablemente distinta a la de otras áreas de nuestro rostro. A diferencia de otras zonas cutáneas, carece de la misma cantidad de glándulas sudoríparas y grasa, lo que la hace más propensa a la deshidratación y al agrietamiento. Los labios, al tener una cantidad mínima de humedad y una mayor concentración de células muertas, requieren cuidados específicos, como el uso regular de humectantes y reparadores labiales para mantener su salud."},
            {"text":"El envejecimiento es un factor significativo que incide en los labios, causando una disminución en la musculatura de esta zona, lo que resulta en una pérdida de volumen y definición."},
            {"title":"¿Qué factores se toman en cuenta para determinar que los labios son armónicos?", "text":"El Dr. Alejandro nos comentó que para determinar la armonía de los labios con el rostro se consideran diversos factores, como la línea de Rickett. Esta línea mide la punta de la nariz, la parte de los labios y la zona del mentón la cual nos determina qué amplitud podemos sacar los labios. Las comisuras de los labios determinan una línea imaginaria llamada línea media pupilar que indica si el labio es grande o pequeño y se hace una división de lado derecho e izquierdo donde se le pide al paciente que haga ciertas muecas o movimientos y con eso, se le describen las características de sus mismos labios."},
            {"text":"Cuando una persona se acerca al Dr. Alejandro pidiendo cierto tipo de labios hay que verificar la simetría de su rostro, mediciones, observaciones, etc., ya que cada persona es distinta y tiene una forma de labios diferente; así se determina si son labios armoniosos de acuerdo al rostro o se le dan sugerencias que resalten sus facciones naturales."},
            {"text":"El ácido hialurónico es una opción innovadora para mejorar y resaltar la apariencia de los labios. Esta molécula, presente naturalmente en el cuerpo, ofrece hidratación constante y distintas posibilidades, desde definir y aumentar el volumen hasta simplemente hidratar los labios, según las necesidades individuales."},
            {"text":"En cuanto a la realización de procedimientos de aumento o hidratación de labios, es importante mencionar que el ácido hialurónico es un producto seguro y biocompatible para todas las edades."},
            {"title":"¿Cómo son los resultados?, ¿son instantáneos?, ¿cuánto tiempo duran?", "text":"El Dr. Alejandro nos compartió que los resultados son visibles de inmediato, pero es normal que los labios se inflamen levemente reduciéndose alrededor de un 15% a un 20% en poco tiempo. La apariencia final se notará completamente después de dos semanas. Tras el procedimiento, se recomienda no maquillarse durante el día, aplicar hielo para desinflamar y, en caso de moretones, usar pomada de árnica. En general, no se necesitan cuidados especiales adicionales."},
            {"title":"¿Cuáles son algunos de los miedos/dudas más comunes de las personas que desean resaltar la apariencia de sus labios?, y, como especialista ¿de qué forma sueles resolver esas dudas o miedos?", "text":"Se suele pensar que el ácido hialurónico pueda hacer que los labios luzcan excesivamente grandes o se confunda este activo con la toxina botulínica. Sin embargo, el ácido hialurónico brinda un aspecto natural y agradable, sin causar molestias."},
            {"text":"Cuando alguna persona llega con dudas o miedos, el Dr. Alejandro le explica detalladamente el proceso, desde los productos utilizados hasta la tecnología involucrada. Aborda posibles problemas de adaptación y advierte sobre la inflamación temporal que puede ocurrir en los labios, siendo estos sensibles, aunque suele disminuir en un par de semanas."},
            {"text":"La confianza aumenta al ver los resultados exitosos, brindando tranquilidad a quienes buscan este procedimiento. El Dr. Alejandro nos dijo que cada individuo es único, por lo que se proporciona una explicación personalizada para garantizar confianza y comodidad."},
            {"text":"¡Mantén tus labios radiantes y descubre más sobre el cuidado personal, la confianza, y la salud en BetterSelf®! Estamos comprometidos a compartir información sorprendente que transformará tu enfoque hacia el autocuidado. ¡Sigue al pendiente de nuestro blog para conocer más consejos valiosos sobre la belleza y salud de tus labios, y mucho más!"},
            {"text":"¡Síguenos para no perderte ningún detalle! Y sigue al Dr. Alejandro García Balbás en sus redes sociales."},
        ],

        "author":{
            "name":"Dr. Alejandro García Balbás",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/dragbqro/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/notodoescirugia?mibextid=LQQJ4d"},
                {social:"Web", text:"", link:"https://notodoescirugia.com/"}
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'tendencias-cuidado-piel-hombres':{
        "image": lifestyle_105,
        "title": ["Tendencias en el cuidado de la piel para hombres: Lifting facial"],
        "pars":[
            {"text":"Vivimos en una época donde la autenticidad y el autocuidado son esenciales, esto no ha sido excepción para los hombres. Ellos también están adoptando técnicas y productos para mejorar su apariencia y sentirse bien consigo mismos. Pero para hablar de estas técnicas y procedimientos, platicamos con el Dr. Jorge Krasovsky. Él es cirujano plástico mexicano con más de 20 años de experiencia y especialización en cirugía facial y estética corporal e inyectables faciales. Además ha participado como conferencista en eventos nacionales e internacionales."},
            {"text":"Antes de entrar de lleno a las tendencias, conozcamos las diferencias entre la piel de los hombres con respecto a la de las mujeres.",
                "list": [
                    "La piel masculina es más gruesa y grasa debido a la testosterona, envejece de manera distinta a la piel femenina. En los hombres las líneas de expresión y arrugas salen a edades más avanzadas pero una vez que aparecen son más profundas en comparación con las de las mujeres.",
                    "En cuanto a las mujeres, las hormonas influyen en una piel más delgada, menos hidratada y con menos grasa. Esto hace que comiencen a aparecer líneas de expresión y arrugas a temprana edad pero son poco profundas al inicio."
                ]
            },
            {"text":"Dato curioso: Hace 25 años, la proporción de personas que cuidaban su piel era de 95% mujeres y 5% hombres. Hoy, la participación masculina ha crecido al 40%, demostrando un cambio en la percepción del cuidado personal."},
            {"text":"Ahora sí, hablemos de las tendencias en el cuidado de la piel y prevención de arrugas:",
                "list":[
                    "1. En cuanto a tratamientos, los hombres buscan desde temprana edad la toxina botulínica para reducir líneas de expresión. Sobre todo en el entrecejo, la frente y la parte lateral de los ojos en donde se hacen las patas de gallo. La duración de los resultados es de entre 4 a 6 meses pero todo dependerá del metabolismo de cada persona.",
                    "2. Como segunda opción, se adentran a los tratamientos con ácido hialurónico. Con este procedimiento se pueden tratar las arrugas del surco nasogeniano (línea de la nariz hacia la boca) o hacer perfilamientos para destacar la mandíbula por ejemplo, que es uno de los rasgos masculinos. Sin embargo, también se puede aplicar solo para hidratar y mantener un glow natural en la piel. Los resultados duran entre 6 meses y 1 año.",
                    "3. Por último, pero no menos importante, tenemos a los bioestimuladores de colágeno. En lo personal, son los favoritos del doctor; ya que  aportan frescura, naturalidad y son ideales para prevenir el envejecimiento prematuro. Destacan por su capacidad para impulsar la producción de colágeno. Y también se pueden realizar perfilamientos faciales con ellos, que realzan la mandíbula y pómulos. Tienen una duración que puede variar, generalmente entre 9 meses y un año. Esta técnica brinda resultados duraderos, añadiendo definición a las facciones y resaltando la belleza natural.",
                    "4. Para el cuerpo, tecnologías como láser, radiofrecuencia o ultrasonidos en tiempo real ofrecen resultados efectivos, complementando tratamientos inyectables como los bioestimuladores de colágeno."
                ]
            },
            {"text":"El Dr. Krasovsky enfatiza que, aunque los tratamientos pueden iniciarse a una edad temprana, la consulta con un médico certificado es esencial, especialmente para aquellos considerando cirugías."},
            {"text":"Para finalizar, el Dr. Krasovsky nos comenta que es importante comprender las expectativas del paciente evitando intervenciones que no beneficien su bienestar. En ocasiones las decisiones se toman por influencias externas y es crucial profundizar en las motivaciones reales detrás de la cirugía para garantizar la satisfacción del paciente."},
            {"text":"¡Sigue al Dr. Krasovsky en sus redes sociales para obtener más consejos y no te pierdas nuestros próximos blogs con información valiosa sobre cuidado personal y salud!"},

        ],

        "author":{
            "name":"Dr. Jorge Krasovsky",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/dockrasovsky/?hl=es-la"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/DrKrasovskyreal"},
                {social:"Web", text:"", link:"https://twitter.com/dockrasovsky"}
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'tips-empezar-anio-propositos':{
        "image": lifestyle_106,
        "title": ["7 Tips para empezar un nuevo año con propósitos y sin pretextos"],
        "pars":[
            {"text":"Con el comienzo de un nuevo año vienen los propósitos, que si ser fit, comer más saludable, hacer ese viaje, etc., etc. Pero antes de que empieces a abrumarte por la interminable lista de cosas que quieres hacer, te compartimos la forma ideal de plantearte objetivos y traducirlos en propósitos logrables durante el 2024."},
            {"text":"Para ello platicamos con Karla Aguirre, ella es Consultora en Imagen, Marketing Specialist, Creadora de Contenido centrada en Estilo de Vida y Bienestar Integral y Coach en Clymb Studio.",},
            {"title":"Tip #1", "text":"Karla nos comenta que lo primero es reflexionar sobre tu situación actual y a dónde quieres llegar. Es vital ser realista y establecer propósitos alcanzables en el periodo de 12 meses. Las circunstancias personales influyen, pero adaptarse a la rutina con tiempo y disciplina es clave."},
            {"text":"Ahora bien, mucho ojo con la \"flojera\" ya que es nuestra enemiga #1 a la hora de cumplir nuestros propósitos. La procrastinación puede bajar nuestro compromiso, al posponer tareas esenciales perdemos tiempo valioso y perdemos motivación, generando poco a poco un desinterés por nuestras metas, especialmente las de corto plazo."},
            {"title":"Tip #2", "text":"Para definir metas específicas y reales, Karla nos comparte esta fórmula:"},
            {
                "list":[
                    "División Temporal: Metas a corto (1 mes), mediano (6 meses) y largo plazo (12 meses). Ve visualizando tus logros mes a mes, comenzando con cosas fáciles y subiendo la apuesta.",
                    "Priorización Inicial: Organiza tus propósitos colocando las metas sencillas al principio y las más retadoras en un periodo más largo. Al final de cada mes, evalúa y ajusta.",
                    "Hábitos Sostenibles: Enfócate en hábitos que puedas integrar fácilmente en tu vida diaria. La constancia durante todo el año es la llave del éxito.",
                ]
            },
            {"title":"Tip #3","text":"En cuanto al ejercicio, Karla nos aconseja no exagerar con los objetivos. Nada de ponernos la meta de hacer dos horas diarias si llevamos 15 años en ociosidad. Empieza con 15 minutos diarios y sube gradualmente. Cero comparaciones mejor cada quien a su ritmo."},
            {"text":"Recuerda, el ejercicio es tu espacio personal, no una obligación. Desconéctate de las presiones externas y céntrate en cómo te sientes. Encuentra una disciplina que disfrutes, como yoga, boxeo o caminatas; para mantener la motivación y la constancia."},
            {"title":"Tip #4","text":"Si ya pasó el tiempo que definiste para una meta a corto plazo, deja de juzgarte y actúa ahora, no esperes al próximo lunes o enero para comenzar. A menudo, las presiones externas y expectativas sociales pueden distorsionar el objetivo principal que es: sentirse bien. La disciplina supera la motivación. Reconoce que no siempre te sentirás motivado, pero la disciplina te mantendrá constante en tus propósitos."},
            {"title":"Tip #5", "text":"Y si tus metas no se han logrado en meses, ajusta el plan. Nada de dramas y mejor retoma el camino. La ruta al éxito no es una línea recta, pero la perseverancia y la adaptabilidad son tus armas secretas. Recuerda que retomar no implica empezar de cero, cada día es una nueva oportunidad para avanzar."},
            {"title":"Tip #6", "text":"Evita la comparación, sé tú mism@. A veces seguimos metas basadas en estándares sociales, como mantener una dieta saludable o hacer ejercicio. En temas de salud, es común buscar consejos, pero debemos verificar la información y solo hacer caso a un profesional que conoce lo que será mejor para nosotros. Evita seguir tendencias especialmente en alimentación, todos los cuerpos son diferentes y conocernos es fundamental."},
            {"title":"Tip #7", "text":"El último tip que nos da Karla es analizar qué aporta la gente que nos rodea, ser responsables y autocríticos. Si alguien que sigues en redes sociales no está alineado con lo que tú buscas o a ti te funciona, también se vale dejar de seguir lo que no va con nosotros."},
            {"text":"Si quieres conocer más, sigue a Karla en sus redes sociales para obtener más consejos sobre estilo de vida y bienestar."},
            {"text":"¡Síguenos para mejorar tu bienestar y haz del 2024 tu mejor año! ✨"}
        ],

        "author":{
            "name":"Karla Aguirre",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/kar_agib/?hl=es"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'cambios-vida-persona-adicciones':{
        "image": lifestyle_107,
        "title": ["Cambios en la vida de una persona con adicciones"],
        "pars":[
            {"text":"Las adicciones son algo muy complejo que afecta no solo a las personas adictas sino a todas aquellas personas que forman parte de su núcleo social y familiar."},
            {"text":"El día de hoy platicamos con María Cardona, fundadora de Bendita Salud. Ella es dietista, certificada en Salud Hormonal Femenina y Fertilidad, así como en Microbiota Humana. Actualmente cursa la Licenciatura en Nutrición y comenzó a realizar divulgación en redes sociales desde el 2020, con el objetivo de informar de manera digerible y basada en la ciencia, sobre salud integral.",},
            {"text":"María nos platicó cómo es que las adicciones impactan no solo en nuestra salud física, sino también en nuestra autopercepción y autocuidado. María comenzó aclarando que las adicciones normalmente son catalogadas como el abuso de alguna sustancia que altera nuestros neurotransmisores, como el cigarro y el alcohol. Pero hoy en día existen muchas más adicciones que no requieren del uso de sustancias, como lo es la adicción al trabajo, a las apuestas o incluso las compras."},
            {"text":"Las causas exactas para caer en cualquier adicción se desconocen, pero normalmente están ligadas al sufrimiento emocional, la ansiedad, la depresión, el estrés ambiental y en muchas ocasiones a la presión social de un grupo al que se desea pertenecer. Pueden haber muchos detonantes ambientales que pudieran favorecer una adicción como violencia, heridas, o la historia de vida de las personas y también hay ciertas predisposiciones genéticas que se pueden detonar o desarrollar y culminar en una adicción."},
            {"text":"En una adicción el autocuidado pasa a un segundo plano pues partimos de que una persona con adicciones normalmente tiene una autoestima más frágil y un amor propio fragmentado;  tan es así que pone en segundo plano su salud física, mental, social y emocional pues lo único que en ese momento importa es adormecer la causa de su sufrimiento."},
            {"text":"María nos dice que trabajando de la mano con psicólogos, ella ha podido notar cómo es que los asuntos que no resolvemos desde la infancia o situaciones que nos afectaron en su momento y hemos dejado reprimidas pueden detonar la vulnerabilidad para desencadenar una adicción de cualquier tipo. La falta de confianza en uno mismo también puede impulsar acciones perjudiciales para la salud, especialmente en edades como la adolescencia cuando la aceptación y presión social son mayores y nosotros como individuos somos más vulnerables porque aún no tenemos una identidad bien definida."},
            {"text":"A medida que avanza una adicción, todas las áreas de la vida se ven afectadas. Desde lo laboral hasta los hábitos diarios, la funcionalidad disminuye gradualmente, impactando negativamente en las relaciones y responsabilidades cotidianas. Por lo que el acceso a un proceso de rehabilitación se vuelve crucial. María destaca la necesidad de un enfoque multidisciplinario, que trate desde la parte psicológica, salud mental, amor propio y funcionalidad en el entorno social."},
            {"text":"Hoy en día se puede llegar a minimizar e incluso normalizar el tema de ciertas adicciones como el alcohol, cigarro o  incluso los vapeadores. Pero todos ellos afectan fuertemente a nuestra salud física y mental, afectan a nuestro rendimiento diario,  influyen en el resto de nuestros hábitos y aceleran nuestros procesos de envejecimiento celular que se traduce a que los “achaques” y los signos de envejecimiento lleguen antes de tiempo.  Incluso si tienes alguna adicción y utilizas  tratamientos estéticos para prevenir o corregir las arrugas o líneas de expresión, estos tratamientos tendrán un efecto menos duradero. Es decir, una adicción impacta en todos los sentidos de la vida y del cuidado personal."},
            {"text":"Reducir el estigma y fomentar espacios abiertos para discutir temas de salud mental son esenciales para prevenir adicciones. Desde la crianza hasta la educación, cada aspecto de la vida contribuye a la prevención. La conciencia sobre el impacto de las sustancias en el cuerpo y la necesidad de espacios no estigmatizados impulsan una prevención más efectiva. Por ejemplo, si entendemos lo que hace el alcohol en nuestro cuerpo tendremos más razones para no consumirlo."},
            {"text":"En conclusión, María nos invita a ser conscientes de nuestro entorno, mostrar empatía y comprensión, y reconocer la importancia de reconstruir una autopercepción positiva. Hay que tener muy en cuenta que sistemáticamente hay más factores que facilitan una adicción, que factores que facilitan que alguien pueda salir de ella.  Es importante que empecemos a poner estos temas sobre la mesa con amigos o familia porque realmente uno no sabe qué lucha interna tiene el que está a un lado de ti y si necesita apoyo, que lo escuches o que lo acerques con alguien que lo pueda ayudar."},
            {"text":"La lucha contra las adicciones es colectiva, y cada uno de nosotros desempeña un papel crucial en la construcción de una sociedad más compasiva y saludable. Hay que poner más atención a nuestro entorno, a las personas que queremos y nos importan y claro que a nosotros mismos, nuestro propio corazón y nuestra propia mente."}
        ],

        "author":{
            "name":"María Cardona, fundadora de Bendita Salud",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/benditasalud.mx/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/benditasaludoficial"},
                {social:"Web",text:"", link:"https://www.tiktok.com/@benditasalud"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'nutricion-sintonia-etapas-vida':{
        "image": lifestyle_108,
        "title": ["Nutrición en sintonía: comer de acuerdo a las etapas de la vida."],
        "pars":[
            {"text":"Comienza un nuevo año y con él la oportunidad de plantearnos distintos propósitos, como el de comer más saludable. Pero… ¿Es lo mismo comer saludable a los 20’s que a los 40’s? Vamos a conocer cómo una dieta equilibrada puede ser la clave para enfrentar con éxito los desafíos diarios y alcanzar tus objetivos de bienestar a cualquier edad.  Nuestra experta de hoy nos platicará algunos tips y consejos para tener una buena alimentación, crear hábitos saludables y vivir plenamente."},
            {"text":"Diana Ancona cuenta con una licenciatura en Nutrición y Ciencia de los Alimentos,  una maestría en salud global, está certificada en alimentación complementaria y  además es creadora de 6 recetarios digitales enfocados a nutrición infantil y co-autora de varios libros como:  “Mamá en Espera”; un libro enfocado a la nutrición durante el embarazo y “Healthy Kids - Delicioso y Saludable”, un libro enfocado a la alimentación complementaria e incorporación a la dieta familiar. Este último se vende en Amazon y es top seller.",},
            {"text":"Durante nuestra charla, Diana nos reveló que el primer paso es ser consciente y estar decidido a cambiar. Contar con la orientación de un profesional puede marcar la diferencia al modificar hábitos de manera saludable. Lo ideal es que el profesional nos acompañe a lo largo de nuestra vida  y vaya adaptando nuestra alimentación conforme vamos evolucionando, pues no es lo mismo los requerimientos nutricionales que tiene un niño a los que podríamos llegar a tener en la vejez."},
            {
                "list": [
                    "1.- Una buena alimentación comienza desde la infancia. Ofrecer una buena alimentación desde los 6 meses, es decir una dieta equilibrada es un regalo de vida para los pequeños. Podemos prevenir muchas enfermedades y vamos a asegurar una buena relación con la comida y un gran futuro porque tienen menos enfermedades, mejor estilo de vida emocional y mental.",
                    "2.- Para las personas en sus 20’s, Diana nos da el tip de planificar las comidas, por ejemplo cocinar proteínas como pollo, carne o pescado para toda la semana y complementar con alimentos frescos diarios  como frutas, verduras o alimentos de temporada asegura una dieta variada y saludable.",
                    "3.- Al llegar a los 30’s, por todos los cambios físicos, metabólicos y hormonales;  es cuando más ligamos la salud mental con la alimentación y cuando llega el momento de incorporar a nuestras dietas una mayor porción de alimentos ricos en omega 3, antioxidantes y vitaminas como el complejo B que pueden impactar positivamente en nuestro bienestar emocional y mental.",
                    "4.- A los 40’s y 50’s, usualmente las personas están en el tope de la vida laboral, hay mucho estrés y empiezan a aumentar los cravings o la tentación de recurrir a alimentos altos en azúcares y/o procesados ya que ofrecen un corto \"boost\" de felicidad. Sin embargo, este impulso momentáneo es seguido por el conocido \"pico de glucosa\", resultando en cansancio, irritabilidad y antojos constantes. Este círculo vicioso no solo afecta nuestra energía física, sino que también tiene un impacto significativo en la salud mental y emocional.",
                    "5.- En cuanto a los 60’s en adelante, Diana menciona la importancia de una dieta rica en antioxidantes y antiinflamatorios para reducir el estrés oxidativo, ese estrés oxidativo causa el deterioro celular y es lo que va a hacer que luzcamos más envejecidos. También es muy importante consumir suficiente proteína y calcio para que sea más lento el proceso de envejecimiento.",
                ]
            },
            {"text":"Diana nos da un dato curioso: Importa más la calidad y cantidad de lo que consumes en el día que las horas a las que comes. Algunos alimentos de calidad que son ideales para complementar tu dieta, son los superfoods. Algunos de los que Diana nos recomienda incluir son el ajo, jengibre, quinoa, chía, frutos rojos y maca. Pero ojo, son complementos, no sustitutos de una dieta saludable. Y siempre es bueno consultar con un profesional antes de comenzar con vitaminas y suplementos."},
            {"text":"¿Te suena eso de \"somos lo que comemos\"? La piel sí refleja lo que consumimos. Una dieta equilibrada, rica en antioxidantes y vitaminas C y E, no solo mantiene la piel radiante sino que también beneficia la salud hormonal. Además de mantener tu piel joven, también te vas a sentir muy bien por dentro."},
            {"text":"La hidratación en todas las etapas de la vida: La hidratación es crucial a cualquier edad, tomar agua no solo es esencial sino que regula todo; desde el funcionamiento de tus órganos hasta la temperatura corporal."},
            {"text":"Así que, ¿cómo ajustar tu dieta a cada etapa de tu vida? La clave está en buscar el consejo de un profesional de la salud, acudir con alguien que tenga cédula y estudios actualizados, porque cada quien es un mundo y lo que le va bien a uno, al otro no tanto. Mantener una dieta fresca y variada es mejor para nuestro bienestar aunque no siempre es fácil, ¿verdad? Pero vale la pena. Así que, ya sabes, ¡invierte en tu bienestar a través de tu alimentación!"},
            {"text":"Para contactar a Diana, te dejamos sus redes sociales. Recuerda visitar betterself.mx para conocer más sobre salud, nutrición, autoestima, bienestar y más."},
        ],

        "author":{
            "name":"Diana Ancona",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/diana.anconawellness/"},
                {social:"Facebook",text:"", link:"https://www.facebook.com/diana.anconawellness"},
                {social:"Web",text:"", link:"https://www.tiktok.com/@diana.anconawellness?_t=8f36rvTGtJW&_r=1"},
                {social:"Web",text:"", link:"https://nutrimatmx.com/tienda-en-linea"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'importancia-amar-reflejo':{
        "image": lifestyle_109,
        "title": ["La importancia de amar tu reflejo (rostro y cuerpo)"],
        "pars":[
            {"text":"Hace unos días fue el Día Mundial de la Lucha contra la Depresión, es por eso que el tema de hoy está relacionado a una de las muchas causas de esta enfermedad."},
            {"text":"Profundizaremos en el impacto que la apariencia puede tener en nuestro bienestar emocional con nuestra invitada Renata Quijano. Ella es una auténtica pionera en el mundo del modelaje desde hace 9 años siendo modelo plus size en México, con 30 años de edad ha llevado su talento hasta Nueva York, en donde su trabajo ha sido publicado en medios internacionales. Además de su destacada carrera como modelo, Renata también se desempeña como editora de moda y belleza con una perspectiva de diversidad e inclusión para la comunidad latina.",},
            {"text":"Renata nos comparte su perspectiva única sobre la importancia de amar nuestro reflejo, abordando tanto la conexión física como la emocional. La relación entre nuestra percepción corporal y nuestra autoestima es innegable, y ella destaca tres pilares fundamentales que sustentan este vínculo profundo: el amor propio, la autoestima y la imagen corporal."},
            {
                "list": [
                    "Amor Propio: El amor propio es algo esencial y completamente nuestro, sin depender de estímulos externos. Implica conocer quiénes somos, identificar lo que nos gusta de nosotros mismos y sentirnos orgullosos de nuestra esencia.",
                    "Autoestima: Es el conjunto de percepciones, evaluaciones y sentimientos dirigidos hacia uno mismo, hacia nuestra manera de ser y hacia los rasgos de nuestro cuerpo. La valoración que nos damos, ya sea positiva o negativa, influye directamente en nuestra autoestima. Desde una edad temprana, la sociedad comienza a moldear esta valoración, pero es importante separar nuestras creencias internas de las influencias externas.",
                    "Imagen Corporal o Autopercepción: Este aspecto, que cambia constantemente, está vinculado a nuestra salud mental. A veces tenemos una selfie mental  de nosotros mismos y no siempre es al 100% como te ven los demás;  entonces cuando no tenemos un amor propio sólido nuestra imagen corporal se ve afectada y menospreciada. Renata aconseja separar la percepción externa de la interna, evitando que los cambios físicos afecten nuestra estabilidad mental.",
                ]
            },
            {"title":"Los estándares de belleza y las redes sociales:", "text":"Renata, apasionada de la lectura, comparte la teoría de la objetivación de Fredrickson and Roberts, que nos dice que las mujeres son objetivadas en la sociedad para cumplir con estándares predefinidos de belleza, razón por la que ella lucha por la inclusión y la diversidad, abogando por el respeto hacia todos los tipos de cuerpos y los diferentes tipos de belleza."},
            {"text":"Además, Renn nos recomendó el libro \"Beauty Sick\" de Renee Engeln, que analiza cómo nos relacionamos con el concepto de belleza desde que somos pequeñas y cómo los estándares de la sociedad nos afectan y nos “enfermamos de belleza”. Habla sobre cómo los medios de comunicación usan esta forma de control, porque entre más tiempo pasemos pensando en cómo encajar, en cómo ser más bonitas, más delgadas o aceptables; menos tiempo tendremos para pensar en lo que realmente importa, que es nuestra salud mental, alimentar nuestro amor propio y decidir hacer los cambios necesarios por nosotras mismas no por lo que opinen aquellos que nos rodean. Hay estudios que mencionan que el 40% de las niñas entre 4 y 9 años ya se encuentran preocupadas por su apariencia física."},
            {"text":"Renata sugiere hacer una limpieza en nuestras redes sociales, seguir contenido que nos haga ser mejores, en donde nos identifiquemos y nos motiven a ser nuestra mejor versión, con lo bueno y con lo malo pero siempre trabajando en nuestra confianza, autoestima y autenticidad."},
            {"title":"La presión social", "text":"La presión social y la comparación constante afectan nuestra autoimagen ya que somos seres humanos, seres sociales, seres que nos estamos relacionando con otras personas y claro que tomamos en cuenta las opiniones de los demás. Renata comparte su evolución personal, destacando la importancia de no seguir tendencias ciegamente, se dio cuenta de que la vida sólo es una y estamos aquí para disfrutarla y que la imagen corporal es nuestra carta de presentación con los demás pero también con nosotros mismos."},
            {"text":"En el Día Mundial de la Lucha contra la Depresión, Renn nos comparte que para ella el amor propio es como un salvavidas, porque nos rescata de situaciones tóxicas o que nos pueden poner en riesgo, pero también salva nuestra salud mental."},
            {"text":"Cuando tenemos un amor propio sólido y bien construido somos capaces de poner límites inamovibles, cuando realmente te amas lo suficiente no permites que nadie venga a mover tu estructura. Si lo que pensamos sobre nosotros mismos es negativo nos convertimos en nuestro peor enemigo, porque ¿cómo vamos a cuidar y proteger algo que no amas?  Es justo ahí en donde entra en juego la autocompasión, que es tratarte con todo el amor y compasión del mundo, como si estuvieras tratando a la persona que más amas."},
            {"title":"El amor propio en nuestras relaciones con los demás", "text":"El amor propio influye en todas las relaciones, porque la forma en la que nosotros nos amamos,  es cómo les enseñamos a los demás a amarnos. Es importante siempre establecer límites y construir relaciones basadas en respeto. Esto es un trabajo constante pues somos seres cambiantes y en constante evolución."},
            {"text":"Amar nuestro reflejo implica una dualidad única: conectar lo que vemos externamente con nuestra esencia interna. Renata destaca comenzar desde adentro. Reconoce que el trabajo en nosotros mismos no se limita a lo externo; al contrario, al empezar internamente, aprendemos a amar incluso nuestras \"imperfecciones\", logrando una mayor satisfacción personal."},
            {"text":"Amar lo que vemos en el espejo va más allá de lo físico, recuerda que el equilibrio está en disfrutar plenamente de nuestra vida, belleza y autopercepción."},
            {"title":"Una frase muy valiosa que nos dio Renata es decir nos vamos a “alistar” más no “arreglar” porque no estamos rot@s. Las creencias que tenemos sobre nosotros mismos son difíciles de romper pero nos ayudarán a crecer mucho y sobre todo fortalecerán la confianza de ser nosotros mismos."},
            {"text":"¡Sigue a Renata en sus redes sociales para más inspiración y visita betterself.mx para explorar temas fascinantes sobre amor propio y bienestar emocional!"}
        ],

        "author":{
            "name":"Renata Quijano",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/rennquijano/"},
                {social:"Web",text:"", link:"https://www.tiktok.com/@rennquijano17"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'real-skin-celebrando-belleza':{
        "image": lifestyle_110,
        "title": ["Real Skin, celebrando la belleza única de cada tipo de piel"],
        "pars":[
            {"text":"En esta ocasión, a unos días del día del dermatólogo, exploramos la belleza diversa y única de cada tipo de piel, destacando la importancia de su cuidado y alentandote a buscar la orientación de un profesional médico."},
            {"text":"En este blog hablaremos sobre la importancia de apreciar las cualidades individuales de nuestra piel y cómo un dermatólogo puede ayudarnos a realzar esa belleza. Dejemos de lado los productos por un momento y sumerjámonos en este interesante tema del cuidado dermatológico en conjunto con nuestra invitada especial la Dra. Karen Sánchez.",},
            {"text":"Ella es especialista en dermatología oncológica y cirugía dermatológica, cuya experiencia y dedicación la han posicionado como líder en su campo. Es graduada con honores de la Universidad La Salle, a lo largo de los años ha continuado destacándose en su carrera, siendo miembro activo de prestigiosas instituciones médicas y contribuyendo con valiosas investigaciones publicadas. Su trayectoria la ha convertido en una voz influyente en la promoción de la salud cutánea y la excelencia en la dermatología."},
            {"title":"¿Qué es la real skin?", "text":"La Dra. Karen nos explica que la Real Skin es una piel sana que refleja lo mejor de nuestro tipo de piel. No se trata de buscar la perfección, sino de cuidar y respetar su equilibrio para alcanzar su mejor versión."},
            {"text":"En un consultorio dermatológico nos dice la Dra. Karen que lo que se busca no solo una piel estéticamente agradable sino también una piel que funcione al 100%, cumpliendo su función como mecanismo de protección. Así, logramos la imagen que queremos proyectar al mundo; pero también una piel funcional y saludable."},
            {"title":"¿Los factores genéticos influyen en la salud de la piel?", "text":"La presión social y la comparación constante afectan nuestra autoimagen ya que somos seres humanos, seres sociales, seres que nos estamos relacionando con otras personas y claro que tomamos en cuenta las opiniones de los demás. Renata comparte su evolución personal, destacando la importancia de no seguir tendencias ciegamente, se dio cuenta de que la vida sólo es una y estamos aquí para disfrutarla y que la imagen corporal es nuestra carta de presentación con los demás pero también con nosotros mismos."},
            {"title":"El acné y su impacto en la autoconfianza", "text":"La Dra. nos comentó que el acné es una enfermedad común, que afecta hasta al 97% de sus pacientes en consultorio, especialmente durante la adolescencia, donde el 99% de las personas la experimentan. Además, es importante tener en cuenta que incluso en adultos jóvenes, especialmente entre los 20 y 30 años, el acné puede persistir. Esta condición puede tener un impacto negativo en la autoestima de quienes la padecen, por lo que es crucial buscar tratamiento con un dermatólogo, especialista en enfermedades de la piel. Un diagnóstico y tratamiento adecuados pueden mejorar significativamente la autoestima al lograr una piel sana y hermosa."},
            {"title":"¿Qué opciones existen para tratar el acné?", "text":"Afortunadamente, actualmente contamos con una amplia gama de herramientas, desde una rutina cuidadosamente diseñada por un dermatólogo hasta medicamentos específicos. La Dra. Karen nos ofrece algunas recomendaciones importantes:",
                "list": [
                    "Evitar mezclar productos.",
                    "Tener cuidado con las sugerencias bien intencionadas, ya que pueden resultar contraproducentes si ya estamos siguiendo un tratamiento eficaz.",
                    "Consultar a un dermatólogo, quien puede ofrecer opciones como herramientas especializadas, equipos, láser y bioestimuladores de colágeno.",
                    "No olvidar usar protector solar para proteger la piel."
                ]
            },
            {
                "text":"Dato importante: El acné es de los pocos padecimientos que se acercan a un 100% de posible recuperación si es que están bien diagnosticados y el paciente sigue el tratamiento."
            },
            {"title":"¿Cómo proteger nuestra piel del sol y de la contaminación?", "text":"La Dra. Sánchez nos alerta sobre los dos grandes enemigos de nuestra piel: el sol y la contaminación. Estos pueden causar daños a largo plazo como manchas, arrugas e incluso cáncer de piel. Por ello, es esencial proteger nuestra piel de estos agentes nocivos. Aquí algunos consejos que nos brinda:",
                "list": [
                    "Utiliza un protector solar de amplio espectro todos los días, sin importar si está nublado o si pasas poco tiempo al aire libre. Este bloqueará los rayos UV y combatirá el daño celular y el estrés oxidativo. Elige un protector solar adecuado para tu tipo de piel y reaplícalo cada 4 horas, especialmente si estás expuesto a la luz azul de las pantallas, una exposición que se ha vuelto más común desde la pandemia.",
                    "Protege tu piel con ropa, sombreros, gafas de sol y sombrillas, sobre todo durante las horas de mayor radiación, que suelen ser entre las 10 de la mañana y las 4 de la tarde.",
                    "Incorpora productos con antioxidantes, como la vitamina C o la niacinamida, para combatir los radicales libres y mejorar el tono y la luminosidad de la piel. También considera suplementos antioxidantes, siempre bajo la supervisión médica."
                ]
            },
            {"text":"'Real Skin', una piel sana y hermosa, es alcanzable cuidando nuestros hábitos, eligiendo productos adecuados y buscando la orientación de un dermatólogo. Sigue los sabios consejos de la Dra. Karen, establece una rutina de cuidado personalizado y busca siempre la asesoría profesional para lograr una piel radiante. ¡Cuida tu piel y celebra su belleza única!"},
            {"text":"Amar lo que vemos en el espejo va más allá de lo físico, recuerda que el equilibrio está en disfrutar plenamente de nuestra vida, belleza y autopercepción."},
            {"text":"No olvides seguir a la Dra. Karen en sus redes sociales para obtener más consejos sobre el cuidado de la piel."},
            {"text":"Encuéntranos en Facebook, Instagram y Spotify como @betterselfmx"},
            {"text":"Dra. Karen Sánchez Armendáriz Cédula Profesional Especialidad en Dermatología: 8129377. Escuela: Universidad Nacional Autónoma De México. Cédula Profesional Licenciatura como Médico Cirujano: 5579713. Universidad La Salle"}
        ],

        "author":{
            "name":"Dra. Karen Sánchez Armendáriz.",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/dra.karensaderma/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'hacks-incluir-ejercicio-vida': {
        "image": lifestyle_111,
        "title": ["Hacks para incluir de forma efectiva el ejercicio en tu vida"],
        "pars":[
            {"text":"Incorporar el ejercicio en nuestra rutina diaria puede ser un desafío, pero también es una clave fundamental para una vida saludable y activa. En este blog, nuestro invitado Armando Ubeda nos dará unos hacks y consejos prácticos para superar el sedentarismo y dar paso a un estilo de vida más enérgico. Él es corredor maratonista, ha corrido 14 maratones alrededor del mundo, es líder y entrenador de una comunidad de runners en CDMX; asimismo, ha trabajado en diferentes actividades para crear un ambiente seguro e inclusivo en los running crews de la ciudad y amante de vivir en balance en la vida, con un ambiente saludable y contagiar a su comunidad del espíritu corredor. Descubre cómo hacer del ejercicio una parte integral de tu día a día, ya sea que estés dando tus primeros pasos hacia una vida más activa o buscando revitalizar tu rutina de ejercicios."},
            {"text":"Armando nos platica que el tiempo es el principal obstáculo para muchos que queremos incorporar el ejercicio a nuestro día a día. La vida agitada, el trabajo y las responsabilidades familiares a menudo nos dejan exhaustos. Sin embargo, nos insta a priorizar el ejercicio, destacando que siempre hay tiempo si lo consideramos una prioridad así que comencemos con los hacks:",},
            {"text":[<span className="texto-pink">Hack #1: </span>, "Armando nos menciona que es esencial establecer objetivos claros. Inscríbete a una carrera o establece metas alcanzables. Esto te dará la motivación necesaria para superar la pereza inicial y te ayudará a mantener una rutina constante. En ocasiones, los primeros días pueden ser fáciles pero al paso de los días puedes llegar a preguntarte ¿por qué estoy haciendo esto? Y optas por dejar el hábito de hacer ejercicio. Es por ello que tener objetivos claros es esencial."]},
            {"text":[<span className="texto-pink">Hack #2: </span>, "Dejar de lado los prejuicios y probar distintas actividades. Armando nos dice que él antes criticaba correr y una vez que comenzó a hacerlo, encontró su pasión en esta disciplina. Hizo la prueba con el fútbol y definitivamente no le gustó. De ahí que nos sugiere explorar diversas opciones hasta dar con algo que realmente disfrutes. No todos los ejercicios son iguales, ni todos nos gustan o nos benefician de la misma manera. Por eso, es importante que encontremos la actividad física que se adapte mejor a nuestros gustos y necesidades, y que nos haga sentir bien y felices."]},
            {"text":[<span className="texto-pink">Hack #3: </span>, "Estos tips están basados en la vida cotidiana y laboral. Entendemos que tenemos responsabilidades pero darnos un tiempo para nosotros es importante incluso para tu salud mental, nos dice Armando."], list: [ 
                "Estar en movimiento.",
                "Tomar las escaleras en lugar del elevador.",
                "Darte un break de 10 minutos para parate.",
                "Si estás en una oficina, salir un momento a tomar aire fresco y caminar.",
                "50 minutos concentrado en una actividad y 10 de distracción o descanso.",
                "Darte 10 minutos de estiramiento.",
                "Despertar temprano para tener más tiempo libre e incluso ser más productivo.",
            ]},
            { "text":[<span className="texto-pink">Hack #4: </span>, "Armando destaca la importancia de encontrar tiempo para el ejercicio sin descuidar otras responsabilidades. Sugiere aprovechar las pausas durante el día y dividir el tiempo de ocio para incluir la actividad física."]},
            {"text":"Armando nos dice cómo el ejercicio beneficia no solo físicamente, sino también mental y emocionalmente. Nos comenta que las personas que hacen actividad física tienden a sentirse más felices, con energía y mucho más relajadas. Por ejemplo, las personas que tienen ansiedad bajan su nivel de estrés. Sin embargo, cabe mencionar que el ejercicio no sustituye a la terapia, aunque ayuda a relajarse y, sobre todo, a mejorar emocional y mentalmente."},
            {
                "text":"Como coach, nos comparte una analogía que es comparar la vida con un maratón. Ya que en ambos enfrentamos momentos de desafío y satisfacción durante el trayecto. Entonces ¿por qué no darle una oportunidad al ejercicio y descubrir cómo puede transformar tu vida? No se trata solo de mantenerse activo físicamente, sino de encontrar un equilibrio que te haga sentir bien contigo mismo e impulse tanto tu salud física como mental. ¡Atrévete a dar el primer paso hacia una vida más enérgica y saludable!"
            },
            {"text":"Sigue a Armando en sus redes sociales para obtener más consejos sobre actividad física, comparte esta valiosa información con tus amigos y mantente al tanto de nuestros próximos blogs donde abordaremos más temas fascinantes relacionados a la salud y el bienestar general.",},
            {"text":"Encuéntranos en Facebook, Instagram y Spotify como @betterselfmx"}
        ],

        "author":{
            "name":"Armando Ubeda",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/mandoubeda/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'mujer-siglo-xxi': {
        "image": lifestyle_112,
        "title": ["Ser mujer en el siglo XXI"],
        "pars":[
            {"text":"En este tiempo, ser mujer se ha convertido en un cambio multifacético donde la fuerza se entrelaza con la sensibilidad. En este blog, hablamos con Antonina Jourdain que es una mujer multifacética, madre, blogger y co-fundadora de “Madre Tribu” junto con Tabatha Vizuet. También es Licenciada en Comunicación por la Universidad Anáhuac del Sur con dos másteres en producción y conducción de programas en vivo por el Instituto de radio y televisión Española."},
            {"text":"Nina nos comenta que el autodescubrimiento y empoderamiento de ser mujer es abrazar la diversidad, celebrar nuestras fortalezas y enfrentar juntas los desafíos que se presenten; es por ello que nos comparte una visión inspiradora sobre lo que significa ser mujer en esta era de transformación.",},
            {"text":"Las mujeres han evolucionado mucho en los últimos años. Antes, la mujer estaba dedicada solamente a la casa. Ahora, la mujer tiene un rol más trascendental, sobre todo en su crecimiento profesional y laboral. Sin embargo, esto no significa que haya dejado de lado su amor propio y su bienestar. Al contrario, las mujeres han aprendido a encontrar el equilibrio entre sus diferentes facetas y a llenar ese hueco que a veces sentían cuando solo tenían el papel de ama de casa."},
            {"text":"Las mujeres han aprendido a ser multitask, a que todo lo pueden, que nadie les dice que no y que no necesitan ayuda. Pero la realidad es que sí, sí necesitamos ayuda y apoyo. Nina nos dice que lo que hablan un poco en Madre Tribu es esto: tengas o no una pareja, tener un grupo de mujeres que te ayude, escuche y que junto con ellas puedas crecer es esencial. Además nos compartió una frase que le encanta de Odin Dupeyron, que es “la terapia es canasta básica”. Por lo que para Nina, el no abandonarse es lo más importante."},
            {"text":"Pero no todo es color de rosa, ¿a qué retos nos enfrentamos hoy en día las mujeres? Nina nos platica que los principales retos son:", list: [
                "Los estándares son inalcanzables, no tenemos que ser multitask todo el tiempo, lucir perfectas y ser de cierta forma para que la sociedad nos acepte.",
                "Nos importa mucho lo que otros opinen de nosotras, hay que aprender a sacudir lo que nos “critican” y tomar las cosas de quien vienen.",
                "No aprendemos a comunicarnos por los miedos y el tabú que también es un reto.",
                "Si te estás comparando constantemente, nunca vas a lograr llegar a lo que quieres ser, porque estás tratando de llenar las expectativas de otra persona, más no las tuyas y eso afecta mucho la salud emocional.",

            ]},
            { "text":"Por eso, es importante tener una pasión, nutrirte con lo que te gusta, tener alguien con quien platicar y no olvidarte de ti misma. En este punto en particular, Nina nos contó una experiencia personal de hace años; nos relata que tuvo anorexia y bulimia y después fue comedora compulsiva, lo que la llevó a crear una dismorfia por temas emocionales. Cuando tocó fondo y se dio cuenta de que su autopercepción estaba alejada de la realidad, buscó ayuda de un profesional y pudo superarlo. Por lo que nos aconseja que: si una mujer se quiere a sí misma en todos los aspectos, si se quiere cuidar, se va a cuidar sin dejarse llegar a extremos."},
            {"text":"Cuando empiezas a perder tu seguridad, tu confianza y te das cuenta de eso tienes que hablarlo o buscar ayuda de expertos, como Madre Tribu o BetterSelf®; donde te escuchen o canalicen con las personas correctas que te puedan ayudar. Además, Nina nos recuerda que hay que seguir en redes sociales a personas que te den paz y evitar el querer ser como esa persona. Porque lo que se muestra en redes es su realidad, más no la suya. Toma lo bueno de esos influencers, pero no lo conviertas en una regla en tu vida."},
            {
                "text":"Nina nos da una analogía: No debes ser tan exigente, porque no todo es negro y blanco en la vida. Incluso, las tonalidades grises son muy bonitas. Hay puntos medios y en esos puntos se encuentra la armonía de la vida. Aprende a vivir en las tonalidades de grises."
            },
            {"text":"Como consejo final, Nina dice que hay que sacudirse el miedo a no cumplir la expectativa de alguien más, a no esperar qué dirán de ti en cualquier situación. Hacer las cosas que te hagan feliz, buscar cumplir tus expectativas, porque si no estás bien, no le das lo mejor a quienes te rodean. Tienes que estar bien para proyectar ese bienestar.  Se vale no poder, se vale llorar un día, se vale caerse pero lo que no se vale es esperar que alguien más te levante. Si un día te caes al día siguiente te paras, pones una sonrisa y vas por tus sueños, si un día fue malo al día siguiente va a ser mejor pero no esperar que alguien más haga lo que tú tienes que hacer por ti. Solo tú puedes cambiar tu destino, sólo tú puedes cambiar lo que tú piensas de ti, el cambio viene de ti hacia ti y de ahí vas a irradiar la luz que necesitas para que todo el mundo empiece a ver lo que tú quieres que vean de ti.",},
            {"text":"Ser mujer es un reto, pero también una oportunidad. De ser auténticas, de expresar tu voz, de crear tu propia historia. Una oportunidad de ser felices, de ser plenas, de ser tú misma. Una oportunidad de ser tu BetterSelf®."},
            {"text":"Recuerda seguir a Nina en sus redes sociales ya que es una gran referente de empoderamiento femenino. Y si quieres conocer más tips de bienestar, autocuidado, nutrición, y más, BetterSelf® es para ti."},
        ],

        "author":{
            "name":"Antonina Jourdain",
            "contacts":[
                {social:"Instagram",text:"", link:"https://www.instagram.com/soyninamx/"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/madretribu/"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    'salud-360-mental-fisica-pareja-laboral': {
        "image": lifestyle_113,
        "title": ["Salud 360° - mental, física, de pareja, laboral"],
        "pars":[
            {"text":"La salud es un concepto amplio que abarca no solo el aspecto físico, sino también el mental, el emocional, el social y el laboral. Cada uno de estos ámbitos influye en nuestra calidad de vida y en nuestra felicidad. Por eso, es importante cuidar nuestra salud de forma integral, atendiendo a las necesidades y los desafíos que se presentan en cada uno de ellos."},
            {"text":"Para hablar más sobre este tema invitamos a Adály López Sierra, quien es Licenciada en Psicología Clínica con una destacada trayectoria y un profundo compromiso con el bienestar integral. Cuenta con una Especialidad en Clínica Sistémica Familiar, y una Maestría en Psicología Clínica. Su impacto se extiende aún más a través de sus redes sociales, donde su espacio psicoeducativo \"1minutodepsicologia\" cuenta con más de 1.7 millones de seguidores entre TikTok e Instagram.",},
            {"text":"Además es autora del libro \"MANUAL DE EMOCIONES\", un recurso invaluable para aquellos que buscan comprender y gestionar sus emociones de manera saludable. Como socia de Mujer21, una organización dedicada a la identificación, prevención y erradicación de la violencia contra la mujer, Adály demuestra un compromiso activo con la promoción de relaciones saludables y la erradicación de la violencia de género."},
            {"text":"Las mujeres han aprendido a ser multitask, a que todo lo pueden, que nadie les dice que no y que no necesitan ayuda. Pero la realidad es que sí, sí necesitamos ayuda y apoyo. Nina nos dice que lo que hablan un poco en Madre Tribu es esto: tengas o no una pareja, tener un grupo de mujeres que te ayude, escuche y que junto con ellas puedas crecer es esencial. Además nos compartió una frase que le encanta de Odin Dupeyron, que es “la terapia es canasta básica”. Por lo que para Nina, el no abandonarse es lo más importante."},
            { "title":"La interconexión de la salud","text":"En un mundo interconectado, la salud mental, física, de pareja y laboral interactúan de manera compleja en la vida de cada individuo. Adály López Sierra nos enseña que somos sistemas inmersos en otros sistemas, como la pareja, el trabajo, los amigos y la sociedad en general. Cada interacción y dinámica dentro de estos sistemas puede tener un impacto significativo en nuestro bienestar. Esta comprensión nos lleva a reconocer la importancia de abordar la salud de manera holística, considerando cada aspecto de nuestra vida."},
            {"text":"Los roles de género pueden influir en la forma en que hombres y mujeres abordan su salud mental y emocional. Desde las expectativas sociales impuestas a las mujeres para equilibrar la maternidad y la carrera, hasta la tendencia de los hombres a evitar buscar ayuda, estos roles pueden impactar en la salud de manera significativa."},
            {"title":"Desafíos en el ámbito laboral", "text":"Desde su experiencia clínica, Adály identifica varios desafíos que pueden afectar la salud mental y física en el entorno laboral. La falta de límites, tanto por parte del individuo como de la empresa, puede llevar a un desequilibrio entre el trabajo y la vida personal. La falta de apoyo, reconocimiento y un ambiente laboral violento como no respetar los horarios laborales o de comida, días de descanso, humillaciones, falta de flexibilidad, etc., también pueden contribuir a estos desafíos."},
            {"text":"Para mantener un equilibrio saludable entre la vida laboral y personal, Adály nos recomienda identificar nuestras emociones y necesidades dentro y fuera del trabajo. Esto nos permite tomar conciencia de los desencadenantes del estrés y encontrar formas efectivas de gestionarlo. Además, destaca la importancia de establecer límites, buscar apoyo y fomentar relaciones positivas tanto en el trabajo como en casa, personas que están para ayudarte, acompañarte, escucharte o darte otra perspectiva que te pueda brindar tranquilidad, por ejemplo ante una situación."},
            {"text":"Las relaciones interpersonales y el ambiente laboral desempeñan un papel importante en la salud mental de los trabajadores. Un ambiente laboral que fomente la comunicación, el apoyo y la autonomía puede contribuir significativamente al bienestar de los empleados."},
            {"text":"Identificar y gestionar el estrés laboral de manera efectiva es esencial para preservar el bienestar físico y emocional. Adály nos recomienda buscar ayuda profesional, establecer límites y practicar el autocuidado fuera del trabajo."},
            {"title":"Importancia de las relaciones de pareja", "text":"La calidad de una relación de pareja puede tener un impacto profundo en la salud general de cada individuo. Adály nos dice que la pareja es una dinámica que se va autoconstruyendo, no es algo fijo y universal; entonces en esta construcción, la comunicación, el apoyo mutuo y el respeto son fundamentales para crear un espacio seguro que contribuya al bienestar emocional y mental."},
            {"text":"Adály nos comparte los signos de alerta que podrían indicar una relación de pareja negativa, como la falta de equidad, la falta de libertad individual, la violencia física, económica, psicológica o patrimonial. Reconocer estos signos es crucial para preservar la salud mental y emocional."},
            {"title":"Fortaleciendo la autoestima","text":"Adály nos explica que la autoestima tiene que ver con cómo nos percibimos a nosotros, cómo nos relacionamos con los demás, cómo afrontamos los problemas de la vida. Pero que a veces uno piensa que el autoestima siempre va a estar arriba pero la realidad es que el autoestima siempre va fluctuando, por ello, debemos fortalecer nuestra autoestima mediante el establecimiento de límites, la búsqueda de apoyo y la toma de decisiones que promuevan nuestro bienestar."},
            {"text":"Finalmente, nos ofrece consejos prácticos para aquellos que buscan alcanzar un estado de salud 360°: Priorizar la salud, establecer límites y contar con redes de apoyo son pasos clave para lograr un equilibrio saludable en todas las áreas de la vida."},
            {"text":"En resumen, ver la salud 360° nos invita a reflexionar sobre la importancia de cuidar nuestra salud de manera integral, reconociendo la interconexión entre todos los aspectos de nuestra vida. Con el conocimiento y las herramientas adecuadas, podemos trabajar hacia un estado de bienestar físico, mental, emocional, social y laboral."},
            {"text":"Si te gustó este blog, te invitamos a seguirnos en redes sociales para conocer más sobre temas de autocuidado y bienestar integral."},
            {"text":"¡No olvides seguir a Adály en sus redes para conocer más de sus increíbles e importantísimos consejos!"},
        ],

        "author":{
            "name":"Adály López Sierra",
            "contacts":[
                {social:"Facebook",text:"", link:"https://www.facebook.com/1minutodepsicologiamexico"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/1minutodepsicologia/"},
                {social:"Web",text:"", link:"https://www.tiktok.com/@1minutodepsicologia"},
            ],
            "links":[
                {text:"LICENCIATURA EN PSICOLOGÍA - UNIVERSIDAD REGIOMONTANA - 12854063"},
                {text:"MAESTRÍA EN PSICOLOGÍA CLÍNICA - UNIVERSIDAD DE MONTERREY - 13542242"},
                {text:"ESPECIALIDAD EN CLÍNICA SISTÉMICA - UNIVERSIDAD DE MONTERREY - 13437733"},
            ]
        },
        "refs":[
        ]
    },
    'armonia-facial-masculina': {
        "image": lifestyle_114,
        "title": ["Armonía facial masculina"],
        "pars":[
            {"title":"¿Alguna vez te has preguntado cómo la Medicina Estética puede ser la clave para resaltar la auténtica armonía de tu rostro?", "text":"En este blog hablaremos con la Dra. María Lozada, egresada de la Universidad Nacional Autónoma de México, quien tiene un Máster en Ozonoterapia avalado por la Asociación Española de Profesionales Médicos en Ozonoterapia. La Dra. María ama la medicina integral, ya que es el lugar donde se puede juntar el bienestar interior que se refleja en lo físico; así que en su trayectoria de medicina estética busca que encuentres tu mejor versión. Ella nos habla sobre cómo lograr una imagen que refleje la verdadera armonía facial masculina."},
            {"text":"La Dra. María nos explica primero sobre las diferencias clave en la armonía facial masculina y femenina y nos dice que la parte clave es el tema de la mandíbula, pues en la masculinización las aplicaciones buscan lograr una mandíbula intensa y ruda, así como un mentón mucho más proyectado. Mientras que en la feminización del rostro se busca una cara más delgada, afilada y finita. Además, nos habla sobre cómo los hombres tienden a preocuparse menos por las arrugas y más por la estructura facial que las mujeres."},
            {"title":"¿Cuáles son las principales zonas/características que deben considerarse para lograr un rostro masculino?", "text":"La Dra. María nos revela que son el mentón, el perfil mandibular, la rama mandibular y los pómulos. Destaca la importancia de tener en cuenta la piel más gruesa de los hombres al planificar los tratamientos."},
            {"text":"Con base en su experiencia la Dra. Lozada nos dice que la demanda de procedimientos estéticos entre los hombres ha aumentado significativamente ya que tiene un 30-40% de pacientes que buscan aplicarse tratamientos como toxina botulínica o ácido hialurónico; y que de hecho los hombres suelen ser más constantes en sus citas. Nos comenta que en su clínica, la mayoría de los pacientes masculinos suelen decir cosas como “hazme todo lo que quieras hoy” o “yo quiero venir dos o tres veces máximo”, no buscan apapachos sino ir a consulta, recibir su tratamiento inyectable y posterior a ello preguntan cuándo deben regresar y se comprometen verdaderamente a acudir a su siguiente cita. Son pacientes que saben y se comprometen con el tiempo, pero no son personas a quienes se pueda citar tan constantemente."},
            {"title":"Percepciones erróneas comunes sobre el cuidado de la piel:", "text":"La Dra. nos dice que lo más importante es la protección solar, ya que siendo realistas a todos nos pega el sol diariamente, por lo que debemos empezar a quitar estereotipos donde solo las mujeres deben cuidarse para verse bien. Realmente no es una cuestión solo de verse bien, sino de lucir una piel saludable."},
            {"text":"Por ejemplo, la Dra. nos platica que algunos de sus pacientes le comentan que se sienten bien pero sienten que su rostro y facciones lucen cansados aunque no lo estén. En estos casos, la Dra. María les explica que no pasa nada, todos tenemos arrugas o cositas que no nos gustan y lo que se busca en consulta es vernos como nos sentimos. Si te sientes bien deberías lucir bien."},
            {"title":"¿Qué se puede hacer para recuperar el volumen facial perdido?", "text":"En estos casos nos dice la Dra. María que lo primero es generar las medidas de la cara del paciente, nos comenta que estas medidas las saca a través de una cámara 3D para medicina estética que da los datos automáticamente. Pero en caso de no tenerlo, hay que tomar las medidas con una regla de las porciones para generar una simetría con rellenos como lo es el ácido hialurónico, que es un activo que produce el cuerpo de forma natural. En temas de voluminización nos explica que por ejemplo, el lado izquierdo de la cara casi siempre es más grande que el derecho, entonces necesitaremos más ácido hialurónico de un lado que del otro. También se debe contemplar de qué lado duerme el paciente para poder darse cuenta de la cantidad que necesita en la aplicación."},
            {"title":"¿Cuáles son las diferencias entre un filler y el skincare tópico en cuanto a resultados y duración?", "text":"Cuando algún paciente llega a consulta, la Dra. les explica que el skincare en casa es algo para que el tratamiento estético hecho en cabina pueda durar más tiempo. Una crema jamás aportará volumen, tampoco hará que tus huesos luzcan más gruesos, ni te quitará las arrugas, pero lo que una buena rutina de skincare sí generará es que posteriormente al trabajo en cabina y aplicación de fillers, como ácido hialurónico o toxina botulínica, te duren más los resultados."},
            {"title":"¿Cómo lograr resultados sutiles y armoniosos sin perder la expresión facial masculina?","text":"Actualmente todos, tanto médico como paciente, buscamos un resultado natural. Pero esta naturalidad no se basa en el número de jeringas que se apliquen a cada paciente, pues nos comenta la Dra. Lozada que tiene pacientes que lucen igual de naturales así uno se aplique 10 jeringas y el otro solo 2, la valoración individual de cada paciente es importante."},
            {"text":"Un valioso consejo que la Dra. comparte es este: antes de someterte a un tratamiento estético, observa el aspecto de tu médico para lograr la naturalidad que buscas. La percepción de la \"naturalidad\" es subjetiva, ya que cada persona tiene su propio concepto y quizá la percepción de naturalidad de tu médico es muy distinta a la tuya. Por lo tanto, como paciente, es crucial observar a tu médico, al igual que para el médico, es fundamental analizar las medidas del paciente para obtener los mejores resultados. Es importante tener en cuenta que todos perdemos grasa y hueso con el tiempo y es una alternativa para reestructurarlos es la aplicación de ácido hialurónico para alcanzar una armonía facial masculina. Recuerda siempre consultar a un especialista de la salud certificado antes de cualquier procedimiento."},
            {"text":"Nos encantaría concluir este blog con una frase de la doctora:"},
            {"text":"\"La belleza es mi filosofía de vida y todos podemos habitar la versión más auténtica de nosotros mismos y reflejarla en el exterior. La armonía está en los detalles y no importa el tamaño del cambio, todos hacen una diferencia.\" - Dra. María Lozada."},
            {"text":"Para saber más sobre el cuidado de la piel no olvides seguir a la Dra. María Lozada en sus redes sociales y recuerda que tenemos más de estos temas de la mano de expertos en nutrición, salud, bienestar y más."}
        ],

        "author":{
            "name":"Dra. María Lozada",
            "contacts":[
                {social:"Facebook",text:"", link:"https://www.facebook.com/DraMarLozada"},
                {social:"Instagram",text:"", link:"https://www.instagram.com/dra.marialozada/"},
                {social:"Web",text:"", link:"https://cliniquedozono.com.mx/"},
            ],
            "links":[
                {text:"MARIA GUADALUPE LOZADA ARENAS. LICENCIATURA COMO MÉDICO CIRUJANO. CÉD. PROF.: 10973950. UNIVERSIDAD NACIONAL AUTÓNOMA DE MÉXICO."},
                {text:"MAESTRÍA EN MEDICINA ESTÉTICA Y LONGEVIDAD. CÉD. PROF.: 12019381. CENTRO DE ESTUDIOS UNIVERSITARIOS DEL CONDE."},
            ]
        },
        "refs":[
        ]
    },
}
