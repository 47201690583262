import React from 'react';
import './App.css';

import {Link} from 'react-router-dom';

import logo from './images/1x/betterself_logo.png';
import icono from './images/1x/betterself_logo.png';
import fb_logo from './images/1x/betterself_fb_logo.png';
import instagram_logo from './images/1x/betterself_instagram_logo.png';
class PieDePagina extends React.Component {

render(){
    return(
      <div className="PieDePagina">  
        <div className="footer-info">
          <div className="footer-left">
            <div className="footer-left-data">
              <div className="footer-links">
                <Link className="foot-link texto-white texto-bold texto-l texto-spaced selected" to="/">BETTERSELF</Link>
                <Link className="foot-link texto-white texto-s" to="/cosmetics">COSMÉTICA</Link>            
                <Link className="foot-link texto-white texto-s" to="/lifestyle">ESTILO DE VIDA</Link>
                {false && <Link className="foot-link texto-white texto-s" to="/diagnostic">DIAGNÓSTICO</Link>}
                {false && <Link className="foot-link texto-white texto-s" to="/specialist">ESPECIALISTAS</Link>}
              </div>
              <div className="footer-social">
                <a href="https://www.facebook.com/betterselfmx/" rel="noopener noreferrer" target="_blank"><img src={fb_logo} alt="Facebook logo"/></a>
                <a href="https://instagram.com/betterselfmx?igshid=w2jo00zje5kx" rel="noopener noreferrer" target="_blank"><img src={instagram_logo} alt="Instagram logo"/></a>
              </div>
              
            </div>
          </div>
          <div className="footer-right">
            {false && <div className="footer-right-data">
              <span className="texto-white texto-bold texto-m texto-spaced">Inscríbete a nuestro newsletter</span>
              <form className="contact_form">
                <div className="form_base_data">
                  <div className="form_input">
                    <label htmlFor="name" className="texto-white texto-s texto-spaced texto-raleway">NOMBRE:</label>
                    <input type="text" className="texto-white texto-s texto-raleway"  id="contact_name" name="name" placeholder="" />
                  </div>
                  <div className="form_input">
                    <label htmlFor="email" className="texto-white texto-s texto-spaced texto-raleway">CORREO ELECTRÓNICO:</label>
                    <input type="text" className="texto-white texto-s texto-raleway" id="contact_email" name="email" placeholder="" />
                  </div>
                </div>
                <div className="form_message_data">
                  <div className="form_input">
                    <label htmlFor="name" className="texto-white texto-s texto-spaced texto-raleway">MENSAJE:</label>
                    <textarea className="texto-white texto-s texto-raleway"  id="contact_message" name="message" placeholder="" />
                  </div>
                  <div className="form_input">
                    <button className="texto-white texto-s texto-spaced texto-raleway submitButton" method="POST">
                      ENVIAR
                    </button>
                  </div>
                </div>
              </form>
            </div>}        
          </div>
        </div>
        <div className="footer-final">
          <button style={{border:'none',backgroundColor:'transparent', color:'white', textTransform:'uppercase'}} id="ot-sdk-btn" className="ot-sdk-show-settings foot-link texto-white texto-s"> Ajustes de cookies</button>
          <div className="" style={{display:'flex',flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
            <a  className="foot-link texto-white texto-s" target="_blank" rel="noopener noreferrer" href='/ES_Política_de_Privacidade_Externa_VF_agosto_2023.pdf' >AVISO DE PRIVACIDAD</a>
            <span className="texto-white " style={{fontSize:'0.75em'}} >Permiso No. 203300201B0104 <br/> CONSULTE A SU MÉDICO</span>
          </div>
          <a className="foot-link texto-white texto-s" target='_blank' rel='noopener noreferrer' href='https://www.betterself.mx/cookies' >LISTA DE COOKIES</a>
        </div>
      </div>
    )}
}
    export default PieDePagina;
