import React from 'react';
import './App.css';

import quote_icon from './images/1x/betterself_quote_icon.jpg';

import {Link} from 'react-router-dom';

class Paciente extends React.Component{
    render(){
        return(
          <div className="paciente-post" style={{color:'#808080', textAlign:'left'}}>
            <div className="paciente-image" >
              <img src={this.props.imagen} style={{width:'100%'}} alt='Imagen de blog'/>
            </div>
            <div className="paciente-content">
              <div className='paciente-quote'>
                <img src={quote_icon} style={{width: '100%'}} alt='Quote'/>
              </div>
              <div className='paciente-text'>
                <span className='texto-pink texto-m'>{this.props.nombre},</span>
                <span className='texto-pink texto-s'>{this.props.paciente}</span>
                <span className='texto-normal texto-raleway texto-s'>"{this.props.descripcion}".</span>
              </div>
            </div>
          </div>
        )
    }}
    export default Paciente;