export default {
    "image": "",
    "title":"AVISO DE PRIVACIDAD",
    "pars":[
        {
            "title":"1. CONSENTIMIENTO",
            "text":"El titular de los datos personales (en adelante el “Titular”) expresa su consentimiento liso y llano con el presente Aviso de Privacidad de Merz Pharma, S.A. de C.V. (en adelante “Merz”). El Titular expresamente autoriza a Merz para que lleve a cabo la obtención, acceso, uso, aprovechamiento, divulgación, transferencia, disposición y/o almacenamiento (en lo sucesivo todas y cada una de estas actividades denominadas conjuntamente como el “Tratamiento”) de la información general, legal, contable, financiara y patrimonial, así como de los datos personales del Titular, tales como nombre completo, domicilio, teléfonos, correo electrónico, fecha de nacimiento, información de formas de pago, CURP y RFC (en lo sucesivo denominados conjuntamente como los “Datos Personales”), obtenidos mediante y/o a través de nuestra(s) página(s) de internet; correos electrónicos; formularios y/o de cualquier otra forma, en el entendido que en ningún caso se solicita se incluyan “datos personales sensibles”, como dicho término se define en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en adelante la “Ley”)."
        },
        {
            "title":"2. TRATAMIENTO",
            "text":"Merz se compromete a que el Tratamiento de los Datos Personales del Titular sea con apego y cumplimiento a lo dispuesto por la Ley, la legislación mexicana y el derecho internacional. Adicionalmente, el Tratamiento de los Datos Personales del Titular queda sujeto a todo lo siguiente:"
        },
        {
            "title":"2.1 Revocación del consentimiento",
            "text":"Salvo por disposición legal en contrario, la autorización para el Tratamiento de Datos Personales será revocable en cualquier momento por el Titular de conformidad con el procedimiento que se indica en la sección 2.7 siguiente."
        },
        {
            "title":"2.2 Finalidades",
            "text":"El Tratamiento de los Datos Personales se llevará a cabo con las siguientes finalidades originales y necesarias: (i) proveer, vender y/o comprar los productos y/o servicios que el Titular ha solicitado a Merz, así como para documentar cualquier relación entre Merz y el Titular; (ii) notificar al Titular sobre nuevos productos y/o servicios; y (iii) comunicar al Titular respecto a cambios en los productos y/o servicios. Asimismo, el Tratamiento de los Datos Personales también podrá tener las siguientes finalidades no necesarias: (i) evaluar la calidad de los productos y/o servicios y dar cumplimiento a obligaciones adquiridas; (ii) crear comprobantes fiscales como facturas, notas de crédito o de cargo; (iii) enviar información como estados de cuenta, cartas y comunicados; (iv) elaborar reportes que Merz deba rendir a su sociedad matriz, sociedades controladoras, subsidiarias, afiliadas o bajo el control común del mismo grupo al cual Merz pertenece (en lo sucesivo denominadas conjuntamente como las “Afiliadas”), inversionistas y autoridades; (v) preparar procesos, aprobaciones, monitoreos, evaluaciones, estadísticas, proyecciones y auditorias; y/o (vi) para fines de mercadotecnia, publicidad y/o prospección comercial."
        },
        {
            "title":"2.3 Transferencias",
            "text":" El Titular acepta la transferencia, nacional e internacional, de sus Datos Personales conforme al presente Aviso de Privacidad. Merz tomará las medidas que sean necesarias y suficientes para garantizar que este Aviso de Privacidad sea respetado en todo momento por él o los terceros que los reciban."
        },
        {
            "title":"2.4 Temporalidad",
            "text":"El Tratamiento de los Datos Personales se llevará a cabo por todo el tiempo que sea necesario para que Merz cumpla cabalmente con todas y cada una de sus obligaciones conforme al (los) contrato(s) que en su caso celebren y por el tiempo adicional que se prevea de conformidad con la disposiciones legales aplicables."
        },
        {
            "title":"2.5 Responsable",
            "text":"El Tratamiento de los Datos Personales se llevará acabo única y exclusivamente por las siguientes personas: Merz, sus socios o accionistas, sus administradores, gerentes, empleados y apoderados, sus consultores, contrapartes en contratos bilaterales y multilaterales y representantes, así como sus Afiliadas, incluyendo los socios o accionistas, administradores, gerentes, empleados, apoderados, consultores y representantes de dichas Afiliadas."
        },
        {
            "title":"2.6 Confidencialidad",
            "text":" Se establece que los Datos Personales constituyen información confidencial del Titular. Por tanto, Merz acuerda mantener todos y cada una de los Datos Personales en estricta confidencialidad y se obliga a no divulgar ni revelar los Datos Personales a personas distintas de las señaladas anteriormente sin contar con el previo consentimiento otorgado por escrito del Titular. La obligación de confidencialidad no será aplicable a: (i) información que sea del dominio público, que sea pública o que obre en Fuentes de Acceso Público como dicho término se define en la Ley; (ii) información que resulte evidente, incluyendo para un técnico en la materia; (iii) información que previamente a su divulgación ya obre en poder de Merz o de cualquiera de las personas a que se refiere el párrafo anterior o haya sido desarrollada independientemente por Merz o por dichas personas; o (iv) información que deba ser divulgada por disposición legal, orden judicial o de autoridad competente. Asimismo, se informa al Titular que Merz ha adoptado medidas de seguridad para la protección de los Datos Personales, incluyendo medidas para evitar la pérdida, mal uso, alteración, acceso no autorizado o robo de los Datos Personales, tales como la encriptación de datos y claves de acceso."
        },
        {
            "title":"2.7 Derechos ARCO",
            "text":"Se establece que el Titular de los Datos Personales tendrá el derecho de acceso, rectificación, cancelación u oposición de conformidad con lo dispuesto por la Ley. Para hacer efectivos estos derechos, así como el derecho a revocar su consentimiento, el Titular de los Datos Personales deberá seguir el procedimiento que se describe a continuación:",
            "list":[
                "2.7.1 La solicitud de acceso, rectificación, cancelación u oposición, así como la revocación del consentimiento, deberá hacerse por escrito y dirigirse al departamento de recursos humanos de Merz, con domicilio en: Insurgentes Sur No. 1196, Piso 15, Colonia del Valle, Delegación Benito Juarez, Ciudad de México, C.P. 03200, teléfono 55 91400682, dirección en Internet: https://www.merz.com.mx y/o al correo electrónico rh.mexico@merz.com.mx",
                "2.7.2 La solicitud deberá contener y acompañar lo siguiente: (i) el nombre del Titular y domicilio u otro medio para comunicarle la respuesta a su solicitud; (ii) los documentos que acrediten la identidad o, en su caso, la representación legal del Titular; (iii) la descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes mencionados; (iv) cualquier otro elemento o documento que facilite la localización de los Datos Personales; (v) en su caso, las modificaciones a realizarse y aportar la documentación que sustente su petición.",
                "2.7.3 Merz comunicará al Titular, en un plazo máximo de 20 (veinte) días hábiles contados desde la fecha en que recibió la solicitud correspondiente debidamente integrada, la determinación adoptada, a efecto de que, si resulta procedente, se haga efectiva la misma dentro de los 15 (quince) días hábiles siguientes a la fecha en que se comunica la respuesta al Titular. De conformidad con lo establecido en la Ley, los plazos antes referidos podrán ser ampliados una sola vez por un periodo igual, siempre y cuando así lo justifiquen las circunstancias del caso.",
                "2.7.4 El hecho de que el Titular manifieste su oposición al Tratamiento de sus Datos Personales no será un motivo para que Merz niegue los productos y/o servicios ofrecidos y/o contratados."
            ]
        },
        {
            "title":"3. Modificaciones",
            "text":"El presente Aviso de Privacidad podrá ser modificado mediante un simple aviso por escrito que Merz dirija al Titular de los Datos Personales o mediante la publicación de la modificación correspondiente en la siguiente página de Internet de Merz https://www.merz.com.mx con por lo menos 30 (treinta) días naturales de anticipación a la fecha efectiva de la modificación. En el caso en que el Titular de los Datos Personales no esté de acuerdo con las modificaciones, éste tendrá el derecho de oposición en los términos del párrafo 2.7 anterior. De no presentarse oposición durante los 30 (treinta) días naturales señalados anteriormente o si la oposición resulta infundada, las modificaciones a este Aviso de Privacidad se tendrán por aceptadas para todos los efectos legales a que haya lugar. No será necesario el procedimiento anterior cuando las modificaciones consten en acuerdo por escrito firmado por el Titular de los Datos Personales."
        }
    ]
}