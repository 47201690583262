import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Router } from 'react-router-dom'
import ScrollToTop from './ScrollTop';

import ReactGA from 'react-ga';
import createBrowserHistory from 'history/createBrowserHistory';

ReactGA.initialize('UA-144463571-2');
ReactGA.pageview(window.location.pathname);

var history = createBrowserHistory();

history.listen((location) =>{
    console.log(location);
    ReactGA.send('set', 'page', location.pathname + location.search);
    ReactGA.pageview(location.pathname);

});



ReactDOM.render(
    <Router history={history}>
        <App />
    </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
