import React from 'react';
import './App.css';


import SoloTexto from './SoloTexto';


import privacidad from './data/privacidad.js';


class AvisoPrivacidad extends React.Component {

  constructor(props){
    super(props);
    this.state = {blogData : privacidad };

  }
  
  componentDidUpdate(){
    
  }

  render() {
   return  (<div className="Contenedor">
    <div id="proyectar">
      <div className="blog-page">
        <SoloTexto titulo={this.state.blogData.title} color='texto-mayus texto-pink texto-spaced texto-bold texto-l' alineacion='center'/>
        {this.state.blogData.pars.map((par,id)=>{
          return <div key={"blog-page-par" + id} className="blog-page-par" >
              {par.title && <span className="blog-page-par-title">{par.title}</span>}
              {par.text && <span className="blog-page-par-text">{par.text}</span>}
              {par.list && <ul>{par.list.map((text,idx)=>{return <li key={'blog-page-par-list-' + idx}>{text}</li>})}</ul>}
              {par.gallery && <div className="blog-page-par-gallery">{
                par.gallery.map((image, idx)=>{return <img key={'blog-page-gallery-' + idx} src={image.src} alt={image.alt} />} )}
              </div>}
            </div>
        })}
      </div>
    </div>
    </div>);
  }
}

export default AvisoPrivacidad;
