import React from 'react';
import './App.css';

import lifestyle from './data/lifestyle.js';

import BannerHeader from './BannerHeader.js';
import Blog from './Blog.js';
import BlogHoriz from './BlogHoriz.js'

import SoloTexto from './SoloTexto';

import encabezado from './images/1x/betterself_banner_1.jpg';
import banner_lifestyle from './images/1x/betterself_banner_lifestyle.jpg';
import banner_consulta from './images/1x/betterself_banner_consulta.jpg';

import { ReactComponent as IconFacebook} from './images/icons/SVG/icon_facebook.svg';
import { ReactComponent as IconTwitter} from './images/icons/SVG/icon_twitter.svg';
import { ReactComponent as IconWeb } from './images/icons/SVG/icon_web.svg';
import { ReactComponent as IconInstagram } from './images/icons/SVG/icon_instagram.svg';



import {Link} from 'react-router-dom';


const socialIcons = {
  Web: IconWeb,
  Instagram: IconInstagram,
  Facebook: IconFacebook,
  Twitter: IconTwitter,
  default: IconWeb
};


class LifestylePage extends React.Component {

  constructor(props){
    super(props);
    const post = props.match.params.post;

    this.state = {
      post: post,
      blogData: lifestyle[post]
    }
  }

  
  componentDidUpdate(){
    const changes = {};


    if(this.state.post !== this.props.match.params.post){
      changes.post = this.props.match.params.post;
      changes.blogData = lifestyle[changes.post];
    }
    if(Object.keys(changes).length > 0){
      this.setState(changes);
    }
  }

  render() {
    console.log(this.state.blogData);
   return  (<div className="Contenedor">
     <img className="blog-page-banner" alt={this.state.blogData.title} src = {this.state.blogData.image || banner_lifestyle}/>      
    <div id="proyectar">
      <SoloTexto titulo={this.state.blogData.title} color='texto-mayus texto-pink texto-spaced texto-bold texto-l' alineacion='center'/>
      <div className="blog-page">
        {this.state.blogData.pars.map((par,id)=>{
          return <div key={"blog-page-par" + id} className="blog-page-par" >
              {par.title && <span className="blog-page-par-title">{par.title}</span>}
              {par.text && <span className={"blog-page-par-text " + par.className ? par.className:"" }>{par.text}</span>}
              {par.list && <ul>{par.list.map((text,idx)=>{return <li key={'blog-page-par-list-' + idx}>{text}</li>})}</ul>}
              {par.gallery && <div className="blog-page-par-gallery">{
                par.gallery.map((image, idx)=>{return <img key={'blog-page-gallery-' + idx} src={image.src} alt={image.alt} style={{width:'30%', maxHeight:'200px', minHeight:'100px', objectFit:'cover'}} />} )}
              </div>}
            </div>
        })}
        {this.state.blogData.refs.length > 0 &&
          <div key="blog-page-reference" className="blog-page-par"><span className="blog-page-par-title">REFERENCIAS</span><ul>
            {this.state.blogData.refs.map((ref, id)=>{
                return <li key={"blog-page-par-ref"+id}>
                  <span className="blog-page-reference">{ref.text}</span>
                  {ref.link && <div><span className="blog-page-par-text">Recuperado de: </span><a className="blog-page-ref-link" href={ref.link} rel="noopener noreferrer" target="_blank">{ref.link}</a></div>}                
                  </li>
            })
            }
          </ul></div>
        }
        {this.state.blogData.author && 
          <div className="blog-page-author">
            <span className="blog-page-par-text texto-s">{this.state.blogData.author.brief}</span>
            <div className="blog-author-contact" style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
              <span>Sigue a {this.state.blogData.author.name}: </span>
              {this.state.blogData.author.contacts.map((contact)=>{
                const SocialComponent = socialIcons[contact.social] || socialIcons.default;
                return <div className="texto-s" style={{margin:'0.5em 0'}}><a href={contact.link} rel="noopener noreferrer" target="_blank">{<SocialComponent className="contact_icon" />}</a></div>
              })}
            </div>
            {this.state.blogData.author.links.map((contact)=>{
                const SocialComponent = socialIcons[contact.social] || socialIcons.default;
                return <div className="texto-s" style={{margin:'0.5em 0'}}><a href={contact.link} rel="noopener noreferrer" target="_blank" className="text-blue text-m" style={{textDecoration:'none', display:'flex', alignItems:'center'} }>{<SocialComponent className="contact_icon" />} <span className="texto-blue texto-m">{contact.text}</span></a></div>
            })}

          </div>
        }
        <hr style={{width:'100%', color:'#DCB8A4'}} />
        <div className="blog-share" style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
          <span style={{marginRight:'0.75rem'}} className="texto-mayus texto-s texto-pink">COMPARTIR</span>
          <a href={`https://www.facebook.com/sharer.php?u=${window.location.href}`} target='_blank' rel='noreferrer noopener'><IconFacebook className="share_icon" /></a>
          <a href={`https://twitter.com/intent/tweet?url=${window.location.href}&text=${this.state.blogData.title}`} target='_blank' rel='noreferrer noopener'><IconTwitter className="share_icon" /></a>       
        </div>
      </div>
      <SoloTexto titulo="LO ÚLTIMO EN ESTILO DE VIDA" color='texto-pink texto-bold texto-l' alineacion='center' />
      <div style={{margin:'0em auto 1em auto', width:'100%'}} >
          <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
              {
                Object.keys(lifestyle).reverse().map((blogPost, idx)=>{
                  if(this.props.match.params.post === blogPost) return <></>;
                  const BlogType = idx < 4 ? BlogHoriz : Blog;
                  const post = lifestyle[blogPost];
                  return <BlogType key={`lifestyle-${blogPost}`} imagen={post.image} link={`/lifestyle/${blogPost}`} titulo={post.title} descripcion="" mid />;
                })
              }
          </div>
        </div>
    </div>

    {false && <BannerHeader descripcion='' imagen={encabezado} banners={
        [{
          imagen: banner_consulta,
          text: <div className="textoHeader texto-m" style={{position:'absolute', top:'10%', right:'10%', bottom:'10%', display:'flex', 'flexDirection':'column'}}>
            <span className='texto-white texto-montserrat'>Encuentra a los profesionales de</span>
            <span className='texto-white texto-montserrat'>la salud y estilo de vida</span>
            <span className='texto-white texto-montserrat'>más prestigiados e inicia tu</span>            
            <span className='texto-white texto-montserrat'>transformación</span>
            <span className='texto-white texto-tahu' style={{marginTop:'auto',marginBottom:'auto'}}>¡Descúbrelos!</span>
            <Link to="/specialist" style={{textDecoration:'none', borderTop:'1px solid white', borderBottom:'1px solid white'}} className='texto-spaced texto-white texto-bold specialist-link' >AQUÍ</Link>
          </div>
        }]
      } /> }  
  </div>)
  }
}

export default LifestylePage;
