import React from 'react';
import './App.css';


class Cuadricula extends React.Component{
    render(){
        return(           
            <div style={{width: '100%',  margin:"0 auto"}}>
                <div className="Cuadricula">
                    {this.props.productos.map((producto)=>
                    (                  
                        <div className="Cuadro" key={producto.key.toString()}>
                            <img className="producto" src={producto.imagen} style={{width:'100%', height:'100%'}} alt={producto.nombre} />
                            <span>{producto.nombre}</span>
                        </div>                    
                    
                    )
                    )}
                </div>
                <div style={{paddingTop:"40px"}}>
                    {this.props.children}
                </div>
                <div>   

                </div>            
            </div>            
        )
}
}
export default Cuadricula;