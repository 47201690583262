
import cosmetics_1 from '../images/2x/betterself_cosmetic_blog_1@2x.jpg';
import cosmetics_2 from '../images/2x/betterself_cosmetic_blog_2@2x.jpg';
import cosmetics_3 from '../images/2x/betterself_cosmetic_blog_3@2x.jpg';
import cosmetics_4 from '../images/2x/betterself_cosmetic_blog_4@2x.jpg';
import cosmetics_5 from '../images/1x/betterself_cosmetic_blog_5.jpg';
import cosmetics_6 from '../images/1x/betterself_cosmetic_blog_6.jpg';
import cosmetics_7 from '../images/1x/betterself_cosmetic_blog_7.jpg';
import cosmetics_8 from '../images/1x/betterself_cosmetic_blog_8.jpg';


export default {
    "evita-el-envejecimiento-prematuro":{
        "image": cosmetics_1,
        "title":"Tener 50 y parecer de 30 es el deseo de todas: consejos para evitar el envejecimiento prematuro en la piel",
        "pars":[
            {"text":"Envejecer es inevitable, sin embargo, la adopción de hábitos saludables te ayudará a lucir bien por más tiempo. Descubre aquellos tips que no puedes dejar pasar:"},
            {"title":"ALIMENTACION SANA","text":"La piel necesita una buena nutrición, y para evitar su oxidación es necesario tener una dieta balanceada rica en antioxidantes, vitaminas y minerales que te ayudarán a alimentar tu piel desde el interior. "},
            {"title":"DUERME BIEN","text":"¡Descansar es vital! Durante el sueño, tu organismo segrega sustancias que permiten la regeneración celular de tu piel. Para que esto suceda, es recomendable dormir 8 horas diarias pues es el periodo de tiempo que le toma a tu cuerpo para lograr un buen descanso y recuperarse. Cuando no descansas lo suficiente, corres el riesgo de agilizar el proceso de envejecimiento y puedes llegar a desarrollar signos como las temibles bolsas, ojeras y líneas de expresión. Si te cuesta trabajo conciliar el sueño, nunca está de más un té herbal con propiedades calmantes."},
            {"title":"HIDRATA TU PIEL", "text":"Mantener la piel hidratada es clave para lucir un buen aspecto, y más, durante en épocas de frío. Toma suficiente agua, no solo para eliminar las toxinas que tu cuerpo no necesita, sino también para mantener un cutis sano y natural."},
            {"title":"ADIÓS A LOS VICIOS", "text":"Si necesitas motivos para dejar de fumar, aquí tenemos algunos: la piel de los fumadores envejece más rápido pues el tabaco la reseca, ya que se reduce la cantidad de flujo sanguíneo que llega a este órgano, impidiendo la correcta dosis de oxígeno y otros nutrientes esenciales."},
            {"title":"UN ALIADO: LOS CUIDADOS ESTÉTICOS", "text":"Cuídate con regularidad, utiliza tratamientos de belleza que se adapten a tu tipo de piel y que te ayuden con los resultados que deseas obtener. Los productos de uso externo nutren la piel y brindan ciertos beneficios cosméticos, mientras que los productos inyectables o incluso algunos dispositivos actúan a profundidad y en algunos casos hasta bio-estimulan la producción de sustancias clave para la piel. Claro, estos deben ser siempre aplicados por un médico especializado."},
            {"text":"Sigue leyéndonos y próximamente descubrirás cuáles son los ingredientes más eficaces según tu edad y tipo de piel."}
        ],
        "refs":[
            {"text":"Pérez V. (20 de noviembre). Expansión. Opinión. 7 pasos para un estilo de vida saludable.", link:"https://expansion.mx/opinion/2010/11/20/salud-vida-felicidad-fuerza-cnnexpansion"}, 
            {"text":"Loring C. (31 de agosto de 2017). La Vanguardia. Nutrición. Estos son los 15 alimentos que mejor cuidan la piel.", link:"https://www.lavanguardia.com/vivo/nutricion/20170831/43902654987/alimentos-que-ayudan-a-cuidar-la-piel.html"}
        ] 
    },
    "acido-hialuronico-que-hace":{
        "image": cosmetics_2,
        "title":"Ácido hialurónico: sí. Pero ¿exactamente qué hace?",
        "pars":[
            {"text":"Con el paso de los años, mantener una piel firme y lisa puede resultar sumamente complicado. Afortunadamente existen tratamientos especializados que aportan aquello que nuestros cuerpos van dejando de producir de manera natural."},
            {"text":"El ácido hialurónico es una sustancia que se encuentra en numerosos tejidos y órganos del cuerpo: epidermis, cartílagos, ojos, etc. Una de sus propiedades más importantes es la capacidad para atraer y retener agua, lo que hace que los tejidos, en este caso la piel, se mantengan en buenas condiciones."},
            {"text":"Es precisamente esta capacidad, lo que lo ha convertido en uno de los productos estrella de la medicina estética. Conoce los beneficios principales de este famoso activo “antiaging”: "},
            {"title":"HIDRATA LA PIEL","text":"Sus propiedades hidratantes y humectantes son las más conocidas y buscadas, pues el ácido hialurónico, mantiene la cantidad de agua necesaria para que la piel no pierda su firmeza. Además, forma una barrera que la protege de factores externos como el sol y agentes contaminantes."},
            {"title":"COMBATE Y RELLENA LAS ARRUGAS","text":"Y ¿porqué reduce las arrugas y líneas de expresión? Esto sucede porque genera una malla subcutánea que da un efecto de tensión en las capas profundas de la piel, actuando como un relleno para combatir las arrugas."},
            {"title":"EFECTO REAFIRMANTE","text":"Otro de los grandes beneficios del ácido hialurónico es que ayuda a recuperar la firmeza de la piel. Este activo estimula la producción de colágeno, la proteína encargada de regenerar y tensar nuestros tejidos."},
            {"title":"EVITA EL ACNÉ Y LAS MANCHAS", "text":"El ácido hialurónico ayuda a regenerar las células de tu piel, disminuyendo la aparición de manchas por la edad y de marcas del acné."},
            {"text":"Si quieres mantener una piel sana y visiblemente más joven, al devolver el volumen, firmeza, suavidad e hidratación a la piel, definitivamente te recomendamos el uso de este poderoso activo."},
            {"text":"Lo puedes aplicar en pequeñas dosis de forma tópica para prevenir que tu piel pierda volumen por deshidratación, o, incluso puedes visitar a tu médico para descubrir un ácido hialurónico inyectable, que se integre bien con los tejidos de tu piel para dar un boost de relleno a las zonas que más te importan."},
            {"text":"Solo recuerda que es importante platicar con tu médico sobre los beneficios del tratamiento que te aplicará - como tip, si eres fan de un look natural, te recomendamos que sea un producto que acompañe tu dinámica facial, para que no pierdas tu look y sigas luciendo ¡únic@!"}
        ],
        "refs":[
            {"text":"Fogarty L. (20 de febrero del 2018). Harper’s Bazaar. Belleza. Todo lo que necesitas para reconstruir el colágeno y mejorar tu piel.", link:"https://www.harpersbazaar.com/es/belleza/piel-belleza/a14403096/como-reconstruir-colageno-y-mejorar-la-piel/"},
            {"text":"Redacción. (s/a). Sanitas. Prevención. Propiedades y usos del ácido hialurónico.", "link":"https://www.sanitas.es/sanitas/seguros/es/particulares/biblioteca-de-salud/prevencion-salud/propiedades-usos-acido-hialuronico.html"}
        ]
    },
    "como-lograr-tu-betterself-segun-expertos":{
        "image": cosmetics_3,
        "title":"¿Cómo lograr tu “betterself” según los expertos?",
        "pars":[
            {"text":"Consigue una piel con aspecto sano y natural con estos productos que te aseguramos, cuidarán de tu piel."},
            {"text":"El cuidado de tu piel es algo súper importante para nosotros. Encontrar los mejores productos a veces es difícil. Por eso, es momento de recurrir a una voz experta antes de que tu piel sufra las consecuencias."},
            {"text":"Por ello, tres especialistas recomiendan diferentes tratamientos para la piel únicos respondiendo todas tus preguntas antes de que decidas usarlos."},
            {"title":"Dra. Isela Méndez, CDMX : Ultherapy","text":"Este es el ultrasonido microfocalizado de alta intensidad, un lifting sin cirugía y duradero que estimula la producción de tu propio colágeno para darle más firmeza a la piel.",
            "list":["¿Cómo?: A través de una consulta de aproximadamente una hora, dependiendo la zona que se desea: cara, cuello o escote.",
        "¿Resultados?: El efecto tensado se puede notar de inmediato, pero los resultados finales se pueden percibir a partir del tercer mes. Solo recuerda asegurarte de que sea el equipo Ultherapy Original.",
        "¿Duración?: Lo ideal es que pueda repetirse cada 12 a 18 meses para mantener el efecto de tightening de forma atemporal.",
        "¿Edad mínima?: Lo ideal sería desde los 38 o 40 años y hasta los 70."
        ],
        contacts:[{
            social:"Web",
            "text":"https://iselamendez.mx/",
            "link":"https://iselamendez.mx/"
        },{
            social:"Facebook",
            "text":"@clinicaIselaMendez",
            "link":"https://www.facebook.com/clinicaIselaMendez/"
        },{
            social:"Twitter",
            "text":"@cdiselamendez",
            "link":"https://twitter.com/cdiselamendez/"
        },{
            social:"Youtube",
            "text":"draiselamendez",
            "link":"https://www.youtube.com/user/draiselamendez"
        },{
            social:"Instagram",
            "text":"@clinicaiselamendez",
            "link":"https://www.instagram.com/clinicaiselamendez/"
        }
        ]},  
        {"title":"Dra. Esperanza Welsh, Monterrey : Cellfina", "text": "El tratamiento más efectivo para liberarte de los hoyuelos causados por la celulitis, lo que es tan común en las mujeres", 
    "list":["¿Cómo se realiza? : En el consultorio, solo se necesita anestesia local y al finalizar, la persona puede seguir con su vida sin ningún problema.",
    "¿Resultados?: Desde que te sometes, puedes ver resultados. En algunos casos, puede quedar algún moretón que desaparece en poco tiempo.",
    "¿Duración del efecto?: Estudios confirman su efecto hasta 5 años y más.",
    "¿Soy candidata?: A partir de los 18 años y hasta los 65 se puede realizar este tratamiento."
    ],
    contacts:
    [
        {
            social:"Facebook",
            "text":"@Welsh-Derm-565096097318997",
            "link":"https://www.facebook.com/Welsh-Derm-565096097318997/"
        }
    ]
    },
    {"title":"Dra. Patricia Herrera, Guadalajara : Inyectables", "text":"Hablemos de Belotero, Radiesse y Xeomeen que mejoran la piel dejando un aspecto natural.", "list":[
        "¿Cómo?: El procedimiento es rápido y sencillo. Muchos incluyen lidocaína para que la aplicación sea mucho más cómoda y cuide de tu piel. Se usa en las zonas donde se quiere corregir o mejorar la apariencia.",
        "¿Resultados?: De forma inmediata y mejoran entre la segunda y tercera semana después de la aplicación.",
        "¿Duración del efecto?: Se puede retocar una vez al año, dependiendo de las características de la persona.",
        "¿Candidatos?: Hombres y mujeres que comiencen a notar los primeros signos de envejecimiento."
    ],
    contacts:[{
        social:"Web",
        "text":"http://www.patyherrera.com/#home",
        "link":"http://www.patyherrera.com/#home"
    },{
        social:"Facebook",
        "text":"@patricia.herreravazquez",
        "link":"https://www.facebook.com/patricia.herreravazquez/"
    },{
        social:"Instagram",
        "text":"@dermapatyherrera",
        "link":"https://www.instagram.com/dermapatyherrera/"
    }
    ]
}
    ],
    "refs":[
        {
            "text":"Vogue. (2 de octubre del 2019). Vogue. Belleza. Tres tratamientos para el cuidado de la piel que debes conocer, según expertas","link":"https://www.vogue.mx/belleza/branded/tres-expertas-recomiendan-tratamientos-cuidado-de-la-piel "
        }
    ]

    },
    "bienvenida-al-invierno-beneficios":{
        "image":cosmetics_4,
        "title":"¡Disfruta del frío y descubre los beneficios en tu piel!",
        "pars":[
            {"text":"El invierno está aquí y aunque muchas veces creemos que es una estación complicada por ser muy fría, esta época del año también tiene sus beneficios. ¿Sabías que el invierno ofrece un sinfín de bondades a tu piel? Aquí te las compartimos:"},
            {"title":"LIMPIA LOS POROS", "text":"Las temperaturas bajas son muy buenas para la epidermis (capa superficial de la piel), ya que el frío actúa como un astringente natural que reduce los niveles de grasa y que, a su vez, minimiza la visibilidad de los poros. TIP: lava tu rostro diariamente y aplica un tratamiento hidratante para que tu piel no se reseque"},
            {"title":"ADIÓS A LA INFLAMACIÓN","text":"Es común que en las mañanas el contorno de los ojos se vea más inflamado de lo normal. Seguramente has leído o te han aconsejado muchas veces: “aplica compresas frías o pepinos para reducir esta inflamación”. La buena noticia, es que, en época de frío, de forma natural, podrás reducir esta hinchazón gracias a las temperaturas. ¡Así que, despreocúpate!"},
            {"title":"ACCIÓN REJUVENECEDORA", "text":"Las temperaturas bajas permiten generar un efecto tensor a nivel celular, que te ayudará a lucir un cutis más terso y firme, y ¡hasta lucir más joven! Eso sí, recuerda que, aunque haga frío, los rayos solares siguen presentes, ¡no olvides aplicar tu protector solar todos los días!"},
            {"title":"MEJOR CIRCULACIÓN", "text":"El frío ambiental favorece la microcirculación, que es la encargada de brindarnos un tono de piel más natural, uniforme y luminoso; es por eso que, seguramente cuando te veas en el espejo, lucirás radiante."},
            {"title":"QUEMA CALORÍAS", "text":"Algunos investigadores han descubierto que los climas fríos ayudan a desencadenar la quema de calorías, ya que, las temperaturas bajas, provocan que el metabolismo se acelere, esto en combinación con una buena dieta, te puede ayudar incluso a eliminar algunos kilitos de más."},
            {"text":"¡Quién diría que el frío puede ser un gran aliado de la piel!  Ahora que ya lo sabes, seguramente esperarás con ansias la temporada de suéteres, abrigos, chocolate caliente, y sobre todo…piel de envidia. No olvides acompañar esta temporada con tu rutina de belleza y cuidado personal, para lucir mejor que nunca."}
        ],
        "refs":[
            {"text":"Morán, C. (27 de diciembre del 2018). Tiempo.com. Divulgación. ¿Qué tal nos sienta el vierno?", "link":"https://www.tiempo.com/noticias/divulgacion/que-tal-nos-sienta-el-invierno.html"},
            {"text":"Lizondo M. (s/a). Revista Mia. Belleza. Cómo cuidar la piel en invierno","link":"https://www.miarevista.es/belleza/articulo/como-cuidar-la-piel-en-invierno-841477301119"}
        ]
    },
    "colageno-muy-importante":{
        "image": cosmetics_5,
        "title":"Coláge...¿no? ¡Sí, es muy importante!",
        "pars":[
            {"text":"Piensa en cualquier actividad de tu vida diaria, como cocinar, practicar deporte o salir con tus amigos. Todo esto es relativamente sencillo, ¿sabes porqué? Principalmente es gracias al colágeno que hay en nuestro cuerpo, ya que mantiene unidas las estructuras que conforman a nuestro organismo con firmeza y flexibilidad a la vez."},
            {"title":"¿Qué es el colágeno?", "text":"Es una proteína que aporta grandes beneficios a nuestro cuerpo. Representa un poco más del 25% del total de las proteínas que forman parte de nosotros, desde la piel, hasta tendones y huesos. Existen más de 19 tipos de colágeno, que, entre otras cosas, ayudan a generar fibras colágenas, las cuales forman parte de nuestros tejidos, desde la piel hasta órganos tan complejos como el corazón."},
            {"text":"Por ello, toda persona, hombre o mujer, debe asegurarse de mantener los niveles adecuados de dicha proteína. Después de los 25 años, nuestro cuerpo además de perder aproximadamente 1.5% de sus niveles de colágeno al año, lo regenera de forma cada vez más lenta, por eso se estima que cuando llegamos a las 60 años, hemos perdido aproximadamente la mitad del colágeno esencial para nuestros tejidos, y se acentúa el envejecimiento."},
            {"title":"¿Cómo se regenera el colágeno?","text":"La “neocolagénesis”, es decir la formación de colágeno nuevo, se puede estimular de diversas formas. Puedes lograr tu #Betterself actuando tanto en la prevención como en la corrección."},
            {"text":"La prevención empieza con la alimentación, ya que el colágeno se encuentra en distintos alimentos, por lo que se recomienda consumirlos regularmente, para evitar el desgaste natural. Los alimentos que más destacan son:", "list":[
                "Frutos secos y salmón: Contienen omega 3, 6 y 9, ayudando a regular de forma adecuada, la producción natural de colágeno.",
                "Zanahorias y melón: Ayudan a sintetizar de una mejor manera el colágeno.",
                "Fresas, mandarinas y naranjas: La vitamina C de estas frutas ayuda a eliminar los compuestos que fomentan la pérdida de colágeno.",
                "Suplementos alimenticios: Existen diversos suplementos orales que ayudan a complementar la ingesta diaria de colágeno."
            ]},
            {"text":"Desde el punto de vista médico, la corrección se puede tratar de diversas formas."},
            {"text":"Una opción puede ser a través de inyecciones de colágeno, que se utilizan para rellenar las líneas de expresión o arrugas profundas e incluso para incrementar el volumen de labios o mejillas. Existen otros innovadores componentes inyectables, como la Hidroxiapatita Cálcica, que bio-estimulan la producción de colágeno I y III, esenciales para combatir el envejecimiento de la piel, sin generar granulomas y que son totalmente biodegradables. "},
            {"text":"Otra alternativa, muy popular entre las celebridades nacionales e internacionales, es la utilización de procedimientos no invasivos, como el ultrasonido microfocalizado en tiempo real, que reafirma la piel desde el interior, es decir, genera un lifting, y a la vez fomenta la producción de colágeno joven. La ventaja de este procedimiento es que es muy preciso, no requiere tiempo de recuperación y los resultados son naturales."},
            {"text":"Sea cuál sea tu procedimiento ideal, es importante que siempre lo realices bajo observación médica por algún especialista certificado en el procedimiento y si optas por un dispositivo médico, asegúrate que sea el original o que cuente con algún reconocimiento como el “gold standard”."},
            {"text":"¡No esperes más! Empieza a reactivar el colágeno en tu piel, acude con un profesional para que te evalúe y te recomiendo el tratamiento óptimo."}
        ],
        "refs":[
            {"text":"Rozalén R (28 de noviembre del 2018) Sanitas. ¿Para qué sirve el colágeno?", "link":"https://muysaludable.sanitas.es/salud/para-que-sirve-el-colageno/"},
            {"text":"Neff D (S/f). Cancer Care of Western New York. Inyección de colágeno: relleno de arrugas.", "link":"https://www.cancercarewny.com/content.aspx?chunkiid=629556"}
        ]
    },
    "medicina-genomica":{
        "image": cosmetics_6,
        "title":"FAQs de la Medicina Genómica",
        "pars":[
            {"title":"¿Qué es la medicina genómica?","text":"Todas las personas compartimos aproximadamente el 99% de los genes, siendo solamente el 1% lo que nos diferencia entre unos y otros. La medicina genómica se enfoca en analizar ese pequeño porcentaje de material genético para identificar ciertas características que no se pueden percibir a simple vista, o incluso que aún no se manifiestan, por ejemplo, cómo envejecerá nuestro cuerpo e incluso la posibilidad de adquirir una enfermedad, pero sobre todo nos da claves precisas de cómo contrarrestarlo."},
            {"title":"¿Cómo funciona?", "text":"La medicina genómica localiza, secciona y analiza las partes del ADN, con el fin de guiar al paciente de forma predictiva, preventiva, participativa y personalizada, para mejorar significativamente su calidad de vida."},
            {"text":"A partir de una muestra de saliva, se obtienen células que se envían a un laboratorio especializado en donde se analizan junto con el ADN. Los resultados ayudan a generar un esquema o “mapa” personalizado."},
            {"text":"Las pruebas genómicas ayudan a encontrar probables anormalidades genéticas. Gracias a esta información, nos ayudan a corregir distintos hábitos, para que, ante cualquier amenaza, puedan ser disminuidos o erradicados. Esto es un gran avance tecnológico para nuestro bienestar, ya que, incluso se puede saber qué alimentos, tratamientos médicos o cosméticos, son los ideales para cada paciente."},
            {"title":"¿Qué personas pueden ser tratadas por la medicina genómica?","text":"Esta rama de la medicina es ideal para cualquier persona, pero, sobre todo, para aquellas más interesadas en la prevención. Se estima que en promedio una persona vive cerca de 80 años. Por esta razón, es muy importante cuidar nuestro cuerpo, prevenir enfermedades, pero sobre todo descubrir los tratamientos que nos ayudarán a expresar la mejor versión de nosotros mismos."},
            {"title":"¿Cómo se aplica a la cosmética?","text":"Al descubrir el mapa genético de cada persona, es posible entender cómo va a reaccionar su cuerpo ante ciertos tratamientos y cómo y cuándo se va a presentar el envejecimiento en su piel. La medicina genómica nos ayuda a encontrar el tratamiento ideal para el acné, la alopecia, combatir la aparición de manchas, arrugas o flacidez, y decidir entre la aplicación de rellenos, toxina o ultrasonidos micro focalizados, entre otros."},
            {"text":"No esperes a que sea muy tarde, como bien dicen, más vale prevenir que lamentar. Si buscas una mejor calidad de vida, acude a una especialista en medicina genómica."},
            {
                "title":"Medae",
                "contacts":[
                    {
                        social:'Web',
                        "text":"https://www.medae.mx",
                        "link":"https://www.medae.mx"
                    },
                    {
                        social:"Facebook",
                        "text":"@MedaeClinic",
                        "link":"https://www.facebook.com/MedaeClinic?fref=ts"
                    },
                    {
                        social:"Instagram",
                        "text":"@medae_",
                        "link":"https://www.instagram.com/medae_/"
                    },
                    {
                        social:"Twitter",
                        "text":"@Medae_LS",
                        "link":"https://twitter.com/Medae_LS"
                    }
                ]
            },
            {
                "title":"Dra. Marimar Guerra",
                "contacts":[
                    {
                        social:"Facebook",
                        "text":"@dra.marimarguerra",
                        "link":"https://www.facebook.com/dra.marimarguerra/"
                    },
                    {
                        social:"Instagram",
                        "text":"@dra.marimarguerra",
                        "link":"https://www.instagram.com/dra.marimarguerra/"
                    }
                ]
            }
        ],
        "refs":[
            {"text":"Flores, S. (25 de agosto del 2019). Nexos. Discapacidades. ¿Qué es la medicina genómica y por qué promete salvar a la humanidad?", "link":"https://discapacidades.nexos.com.mx/?p=1117"},
            {"text":"Ávila, R; Samar, M; Díaz, L; Esteban, F. Medicina Genómica: Aspectos éticos, legales y sociales del Genoma Humano, Revista Bicética, Número 2, Volumen 11, Páginas 18-21.", "link":"http://www.scielo.org.co/pdf/rlb/v11n2/v11n2a03.pdf"},
            {"text":"González, P; Hubard, F. (6 de noviembre del 2017). Vogue México. Bienestar. ¿Qué es la medicina Genómica?","link":"https://www.vogue.mx/belleza/bienestar/articulos/medicina-genomica-bienestar-salud-adn-nutricion-enfermedades/8896"},
            {"text":"Entrevista a Guerra M, comunicación personal, 22 de enero del 2020"}
        ],
        
    },
    "ultherapy":{
        "image": cosmetics_7,
        "title": "Ultherapy en voz de la Dra. Paty Herrera",
        "pars":[
            {"text":"No podemos evitar el paso del tiempo y los cambios que vienen con la edad. Estos en consecuencia, los vemos reflejados en el aspecto de nuestro rostro y cuerpo. Para nuestra fortuna, existen tratamientos estéticos que nos permiten mejorar esas secuelas del envejecimiento para conseguir esa apariencia que tanto buscamos."},
            {"text":"De acuerdo con la dermatóloga Dra. Paty Herrera, “Ultherapy es el tratamiento ideal para rostro, cuello y escote. Es un innovador equipo que nos permite tratar de forma no invasiva, los irremediables signos del envejecimiento. Se trata de un sistema que utiliza el ultrasonido micro focalizado que tensa la piel de forma segura y eficaz. Gracias a que este equipo cuenta con visualización en tiempo real, es posible controlar las partes o la profundidad a tratar, ya que estos varían según el tipo de paciente, por lo que, hace a este tratamiento único, eficaz y seguro”. "},
            {"title":"¿Cómo es el proceso?","text":"Ultherapy se lleva a cabo a través de un ultrasonido micro focalizado, el cual consigue el tensado en la piel de forma precisa, dando pauta a que se inicie el proceso de neocolagénesis (estimulación en la producción de colágeno). En el equipo se encuentra un control ecográfico – ojo este elemento es lo que lo hace único en su categoría - que permite ver de una mejor manera las capas de la piel que se tratan en dicho momento. Además, se realiza en una sola sesión que, normalmente dura de 60 a 90 minutos. Después de la aplicación, el paciente puede reanudar su vida de forma normal sin necesidad de hospitalización o tiempo de reposo. En la mayoría de las personas, no hay efectos secundarios, y en los que sí, son leves, como sensibilidad, hormigueo o leve enrojecimiento."},
            {"title":"Aprobado científicamente","text":"Ultherapy es el único tratamiento aprobado por distintas autoridades, entre ellas, por la FDA en Estados Unidos, garantizando la efectividad y seguridad del tratamiento. Además, los profesionales de la salud que aplican Ultherapy están capacitados para su correcta aplicación."},
            {"title":"¿En qué zonas se puede aplicar?", "list":[
                "El tratamiento Ultherapy ayuda a levantar y reafirmar la piel de la cara, cuello y mejora las líneas y arrugas del escote",
                "Eleva las cejas para enmarcar la mirada",
                "Renueva naturalmente la producción de colágeno",
                "¡Y para hombres también! Levanta y redefine la piel de cara y cuello, además de mejorar la región sub-mandibular."
            ]},
            {"title":"¿Cómo puedes identificar que se utilice el tratamiento Ultherapy auténtico?", "text":"Porque no todos los ultrasonidos para lifting son iguales, te recomendamos verificar que se utilice el equipo auténtico. Es muy fácil, solo debes ubicar 4 elementos en el consultorio de tu médico:", "list":[
                "1.- Logo de autenticidad",
                "2.- Vinil con logo de autenticidad",
                "3.- Diamante de autenticidad",
                "4.- Certificado de autenticidad"
            ]},
            {"text":"Porque envejecer es inevitable. Consigue una piel más tersa y firme con Ultherapy y construye tu mejor versión."},
        ],
        "author":{
            "name":"Dra. Paty Herrera",
            "contacts":[
                {social:"Web",text:"www.alecuadros.com",link:" http://www.patyherrera.com/"},
                {social:"Instagram",text:"@dermapatyherrera", link:"https://www.instagram.com/dermapatyherrera/"},
                {social:"Youtube", text:"", link:"https://www.youtube.com/watch?v=Prjhvm0QNbY"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
    "inyectables-usos-mitos-realidades":{
        "image": cosmetics_8,
        "title": "Procedimiento estéticos inyectables: usos, mitos y realidades",
        "pars":[
            {"text":"Cada vez más mujeres y hombres llegan al consultorio médico con ganas de verse mejor y detener el paso de la edad con tratamientos poco invasivos y sin la necesidad de pasar por el temido quirófano."},
            {"text":"Uno de los tratamientos que, en los últimos años, ha tomado relevancia y que muchos pacientes prefieren, son los tratamientos inyectables. ¿Por qué? Súper sencillo: el tiempo de recuperación es rápido y los pacientes pueden volver a hacer su rutina de manera normal sin ningún problema. Además, los beneficios permanecen a mediano plazo."},
            {"text":"En general, estos procedimientos estéticos sirven para inmovilizar o rellenar arrugas, líneas de expresión, pliegues y surcos faciales, dando una apariencia más tersa a la piel, e incluso moldear o resaltar algunas facciones del rostro; siempre de la mano de un experto que te aplicará productos de calidad, con la técnica de aplicación ideal y sin poner en riesgo tu salud."},
            {"text":"Bonus: Siempre pregunta a tu especialista qué marca te aplicará, revisa que sea un producto nuevo, que la fecha de caducidad esté bien y que sea una marca aprobada por distintos organismos locales e internacionales, como la FDA (Food and Drug Administration de Estados Unidos), esto, para ofrecerte mayor seguridad y eficacia."},
            {"title":"Mitos y realidades:"},
            {"title":"¿Puedo aplicarme un tratamiento estético en cualquier lugar, como una fiesta o mi casa?", "text":"¡No! Estos tratamientos deben llevarse a cabo por especialistas en un entorno perfectamente esterilizado. La ejecución de estos tratamientos por una persona que no está capacitada puede tener consecuencias graves."},
            {"title":"¿Los tratamientos inyectables son para todos?", "text":"¡Sí! La medicina estética es mínimamente invasiva y existe una gama de productos para cada necesidad y estilo de vida, por lo que los tratamientos están indicados para hombres o mujeres de cualquier edad, es decir de forma preventiva o correctiva."},
            {"title":"¿Si me aplico un tratamiento estético inyectable voy a verme poco natural?", "text":" ¡No! Los efectos exagerados normalmente se derivan de malas aplicaciones, del uso de productos que no están aprobados por instituciones médicas o incluso por las peticiones de un paciente que quiere ver un resultado un poco más exagerado. Te recomendamos siempre visitar a un profesional calificado para un resultado natural y conforme a la anatomía de tu rostro. La mayoría de los médicos cuentan con redes sociales en donde muestran los resultados de su trabajo, fíjate que los resultados estén conforme a lo que a ti te gusta."},
            {"title":"¿Las aplicaciones son doloras?", "text":"¡No! Los tratamientos inyectables se caracterizan por ser poco dolorosos, ya que son procedimientos poco invasivos. Se realiza mediante pequeñas inyecciones con las que, a lo mucho, el paciente notará pinchazos leves. Además de que los productos de alta calidad normalmente ya contienen un anestésico para que la sensación sea lo más ligera posible. Recuerda, la sensación de comodidad o incomodidad varía de acuerdo con cada persona, ya que el umbral de dolor es diferente para todos, pero esa sensación solo dura mientras el tratamiento está siendo aplicado."},
            {"text":"Así que ya lo sabes. Si quieres someterte a un tratamiento estético inyectable, ¡puedes hacerlo de forma segura! Solamente empieza por la correcta selección del médico que la realizará. Visita nuestra sección de “Especialistas” para descubrir algunas recomendaciones."},
        ],
        "author":{
            "name":"Betterself",
            "contacts":[
                {social:"Facebook", text:"", link:"https://m.facebook.com/betterselfmx/"},
                {social:"Instagram",text:"", link:"https://instagram.com/betterselfmx?igshid=1mynxyvns376e"},
            ],
            "links":[

            ]
        },
        "refs":[
        ]
    },
}