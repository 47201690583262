import React from 'react';
import './App.css';

import lifestyle from './data/lifestyle.js';

import BannerHeader from './BannerHeader.js';
import SoloTexto from './SoloTexto';
import Paciente from './Paciente';
import Modal from './Modal';

import encabezado from './images/1x/betterself_banner_diagnostic.jpg';
import default_user from './images/1x/betterself_default_user_image.jpg'

import {ReactComponent as BarLeft} from './images/icons/SVG/betterself_bar_left.svg';
import {ReactComponent as Bar} from './images/icons/SVG/betterself_bar.svg';
import {ReactComponent as BarRight} from './images/icons/SVG/betterself_bar_right.svg';

class Diagnostic  extends React.Component {

  constructor(props){
    super(props);
    const post = props.match.params.post;

    this.state = {
        lifeStyleA:false,
        lifeStyleB:false,
        lifeStyleC:false,
        lifeStyleD:false,
        lifeStyleE:false,
        edad: false,
        piel: false,
        rostro1: "1",
        rostro2: "0",
        piel1: "1",
        piel2: "0"
    }
    this.toggleLifeStyle = this.toggleLifeStyle.bind(this);
    this.setEdad = this.setEdad.bind(this);
    this.setPiel = this.setPiel.bind(this);
    this.setRostro1 = this.setRostro1.bind(this);
    this.setRostro2 = this.setRostro2.bind(this);
    this.setPiel1 = this.setPiel1.bind(this);
    this.setPiel2 = this.setPiel2.bind(this);
    this.checkForm = this.checkForm.bind(this);
    this.formResult = this.formResult.bind(this);
  }

  toggleLifeStyle(evt){
      let {
        lifeStyleA,
        lifeStyleB,
        lifeStyleC,
        lifeStyleD,
        lifeStyleE
      } = this.state;
      if(evt.target.id === 'lifeStyleA') lifeStyleA = !lifeStyleA;
      if(evt.target.id === 'lifeStyleB'){
          lifeStyleB = !lifeStyleB;
          if(lifeStyleB) lifeStyleE = false;
      }
      if(evt.target.id === 'lifeStyleC') lifeStyleC = !lifeStyleC;
      if(evt.target.id === 'lifeStyleD') lifeStyleD = !lifeStyleD;
      if(evt.target.id === 'lifeStyleE'){
        lifeStyleE = !lifeStyleE;
        if(lifeStyleE) lifeStyleB = false;
      }
      this.setState({
          lifeStyleA,
          lifeStyleB,
          lifeStyleC,
          lifeStyleD,
          lifeStyleE
      })
  }
  
  setEdad(evt){
      this.setState({
          edad: evt.currentTarget.dataset.edad
      });
  }

  setPiel(evt){
      this.setState({
          piel: evt.currentTarget.dataset.piel
      })
  }

  setRostro1(evt){
      this.setState({
          rostro1: evt.target.value
      })
  }

  setRostro2(evt){
      this.setState({
          rostro2: evt.target.value
      })
  }

  setPiel1(evt){
      this.setState({
          piel1: evt.target.value
      })
  }

  setPiel2(evt){
      this.setState({
          piel2: evt.target.value
      })
  }

  checkForm(){
      return (!this.state.lifeStyleA && !this.state.lifeStyleB && !this.state.lifeStyleC && !this.state.lifeStyleD && !this.state.lifeStyleE)
                || !this.state.edad 
                || !this.state.piel
  }
  
  formResult(){
    let points = 0;
    const tratments=[];
    if(this.state.lifeStyleA) points += 1;
    if(this.state.lifeStyleB) points += 1;
    if(this.state.lifeStyleC) points += 1;
    if(this.state.lifeStyleD) points += 2;
    if(this.state.piel === "2") points += 2;
    if(this.state.piel === "3") points += 4;
    if(this.state.piel === "4") points += 8;
    if(this.state.piel === "5") points += 10;
    if(this.state.rostro1 === "1" || this.state.rostro1 === "2" || this.state.rostro2 === "1" || this.state.rostro2 === "2"){
        tratments.push({name:"Ácido hialurónico", "desc":"Armoniza, estructura, voluminiza y define, según sea el caso. Es ideal para corregir algunos rasgos de rostro y labios sin efecto manzana; brinda firmeza sin perder expresión."});
    }
    if( (this.state.rostro1 === "3" || this.state.rostro1 === "9" || this.state.rostro2 === "3" || this.state.rostro2 === "9") 
        || this.state.piel1 === "1" || this.state.piel1 === "2" || this.state.piel1 === "5" || this.state.piel2 === "1" || this.state.piel2 === "2" || this.state.piel2 === "5"){
        tratments.push({name:"Bioestimulador de colágeno", "desc": "Mejora la calidad y firmeza de la piel, ayudando a prolongar el aspecto joven del rostro."});
    }
    if(this.state.rostro1 === "4" || this.state.rostro1 === "5" || this.state.rostro1 == "6" || this.state.rostro2 === "4" || this.state.rostro2 === "5" || this.state.rostro2 === "6"){
        tratments.push({ name:"Tratamiento inyectable para las arrugas de la frente y entrecejo", "desc":"Acción focalizada y pura para las zonas de mayor expresión, sin perder movimiento y naturalidad, sin efecto congelado, y además previene la formación de arrugas profundas."});
    }
    if(this.state.rostro1 === "7" || this.state.rostro1 === "8" || this.state.rostro2 === "7" || this.state.rostro2 === "8"){
        tratments.push({name:"Ultrasonido microfocalizado en tiempo real", "desc": "Estimula la producción de colágeno para lograr una reducción de líneas de expresión, efecto lifting sin cirugía y mejorar la calidad de la piel."});
    }
    if(this.state.piel1 === "3" || this.state.piel1  === "4" || this.state.piel2 === "3" || this.state.piel2 === "4"){
        tratments.push({name:"Tratamiento anti-celulitis", desc:"Elimina los hoyuelos de la celulitis de forma mínimamente invasiva, en una sola sesión y de forma duradera hasta por 3 años."});
    }
    let tipo = "";
    if(points >= 0 && points <= 6) tipo = "Prevención";
    if(points >= 7 && points <= 13) tipo = "Corrección intermedia";
    if(points > 13 ) tipo = "Corrección avanzada";
    this.setState({
        showModal: true,
        tipo: tipo, 
        tratments: tratments
    })
  }

  componentDidUpdate(){
    const changes = {};


    if(this.state.post !== this.props.match.params.post){
      changes.post = this.props.match.params.post;
      changes.blogData = lifestyle[changes.post];
    }
    if(Object.keys(changes).length > 0){
      this.setState(changes);
    }
  }

  banners = [
    {
      imagen: encabezado,
      text: ""
    },
  ]

  render() {
      console.log(this.state);
   return  (
    <div className="Contenedor">
    <BannerHeader descripcion='' imagen={encabezado} banners={this.banners} />      
    <div id="nuevo" style={{width:'100%'}}>
      <SoloTexto titulo='UNOS MINUTOS PARA ENTENDER TU PIEL' color='texto-pink texto-bold texto-xl' style={{borderBottom:'2px solid #DCB8A4'}} alineacion='center'/>
      <hr style={{color: 'rgba(219,183,163,0.5)'}}/>
      <SoloTexto titulos={[
        '¿Sabes lo que tu piel necesita? Resuelve este test y da el primer paso para descubrirlo. '
      ]} color='texto-gray texto-montserrat texto-m' alineacion='center'/>
  </div>
  <div className="diagnostic">
    <div style={{boxSizing:'border-box',width:'100%', padding:'2rem', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between'}}>
        <SoloTexto titulo='TU ESTILO DE VIDA:' color='texto-pink texto-bold texto-m' style={{borderBottom:'2px solid #DCB8A4'}} alineacion='center'/>
        <div style={{width:'100%', display:'flex', flexDirection:'row', margin:'3rem auto', alignItems:'center', justifyContent:'space-between'}}>
            <div className="diagnostic-life-style-item">
                <span className="texto-s texto-grey">Me desvelo <br/> frecuentemente</span>
                <div id="lifeStyleA" onClick={this.toggleLifeStyle} className={"LinkButton " + (this.state.lifeStyleA ? "selected" : "")} >A</div>
            </div>
            <div className="diagnostic-life-style-item">
                <span className="texto-s texto-grey">Tengo una vida <br/> agitada</span>
                <div id="lifeStyleB" onClick={this.toggleLifeStyle} className={"LinkButton " + (this.state.lifeStyleB ? "selected" : "")}>B</div>
            </div>
            <div className="diagnostic-life-style-item">
                <span className="texto-s texto-grey">Mi dieta es poco <br/> balanceada</span>
                <div id="lifeStyleC" onClick={this.toggleLifeStyle} className={"LinkButton " + (this.state.lifeStyleC ? "selected" : "")}>C</div>
            </div>
            <div className="diagnostic-life-style-item">
                <span className="texto-s texto-grey">Fumo</span>
                <div id="lifeStyleD" onClick={this.toggleLifeStyle} className={"LinkButton " + (this.state.lifeStyleD ? "selected" : "")}>D</div>
            </div>
            <div className="diagnostic-life-style-item">
                <span className="texto-s texto-grey">Tengo una vida <br/> equilibrada</span>
                <div id="lifeStyleE" onClick={this.toggleLifeStyle} className={"LinkButton " + (this.state.lifeStyleE ? "selected" : "")}>E</div>
            </div>
        </div>
        <SoloTexto titulo='TU EDAD:' color='texto-pink texto-bold texto-m' style={{borderBottom:'2px solid #DCB8A4'}} alineacion='center'/>
        <div style={{width:'90%', display:'flex', flexDirection:'row', margin:'3rem auto', alignItems:'center', justifyContent:'space-between'}}>
            <div className="diagnostic-age-item">
                <span className="texto-s texto-grey">Menos de 20 años</span>
            </div>
            <div className={"diagnostic-age-item " + (this.state.edad === "1" ? "selected" : "")} data-edad="1" onClick={this.setEdad}>
                <BarLeft />
            </div>
            <div className={"diagnostic-age-item " + (this.state.edad === "2" ? "selected" : "")} data-edad="2" onClick={this.setEdad}>
                <Bar />
            </div>
            <div className={"diagnostic-age-item " + (this.state.edad === "3" ? "selected" : "")} data-edad="3" onClick={this.setEdad}>
                <Bar />
            </div>
            <div className={"diagnostic-age-item " + (this.state.edad === "4" ? "selected" : "")} data-edad="4" onClick={this.setEdad}>
                <Bar />
            </div>
            <div className={"diagnostic-age-item " + (this.state.edad === "5" ? "selected" : "")} data-edad="5" onClick={this.setEdad}>
                <BarRight />
            </div>
            <div className="diagnostic-age-item">
                <span className="texto-s texto-grey">Más de 50 años</span>
            </div>
        </div>
        <SoloTexto titulo='DIRÍAS QUE TU PIEL TIENE:' color='texto-pink texto-bold texto-m' style={{borderBottom:'2px solid #DCB8A4'}} alineacion='center'/>
        <div style={{width:'90%', display:'flex', flexDirection:'row', margin:'3rem auto', alignItems:'center', justifyContent:'space-between'}}>
            <div className="diagnostic-age-item">
                <span className="texto-s texto-grey">Líneas de expresión finas o nulas</span>
            </div>
            <div className={"diagnostic-age-item " + (this.state.piel === "1" ? "selected" : "")} data-piel="1" onClick={this.setPiel}>
                <BarLeft />
            </div>
            <div className={"diagnostic-age-item " + (this.state.piel === "2" ? "selected" : "")} data-piel="2" onClick={this.setPiel}>
                <Bar />
            </div>
            <div className={"diagnostic-age-item " + (this.state.piel === "3" ? "selected" : "")} data-piel="3" onClick={this.setPiel}>
                <Bar />
            </div>
            <div className={"diagnostic-age-item " + (this.state.piel === "4" ? "selected" : "")} data-piel="4" onClick={this.setPiel}>
                <Bar />
            </div>
            <div className={"diagnostic-age-item " + (this.state.piel === "5" ? "selected" : "")} data-piel="5" onClick={this.setPiel}>
                <BarRight />
            </div>
            <div className="diagnostic-age-item">
                <span className="texto-s texto-grey">Líneas de expresión profundas</span>
            </div>
        </div>
        <SoloTexto titulo={['SELECCIONA LAS CONDICIONES QUE DESCRIBAN',<br/>,'EL ESTADO ACTUAL DE LA PIEL DE TU ROSTRO']} color='texto-pink texto-bold texto-m' style={{borderBottom:'2px solid #DCB8A4'}} alineacion='center'/>
        <div className="diagnostic-selections">
            <div className='diagnostic-select' >
                <select onChange={this.setRostro1} className="texto-bold texto-m texto-pink texto-montserrat">
                    <option value="1">Arrugas nasolabiales profundas</option>
                    <option value="2">Falta de  volumen en labios</option>
                    <option value="3">Arrugas nasolabiales finas </option>
                    <option value="4">Arrugas en el entrecejo </option>
                    <option value="5">Arrugas en frente </option>
                    <option value="6">Patas de gallo </option>
                    <option value="7">Falta de densidad y firmeza </option>
                    <option value="8">Tono Irregular </option>
                    <option value="9">Marcas de acné </option>
                </select>
            </div>
            <div className='diagnostic-select' >
                <select onChange={this.setRostro2} className="texto-bold texto-m texto-pink texto-montserrat">
                    <option value="0">Ninguna de las opciones</option>
                    <option value="1">Arrugas nasolabiales profundas</option>
                    <option value="2">Falta de  volumen en labios</option>
                    <option value="3">Arrugas nasolabiales finas </option>
                    <option value="4">Arrugas en el entrecejo </option>
                    <option value="5">Arrugas en frente </option>
                    <option value="6">Patas de gallo </option>
                    <option value="7">Falta de densidad y firmeza </option>
                    <option value="8">Tono Irregular </option>
                    <option value="9">Marcas de acné </option>
                </select>
            </div>
        </div>
        <SoloTexto titulo={['SELECCIONA LAS CONDICIONES QUE DESCRIBAN',<br/>,'EL ESTADO ACTUAL DE LA PIEL DE TU CUERPO']} color='texto-pink texto-bold texto-m' style={{borderBottom:'2px solid #DCB8A4'}} alineacion='center'/>
        <div className="diagnostic-selections">
            <div className='diagnostic-select' >
                <select onChange={this.setPiel1} className="texto-bold texto-m texto-pink texto-montserrat">
                    <option value="1">Abdomen flácido </option>
                    <option value="2">Brazos sin tono muscular </option>
                    <option value="3">Glúteos con celulitis </option>
                    <option value="4">Piernas con celulitis</option>
                    <option value="5">Piel adelgazada en manos </option>
                </select>
            </div>
            <div className='diagnostic-select' >
                <select onChange={this.setPiel2} className="texto-bold texto-m texto-pink texto-montserrat">
                    <option value="0">Ninguna de las opciones</option>
                    <option value="1">Abdomen flácido </option>
                    <option value="2">Brazos sin tono muscular </option>
                    <option value="3">Glúteos con celulitis </option>
                    <option value="4">Piernas con celulitis</option>
                    <option value="5">Piel adelgazada en manos </option>
                </select>
            </div>
        </div>
        <div style={{width:'80%', display:'flex', flexDirection:'row', margin:'3rem auto', alignItems:'center', justifyContent:'center'}}>
            <div className='diagnostic-life-style-item' style={{width:'40%'}} >
                <button disabled={this.checkForm()} onClick={this.formResult} className="LinkButton texto-m texto-bold" >DIAGNOSTICAR</button>
            </div>
        </div>

    </div>
  </div>
  <div id="experiencias">
    <SoloTexto titulo='EXPERIENCIAS DE CAMBIO DE VIDA' color='texto-pink texto-bold texto-xl' alineacion='center'/>
    <hr style={{color: 'rgba(219,183,163,0.5)', height:'0px'}}/>
    <SoloTexto titulos={[
      '¿Te identificas con estas historias?',
      '¡No esperes más y acude a un especialista!' 
    ]} color='texto-grey texto-montserrat texto-m' alineacion='center'/>
    <div style={{margin:'0em auto 1em auto', display:'flex', flexDirection:'row', flexWrap:'wrap', alignItems:'flex-start', width:'100%'}} >
      <Paciente imagen={default_user} nombre="Susana Sil" paciente="Dermatóloga" descripcion="Después de cierta edad, perdemos  1% del colágeno en nuestra piel al año. Hoy en día, existen tratamientos estéticos no invasivos para recuperar este porcentaje y mantenerlo. Nunca hay que olvidar una buena rutina de skincare y gym facial"/>
      <Paciente imagen={default_user} nombre="Michele" paciente="Paciente Ultherapy" descripcion="Después de recibir este tratamiento, siento que mi apariencia coincide con la forma en que me siento por dentro"/>
      <Paciente imagen={default_user} nombre="Daniela Méndez" paciente="Psicóloga y Terapeuta Ocupacional" descripcion="Algunos tips para mejorar el rendimiento en tu trabajo, consisten en mantener una postura adecuada, colocar el monitor de la computadora a la altura de tus ojos, evitando puntos de tensión en manos y brazos, y sobre todo tomar tomar descansos para relajar tu cuerpo y articulaciones" />
      <Paciente imagen={default_user} nombre="Caro" paciente="Paciente Belotero" descripcion="Es súper efectivo; he visto el cambio en mi piel desde mi primera aplicación"/>
    </div>
  </div>
  {this.state.showModal &&
    <Modal onClose={(e)=>{this.setState({showModal:false})}} show={this.state.showModal}>
        <div style={{backgroundColor:"white", width:"100%", minHeight:'400px', display:'flex', paddingTop:'3rem', flexDirection:'column', borderRadius:'10px'}} >
            <SoloTexto titulo={'ESTOS SON LOS TRATAMIENTOS COSMÉTICOS DE ' + this.state.tipo.toUpperCase() + ' IDEALES' } color='texto-pink texto-bold texto-m' alineacion='center'/>
            {
                this.state.tratments.map((tratment)=>{
                    return <span style={{padding: "1rem", boxSizing:'border-box', textAlign:'left', borderTop:'1px #70AEC1 solid'}} className="texto-gray texto-m"><span className="texto-pink texto-bold" style={{textTransform:'uppercase'}}>{tratment.name}:</span> {tratment.desc}</span>
                })
            }
            
        </div>
    </Modal>
  }
</div>
   )
  }
}

export default Diagnostic;
