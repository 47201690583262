import React from 'react';
import './App.css';
import { isUndefined } from 'util';

class SoloTexto extends React.Component{
constructor(props){
  super(props);
  this.state={texto:" "};  
  this.handleChangeText=this.handleChangeText.bind(this);
}
static defaultProps ={
  clickHandler2:""
  }
  
  handleChangeText=
  function(texto2) {
  this.setState({texto:texto2})  
  console.log('solotexto '+texto2);
 }
    render(){
      return(
          <div className="BannerInformativo" style={{textAlign:this.props.alineacion}}>            
            {
              this.props.titulo && <span className={this.props.color} style={{textAlign:this.props.alineacion}}>{this.props.titulo}</span>
            }
            {
              this.props.titulos && this.props.titulos.map((titulo, idx)=>{
                return <span key={"props_titulo" + idx} className={this.props.color} style={{textAlign:this.props.alineacion}}>{titulo}</span>
              })
            }
            {this.props.children}
        </div>
        )
      
      
      }}

    
        export default SoloTexto;