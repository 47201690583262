import React from 'react';
import './App.css';
import BannerPrincipal from './BannerPrincipal.js';
import BannerSecundario from './BannerSecundario.js';
import Cuadricula from './Cuadricula.js';
import BannerH from './BannerHorizontal.js';
import BannerHeader from './BannerHeader.js';
import Blog from './Blog.js';
import BlogHoriz from './BlogHoriz.js'
import Paciente from './Paciente.js';

import SoloTexto from './SoloTexto';
import Separador from './Separador';
import BarraOpciones from './BarraOpciones.js';
import {Link} from 'react-router-dom';
import Certificaciones from './Certificaciones.js';
import Cotizar from './Cotizar.js';

import encabezado from './images/1x/betterself_banner_1.jpg';
import banner1 from './images/1x/betterself_nuevo_banner1.jpg';
import banner2 from './images/1x/betterself_nuevo_banner2.jpg';
import banner3 from './images/1x/betterself_nuevo_banner3.jpg';
import banner_cosmetics from './images/1x/betterself_banner_cosmetics.jpg';
import banner_lifestyle from './images/1x/betterself_banner_lifestyle.jpg';
import banner_consulta from './images/1x/betterself_banner_consulta.png';

import blog1Img from './images/gallery/Post10_activos_BetterSelf.jpg'
import blog2Img from './images/gallery/Post9_blog_BetterSelf.jpg';
import blog3Img from './images/gallery/Post12_eficacia_BetterSelf.jpg';
import blog4Img from './images/gallery/Post2_filosofia_BetterSelf.jpg';

import default_user from './images/1x/betterself_default_user_image.jpg'

import cosmetics_1 from './images/1x/betterself_cosmetic_blog_1.jpg';
import cosmetics_2 from './images/1x/betterself_cosmetic_blog_2.jpg';
import cosmetics_3 from './images/1x/betterself_cosmetic_blog_3.jpg';
import cosmetics_4 from './images/1x/betterself_cosmetic_blog_4.jpg';
import cosmetics_5 from './images/1x/betterself_cosmetic_blog_5.jpg';
import cosmetics_6 from './images/1x/betterself_cosmetic_blog_6.jpg';
import cosmetics_7 from './images/1x/betterself_cosmetic_blog_7.jpg';
import cosmetics_8 from './images/1x/betterself_cosmetic_blog_8.jpg';
import cosmetics_9 from './images/1x/betterself_cosmetic_blog_9.jpg';


import lifestyle from './data/lifestyle.js';
import lifestyle_86 from './images/1x/betterself_blog_lifestyle_86.jpg';
import lifestyle_87 from './images/1x/betterself_blog_lifestyle_87.jpg';
import lifestyle_88 from './images/1x/betterself_blog_lifestyle_88.jpg';
import lifestyle_89 from './images/1x/betterself_blog_lifestyle_89.jpg';


import video_home_low from './video/BETTERSELF_HOR_44SEG_480.mp4';
import video_home_medium from './video/BETTERSELF_HOR_44SEG_960.mp4';
import video_home_high from './video/BETTERSELF_HOR_44SEG_1920.mp4';

class Opc extends React.Component{
  constructor(props){
    super(props);    
  }
  
  componentDidMount () {
    if(this.props.val === 0){
      const script = document.createElement("script");

      script.src = "https://www.sociablekit.com/app/embed/instagram-feed/widget.js";
      script.async = true;

      document.body.appendChild(script);
      console.log("Sociablekit");
    }
  }

  
    static defaultProps ={
        val:0 
      }    
      render(){
        
 var val=this.props.val
if (val==0){
    var banners = [
      {
        imagen: banner1,
        text: ""
      },
      {
        imagen: banner2,
        text: ""
      },
      {
        imagen: banner3,
        text: ""
      },
    ]
      return( 
    <div className="Contenedor">
        {
          (window.innerWidth > 711)?
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', position:'relative', boxSizing:'border-box'}}>
            <video playsInline autoPlay muted loop style={{ width: window.innerWidth <= 711? '100%' : '80%'}}  >
              {window.innerWidth <= 711 && <source src={video_home_low} type="video/mp4" media="all and (max-width: 711px)" />}
              {window.innerWidth > 711 && window.innerWidth <= 1280 && < source src={video_home_medium} type="video/mp4"/>}
              {window.innerWidth > 1280 && < source src={video_home_high} type="video/mp4"/>}
            </video>
          </div>
          :
          <BannerHeader descripcion='' imagen={encabezado} banners={banners} />      
        }
        <div id="nuevo">
          <SoloTexto titulo='NUESTRO BLOG DE BELLEZA Y BIENESTAR' color='texto-pink texto-bold texto-xl' style={{borderBottom:'2px solid #DCB8A4'}} alineacion='center'/>
          <hr style={{color: 'rgba(219,183,163,0.5)'}}/>
          <SoloTexto titulos={[
            'Proyectar tu belleza externa e interna no es una tarea fácil.',
            'En Betterself, los expertos ' +
            'en bienestar integral más aclamados de la industria, te ayudarán con consejos reales ' +
            'y efectivos para alcanzar tus metas. En este espacio hablaremos de salud, moda, ' +
            'belleza, cuidado personal, tratamientos, mitos y realidades ¡y más!' 
          ]} color='texto-gray texto-montserrat texto-m' alineacion='center'/>
        <div style={{margin:'0em 0em 1em 0em', display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-evenly', width:'100%'}} >
          {
            Object.keys(lifestyle).reverse().filter((blogPost, idx) => (idx < 4)).map((blogPost, idx)=>{
              const post = lifestyle[blogPost];
              return <Blog key={`lifestyle-${blogPost}`} imagen={post.image} link={`/lifestyle/${blogPost}`} titulo={post.title} descripcion=""  />;
            })
          }
        </div>
      </div>
      { false && <BannerHeader descripcion='' imagen={encabezado} banners={
        [{
          imagen: banner_consulta,
          text: <div className="textoHeader texto-m" style={{position:'absolute', top:'10%', right:'10%', bottom:'10%', display:'flex', 'flexDirection':'column'}}>
            <span className='texto-white texto-montserrat'>Encuentra a los profesionales de</span>
            <span className='texto-white texto-montserrat'>la salud y estilo de vida</span>
            <span className='texto-white texto-montserrat'>más prestigiados e inicia tu</span>            
            <span className='texto-white texto-montserrat'>transformación</span>
            <span className='texto-white texto-tahu' style={{marginTop:'auto',marginBottom:'auto'}}>¡Descúbrelos!</span>
            <Link to="/specialist" style={{textDecoration:'none', borderTop:'1px solid white', borderBottom:'1px solid white'}} className='texto-spaced texto-white texto-bold specialist-link' >AQUÍ</Link>
          </div>
        }]
      } />}

      <div id="experiencias">
        <SoloTexto titulo='EXPERIENCIAS DE CAMBIO DE VIDA' color='texto-pink texto-bold texto-xl' alineacion='center'/>
        <hr style={{color: 'rgba(219,183,163,0.5)', height:'0px'}}/>
        <SoloTexto titulos={[
          '¿Te identificas con estas historias?',
          '¡No esperes más y acude a un especialista!' 
        ]} color='texto-grey texto-montserrat texto-m' alineacion='center'/>
        <div style={{margin:'0em auto 1em auto', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-around', flexWrap:'wrap', alignItems:'flex-start', width:'100%'}} >
          <Paciente imagen={default_user} nombre="Susana Sil" paciente="Dermatóloga" descripcion="Después de cierta edad, perdemos  1% del colágeno en nuestra piel al año. Hoy en día, existen tratamientos estéticos no invasivos para recuperar este porcentaje y mantenerlo. Nunca hay que olvidar una buena rutina de skincare y gym facial"/>
          {false && <Paciente imagen={default_user} nombre="Michele" paciente="Paciente Ultherapy" descripcion="Después de recibir este tratamiento, siento que mi apariencia coincide con la forma en que me siento por dentro"/>}
          <Paciente imagen={default_user} nombre="Daniela Méndez" paciente="Psicóloga y Terapeuta Ocupacional" descripcion="Algunos tips para mejorar el rendimiento en tu trabajo, consisten en mantener una postura adecuada, colocar el monitor de la computadora a la altura de tus ojos, evitando puntos de tensión en manos y brazos, y sobre todo tomar tomar descansos para relajar tu cuerpo y articulaciones" />
          {false && <Paciente imagen={default_user} nombre="Caro" paciente="Paciente Belotero" descripcion="Es súper efectivo; he visto el cambio en mi piel desde mi primera aplicación"/>}
        </div>
      </div>
    </div>)
}
if (val==1){
  return(    
    <div className="Contenedor">
      <BannerHeader descripcion='' imagen={encabezado} banners={[
        {
          imagen: banner3,
          text:""
        }
      ]} />      
      <div id="proyectar">
        <SoloTexto titulo='PROYECTAR TU BELLEZA EXTERNA E INTERNA NO ES UNA TAREA FÁCIL' color='texto-pink texto-bold texto-xl' alineacion='center'/>
        <SoloTexto titulo={
          'En BetterSelf, los expertos en bienestar integral más aclamados de la ' +
          'industria, te ayudarán con consejos reales y efectivos para alcanzar tus ' +
          'metas. En este espacio hablaremos de salud, moda, belleza, cuidado ' +
          'personal, tratamientos, mitos, realidades ¡y más!' 
        } color='texto-grey texto-montserrat texto-m' alineacion='center'/>
        <div style={{margin:'0em auto 1em auto', width:'100%'}} >
          <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
            <BlogHoriz imagen={cosmetics_8} link="/cosmetics/inyectables-usos-mitos-realidades" titulo="Procedimiento estéticos inyectables: usos, mitos y realidades" descripcion=""/>
            <BlogHoriz mid imagen={cosmetics_7} link="/cosmetics/ultherapy" titulo="Ultherapy en voz de la Dra. Paty Herrera" />
            <Blog mid imagen={cosmetics_6} link="/cosmetics/medicina-genomica" titulo="FAQs de la Medicina Genómica" />
            <Blog mid imagen={cosmetics_5} link="/cosmetics/colageno-muy-importante" titulo="Coláge...¿no? ¡Sí, es muy importante!" />
            <Blog mid imagen={cosmetics_1} link="/cosmetics/evita-el-envejecimiento-prematuro" titulo="Tener 50 y parecer de 30 es el deseo de todas" />
            <Blog mid imagen={cosmetics_2} link="/cosmetics/acido-hialuronico-que-hace" titulo="Ácido hialurónico: sí. Pero, ¿exactamente qué hace?" /> 
            <Blog mid imagen={cosmetics_3}  link="/cosmetics/como-lograr-tu-betterself-segun-expertos" titulo="¿Cómo lograr tu BetterSelf, según los expertos?" />
            <Blog mid imagen={cosmetics_4} link="/cosmetics/bienvenida-al-invierno-beneficios" titulo="Dale la bienvenida al invierno. ¡Descubre los beneficios"/> 
          </div>
        </div>
      </div>
      <div id="experiencias">
        <SoloTexto titulo='EXPERIENCIAS DE CAMBIO DE VIDA' color='texto-pink texto-bold texto-xl' alineacion='center'/>
        <SoloTexto titulos={[
          '¿Te identificas con estas historias?',
          '¡No esperes más y acude a un especialista!' 
        ]} color='texto-grey texto-montserrat texto-m' alineacion='center'/>
        <div style={{margin:'0em auto 1em auto', display:'flex', flexDirection:'row', flexWrap:'wrap', alignItems:'center', justifyContent:'space-around', width:'100%'}} >
          <Paciente imagen={default_user} nombre="Susana Sil" paciente="Dermatóloga" descripcion="Después de cierta edad, perdemos  1% del colágeno en nuestra piel al año. Hoy en día, existen tratamientos estéticos no invasivos para recuperar este porcentaje y mantenerlo. Nunca hay que olvidar una buena rutina de skincare y gym facial"/>
          {false && <Paciente imagen={default_user} nombre="Michele" paciente="Paciente Ultherapy" descripcion="Después de recibir este tratamiento, siento que mi apariencia coincide con la forma en que me siento por dentro"/>}
          <Paciente imagen={default_user} nombre="Daniela Méndez" paciente="Psicóloga y Terapeuta Ocupacional" descripcion="Algunos tips para mejorar el rendimiento en tu trabajo, consisten en mantener una postura adecuada, colocar el monitor de la computadora a la altura de tus ojos, evitando puntos de tensión en manos y brazos, y sobre todo tomar tomar descansos para relajar tu cuerpo y articulaciones" />
          {false && <Paciente imagen={default_user} nombre="Caro" paciente="Paciente Belotero" descripcion="Es súper efectivo; he visto el cambio en mi piel desde mi primera aplicación"/>}
          </div>
        </div>

      {false && <BannerHeader descripcion='' imagen={encabezado} banners={
        [{
          imagen: banner_consulta,
          text: <div className="textoHeader texto-m" style={{position:'absolute', top:'10%', right:'10%', bottom:'10%', display:'flex', 'flexDirection':'column'}}>
            <span className='texto-white texto-montserrat'>Encuentra a los profesionales de</span>
            <span className='texto-white texto-montserrat'>la salud y estilo de vida</span>
            <span className='texto-white texto-montserrat'>más prestigiados e inicia tu</span>            
            <span className='texto-white texto-montserrat'>transformación</span>
            <span className='texto-white texto-tahu' style={{marginTop:'auto',marginBottom:'auto'}}>¡Descúbrelos!</span>
            <Link to="/specialist" style={{textDecoration:'none', borderTop:'1px solid white', borderBottom:'1px solid white'}} className='texto-spaced texto-white texto-bold specialist-link' >AQUÍ</Link>
          </div>
        }]
      } />}

    </div>)
  }
  if (val==2){
    return(    
      <div className="Contenedor">
        <BannerHeader descripcion='' imagen={encabezado} banners={[
          {
            imagen: banner2,
            text:""
          }
        ]} />      
        <div id="proyectar">
          <SoloTexto titulo='PROYECTAR TU BELLEZA EXTERNA E INTERNA NO ES UNA TAREA FÁCIL' color='texto-pink texto-bold texto-xl' alineacion='center'/>
          <SoloTexto titulos={[
            'En BetterSelf, los expertos en bienestar integral más aclamados de la ' +
            'industria, te ayudarán con consejos reales y efectivos para alcanzar tus ' +
            'metas.',
            'En este espacio hablaremos de salud, moda, belleza, cuidado ' +
            'personal, tratamientos, mitos, realidades ¡y más!' 
          ]} color='texto-grey texto-montserrat texto-m' alineacion='center'/>
          <div style={{margin:'0em auto 1em auto', width:'100%'}} >
            <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
              {
                Object.keys(lifestyle).reverse().map((blogPost, idx)=>{
                  if(this.props.match.params.post === blogPost) return <></>;
                  const BlogType = idx < 4 ? BlogHoriz : Blog;
                  const post = lifestyle[blogPost];
                  return <BlogType key={`lifestyle-${blogPost}`} imagen={post.image} link={`/lifestyle/${blogPost}`} titulo={post.title} descripcion="" mid />;
                })
              }
            </div>
          </div>
        </div>
  
        {false && <BannerHeader descripcion='' imagen={encabezado} banners={
        [{
          imagen: banner_consulta,
          text: <div className="textoHeader texto-m" style={{position:'absolute', top:'10%', right:'10%', bottom:'10%', display:'flex', 'flexDirection':'column'}}>
            <span className='texto-white texto-montserrat'>Encuentra a los profesionales de</span>
            <span className='texto-white texto-montserrat'>la salud y estilo de vida</span>
            <span className='texto-white texto-montserrat'>más prestigiados e inicia tu</span>            
            <span className='texto-white texto-montserrat'>transformación</span>
            <span className='texto-white texto-tahu' style={{marginTop:'auto',marginBottom:'auto'}}>¡Descúbrelos!</span>
            <Link to="/specialist" style={{textDecoration:'none', borderTop:'1px solid white', borderBottom:'1px solid white'}} className='texto-spaced texto-white texto-bold specialist-link' >AQUÍ</Link>
          </div>
        }]
      } />}
      </div>)
    }
  if (val==3){
    var tamanios=[
      {key:1, nombre:[<br></br>,'38/42'], titulo:'Tamaño 1'},
      {key:2, nombre:[<br></br>,'40/50'], titulo:'Tamaño 2'},
      {key:3, nombre:[<br></br>,'Mitades'], titulo:'Tamaño 3'},
      {key:4, nombre:[<br></br>,'¿?'], titulo:'Tamaño 4'}];
    var variedades=[
      {key:1, nombre:[<br></br>,'Virginia'], descripcion:'LoremIpsumis simply dummy text of the printing and typesetting industry. LoremIpsumhas', link:[<br></br>,'Descargar ficha tècnica']},
      {key:2, nombre:[<br></br>,'Runner'], descripcion:'LoremIpsumis simply dummy text of the printing and typesetting industry. LoremIpsumhas' , link:[<br></br>,'Descargar ficha tècnica']},
      {key:3, nombre:[<br></br>,'Español'], descripcion:'LoremIpsumis simply dummy text of the printing and typesetting industry. LoremIpsumhas', link:[<br></br>,'Descargar ficha tècnica']},
      {key:4, nombre:[<br></br>,'Valencia'], descripcion:'LoremIpsumis simply dummy text of the printing and typesetting industry. LoremIpsumhas', link:[<br></br>,'Descargar ficha tècnica']}];
    return(    
      <div className="Contenedor">
        <BannerPrincipal descripcion='BANNER CACAHUATES'/>        
        <SoloTexto texto={["Lorem Ipsum is simply dummy text of the printing and typesetting industry.",<br></br>,"LoremIpsumhas been the industry'sstandard dummy text ever since the 1500s"]} titulo=""/>     
        <SoloTexto titulo="Tamaños"/>     
        <BannerH conceptos={tamanios}/>
        <BannerH estilo="cuadrado" conceptos={variedades}/>      
        <div style={{width:"100%"}}>
          <br></br>           
          <Link className="LinkButton"  style={{float:"center"}} to="/products">Regresar a todos los productos</Link>        
        </div>         
        <Cotizar/>
        <SoloTexto titulo="Proceso productivo / Cadena de valor"/>
        <BannerSecundario descripcion="video animado sobre el proceso"/>  
      </div>)
  }
  if (val==4){
    var servicios=[
      {key:1, nombre:'Servicio 1', descripcion:['Marca blanca',<br></br>,'Empaquetado']},
      {key:2, nombre:'Servicio 2', descripcion:['Productos',<br></br>,'a la medida']},
      {key:3, nombre:'Servicio 3', descripcion:['Siembra',<br></br>,'Agroindustria']},
      {key:4, nombre:'Servicio 4', descripcion:['Proveedor',<br></br>,'de ingredientes']},
      {key:5, nombre:'Servicio 5', descripcion:'Descripcion del servicio 5'},
      {key:6, nombre:'Servicio 6', descripcion:'Descripcion del servicio 6'},
      {key:7, nombre:'Servicio 7', descripcion:'Descripcion del servicio 7'},
      {key:8, nombre:'Servicio 8', descripcion:'Descripcion del servicio 8'}];
    return(    
      <div className="Contenedor">
        <BannerPrincipal descripcion='BANNER SERVICIOS'/>
        <BannerH conceptos={servicios}/>
        <Cotizar/>
        <div className="Banner2cols">          
            <BannerPrincipal descripcion='Ventajas competitivas de la organizacion'/>
            <SoloTexto titulo='Programa de campo' texto='Nos comprometemos a ofrecer el mejor sabor..'/>
        </div>
        <div className="Banner2cols">          
            <SoloTexto titulo='Fechas importantes' texto='Nos comprometemos a ofrecer el mejor sabor..'/>
            <BannerPrincipal descripcion='calendario'/>          
        </div>
      </div>)
  }

  if (val==5){
    var noticias=[
      {key:1 },
      {key:2},
      {key:3}];
    return(    
      <div className="Contenedor">
        <BannerPrincipal descripcion='BANNER ESTILO DE VIDA'/>
        <SoloTexto titulo='Noticias de la industria' />
        <BannerH conceptos={noticias} estilo="cuadrado"/>        
        <Separador/>
        <div className="Banner2cols">          
            <SoloTexto titulo='Overview mercado del cacahuate / confiteria' texto='“Sed ut perspiciatis, undeomnis iste natus error sitvoluptatem accusantium doloremque laudantium, totam rem aperiam eaqueipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo'/>
            <BannerPrincipal descripcion='Panorama del mercado'/>          
        </div>
        <Separador/>
        <Link style={{width:"100%"}} to="/articles">         
          <div className="Mosaico2">            
            <div className="B1">Blog 1</div>
            <div className="B2">Blog 2</div>
            <div className="B3">Blog 2</div>
            <div className="B4">Blog 4</div>          
          </div>  
          </Link>  
        
      </div>)
  }
  if (val==6){
    var naturales=[
      {key:1, nombre:[<br></br>,'Cacahuate',<br></br>,'con càscara'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:2, nombre:[<br></br>,'Cacahuate',<br></br>,'blanqueado',<br></br>,'entero'], descripcion:'Nombre en latin y toda la cosa' , link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:3, nombre:[<br></br>,'Cacahuate',<br></br>,'blanqueado',<br></br>,'mitad'], descripcion:'Descripcion del servicio 3', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:4, nombre:[<br></br>,'Cacahuate',<br></br>,'crudo'], descripcion:'Descripcion del servicio 4', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']}];
    return(    
      <div className="Contenedor">
        <BannerPrincipal descripcion='BANNER INGREDIENTES NATURALES'/>
        <SoloTexto texto={["Lorem Ipsum is simply dummy text of the printing and typesetting industry.",<br></br>,"LoremIpsumhas been the industry'sstandard dummy text ever since the 1500s"]} titulo=""/>     
         <BannerH conceptos={naturales} estilo="cuadrado"/>        
         <div style={{width:"100%"}}>
          <br></br>           
          <Link className="Link LinkButton"  style={{float:"center"}} to="/products">Regresar a todos los productos</Link>        
        </div>         
        <Cotizar/>
        <Certificaciones/>
      </div>)
  }  
  if (val==7){
    var procesados=[
      {key:1, nombre:[<br></br>,'Mantequilla',<br></br>,'de',<br></br>,'cacahuate'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:2, nombre:[<br></br>,'Cacahuate',<br></br>,'en trozo'], descripcion:'Nombre en latin y toda la cosa' , link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:3, nombre:[<br></br>,'Pasta',<br></br>,'de cacachuate'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:4, nombre:[<br></br>,'Cacahuate',<br></br>,'horneado'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']}
    ];
    return(    
      <div className="Contenedor">
        <BannerPrincipal descripcion='BANNER INGREDIENTES PROCESADOS'/>
        <SoloTexto texto={["Lorem Ipsum is simply dummy text of the printing and typesetting industry.",<br></br>,"LoremIpsumhas been the industry'sstandard dummy text ever since the 1500s"]} titulo=""/>     
         <BannerH conceptos={procesados} estilo="cuadrado"/>  
         <div style={{width:"100%"}}>
          <br></br>           
          <Link className="Link LinkButton"  style={{float:"center"}} to="/products">Regresar a todos los productos</Link>        
        </div>               
        <Cotizar/>
        <div className="Banner2cols">
          <BannerPrincipal descripcion='Aplicaciones 1'/>
          <BannerPrincipal descripcion='Aplicaciones 2'/>
        </div>
        <Certificaciones/>
      </div>)
  }  
  if (val==8){
    var granel=[
      {key:1, nombre:[<br></br>,'Pistache',<br></br>,'horneado /',<br></br>,'salado'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:2, nombre:[<br></br>,'Jamaica',<br></br>,'natural'], descripcion:'Nombre en latin y toda la cosa' , link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:3, nombre:[<br></br>,'Nuez de la',<br></br>,'India natural/',<br></br>,'horneada con',<br></br>,'o sin sal'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      
    ];
    var granel3=[
      {key:1, nombre:[<br></br>,'Semilla de',<br></br>,'calabaza'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:2, nombre:[<br></br>,'Garbanzo'], descripcion:'Nombre en latin y toda la cosa' , link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:3, nombre:[<br></br>,'Habas',<br></br>,'o sin sal'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:4, nombre:[<br></br>,'Arandano',<br></br>,'natural'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']}
    ];
    return(    
      <div className="Contenedor">
        <BannerPrincipal descripcion='BANNER PRODUCTOS A GRANEL'/>
        <SoloTexto texto={["Lorem Ipsum is simply dummy text of the printing and typesetting industry.",<br></br>,"LoremIpsumhas been the industry'sstandard dummy text ever since the 1500s"]} titulo=""/>     
         <BannerH conceptos={granel} estilo="cuadrado"/>  
         <BannerH conceptos={granel3} estilo="cuadrado"/>  
         <div style={{width:"100%"}}>
          <br></br>           
          <Link className="Link LinkButton"  style={{float:"center"}} to="/products">Regresar a todos los productos</Link>        
        </div>               
        <Cotizar/>
        <Certificaciones/>
      </div>)
  }  
  if (val==9){
    var articulos=[
      {key:1}, 
      {key:2},
      {key:3}
      
    ];
    
    return(    
      <div className="Contenedor">
        <BannerPrincipal descripcion='BANNER CON IMAGEN DEL ARTICULO'/>
        <SoloTexto texto="“Sed ut perspiciatis, unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam eaque ipsa,
              quae ab illo inventore veritatis et quasi architecto beatae vitae dicta
              sunt, explicabo. Nemo"
              titulo=""/>     
          <SoloTexto titulo="Articulos relacionados"/>
         <BannerH conceptos={articulos} estilo="cuadrado"/>
         <div style={{width:"100%"}}>
          <br></br>           
          <Link className="LinkButton"  style={{float:"center"}} to="/blog">Regresar a todos los blogs</Link>        
          <br></br>           
          <br></br>           
        </div>         
      </div>)
  }  
  if (val==10){
    var rs=[
      {key:1}, 
      {key:2},
      {key:3},
      {key:4},
      {key:5},
      {key:6}
      
    ];
    var SegCal=[
      {key:1, texto:'Politica Inocuidad',detalle:'aqui datammakos la inocuidad'},
      {key:2, texto:'Policita Calidad' ,detalle:'clidad inocuidad'}
    ];
    return(    
      <div className="Contenedor">
        <BannerPrincipal descripcion='BANNER RSS'/>
        <SoloTexto titulo="Desarrollo sustentable"/>     
        <BannerH conceptos={rs} />
        <Separador/>
        <SoloTexto titulo="Responsabilidad social" texto="Nos comprometemos ..."/>     
        <Separador/>
        <div className="Banner2cols">        
          <BannerPrincipal/>             
          <BarraOpciones conceptos={SegCal} childComponent={SoloTexto} titulo='Seguridad alimentaria & Politica de Inocuidad' />          
        </div>
        <Separador/>
        <Certificaciones/>
      </div>)
  }  
  if (val==11){
    var gramajes=[
      {key:1,nombre:'1-100 grs'},
      {key:2,nombre:'100- infinito'}        ];  
    var prods=[
      {key:1, nombre:['Cacahuate con càscara'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:2, nombre:['Cacahuate blanqueado entero'], descripcion:'Nombre en latin y toda la cosa' , link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:3, nombre:['Cacahuate blanqueado mitad'], descripcion:'Descripcion del servicio 3', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:4, nombre:['Cacahuate crudo'], descripcion:'Descripcion del servicio 4', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:5, nombre:['Mantequilla de cacahuate'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:6, nombre:['Cacahuate en trozo'], descripcion:'Nombre en latin y toda la cosa' , link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:7, nombre:['Pasta de cacachuate'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:8, nombre:['Cacahuate horneado'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:9, nombre:['Pistache horneado / salado'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:10, nombre:['Jamaica natural'], descripcion:'Nombre en latin y toda la cosa' , link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:11, nombre:['Nuez de la India natural/ horneada con o sin sal'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:12, nombre:['Arandano natural'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:13, nombre:['Semilla de calabaza blanca)'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:14, nombre:['Semilla de calabaza amarilla horneda / salada'], descripcion:'Nombre en latin y toda la cosa' , link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:15, nombre:['Semilla de calabaza sin cascara natural/salada'], descripcion:'Nombre en latin y toda la cosa' , link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']},
      {key:16, nombre:['Garbanzo crudo /botana'], descripcion:'Nombre en latin y toda la cosa', link:['Valor nutricional',<br></br>,'Descargar ficha tècnica']}];
    return(    
      <div >        
        <div className="Cotizacion">                    
          <div className="Leyenda">Nombre</div>
          <div className="Texto"><input type="Text" className="Caja"></input></div>
          <div className="Leyenda">Industria</div>
          <div className="Texto"><input type="Text" className="Caja"></input></div>
          <div className="Leyenda"> Mail</div>
          <div className="Texto"><input type="Text" className="Caja"></input></div>
          <div></div>
          <div></div>
          <div className="Leyenda">Producto de interès</div>
          <div className="Texto">
            <select id="Productos" className="Caja">
              {prods.map((prod)=>
                  <option id={prod.key.toString}>{prod.nombre}</option>
                  )}                                        
            </select>
          </div>
          <div className="Leyenda">Gramaje</div>
          <div className="Texto">
          <select id="Gramajes" className="Caja">
              {gramajes.map((gramaje)=>
                  <option id={gramaje.key.toString}>{gramaje.nombre}</option>
                  )}                                        
            </select>
          </div>
          <div className="Leyenda"> Mensaje</div>
          
        </div>
        <div style={{width:'80%', margin:'0 auto'}} className="Texto">
          <input type="Text" className="Caja" style={{width:'70%',height:'100px'}}>
          </input><br></br><br></br>

        </div>
        <div style={{width:"fit-content" }}>
          <br></br><br></br>
          <a className="LinkButton" style={{width:'70%', margin:'0 auto',float:"center"}}>Solicitar cotización</a>        
          <br></br><br></br> 
        </div>  
      </div>)
  }  
}} 
export default Opc;