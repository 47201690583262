export default [
    {
        "name":"Dra. Isela Mendez - Clínica Isela Mendez",
        "address":"Plinio 128, Polanco, Polanco II Secc, 11560 Ciudad de México, CDMX, México",
        "phone":"55 5280 4218",
        "lat":19.4374891,
        "lng":-99.2074324,
        "tratments":[
            "Inyectables con ácido hialurónico",
            "Bio-estimulación de colágeno",
            "Ultrasonido micro-focalizado para lifting",
            "Inyectables para líneas de expresión y arrugas"
        ],
        "contacts":[
            {social:"Web",text:"", link:"https://iselamendez.mx/"},
            {social:"Facebook", text:"", link:"https://www.facebook.com/clinicaIselaMendez/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/clinicaiselamendez/"}
        ]
    },
    {
        "name":"Dra. Esperanza Welsh - Welsh Dermatology",
        "address":"Lic. José Benitez 2704, Consultorio 410, Obispado, 64060,Monterrey, Nuevo León, México",
        "phone":"81 8123 4636",
        "lat":25.6756799,
        "lng":-100.3531624,
        "tratments":[
            "Inyectables con ácido hialurónico",
            "Tratamiento para los hoyuelos de celulitis",
            "Bio-estimulación de colágeno",
            "Ultrasonido micro-focalizado para lifting",
            "Inyectables para líneas de expresión y arrugas"
        ],
        "contacts":[
            {social:"Facebook", text:"", link:"https://www.facebook.com/Welsh-Derm-565096097318997/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/welsh.derm/"}
        ]
    },
    {
        "name":"Dra. Patricia Herrera - Dermatóloga",
        "address":"Avenida del Agrónomo 5474, Arcos de Guadalupe, 45037, Zapopan, Jalisco, México",
        "phone":"33 3125 1077",
        "lat":20.6614965,
        "lng":-103.434243,
        "tratments":[
            "Inyectables con ácido hialurónico",
            "Bio-estimulación de colágeno",
            "Ultrasonido micro-focalizado para lifting",
            "Inyectables para líneas de expresión y arrugas"
        ],
        "contacts":[
            {social:"Web", text:"", link:"http://www.patyherrera.com/#home"},
            {social:"Facebook", text:"", link:"https://www.facebook.com/dermaheilen/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/dermapatyherrera/"}
        ]
    },
    {
        "name":"Alejandra Cuadros - Coach Espiritual",
        "address":"Africa 6, La Concepción, Coyoacán, 04020 Ciudad de México, CDMX",
        "phone":"N/A",
        "lat":19.3451032,
        "lng":-99.1583336,
        "tratments":[
            "Coach Angelical certificada",
            "Terapeuta en Sanación",
            "Maestra de Meditación certificada",
            "Experta en desarrollo personal"
        ],
        "contacts":[
            {social:"Web", text:"", link:"https://www.alecuadros.com/"},
            {social:"Facebook", text:"", link:"https://www.facebook.com/alecuadros.expandetuluz/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/ale_cuadros_/"}
        ]        
    },
    {
        "name":"Dra. Marimar Guerra - Medicina Genómica",
        "address":"Av. Isaac Newton 286, Polanco, Polanco V Secc, Miguel Hidalgo, 11560 Ciudad de México, CDMX, México",
        "phone":"55 3079 1427",
        "lat":19.437113,
        "lng":-99.1867507,
        "tratments":[
            "Inyectables con ácido hialurónico",
            "Bio-estimulación de colágeno",
            "Inyectables para líneas de expresión y arrugas"
        ],
        "contacts":[
            {social:"Web", text:"", link:"https://www.medae.mx/"},
            {social:"Facebook", text:"", link:"https://www.facebook.com/MedaeClinic/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/medae_/"}
        ]        
    },
    {
        "name":"Dra. Susana Sil - Dermatóloga",
        "address":"Camino de Sta. Teresa 1055-S, Heroes de Padierna, Héroes de Padierna, La Magdalena Contreras, 10700 Ciudad de México, CDMX",
        "phone":"55 2891 0636",
        "lat":19.2464696,
        "lng":-99.1013498,
        "tratments":[
            "Inyectables con ácido hialurónico",
            "Bio-estimulación de colágeno",
            "Inyectables para líneas de expresión y arrugas"
        ],
    },
    {
        "name":"Daniella Moreno - Consultora en imagen",
        "address":"Berlín 16, Colonia Juárez, 06600 Ciudad de México, CDMX, México",
        "phone":"55 1460 7735",
        "lat":19.4280469,
        "lng":-99.1594147,
        "tratments":[
        ],
        "contacts":[
            {social:"Web", text:"", link:"http://www.daniellamoreno.com/index.php"},
            {social:"Facebook", text:"", link:"https://www.facebook.com/consultoradeimagen/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/daniellamoreno/"}
        ]        
    },
    {
        "name":"Vivian Sadovitch - Nutrióloga",
        "address":"Homero 1804 int 801, Polanco, 11510, Ciudad de México, CDMX, México",
        "phone":"55 5557 9044",
        "lat":19.4365608,
        "lng":-99.2125603,
        "tratments":[
        ],
        "contacts":[
            {social:"Web", text:"", link:"http://www.salud360.com.mx/"},
            {social:"Facebook", text:"", link:"https://www.facebook.com/Salud360/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/salud360/"}
        ]        

    },
    {
        "name":"Dr. Federico Zaldivar - Clini-Medica Valle",
        "address":"Av. Fundadores 955 Local 206, Valle Oriente,  64753, Monterrey, Nuevo León, México",
        "phone":"81 8486 0458",
        "lat":25.6377265,
        "lng":-100.3182701,
        "tratments":[
            "Bio-estimulación de colágeno", 
            "Inyectables con ácido hialurónico",
            "Inyectables para líneas de expresión y arrugas",
            "Tratamiento para los hoyuelos de celulitis"
        ],
        "contacts":[
            {social:"Web", text:"", link:"https://clinimedica.com.mx/"},
            {social:"Facebook", text:"", link:"https://www.facebook.com/CliniMedica/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/clinimedica"}
        ]
    },
    {
        "name":"Dr. Gustavo Jiménez",
        "address":"Miguel Hidalgo 311 Consultorio 504, Centro 37000, León, Guanajuato , México",
        "phone":"47 7176 8791",
        "lat":21.1352691,
        "lng":-101.7161216,
        "tratments":[
            "Ultrasonido micro-focalizado para lifting",
            "Bio-estimulación de colágeno", 
            "Inyectables con ácido hialurónico",
            "Inyectables para líneas de expresión y arrugas",
            "Tratamiento para los hoyuelos de celulitis"
        ],
        "contacts":[
            {social:"Web", text:"", link:"http://www.phiaesthetics.com.mx"},
            {social:"Facebook", text:"", link:"https://www.facebook.com/gustavo.jimenezmunozledo"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/dr.gustavojimenez/"}
        ]
    }, 
    {
        "name":"Dr. Javier Reynoso",
        "address":"Blvd. De los Reyes 6431, San Bernandino, 72820, Tlaxcalancingo, Puebla, México",
        "phone":"22 2363 6029",
        "lat":19.0066461,
        "lng":-98.2680985,
        "tratments":[
            "Bio-estimulación de colágeno", 
        ],
        "contacts":[
            {social:"Facebook", text:"", link:"https://www.facebook.com/MedicinaEsteticaYLongevidad"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/drjavierreynoso"}
        ]
    },
    {
        "name":"Dr. Mario Mucharraz",
        "address":"Prol. Paseo Usumacinta 2085, Tabasco 2000, 36035, Tabasco, Villahermosa, México",
        "phone":"99 3130 6574",
        "lat":17.99558,
        "lng":-92.95379,
        "tratments":[
            "Bio-estimulación de colágeno", 
        ],
        "contacts":[
            {social:"Facebook", text:"", link:"https://www.facebook.com/drmariomucharraz"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/drmariomucharraz"}
        ]
    },
    {
        "name":"Dr. Sergio Verduzco",
        "address":"Diego Rivera 2351 interior 102, Zona Urbana Río, 22010, Tijuana, Baja California, México",
        "phone":"66 4679 6788",
        "lat":32.5250601,
        "lng":-117.0248288,
        "tratments":[
            "Bio-estimulación de colágeno", 
            "Inyectables con ácido hialurónico",
            "Inyectables para líneas de expresión y arrugas",
        ],
        "contacts":[
            {social:"Web", text:"", link:"http://drsergioverduzco.com"},
            {social:"Facebook", text:"", link:"https://www.facebook.com/pg/drsergioverduzco"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/drsergioverduzco/"}
        ]
    },
    {
        "name":"Dr. Jorge Ávila",
        "address":"Calle 26 199, Altabrisa, 97130, Mérida, Yucatán, México",
        "phone": "999 285 5447",
        "lat":21.0162086,
        "lng":-89.5849762,
        "tratments":[
            "Ultrasonido micro-focalizado para lifting",
            "Bio-estimulación de colágeno", 
            "Inyectables con ácido hialurónico",
            "Inyectables para líneas de expresión y arrugas",
        ],
        "contacts":[
            {social:"Facebook", text:"", link:"https://www.facebook.com/medicinaesteticamx/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/dr.jorgeavila"}
        ]
    }, 
    {
        "name":"Bettina Guisa - Experta en Wellness",
        "tratments":[
            "Entrenamiento funcional",
            "Empoderamiento a través del wellness",
            "Rutinas digitales y experiencias vivenciales",
        ],
        "contacts":[
            {social:"Web",text:"",link:"https://www.theempowerprojectt.com/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/theempowerprojectt/"},
            {social:"Instagram",text:"",link:"https://www.instagram.com/bettinaguisa/"}
        ]
    },
    {
        "name":"Daniela Méndez - Terapeuta ocupacional",
        "address":"danielamendezto@gmail.com",
        "phone":"55 8066 0833",
        "tratments":[
        ],
        "contacts":[
            {social:"Instagram", text:"", link:"https://www.instagram.com/danymdzazuela/"}
        ]        
    },
    {
        "name":"Dra. Julia Salinas - Médico y nutrióloga",
        "email":"hola@juliasalinasducker.com",
        "address":"Naucalpan de Juárez 53830, Estado de México",
        "phone":"+52 1 55 1189 2941",
        "lat":19.4320571,
        "lng":-99.2562431,
        "tratments":[
            "Master en Nutrición Clínica",
            "Tallerista en temas relacionados con la salud y nutrición",
            "Capacitaciones y asesorías nutrimentales"
        ],
        "contacts":[
            {social:"Web",text:"",link:"http://www.juliasalinasducker.com/bio.php"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/antojodiabolico/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/danymdzazuela/"}
        ]
    },
    {
        "name":"Fátima González Franco Haghenbeck - Coach de finanzas personales y seguros",
        "address":"finanzasconfa@hotmail.com",
        "tratments":[
            "Asesoría de finanzas personales",
            "Cursos para empresas",
            "Seguros personales (gastos médicos, vida, ahorro y retiro)"
        ],
        "contacts":[
            {social:"Facebook",text:"",link:"https://www.facebook.com/finanzasconfa"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/finanzasconfa/"}
        ]
    },
    {
        "name":"Chantal Escartín - Soy Mamá Feminista",
        "email":"chantal.escartin@gmail.com",
        "address":"",
        "phone":"55 4866 6273 ",
        "tratments":[
            "Master en Nutrición Clínica",
            "Tallerista en temas relacionados con la salud y nutrición",
            "Capacitaciones y asesorías nutrimentales"
        ],
        "contacts":[
            {social:"Facebook",text:"",link:"https://www.facebook.com/SoyMamaFeminista"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/soymamafeminista/"}
        ]
    },    
    {
        "name":"Dra. Celeste Campos",
        "email":"hola@cherish.mx",
        "address":"",
        "phone":"55 2713 3264",
        "tratments":[
            "Psicóloga",
            "Sexóloga",
            "Consejera en VIH e ITS",
            "Tallerista docente en sexualidad",
            "Asesora de contenidos dedicados a la exploración y autoerotismo mediante juguetes sexuales",
            "Acompañamiento psicoterapéutico en salud sexual y reproductiva con perspectiva de género"
        ],
        "contacts":[
            {social:"Web",text:"",link:"https://www.cherish.mx/?fbclid=IwAR0eiEdT3XNxAUJkAPQcWSK8jazuGYP1vWZXKK0D9hnFULpGa9SUi78qaqg"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/groups/926261927932890/?ref=share"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/cherish.mx/"}
        ]
    },
    {
        "name":"Dra. Silvia Patricia González Carmona",
        "email":"s.p.gonzales.carmona@gmail.com",
        "address":"Durango 290, consultorio 501. Col Roma Norte. Alcaldía Cuauhtémoc. CP. 06700. CDMX",
        "phone":"55 5965 1123",
        "lat":19.4125084,
        "lng":-99.1747708,
        "tratments":[
            "Médico Cirujano",
            "Especialidad  en Neurología y Neurofisiología"
        ],
        "contacts":[
            {social:"Web",text:"",link:"https://grupomedicoromacondesa.com/neurologia/dra-silvia-patricia-gonzalez-carmona/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/Grupo-M%C3%A9dico-Roma-Condesa-A-C-172462576675884/"},
        ]
    },
    {
        "name":"Karla Lara - Coach de vida",
        "email":"contacto@karlalara.mx",
        "address":"Calzada desierto de los Leones 5903, Lomas de Los Ángeles Tetelpan, Álvaro Obregón, CDMX, C.P. 01790",
        "phone":"55 2910 0162",
        "lat":19.3423739,
        "lng":-99.2282223,
        "tratments":[
            "Coach de vida, parejas y empresarial",
            "Especialista en inteligencia emocional, inteligencia social y en conductas humanas",
            "Creadora del podcast extraordinario",
            "Especialista en ciencias de la felicidad",
            "Master y coach"
        ],
        "contacts":[
            {social:"Web",text:"",link:"https://karlalara.mx/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/KarlaLaraCoach/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/karlalaracoach"}
        ]
    },
    {
        "name":"Karla Mamá de 4 ",
        "email":"karlalara70@gmail.com",
        "address":"",
        "phone":"55 1810 7031",
        "tratments":[
            "Educadora perinatal, conferencista y podcaster",
            "Colaboradora editorial y blogger",
            "Centrada en Temas de: familia, educación, juego, crianza, libros y contenidos femeninos",
            "Colaboradora en la radio y televisión"
        ],
        "contacts":[
            {social:"Facebook",text:"",link:"https://www.facebook.com/KarlaDoula/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/karlamamadecuatro/"}
        ]
    },
    {
        "name":"Tania Arredondo",
        "email":"contacto@cuidatesiempremx.com",
        "phone":"55 8757 9038",
        "tratments":[
            "Actriz",
            "Conductora",
            "Mamá y esposa apasionada de la belleza",
            "Creadora de Cuidate Siempre Mx:un espacio en el que comparte su filosofía de vida",
            "Lifestyle & beauty influencer"
        ],
        "contacts":[
            {social:"Web",text:"",link:"https://cuidatesiempremx.com/?fbclid=IwAR0O1vhQ6nMZuD9U3thvXhxy9bgae3pQFVdzkn49W4Bphpo89ZY5L_ebsm4"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/taniaarredondoactriz/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/Cuidatesiempremx/"},
            {social:"Instagram", text:"", link:"https://instagram.com/taniaarredondo"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/cuidatesiempremx/"}
        ]
    },   
    {
        "name":"Dr. José Cortés",
        "email":"doctor@josecortes.com",
        "address":"Av. Baja California 261, Piso 7 Col. Condesa 06100 Ciudad de México, México",
        "phone":"55 8580 7556",
        "lat":19.4061915,
        "lng":-99.1724427,
        "tratments":[
            "Cirujano Estético Craneofacial",
            "Miembro del Comité Científico de la Asociación Mexicana de labio paladar Hendido y Anomalías Craneofaciales",
            "Es ponente Nacional e Internacional en procedimientos estéticos faciales"
        ],
        "contacts":[
            {social:"Web",text:"",link:"http://www.josecortes.com/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/cirujanoJoseCortes/"},
            {social:"Instagram", text:"", link:"https://instagram.com/dr.josecortes"}
        ]
    },
    {
        "name":"Mónica León",
        "email":"Monica.leon@merz.com.mx",
        "address":"Salomé Piña #57 Col. San José Insurgentes. 03900 Ciudad de México, México",
        "phone":"55 6132 7993",
        "lat":19.3677814,
        "lng":-99.1877893,
        "tratments":[
            "Psicóloga educativa",
            "Brinda atención psicoeducativa a personas con enfermedades genéticas de baja prevalencia en  México y Guatemala",
            "Diplomado de Terapia Narrativa en la División de Educación C"
        ],
        "contacts":[
            {social:"Web",text:"",link:"https://conversacionesconstructivas.com/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/Conversaciones-Constructivas-103102395130767/"},
            {social:"Instagram", text:"", link:"https://instagram.com/conversacionesconstructivas?igshid=n1e4vpabk0kr"}
        ]
    },
    {
        "name":"Paola Bustani",
        "email":"pbustani@gmail.com",
        "address":"Cd. Guatemala 28 avenida A,5-48, Col. San Lázaro, Zona 15",
        "phone":"50 2468 37914",
        "lat":14.5872037,
        "lng":-90.4858776,
        "tratments":[
            "Psicóloga clínica y terapeuta sistémica-relacional",
            "Brinda terapias online y presencial ",
            "Blogger que ayuda a crecer en consciencia y herramientas para el bienestar humano"
        ],
        "contacts":[
            {social:"Facebook",text:"",link:"https://www.facebook.com/psic.bustani/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/psic.bustani/"}
        ]
    },    
    {
        "name":"Paulina Gómez Mascarell",
        "email":"pgomezm@revistacentral.mx",
        "address":"Periférico Sur 4121 Col. Fuentes del Pedregal",
        "phone":"55 3043 6397",
        "lat":19.305819,
        "lng":-99.2115422,
        "tratments":[
            "Diseñadora Gráfica",
            "Más de 15 años trabajando en la industria editorial",
            "Dirige actualmente Revista Central de Grupo Salinas en su formato digital."
        ],
        "contacts":[
            {social:"Web",text:"",link:"https://www.revistacentral.com.mx/contacto"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/pgommasc/"}
        ]
    },
    {
        "name":"Claudia Padilla",
        "email":"claupadillag@gmail.com",
        "address":"",
        "phone":"55 6132 7993",
        "tratments":[
            "Eco blogger",
            "Diseñadora gráfica",
            "Vegetariana y zero waster"
        ],
        "contacts":[
            {social:"Web",text:"",link:"https://linktr.ee/claugoesgreen"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/claugoesgreen/"}
        ]
    },        
    {
        "name":"Ana Paula Alvarado",
        "email":"anapaula_alvarado@hotmail.com",
        "address":"v. Santa Úrsula Mz. 843 Lt 4 Col. Santa Úrsula Coapa. México, D.F.",
        "phone":"55 4062 2920",
        "lat":19.309869,
        "lng":-99.1578965,
        "tratments":[
            "Socia y fundadora de HK CERO",
            "Zero Waster:reciclaje, sustentabilidad y conexión con el medio ambiente",
            "Amante de la naturaleza",
            "Proteccionista independiente",
            "Apasionada en trabajar a favor de los derechos animales y por la conservación del medio ambiente"
        ],
        "contacts":[
            {social:"Web",text:"",link:"https://www.hunabkunan.com/"},
            {social:"Web",text:"",link:"https://www.cosmeticasolida.com/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/hunabkunan/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/anapauguera/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/hk_cero/"}
        ]
    },
    {
        "name":"Paulina Jiménez",
        "email":"paulinajimenezg@hotmail.com",
        "address":"v. Santa Úrsula Mz. 843 Lt 4 Col. Santa Úrsula Coapa. México, D.F.",
        "phone":"",
        "lat":19.309869,
        "lng":-99.1578965,
        "tratments":[
            "Socia y fundadora de HK CERO",
            "Zero Waster:reciclaje, sustentabilidad y conexión con el medio ambiente",
            "Amante y experta de la relación belleza-naturaleza ",
            "Certificada en cosmética natural y cosmética sólida"
        ],
        "contacts":[
            {social:"Web",text:"",link:"https://www.hunabkunan.com/"},
            {social:"Web",text:"",link:"https://www.cosmeticasolida.com/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/hunabkunan/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/pauhkunan/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/hk_cero/"}
        ]
    },
    {
        "name":"Giovanna Massad Soda",
        "email":"giomassadsoda@gmail.com",
        "phone":"N/A",
        "tratments":[
            "Licenciada en Imagología",
            "Coach certificada en el modelo MSMC",
            "Perito en Grafología",
            "Especializta en área de lenguaje corporal específicamente en detección de mentiras y lectura de rostro",
            "Experta en creación y desarrollo de imagen.",
            "Experta en construcción de estímulos para generar consecuencias en públicos objetivos."
        ],
        "contacts":[
            {social:"Facebook",text:"",link:"https://m.facebook.com/profile.php?id=100057615422426&_rdr"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/giovannamassadsoda/"},
        ]
    },
    {
        "name":"Jenniffer Delgado",
        "email":"organicalux@gmail.com",
        "phone":"55 9163 5717",
        "tratments":[
            "Chef",
            "Psicóloga",
            "Fundadora y directora general de Anima Lux, empresa enfocada a la educación sobre alimentación",
            "Tallerista y conferencista en Anima Lux",
            "Experta en cocina y repostería sin gluten",
            "Escritora del libro \"Cocina rica & sana.\"",
            "Especialista en aplicación de nutrición ortomolecular, en psicología de la alimentación a la cocina y tratamientos alimenticios integrales."
        ],
        "contacts":[
            {social:"Web",text:"", link:"http://animalux.net/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/animaluxmx/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/jennjoydelgado/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/animaluxmx/"},
        ]
    },   
    {
        "name":"Sofía Domínguez",
        "email":"sofia.dominguez@condenast.com.mx",
        "address":"Calle Montes Urales 415, Lomas - Virreyes, Lomas de Chapultepec, Miguel Hidalgo, 11000 Ciudad de México, CDMX",
        "phone":"N/A",
        "lat":19.4285705,
        "lng":-99.2093543,
        "tratments":[
            "Apasionada del glamour y los medios digitales",
            "Editora Digital en Glamour México 2021"
        ],
        "contacts":[
            {social:"Web",text:"",link:"https://www.glamour.mx/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/GlamourMexicoLatinoamerica"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/sofiadmora/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/glamourmexico/"}
        ]
    },
    {
        "name":"Wendy Abascal",
        "email":"wendy.ibelong@gmail.com ",
        "phone":" +1 (917) 972 2107",
        "tratments":[
            "Ejecutiva en Estrategias de Mercadotecnia y Negocios",
            "Wellness blogger",
            "Certificada en Fashion & Sustainability por el London College of Fashion & Kering Group",
            "Fundadora de I Belong "
        ],
        "contacts":[
            {social:"Facebook",text:"",link:"https://www.facebook.com/I-Belong-102111168638812"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/i.belong_"}
        ]
    },
    {
        "name":"Álvaro González Martínez de Lecea",
        "email":"relacionespublicas@colegiolakeside.com",
        "address":"Laguna Yuriria 55, Granada, Miguel Hidalgo, 11520 Ciudad de México, CDMX",
        "phone":"55 2155 9978",
        "lat":19.4418469,
        "lng":-99.1950575,
        "tratments":[
            "Experto en aprendizaje colaborativo", 
            "Certificado por el Cooperative Learning Center de Minnesota",
            "Miembro del Centro Internacional de Aprendizaje Cooperativo.",
            "Experto en aprendizaje colaborativo"
        ],
        "contacts":[
            {social:"Web",text:"",link:"https://colegiolakeside.edu.mx/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/colegiolakesideoficial/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/lakesidecolegio/"}
        ]
    },
    {
        "name":"Daniela Amkie",
        "email":"daniela.amkie@gmail.com",
        "phone":"55 4866 6766",
        "tratments":[
            "Yoga blogger"
        ],
        "contacts":[
            {social:"Facebook",text:"",link:"https://www.facebook.com/daniela.amkie"},
            {social:"Instagram", text:"", link:" https://www.instagram.com/danielaamkie/ "}
        ]
    },    
    {
        "name":"Dr. Harold Dent Alvarado",
        "email":"dr.haroldent@gmail.com",
        "address":"Av. Palmira 600 int 315 Col. Villas del Pedregal 78218 San Luis Potosí - San Luis Potosí, Mexico",
        "phone":"44 4123 2550 - 44 4338 7881",
        "lat":22.1254026,
        "lng":-101.0247587,
        "tratments":[
            "Médico cirujano",
            "Especialista en Cirugía Plástica, Estética y Reconstructiva de Alto Nivel",
            "Forma parte del cuadro médico de importantes instituciones médicas como HAMA Clinic",
            "Experto en Rostro, Abdomen y Liposucción. "
        ],
        "contacts":[
            {social:"Facebook",text:"",link:"https://www.facebook.com/HamaClinicByHaroldent"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/dr.harold_dent/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/hamaclinic//"}            
        ]
    },
    {
        "name":"Grace Ibarra",
        "email":"grace@habitosinteligentes.com",
        "address":"Calle Río Colorado 206 Local 15 Colonia Del Valle San Pedro Garza Garcia, Nuevo León",
        "phone":"81 1629 4931 - 81 1629 4931",
        "lat":19.493145,
        "lng":-99.2954654,
        "tratments":[
            "Nutrióloga especialista en educación nutricional",
            "Trabaja con programas de Salud y Bienestar para empresas como Banregio, Whirlpool, Acosta Verde, Citrofrut.",
            "Certificada en Descodificación Biológica por el Instituto Ángeles Wolder"
        ],
        "contacts":[
            {social:"Facebook",text:"",link:"https://www.facebook.com/habitosinteligentes"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/habitosinteligentes/"},      
        ]
    },
    {
        "name":"Karen Rodarte",
        "email":"Karen@paipai.mx",
        "address":"Félix Cuevas 326, Tlacoquemecatl del Valle Benito Juárez 03200 Benito Juárez, CDMX",
        "phone":"N/A",
        "lat":19.3730277,
        "lng":-99.1768881,
        "tratments":[
            "Co-CEO & Co-founder de Pai Pai"
        ],
        "contacts":[
            {social:"Web", text:"", link:"https://paipai.mx/?fbclid=IwAR0Wbj4J9WZf-yXcOQV58oJEQ-EaNPMh5h99EijfjhCiDFg21-ON-6HXRQ8"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/paipaimx"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/rodartekaren/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/paipaimx/"},
        ]
    },
    {
        "name":"Martha Castañeda- La nonna Fit",
        "email":"m.castaneda@botanicalsint.com",
        "address":"",
        "phone":" 55 9197 4912",
        "tratments":[
            "Maratonista, madre y  abuela",
            "Administradora en un consorcio de 7 empresas"
        ],
        "contacts":[
            {social:"Instagram", text:"", link:"https://www.instagram.com/lanonnafit/"},
        ]
    },  
    {
        "name":"Miguel (Un papá mexicano)",
        "email":"angelrebolledo@hotmail.es",
        "address":"Félix Cuevas 326, Tlacoquemecatl del Valle Benito Juárez 03200 Benito Juárez, CDMX",
        "phone":"N/A",
        "tratments":[
            "Ingeniero Químico",
            "Trabajador en la Industria Energética",
            "Papá de tiempo completo",
            "Dad blogger"
        ],
        "contacts":[
            {social:"Facebook",text:"",link:"https://www.facebook.com/unpapamexicano"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/unpapamexicano/"},
        ]
    },    
    {
        "name":"Dra. María Lozada",
        "email":"maria_lozadaarenas@hotmail.com",
        "address":"Calle Jojutla 53-A Col. Condesa Del. Cuauhtémoc, D.F. C.P. 06140  ",
        "phone":"55 3966 1077",
        "lat":19.4130432,
        "lng":-99.1776178,
        "tratments":[
            "Médico estético",
            "Amante de la medicina integral",
            "Master en Ozonoterapia y Microbiota intestinal"
        ],
        "contacts":[
            {social:"Web", text:"", link:"https://cliniquedozono.com.mx/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/Cliniqueozono"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/dra.marialozada/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/clinicasdeozono/"},
        ]
    },
    {
        "name":"Marta Ro",
        "email":"martharo@thewhyelement.com",
        "phone":"55 6628 8032",
        "tratments":[
            "Mindset coach",
            "Speaker mexicana",
            "Experta en conocimientos profundos y expertise en mindset coaching",
            "Coaching ontológico, transformacional y de potencial humano"
        ],
        "contacts":[
            {social:"Web", text:"", link:"https://www.martarocoach.com/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/martarocoach"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/martarocoach/"},
        ]
    },
    {
        "name":"Ceclia Maafs",
        "email":"hola@ceciliamaafs.com",
        "phone":"N/A",
        "tratments":[
            "Ilustradora y artista mexicana",
            "Art blogger"
        ],
        "contacts":[
            {social:"Web", text:"", link:"https://ceciliamaafs.com/work/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/cecilia.maafs"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/cecilia.maafs/"},
        ]
    },
    {
        "name":"Héctor Cruzado",
        "email":"isra@zoewater.com.mx",
        "address":"Av. Insurgentes Sur 1216 Despacho 405 Col, Tlacoquemecatl del Valle, Benito Juárez, 03200 CDMX",
        "phone":"55 3647 8006",
        "lat":19.376431,
        "lng":-99.177835,
        "tratments":[
            "Fundador de ZoeWater",
            "Forma parte de la lista de las 30 promesas de los negocios de la revista Forbes en 2016",
            "Conferencista en temas de emprendimiento"
        ],
        "contacts":[
            {social:"Web", text:"", link:"https://zoewater.com.mx/?utm_source=RRSS_Org%C3%A1nico&utm_medium=Instagram&utm_content=LINK%20BIO"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/zoewater/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/chavoruqo/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/zoewater/?hl=es-la"},
        ]
    },
    {
        "name":"José Pablo Zavala",
        "email":"josepablo.lopez@mumbii.com",
        "address":"Unnaed Road, Ciudad de México, CDMX, M, Ciudad de México, CDMX",
        "phone":"55 3731 7102",
        "lat":19.2248183,
        "lng":-99.2386219,
        "tratments":[
            "Entrenador canino",
            "Fundador de Mumbii Training"
        ],
        "contacts":[
            {social:"Web", text:"", link:"https://mumbii.com/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/mumbiiresort"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/jpabuelo/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/mumbii_resort/?hl=es-la"},
        ]
    },
    {
        "name":"Pol Martínez Peredo",
        "email":"polmartinezperedo@gmail.com",
        "address":"Voces en Tinta, Calle Niza 23, 06600 Ciudad de México, México",
        "phone":"55 1022 7756",
        "lat":19.4272587,
        "lng":-99.1652681,
        "tratments":[
            "Maestro en sexología y sensibilización.",
            "Manejo de grupos en el Instituto Mexicano de Sexología IMESEX. "
        ],
        "contacts":[
            {social:"Web", text:"", link:"https://www.musasdemetal.org/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/musasdemetalgay/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/musasdemetal/"},
        ]
    },
    {
        "name":"Elsa Rentería - PrincElsa",
        "address":"Real de Reyes 325 Coyoacán 04330 Ciudad de México, México",
        "phone":"55 6229 1718",
        "lat":19.3371756,
        "lng":-99.1530578,
        "tratments":[
            "Mommy blogger mexicana",
            "Conductora de radio y televisión"
        ],
        "contacts":[
            {social:"Instagram", text:"", link:"https://www.instagram.com/princelsa/"},
        ]
    },
    {
        "name":"Natalia Nallino",
        "email":"hola@sersana.com",
        "address":"Perif. Sur 3720, Jardines del Pedregal, Álvaro Obregón, 01900 Ciudad de México, CDMX",
        "phone":"55 7090 2132",
        "lat":19.3136565,
        "lng":-99.2215352,
        "tratments":[
            "Sersana trainer",
            "Psicoterapia en biodinámica corporal"
        ],
        "contacts":[
            {social:"Web", text:"", link:"https://sersana.com/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/SersanaMethod/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/natnallino/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/sersanamethod/"},
        ]
    },
    {
        "name":"Daniel Vásquez ",
        "email":"hola@danielvasquez.com.mx",
        "phone":"N/A",
        "tratments":[
            "Asesor de imagen",
            "Asistente de moda",
            "Fashion Producer y Celebrity Stylist",
            "Editor de la revista Bad Hombre 2021"
        ],
        "contacts":[
            {social:"Web", text:"", link:"https://danielvasquez.com.mx/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/danielvasquez95"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/badhombremag/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/danielvasquez.g/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/badhombremag/"},
        ]
    },
    {
        "name":"Pei Garza",
        "email":"contacto@peigarza.net",
        "phone":"N/A",
        "tratments":[
            "Locutor de radio",
            "Conductor de televisión",
            "Conferencista",
            "Influencer y emprendedor."
        ],
        "contacts":[
            {social:"Web", text:"", link:"https://peigarza.com.mx/"},
            {social:"Facebook",text:"",link:"https://m.facebook.com/PeiGarza"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/peigarza/?hl=es"},
        ]
    },
    {
        "name":"Dr. Gustavo Jimenez",
        "email":"drgustavojimenezml@gmail.com",
        "address":"Calle Hidalgo 311 Calle Hidalgo 311, Obregon, 37320 León, Gto.",
        "phone":"+52 47 7713 2835",
        "lat":21.12563,
        "lng":-101.681554,

        "tratments":[
            "Belotero",
            "Radiesse",
            "Xeomeen",
            "Cirujano Plástico con una Subespecialidad en cirugía plástica, estética y reconstructiva",
        ],
        "contacts":[
            {social:"Web", text:"", link:"https://phiaesthetics.com.mx/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/gustavo.jimenezmunozledo"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/dr.gustavojimenez/?hl=es"},
        ]
    },
    {
        "name":"Luis Feria ",
        "email":"feriarodriguez@yahoo.com.mx",
        "address":"Medellín No. 263 Roma Sur, CDMX",
        "phone":"+52 222 617 9203",
        "lat":19.4081354,
        "lng":-99.1661614,

        "tratments":[
            "Licenciado en Comercio Internacional", 
            "Barista", 
            "Amante y promotor del café de Puebla" 
        ],
        "contacts":[
            {social:"Web", text:"", link:"https://mascafemexico.mx/"},
            {social:"Facebook",text:"",link:"https://www.facebook.com/mascafemexico/"},
            {social:"Instagram", text:"", link:"https://www.instagram.com/feria.l/?utm_medium=copy_link"},
        ]
    },
]